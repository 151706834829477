import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GridDataService } from '../../../shared/services/grid-data.service';
import { State } from '@progress/kendo-data-query';
import { AppConfig } from '../../../app.config';
import { VesselEditorModel, VesselStatusModel, VesselClassModel } from '../models/vessel.models';
import { IKendoGridComponent } from '../../../shared/utils/kendo.utils';

@Injectable()
export class VesselService {
  readonly baseUrl: string = `${AppConfig.settings.apiUrl}/vessels`;
  readonly gridName: string = 'vessels';

  constructor(
    private http: HttpClient,
    private gridData: GridDataService
  ) { }

  getGridData(state: State, filters?: any) {
    return this.gridData.getGridData(this.gridName, this.baseUrl, state, filters);
  }

  get(id: number) {
    return this.http.get<VesselEditorModel>(id ? `${this.baseUrl}/${id}/edit` : `${this.baseUrl}/edit`);
  }

  update(documentType: VesselEditorModel) {
    const url = !!documentType.id ? `${this.baseUrl}/${documentType.id}/edit` : `${this.baseUrl}/edit`;
    return this.http.post(url, documentType);
  }

  getClassOptions() {
    return this.http.get<VesselClassModel[]>(`${this.baseUrl}/edit/classoptions`);
  }

  getStatusOptions() {
    return this.http.get<VesselStatusModel[]>(`${this.baseUrl}/edit/statusoptions`);
  }
}
