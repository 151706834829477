
import {forkJoin as observableForkJoin,  Observable } from 'rxjs';

import {finalize} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { RevisionEditorModel } from '../../models/revision.models';
import { RevisionService } from '../../services/revision.service';
import { RevisionReasonModel } from '../../../setup/revision-reasons/models/revision-reason.models';
import { IValidatable, ValidationResultModel } from '../../../shared/models/validation.models';
import { ILoadable } from '../../../shared/components/loading/loading.models';

@Component({
  selector: 'app-document-add-revision-form',
  templateUrl: './document-add-revision-form.component.html',
  styleUrls: ['./document-add-revision-form.component.scss']
})
export class DocumentAddRevisionFormComponent implements OnInit, IValidatable, ILoadable {
  loading: boolean;
  hasLoaded: boolean;
  succeeded: boolean;
  validationResult: ValidationResultModel;
  editor: RevisionEditorModel = new RevisionEditorModel();
  reasonOptions: RevisionReasonModel[] = [];
  previousRevisionIdentifier: string;
  editMode: boolean = false;
  defaultReasonItem = { id: null, name: 'None' };

  constructor(
    private revisionService: RevisionService
  ) { }

  ngOnInit() {
    this.editMode = this.editor.id != null;
    this.loading = true;

    if (this.editMode) {
      observableForkJoin(
        this.revisionService.get(this.editor.documentId, this.editor.id),
        this.revisionService.getReasonOptions(),
      ).pipe(finalize(() => {
        this.hasLoaded = true;
        this.loading = false
      })).subscribe(results => {
        let editor = results[0];
        editor.revisionDate = editor.revisionDate ? new Date(editor.revisionDate) : new Date();
        editor.reasonId = results[0].reasonId;
        this.reasonOptions = results[1];
        this.editor = editor;
      });
    }
    else {
      observableForkJoin(
        this.revisionService.get(this.editor.documentId),
        this.revisionService.getReasonOptions(),
        this.revisionService.getPreviousForDocument(this.editor.documentId)
      ).pipe(finalize(() => {
        this.hasLoaded = true;
        this.loading = false
      })).subscribe(results => {
        let editor = results[0];
        editor.revisionDate = new Date();
        this.editor = editor;

        this.reasonOptions = results[1];

        if (results[2])
          this.previousRevisionIdentifier = results[2].identifier;
      });
    }
  }
}
