
import {finalize} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { ValidationResultModel, IValidatable } from '../../../../shared/models/validation.models';
import { ILoadable } from '../../../../shared/components/loading/loading.models';
import { RevisionReasonEditorModel, RevisionReasonInstancePermissionsModel } from '../../models/revision-reason.models';
import { ActivatedRoute, Router } from '@angular/router';
import { RevisionReasonService } from '../../services/revision-reason.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-revision-reason-edit',
  templateUrl: './revision-reason-edit.component.html',
  styleUrls: ['./revision-reason-edit.component.scss']
})
export class RevisionReasonEditComponent implements OnInit, ILoadable, IValidatable {
  validationResult: ValidationResultModel;
  hasLoaded: boolean = false;
  loading: boolean = false;
  title: string;
  updated: boolean = false;
  editor: RevisionReasonEditorModel = new RevisionReasonEditorModel();
  permissions: RevisionReasonInstancePermissionsModel;
  isEditMode: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private revisionReasonService: RevisionReasonService
  ) { }

  ngOnInit() {
    this.loading = true;

    this.route.params.subscribe(params => {
      const id = params['id'];

      if(id)
        this.isEditMode = true;

      this.revisionReasonService.get(id).pipe(
        finalize(() => {
          this.hasLoaded = true;
          this.loading = false;
        }))
        .subscribe(editor => {
          this.editor = editor;
          if (!this.editor.description)
          this.editor.description = ""; // need to define description here so that keydown enter will work (see html for description textarea section)
          if (!editor.id)
          editor.active = true;
          
          this.permissions = editor.permissions;

          this.title = id ? (this.permissions.canEdit ? 'Edit' : 'View') + ' Revision/Issue Reason' : 'Revision/Issue Reason Setup';
        });
    });
  }

  submit($event: any) {
    this.validationResult = null;
    this.loading = true;
    this.revisionReasonService.update(this.editor).pipe(
      finalize(() => this.loading = false))
      .subscribe(() => {
        this.updated = true;
        setTimeout(() => {
          this.router.navigateByUrl('/setup/revisionreason');
        }, 1500);
      }, err => {
        if (err instanceof HttpErrorResponse && err.status == 412) {
          this.validationResult = err.error;
        }
      })
  }

  cancel(){
    this.router.navigateByUrl("/setup/revisionreason");
  }
}
