
import {of as observableOf,  Observable } from 'rxjs';

import {mergeMap} from 'rxjs/operators';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { PermissionService } from "../services/permission.service";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private permissionService: PermissionService) { }

    private permissionChecks: { [prefix: string]: (SystemPermissions) => boolean } = {
      '/documents': s => s.documents.canAccess,
      '/import': s => s.historical.canAccess,
      '/transmittals': s => s.transmittals.canAccess || s.externalTransmittals.canAccess,
      '/transmittals/external': s => s.externalTransmittals.canAccess,
      '/setup/audit': s => s.auditing.canAccess,
      '/setup/document/type': s => s.documentTypes.canAccess,
      '/setup/projects': s => s.projects.canAccess,
      '/setup/revisionreason': s => s.revisionReasons.canAccess,
      '/setup/sources': s => s.sources.canAccess,
      '/setup/templates': s => s.templates.canAccess,
      '/setup/users': s => s.users.canAccess,
      '/setup/vessels': s => s.vessels.canAccess
    }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    return this.permissionService.loadPermissions().pipe(mergeMap(result =>
    {
      const permissions = this.permissionService.getPermissions();

      for (const prefix in this.permissionChecks) {
        if (!state.url.startsWith(prefix))
          continue;

        const check = this.permissionChecks[prefix];

        if (!check(permissions)) {
          this.router.navigate(['error', { title: 'Access Denied', message: 'Restricted content' }], {
            skipLocationChange: true
          });

          return observableOf(false);
        }
      }

      return observableOf(true);
    }));
  }
}
