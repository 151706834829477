import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/modules/shared.modules';
import { GridModule } from '@progress/kendo-angular-grid';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HistoricalDocumentsGridComponent } from '../components/historical-documents-grid/historical-documents-grid.component';
import { HistoricalDocumentService } from '../services/historical-document.service';
import { DocumentModule } from '../../documents/modules/document.module';
import { ImportRouterModule } from './import.router.module';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { UploadModule } from '@progress/kendo-angular-upload';
import { ImportDocumentsComponent } from '../components/import-documents/import-documents.component';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { HttpClientModule } from '@angular/common/http';
import { HistoricalDocumentDeleteComponent } from '../components/historical-document-delete/historical-document-delete.component';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { DateInputsModule } from "@progress/kendo-angular-dateinputs";
import { ViewDetailsComponent } from '../components/view-details/view-details.component';
import { HistoricalDocumentDeleteAllImportComponent } from '../components/historical-document-delete-all-import/historical-document-delete-all-import.component';


@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    DateInputsModule,
    GridModule,
    ButtonsModule,
    DropDownsModule,
    DialogModule,
    UploadModule,
    SharedModule,
    DocumentModule,
  ],
  declarations: [
    HistoricalDocumentsGridComponent,
    ImportDocumentsComponent,
    HistoricalDocumentDeleteComponent,
    ViewDetailsComponent  ,
    HistoricalDocumentDeleteAllImportComponent
  ],
  entryComponents: [
    HistoricalDocumentDeleteComponent
  ],
  providers: [
    HistoricalDocumentService
  ],
  exports: [
    ImportRouterModule
  ]
})
export class ImportModule { }
