import { Component, OnInit, Input, Output } from '@angular/core';
import { DocumentService } from '../../services/document.service';
import { ILoadable } from '../../../shared/components/loading/loading.models';
import { IKendoGridComponent, KendoColumnResizeArgs } from '../../../shared/utils/kendo.utils';
import { State } from '@progress/kendo-data-query';
import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { DocumentGridRowModel } from '../../models/document.models';
import { Subject } from 'rxjs';
import { PermissionService } from '../../../shared/services/permission.service';
import { SystemPermissions } from '../../../shared/models/permissions.models';
import { GridColumnState } from '../../../shared/models/grid.models';
import { GridDataService } from '../../../shared/services/grid-data.service';
import {finalize} from 'rxjs/operators';

@Component({
  selector: 'app-document-links-grid',
  templateUrl: './document-links-grid.component.html',
  styleUrls: ['./document-links-grid.component.scss']
})
export class DocumentLinksGridComponent implements OnInit, ILoadable, IKendoGridComponent {
  @Input('documentId') documentId: number;
  @Output() success: Subject<any> = new Subject();
  @Output('countChange') countChange: Subject<number> = new Subject();

  hasLoaded: boolean;
  loading: boolean;
  systemPermissions: SystemPermissions;
  state: State = {
    skip: 0,
    take: 5
  };
  gridData: GridDataResult;

  columnState: GridColumnState = new GridColumnState([
    { name: 'Document Number', field: 'identifier', defaults: { width: 185 } },
    { name: 'Filename', field: 'filename', defaults: { width: 225 } },
    { name: 'Title', field: 'latestRevisionTitle', defaults: { width: 135 } },
    { name: 'Id No\'s', field: 'categoryProjectsCombined', defaults: { width: 135 } },
    { name: 'Vessels', field: 'vesselNames', defaults: { width: 135 } },
    { name: 'Doc Type', field: 'typeAcronym', defaults: { width: 85 } },
    { name: 'Source', field: 'sourceAcronym', defaults: { width: 135 } },
  ]);

  constructor(
    private documentService: DocumentService,
    private permissionService: PermissionService,
    private gridDataService: GridDataService
  ) { }

  ngOnInit() {
    this.systemPermissions = this.permissionService.getPermissions();

    this.gridDataService.initColumnState(this);
    this.gridDataService.applyCachedState('documentLinks', this);

    this.updateGrid();
  }

  dataStateChange(state: DataStateChangeEvent): void {
    this.loading = true;
    this.state = state;
    this.updateGrid();
  }

  unlink(item: DocumentGridRowModel) {
    this.documentService.unlink([this.documentId, item.id])
      .subscribe(res => {
        this.success.next();
        this.updateGrid();
      });
  }

  resetColumnState() {
    this.gridDataService.resetColumnState('documentLinks', this);
  }

  resized(changes: KendoColumnResizeArgs[]) {
    const change = changes[0];
    const col = this.columnState.selected.find(c => c.field == change.column.field);
    col.width = change.newWidth;
    this.gridDataService.saveColumnState('documentLinks', this, true);
  }

  updateGrid() {
    this.loading = true;
    this.documentService.getLinkedGridData(this.state, this.documentId, false).pipe(
      finalize(() => {
        this.hasLoaded = true;
      }))
      .subscribe(data => {
        this.gridData = data;
        this.loading = false;
        this.countChange.next(data.total);
      });
  }
}
