import {NgModule} from '@angular/core';
import {NavComponent} from '../components/layout/nav/nav.component';
import {FooterComponent} from '../components/layout/footer/footer.component';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {AuthInterceptor} from '../utils/auth.interceptor';
import {LoadingComponent} from '../components/loading/loading.component';
import {ErrorComponent} from '../components/error/error.component';
import {AuthGuard} from '../utils/auth.guard';
import {CommonModule} from '@angular/common';
import {PageTitleComponent} from '../components/page-title/page-title.component';
import {RouterModule} from '@angular/router';
import {GridDataService} from '../services/grid-data.service';
import {TickCrossComponent} from '../components/tick-cross/tick-cross.component';
import {ValidationSummaryComponent} from '../components/validation-summary/validation-summary.component';
import {ClipboardButtonComponent} from '../components/clipboard-button/clipboard-button.component';
import {ActionSheetComponent} from '../components/action-sheet/action-sheet.component';
import {ClickOutsideDirective} from '../directives/click-outside/click-outside.directive';
import {PermissionService} from '../services/permission.service';
import {SharedRouterModule} from './shared.router.module';
import {SecurityService} from '../services/security.service';
import {ActionSheetService} from '../components/action-sheet/action-sheet.service';
import {DirtyRecordModule} from './dirty-record/dirty-record.module';
import {DraftRecordModule} from './draft-record/draft-record.module';
import {JsonParseService} from '../services/json-parse.service';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {ButtonModule} from '@progress/kendo-angular-buttons';
import {DropDownListModule} from '@progress/kendo-angular-dropdowns';
import {FormsModule} from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    DragDropModule,
    ButtonModule,
    DropDownListModule,
    FormsModule,
  ],
  declarations: [
    NavComponent,
    FooterComponent,
    LoadingComponent,
    ErrorComponent,
    PageTitleComponent,
    ValidationSummaryComponent,
    TickCrossComponent,
    ClipboardButtonComponent,
    ActionSheetComponent,
    ClickOutsideDirective,
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    GridDataService,
    PermissionService,
    SecurityService,
    ActionSheetService,
    JsonParseService
  ],
  exports: [
    SharedRouterModule,
    NavComponent,
    FooterComponent,
    LoadingComponent,
    ErrorComponent,
    PageTitleComponent,
    ValidationSummaryComponent,
    TickCrossComponent,
    ClipboardButtonComponent,
    ActionSheetComponent,
    ClickOutsideDirective,
    DirtyRecordModule,
    DraftRecordModule,
  ]
})
export class SharedModule {
}
