import { Component, OnInit, Input, Output, ViewChild } from '@angular/core';
import { ContactEditorModel } from '../../models/source.models';
import { Subject } from "rxjs";
import { cloneDeep } from 'lodash';
import { ContactSaveDialogComponent } from '../contact-save-dialog/contact-save-dialog.component';
declare var $;

@Component({
  selector: 'app-contact-editor',
  templateUrl: './contact-editor.component.html',
  styleUrls: ['./contact-editor.component.scss']
})
export class ContactEditorComponent implements OnInit {
  @Input('editor') editor: ContactEditorModel;
  @Output('cancel') cancelEvent: Subject<any> = new Subject();
  @Output('submit') submitEvent: Subject<ContactEditorModel> = new Subject();
  @ViewChild('saveDialog', {static: false}) saveDialog: ContactSaveDialogComponent;

  private original: ContactEditorModel;

  constructor() { }

  ngOnInit() {
    $('#input-contact-forename').focus();
    this.original = cloneDeep(this.editor);
  }

  cancel() {
    Object.assign(this.editor, this.original);
    this.cancelEvent.next();
  }

  submit() {
    if (this.editor.forename && this.editor.surname)
      this.submitEvent.next(this.editor);
    else
      this.saveDialog.open();
  }
}
