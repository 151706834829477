import {of as observableOf, forkJoin as observableForkJoin,  Observable } from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AppConfig } from '../../app.config';
import { HttpClient } from '@angular/common/http';
import { State } from '@progress/kendo-data-query';
import { GridDataService } from '../../shared/services/grid-data.service';
import {
  DocumentIdentifierModel, DocumentEditorModel, DocumentCategoryModel, DocumentTypeModel, DocumentModel,
  DocumentEditorOptions, DocumentParentDetailsModel,
  DocumentControlNumberModel,
  ExternalTransmittalOptionModel
} from '../models/document.models';
import { ProjectModel, SourceModel } from '../../setup/sources/models/source.models';
import { VesselOptionModel } from '../../setup/vessels/models/vessel.models';
import { serialize } from '../../shared/utils/object.utils';
import { ValidationResultModel } from '../../shared/models/validation.models';
import { Router } from '@angular/router';
import { DuplicationModel } from '../models/duplication.models';

@Injectable()
export class DocumentEditorService {
  readonly baseUrl: string = `${AppConfig.settings.apiUrl}/documents`;
  readonly idListKey:string = 'duplicate-document-keys';

  constructor(
    private http: HttpClient,
    private router: Router
  ) { }

  get(id: number, externalCategoryId: string = null): Observable<DocumentEditorModel> {
    let url = !!id ? `${this.baseUrl}/${id}/edit` : `${this.baseUrl}/edit`;
    if (externalCategoryId)
      url += `/${externalCategoryId}`;
    return this.http.get<DocumentEditorModel>(url);
  }

  update(editor: DocumentEditorModel) {
    const url = !!editor.id ? `${this.baseUrl}/${editor.id}/edit` : `${this.baseUrl}/edit`;
    return this.http.post<ValidationResultModel>(url, editor);
  }

  getCategoryOptions(editor: DocumentEditorModel): Observable<DocumentCategoryModel[]> {
    return this.http.post<DocumentCategoryModel[]>(`${this.baseUrl}/edit/categoryoptions`, editor);
  }

  getProjectOptions(editor: DocumentEditorModel, searchText?: string): Observable<ProjectModel[]> {
    let url = `${this.baseUrl}/edit/projectoptions`;
    if (!!searchText)
      url += `?searchText=${searchText}`;
    return this.http.post<ProjectModel[]>(url, editor);
  }

  getVesselOptions(editor: DocumentEditorModel, searchText?: string): Observable<VesselOptionModel[]> {
    let url = `${this.baseUrl}/edit/vesseloptions`;
    if (!!searchText)
      url += `?searchText=${searchText}`;
    return this.http.post<VesselOptionModel[]>(url, editor);
  }

  getSourceOptions(editor: DocumentEditorModel, searchText?: string): Observable<SourceModel[]> {
    let url = `${this.baseUrl}/edit/sourceoptions`;
    if (!!searchText)
      url += `?searchText=${searchText}`;
    return this.http.post<SourceModel[]>(url, editor);
  }

  getTypeOptions(editor: DocumentEditorModel): Observable<DocumentTypeModel[]> {
    return this.http.post<DocumentTypeModel[]>(`${this.baseUrl}/edit/typeoptions`, editor);
  }

  getParentOptions(editor: DocumentEditorModel, searchText?: string): Observable<DocumentModel[]> {
    let url = `${this.baseUrl}/edit/parentoptions`;
    if (!!searchText)
      url += `?searchtext=${searchText}`;
    return this.http.post<DocumentModel[]>(url, editor);
  }

  getParentDetails(id: number): Observable<DocumentParentDetailsModel> {
    return this.http.get<DocumentParentDetailsModel>(`${this.baseUrl}/edit/parentdetails/${id}`);
  }

  getTransmittalOptions(editor: DocumentEditorModel, searchText?: string): Observable<any> {
    let url = `${this.baseUrl}/edit/externaltransmittaloptions`;
    if (!!searchText)
      url += `?searchtext=${searchText}`;
    return this.http.post<any>(url, editor);
  }

  getDocumentEditorOptions(editor: DocumentEditorModel): Observable<DocumentEditorOptions> {
    let options = new DocumentEditorOptions();
    return observableForkJoin<any>([
      this.getCategoryOptions(editor),
      this.getProjectOptions(editor),
      this.getSourceOptions(editor),
      this.getTypeOptions(editor),
      this.getParentOptions(editor),
      this.getVesselOptions(editor),
      this.getTransmittalOptions(editor)
    ]).pipe(mergeMap(results => {
      options.categoryOptions = results[0] as DocumentCategoryModel[];
      options.projectOptions = results[1] as ProjectModel[];
      options.sourceOptions = results[2] as SourceModel[];
      options.typeOptions = results[3] as DocumentTypeModel[];
      options.parentOptions = results[4] as DocumentModel[];
      options.vesselOptions = results[5] as VesselOptionModel[];
      options.transmittalOptions = results[6] as ExternalTransmittalOptionModel[];

      return observableOf(options);
    }))
  }

  getDocumentIdentifier(editor: DocumentEditorModel): Observable<DocumentIdentifierModel> {
    return this.http.post<DocumentIdentifierModel>(`${this.baseUrl}/edit/identifier`, editor);
  }

  getNextImsControlNumber(editor: DocumentEditorModel): Observable<DocumentControlNumberModel> {
    return this.http.post<DocumentControlNumberModel>(`${this.baseUrl}/edit/nextimscontrolnumber`, editor);
  }

  addToExternalTransmittal(transmittalId: number, documentRevisionId: number): Observable<any> {
    let data: number[] = [transmittalId, documentRevisionId];
    return this.http.post<number[]>(`${this.baseUrl}/addtoexternaltransmittal`, data);
  }

  getDuplicateDocumentIds(){
    return JSON.parse(localStorage.getItem(this.idListKey));
  }

  clearDuplicateDocumentIds(){
    localStorage.removeItem(this.idListKey);
  }

  saveDuplicateDocumentId(id?: number){
    if(id){
      var currentList = JSON.parse(localStorage.getItem(this.idListKey));

      if (!currentList) {
        currentList = [];
      }

      currentList.push(id);

      localStorage.setItem(this.idListKey, JSON.stringify(currentList));
    }
    else{
      localStorage.removeItem(this.idListKey);
    }
  }

  duplicate(editor?: DocumentEditorModel): DocumentEditorModel {
    let key = 'duplicate-document';

    if (!editor)
      return JSON.parse(localStorage.getItem(key));

    editor.id = null;

    localStorage.setItem(key, JSON.stringify(editor));
  }
}
