import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {UserGridComponent} from '../components/user-grid/user-grid.component';
import {UserEditComponent} from '../components/user-edit/user-edit.component';

const routes: Routes = [
  {
    path: 'setup',
    children: [
      {path: 'users', component: UserGridComponent},
      {path: 'users/:id/edit', component: UserEditComponent},
      {path: 'users/create', component: UserEditComponent}
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UsersRouterModule { }
