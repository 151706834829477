import { Injectable } from '@angular/core';
import { AppConfig } from '../../app.config';
import { HttpClient } from '@angular/common/http';
import { DocumentEditorModel, DocumentCategoryModel } from '../../documents/models/document.models';
import { Observable } from 'rxjs';
import { HistoricalDocumentDetailModel, HistoricalDocumentImportResponseModel, HistoricalDocumentImportRequestModel, HistoricalDocumentSaveResponseModel } from '../models/historical-document.models';
import { ApiOption } from '../../shared/models/base.models';
import { ProjectModel } from '../../setup/sources/models/source.models';

@Injectable()
export class HistoricalDocumentService {
  readonly baseUrl: string = `${AppConfig.settings.apiUrl}/historical/documents`;
  readonly gridName: string = 'historical/documents';

  constructor(
    private http: HttpClient
  ) { }

  create(id: number): Observable<DocumentEditorModel> {
    return this.http.get<DocumentEditorModel>(`${this.baseUrl}/${id}/create`);
  }

  getImportTotalRowCount(importId: number): Observable<any> {
    return this.http.get<number>(`${this.baseUrl}/${importId}/getimportrowcount`);
  }

  delete(id: number): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/${id}/delete`);
  }

  deleteAllImport(importId: number): Observable<any> {
    return this.http.get<ApiOption<string>[]>(`${this.baseUrl}/${importId}/deleteallimport`);
  }

  getImportData(): Observable<any> {
    return this.http.get<ApiOption<string>[]>(`${this.baseUrl}/imports/grid/data`);
  }

  get(id: number): Observable<HistoricalDocumentDetailModel[]> {
    return this.http.get<HistoricalDocumentDetailModel[]>(`${this.baseUrl}/${id}/details`);
  }

  getPropertyOptions() {
    return this.http.get<ApiOption<string>[]>(`${this.baseUrl}/propertyoptions`);
  }

  getCategoryOptions(): Observable<DocumentCategoryModel[]> {
    return this.http.get<DocumentCategoryModel[]>(`${this.baseUrl}/categoryoptions`);
  }

  getProjectOptions(searchText?: string): Observable<ProjectModel[]> {
    let url = `${this.baseUrl}/projectoptions`;
    if (!!searchText)
      url += `?searchText=${searchText}`;
    return this.http.get<ProjectModel[]>(url);
  }

  getSaveUrl(): string {
    return `${this.baseUrl}/save`;
  }

  save(): Observable<HistoricalDocumentSaveResponseModel> {
    return this.http.post<HistoricalDocumentSaveResponseModel>(this.getSaveUrl(), null);
  }

  import(request: HistoricalDocumentImportRequestModel): Observable<HistoricalDocumentImportResponseModel> {
    if (!request || !request.fileId)
      throw new Error("Historical document import requires fileId");
    return this.http.post<HistoricalDocumentImportResponseModel>(`${this.baseUrl}/${request.fileId}/import`, request);
  }
}
