
import {forkJoin as observableForkJoin,  Observable } from 'rxjs';
import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ExcelExportComponent, ExcelExportData } from '@progress/kendo-angular-excel-export';
import { DocumentService } from '../../../services/document.service';
import { KendoGridColumn } from '../../../../shared/models/grid.models';
import { SecurityService } from '../../../../shared/services/security.service';

@Component({
  selector: 'app-briefcase-export',
  templateUrl: './briefcase-export.component.html',
  styleUrls: ['./briefcase-export.component.scss']
})
export class BriefcaseExportComponent implements OnInit {
  @Input() ids: number[] = [];
  @Input() columns: KendoGridColumn[] = [];
  @ViewChild('excelExport', {static: false}) private excelExport: ExcelExportComponent;

  visibleColumns: any[] = [];
  readonly fileName = 'BriefcaseExport.xlsx';

  constructor(
    private documentService: DocumentService,
    private securityService: SecurityService
  ) {
  }

  ngOnInit() {
  }

  export() {
    if (!this.ids.length || !this.columns.length) {
      return;
    }

    observableForkJoin(
      this.documentService.getExportData(this.ids),
      this.securityService.getUser()
    ).subscribe(results => {
      const data = results[0];
      const user = results[1];
      const exportData: ExcelExportData = { data: data.data };
      this.excelExport.filterable = true;
      this.excelExport.fileName = this.fileName;
      this.excelExport.creator = user.username;
      this.excelExport.save(exportData);
    });
  }
}
