export class ReorderListPermissions {
  canViewListItem: boolean;
  canEditListItem: boolean;
  canArchiveListItem: boolean;
  canDeleteListItem: boolean;
}

export class ReorderListActions {
  hasViewButton: boolean;
  hasEditButton: boolean;
  hasArchiveButton: boolean;
  hasDeleteButton: boolean;
}
