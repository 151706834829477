import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {AppConfig} from '../../../app.config';
import {PermissionModel, RoleModel, UserEditorModel, UserGridFiltersModel} from '../models/user.models';
import {State} from "@progress/kendo-data-query";
import {GridDataService} from '../../../shared/services/grid-data.service';

@Injectable()
export class UserService {
  readonly baseUrl: string = `${AppConfig.settings.apiUrl}/users`;
  readonly gridName: string = 'users';

  constructor(
    private http: HttpClient,
    private gridData: GridDataService
  ) { }

  getGridData(state: State, filters: UserGridFiltersModel) {
    return this.gridData.getGridData(this.gridName, this.baseUrl, state, filters);
  }

  getUser(id: number) {
    return this.http.get<UserEditorModel>(`${this.baseUrl}/${id}/edit`);
  }

  getRoleOptions() {
    return this.http.get<RoleModel[]>(`${this.baseUrl}/edit/roleoptions`);
  }

  getFilterRoleOptions() {
    return this.http.get<RoleModel[]>(`${this.baseUrl}/grid/roleoptions`);
  }

  getPermissionOptions() {
    return this.http.get<PermissionModel[]>(`${this.baseUrl}/grid/permissionoptions`);
  }

  updateUser(user: UserEditorModel) {
    return this.http.post(`${this.baseUrl}/${user.id}/edit`, user);
  }

  createUser(user: UserEditorModel) {
    return this.http.post(`${this.baseUrl}/create`, user);
  }

  importUsers() {
    const url = `${AppConfig.settings.apiUrl}/users/import`;
    return this.http.get<any>(url);
  }
}
