import { Component, OnInit, ViewChild, Output, Input, ViewContainerRef } from '@angular/core';
import { RevisionService } from '../../services/revision.service';
import { DialogService } from '@progress/kendo-angular-dialog';
import { DocumentSavedFormComponent } from '../document-saved-form/document-saved-form.component';
import { KendoDialogRef } from '../../../shared/utils/kendo.utils';
import { Subject } from 'rxjs';
import { SaveEvent, SaveAction } from '../../../shared/models/events';

@Component({
  selector: 'app-document-saved',
  templateUrl: './document-saved.component.html',
  styleUrls: ['./document-saved.component.scss']
})
export class DocumentSavedComponent implements OnInit {
  @Input('filename') filename: string;
  @Input('canDuplicate') canDuplicate: boolean;
  @Input('isDuplicate') isDuplicate: boolean;
  @Output('saved') onSaved: Subject<SaveEvent> = new Subject();
  @ViewChild('savedDialogActions', {static: false}) actions;
  @ViewChild('savedDialogContainer', { static: false, read: ViewContainerRef }) savedDialogContainer;

  isFinishBatch: boolean = false;
  succeeded: boolean;

  private dialogRef: KendoDialogRef<DocumentSavedFormComponent>;
  private formComponent: DocumentSavedFormComponent;

  constructor(
    private dialogService: DialogService,
    private revisionService: RevisionService
  ) { }

  ngOnInit() {
  }

  updateFileName(filename: string, otherFilenames: string[] = []) {
    this.filename = filename;
    this.formComponent.filename = this.filename;
    if (this.isFinishBatch) {
      this.formComponent.otherFilenames = otherFilenames || [];
    }
  }

  close() {
    this.dialogRef.close();
    this.dialogRef.dialog.destroy();
    this.succeeded = false;
    this.onSaved.next({ action: SaveAction.Continue });
  }

  open(isFinishBatch: boolean) {

    this.isFinishBatch = isFinishBatch;

    this.dialogRef = this.dialogService.open({
      title: 'Document Saved',
      content: DocumentSavedFormComponent,
      appendTo: this.savedDialogContainer,
      actions: this.actions,
      width: 700
    });

    setTimeout(() => {
      $('kendo-dialog-actions button').last().focus();
    })

    this.formComponent = this.dialogRef.content.instance;
    this.formComponent.filename = this.filename;
  }

  back() {
    this.formComponent.validationResult = null;
    this.formComponent.loading = true;
    this.onSaved.next({ action: SaveAction.Back });
    this.dialogRef.close();
  }

  newDocument() {
    this.formComponent.validationResult = null;
    this.formComponent.loading = true;
    this.onSaved.next({ action: SaveAction.New });
    this.dialogRef.close();
  }

  duplicate() {
    this.formComponent.validationResult = null;
    this.formComponent.loading = true;
    this.onSaved.next({ action: SaveAction.Duplicate });
    this.dialogRef.close();
  }
}
