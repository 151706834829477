import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../../app.config';
import { TransmittalNotePreviewModel, TransmittalNoteUrlModel } from '../models/pdf.models';

@Injectable()
export class PdfService {
  readonly baseUrl: string = `${AppConfig.settings.apiUrl}/pdfs`;

  constructor(
    private http: HttpClient
  ) { }

  getPdfPreview(model: TransmittalNotePreviewModel) {
    return this.http.post<TransmittalNoteUrlModel>(`${this.baseUrl}/transmittal`, model);
  }
}
