
import {mergeMap} from 'rxjs/operators';
import { Component, OnInit, ViewChild, Output, Input } from '@angular/core';
import { DialogService, DialogRef } from '@progress/kendo-angular-dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { KendoDialogRef } from '../../../shared/utils/kendo.utils';
import { ILoadable } from '../../../shared/components/loading/loading.models';
import { TransmittalEditorService } from '../../services/transmittal-editor.service';
import { TransmittalEditorModel, ExternalTransmittalDocumentLinkEditorModel, TransmittalDocumentLinkEditorModel } from '../../models/transmittal.models';
import { RevisionModel } from '../../../documents/models/revision.models';
import { of as observableOf, forkJoin, Observable } from 'rxjs';

@Component({
  selector: 'app-transmittal-view-documents',
  templateUrl: './transmittal-view-documents.component.html',
  styleUrls: ['./transmittal-view-documents.component.scss']
})
export class TransmittalViewDocumentsComponent implements OnInit, ILoadable {
  @ViewChild('viewDocuments', { static: false}) content;
  @ViewChild('dialogActions', { static: false}) actions;

  private dialogRef: DialogRef;
  hasLoaded: boolean;
  loading: boolean;
  editor: TransmittalEditorModel;
  gridData: TransmittalDocumentLinkEditorModel[] = [];

  constructor(
    private dialogService: DialogService,
    private transmittalEditorService: TransmittalEditorService
  ) { }

  ngOnInit() {
  }

  open(id: number, transmittalIdentifier: string) {
    this.loading = true;
    this.transmittalEditorService.get(id).subscribe(editor => {
      this.editor = editor;
      this.gridData = editor.documentLinks;

      // quick dirty way of getting the most recent revision to show on the grid
      // gets all revision options and then gets the index for the current revision id
      forkJoin(
        this.gridData.map(document => {
          return this.transmittalEditorService.getRevisionOptions(this.editor, document.revisionDocumentId).pipe(
            mergeMap(options => {
              let mostRecentRevision = options.findIndex(e => e.id === document.revisionId);
              document.revisionNumber = options[mostRecentRevision].identifier;
              return observableOf(true);
            }))
        })).subscribe(res => {
        this.loading = false;
      })

      this.dialogRef = this.dialogService.open({
          title: transmittalIdentifier + ' - Added documents',
          content: this.content,
          actions: this.actions
        });
    });
  }

  close() {
    this.dialogRef.close();
    this.dialogRef.dialog.destroy();
  }
}
