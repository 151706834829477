import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { GridModule } from '@progress/kendo-angular-grid';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../shared/modules/shared.modules';
import { RevisionReasonGridComponent } from '../components/revision-reason-grid/revision-reason-grid.component';
import { RevisionReasonEditComponent } from '../components/revision-reason-edit/revision-reason-edit.component';
import { RevisionReasonService } from '../services/revision-reason.service';
import { RevisionReasonsRouterModule } from './revision-reasons.router.module';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { FormsModule } from '@angular/forms';
import { DialogModule } from '@progress/kendo-angular-dialog';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    GridModule,
    ButtonsModule,
    DialogModule,
    InputsModule,
    SharedModule
  ],
  declarations: [
    RevisionReasonGridComponent,
    RevisionReasonEditComponent
  ],
  providers: [
    RevisionReasonService
  ],
  exports: [
    RevisionReasonsRouterModule
  ]
})
export class RevisionReasonsModule { }