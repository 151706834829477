import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { GridModule } from '@progress/kendo-angular-grid';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../shared/modules/shared.modules';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { FormsModule } from '@angular/forms';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { RoleRouterModule } from './role.router.module';
import { RoleGridComponent } from '../components/role-grid/role-grid.component';
import { RoleService } from '../services/role.service';
import { RoleEditComponent } from '../components/role-edit/role-edit.component';
import { ViewUsersComponent } from '../components/view-users/view-users.component';
import { PtKendoModule } from '../../../kendo/modules/pt.kendo.module';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { AddedUsersGridComponent } from '../components/added-users-grid/added-users-grid.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    GridModule,
    ButtonsModule,
    DialogModule,
    InputsModule,
    SharedModule,
    PtKendoModule,
    DropDownsModule,
  ],
  declarations: [
    RoleGridComponent,
    RoleEditComponent,
    ViewUsersComponent,
    AddedUsersGridComponent
  ],
  providers: [
    RoleService
  ],
  exports: [
    RoleRouterModule
  ]
})
export class RolesModule { }