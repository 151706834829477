
import {finalize} from 'rxjs/operators';
import { Component, OnInit, Output, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { KendoDialogRef } from '../../../shared/utils/kendo.utils';
import { DocumentVoidFormComponent } from '../document-void-form/document-void-form.component';
import { DocumentGridRowModel } from '../../models/document.models';
import { DocumentService } from '../../services/document.service';
import { DialogService } from '@progress/kendo-angular-dialog';

@Component({
  selector: 'app-document-void',
  templateUrl: './document-void.component.html',
  styleUrls: ['./document-void.component.scss']
})
export class DocumentVoidComponent implements OnInit {
  @Output('voided') onVoided: Subject<any> = new Subject();
  @ViewChild('dialogActions', {static: false}) actions;

  succeeded: boolean;

  private dialogRef: KendoDialogRef<DocumentVoidFormComponent>;
  private formComponent: DocumentVoidFormComponent;

  constructor(
    private dialogService: DialogService,
    private documentService: DocumentService
  ) { }

  ngOnInit() {
  }

  open(documentId: number, title: string) {
    this.dialogRef = this.dialogService.open({
      title: 'Void Document',
      content: DocumentVoidFormComponent,
      actions: this.actions
    });

    setTimeout(() => {
      $('kendo-dialog-actions button').last().focus();
    })

    this.formComponent = this.dialogRef.content.instance;
    this.formComponent.editor.id = documentId;
    this.formComponent.title = title;

    this.succeeded = this.formComponent.succeeded = false;
  }

  submit() {
    if (!this.formComponent.editor.reason) {
      this.formComponent.invalid = true;
      return;
    }

    this.formComponent.loading = true;
    let editor = this.dialogRef.content.instance.editor;
    this.documentService.void(editor).pipe(
      finalize(() => this.formComponent.loading = false))
      .subscribe(res => {
        this.succeeded = this.formComponent.succeeded = true;
        this.onVoided.next();
      }, err => { })
  }

  close() {
    this.dialogRef.close();
    this.dialogRef.dialog.destroy();
  }
}
