
import {finalize} from 'rxjs/operators';
import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Location } from '@angular/common';
import { ILoadable } from '../../../shared/components/loading/loading.models';
import { IValidatable, ValidationResultModel } from '../../../shared/models/validation.models';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ExternalTransmittalDocumentsEditorModel, ExternalTransmittalInstancePermissionsModel,
  TransmittalGridRowModel
} from '../../models/transmittal.models';

import { TransmittalExternalAddDocumentService } from '../../services/transmittal-external-add-document.service';
import { DocumentModel } from '../../../documents/models/document.models';
import { TransmittalExternalAddDocumentGridComponent } from '../transmittal-external-add-document-grid/transmittal-external-add-document-grid.component';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';

@Component({
  selector: 'app-external-add-document',
  templateUrl: './transmittal-external-add-document.component.html',
  styleUrls: ['./transmittal-external-add-document.component.scss']
})

export class TransmittalExternalAddDocumentComponent implements OnInit, ILoadable, IValidatable {
  @ViewChild('documentGrid', {static: false}) documentGrid: TransmittalExternalAddDocumentGridComponent;
  @ViewChild('documentInput', {static: false}) documentInput: DropDownListComponent;

  validationResult: ValidationResultModel;
  hasLoaded: boolean;
  loading: boolean;
  updated: boolean;
  saveClicked: boolean;
  isEditMode: boolean;
  editor: ExternalTransmittalDocumentsEditorModel;
  permissions: ExternalTransmittalInstancePermissionsModel;
  selectedDocs: ExternalTransmittalDocumentsEditorModel;
  docSearchOptions: DocumentModel[] = [];
  anyDocuments: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private transmittalExternalAddDocumentService: TransmittalExternalAddDocumentService
  ) { }

  ngOnInit() {
    this.loading = true;

    this.route.params.subscribe(params => {
      let id = params['id'] || null;
      if (id) {
        this.isEditMode = true;
      }

      this.editor = new ExternalTransmittalDocumentsEditorModel();

      this.transmittalExternalAddDocumentService.get(id).pipe(finalize(() => {
        this.hasLoaded = true;
        this.loading = false;
      })).subscribe(editor => {
        this.editor = editor;
        this.permissions = editor.permissions;

        this.route.queryParams.subscribe(params => {
          let documentId = +params['addedDocument'] || null;
          if (documentId && !this.editor.documentLinks.some(l => l.documentId == documentId)) {
            this.transmittalExternalAddDocumentService
              .getDocumentOption(documentId)
              .subscribe(document => this.documentGrid.add(document, this.editor))
          }
        });

        this.transmittalExternalAddDocumentService.getDocumentOptions(editor).subscribe(items => {
          this.docSearchOptions = items;
        })
      });
    });
  }

  submit() {
    this.documentGrid.saveDocuments().subscribe(res => {
      this.updated = true;
      this.saveClicked = true;
      setTimeout(() => {
        this.router.navigateByUrl('/transmittals');
      }, 1000);
    });
  }

  onDocumentFilterChanged(searchText: string) {
    this.transmittalExternalAddDocumentService
      .getDocumentOptions(this.editor, searchText)
      .subscribe(options => this.docSearchOptions = options);
  }

  documentGridChanged($event: TransmittalGridRowModel[]) {
    this.anyDocuments = $event.length > 0;
    this.transmittalExternalAddDocumentService.getDocumentOptions(this.editor).subscribe(options => this.docSearchOptions = options);
  }

  docSelected(document: DocumentModel) {
    if (!document)
      return;

    this.documentGrid.add(document, this.editor);

    this.documentInput.writeValue(null);
  }

  newProjectDoc() {
    //this.documentGrid.saveDocuments().subscribe();
    this.router.navigate([`/documents/edit/externalnonseajacks`], { queryParams: { transmittal: this.editor.transmittalId } });
  }

  newNonProjectDoc() {
    //this.documentGrid.saveDocuments().subscribe();
    this.router.navigate([`/documents/edit/externalseajacks`], { queryParams: { transmittal: this.editor.transmittalId } });
  }
}
