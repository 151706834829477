import { Injectable } from '@angular/core';
import { State, toDataSourceRequestString, FilterDescriptor, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { serialize } from '../utils/object.utils';
import { GridDataResult, ColumnResizeArgs } from '@progress/kendo-angular-grid';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../app.config';
import { IKendoGridComponent, KendoColumnResizeArgs } from '../utils/kendo.utils';
import { IGridColumnState, IGridFilterDescriptor, GridColumn } from '../models/grid.models';
import { JsonParseService } from './json-parse.service';
import { cloneDeep } from 'lodash';

@Injectable()
export class GridDataService {
  private readonly stateSuffix: string = '-gridstate';
  private readonly filtersSuffix: string = '-grid-filters';
  private readonly columnsSuffix: string = '-grid-columns';

  constructor(
    private http: HttpClient,
    private jsonParse: JsonParseService) {
  }

  getGridData<TFilters>(gridName: string, baseUrl: string, state: State, filters?: TFilters) {
    if (state.filter) {
      for (let i = state.filter.filters.length - 1; i >= 0; i--) {
        let filter = state.filter.filters[i] as FilterDescriptor;
        if (filter.operator == 'contains' && filter.value == '')
          state.filter.filters.splice(i, 1);
      }
    }

    this.saveGridState(gridName, state, filters);

    const stateClone = cloneDeep(state);

    this.sanitizeFilterStrings(stateClone.filter);

    const stateQuery = toDataSourceRequestString(stateClone);
    const filterQuery = !filters ? '' : serialize(filters);
    return this.http.get<GridDataResult>(`${baseUrl}/grid/data?${stateQuery}&${filterQuery}`);
  }

  saveGridState<TFilters>(gridName: string, state: State, filters?: TFilters) {
    if (state)
      localStorage.setItem(gridName + this.stateSuffix, JSON.stringify(state));

    if (filters)
      localStorage.setItem(gridName + this.filtersSuffix, JSON.stringify(filters));
  }

  applyCachedState(gridName: string, component: IKendoGridComponent) {
    let stateJson = localStorage.getItem(gridName + this.stateSuffix);
    let customFiltersJson = localStorage.getItem(gridName + this.filtersSuffix);
    let columnsJson = localStorage.getItem(gridName + this.columnsSuffix);

    if (stateJson) {
      var stateObj = JSON.parse(stateJson);
      this.jsonParse.parseDates(stateObj);
      component.state = stateObj;
      localStorage.removeItem(gridName + this.stateSuffix);
    }

    if (customFiltersJson) {
      var customFiltersObj = JSON.parse(customFiltersJson);
      this.jsonParse.parseDates(customFiltersObj);
      component.filters = customFiltersObj;
      localStorage.removeItem(gridName + this.filtersSuffix);
    }

    if (columnsJson) {
      var columnsObj = JSON.parse(columnsJson) || [];

      this.jsonParse.parseDates(columnsObj);
      component.columnState.selected = columnsObj;

      this.updateColumnVisibility(component);
      this.updateColumnWidths(component);
      this.applyColumnOrder(component);
    }
  }


  resetGrid<TFilters>(gridName: string, baseUrl: string, component: IKendoGridComponent) {
    delete component.state.filter;
    component.state.skip = 0;
    component.state.sort = [];
    //ToDo: This should probably reinitialise the default filters object on the component rather than just create a new anonymous object
    component.filters = !!component.filters ? {} : null;

    this.applyDefaultFilters(component);
    this.applyDefaultSort(component);

    return this.getGridData(gridName, baseUrl, component.state, component.filters);
  }

  removeFilter(component: IKendoGridComponent, field: string) {
    let state = component.state;

    if (!state || !state.filter)
      return;
    // throw new Error("Cannot remove filter when none exist");

    let composite = state.filter.filters.find((cf: CompositeFilterDescriptor) =>
      !!cf.filters &&
      cf.filters.some((f: FilterDescriptor) => f.field == field)
    ) as CompositeFilterDescriptor;

    if (!!composite) {
      let filters = composite.filters.filter((f: FilterDescriptor) => f.field == field) as FilterDescriptor[];
      if (filters) {
        filters.forEach(f => {
          let i = composite.filters.indexOf(f);
          composite.filters.splice(i, 1);
        });
        if (!composite.filters.length) {
          let i = state.filter.filters.indexOf(composite);
          state.filter.filters.splice(i, 1);
        }
      }
    } else {
      let filter = state.filter.filters.find((f: FilterDescriptor) => f.field == field);
      if (filter) {
        let i = state.filter.filters.indexOf(filter);
        state.filter.filters.splice(i, 1);
      }
    }
  }

  initColumnState(component: IKendoGridComponent): void {
    component.columnState.selected = [];
    this.applyDefaultColumns(component);
    this.applyDefaultSort(component);
  }

  saveColumnState(gridName: string, component: IKendoGridComponent, preventVisibilityRefresh: boolean = false) {
    if (!component.columnState) {
      return;
    }

    if (!preventVisibilityRefresh) {
      this.updateColumnVisibility(component);
      component.updateGrid();
    }

    localStorage.setItem(gridName + this.columnsSuffix, JSON.stringify(component.columnState.selected));
  }

  resetColumnState(gridName: string, component: IKendoGridComponent) {
    localStorage.removeItem(gridName + this.columnsSuffix);
    this.initColumnState(component);
    this.applyDefaultFilters(component);
  }

  private applyDefaultSort(component: IKendoGridComponent) {
    if ((component.state && component.state.sort && component.state.sort.length) || !component.columnState || !component.columnState.options)
      return;
    component.columnState.options.forEach((e, i) => {
      if (!!e.defaults && !!e.defaults.sort) {
        let s = e.defaults.sort;
        if (!component.state.sort)
          component.state.sort = [];
        component.state.sort.push({ field: e.field, dir: s.dir });
      }
    });
  }

  private applyDefaultFilters(component: IKendoGridComponent) {
    if (component.columnState != null) {
      component.columnState.options.forEach((option, i) => {
        if (!!option.defaults && !!option.defaults.filter) {
          let defaultFilter = option.defaults.filter;

          if (!component.state.filter)
            component.state.filter = { filters: [], logic: 'and' };

          let existing = component.state.filter.filters.find((f: FilterDescriptor) => f.field == option.field) as FilterDescriptor;

          if (!!existing) {
            existing.operator = defaultFilter.operator;
            existing.value = defaultFilter.value;
          } else {
            component.state.filter.filters.push({ field: option.field, operator: defaultFilter.operator, value: defaultFilter.value });
          }
        }
      })
    }
  }

  private applyDefaultColumns(component: IKendoGridComponent) {
    component.columnState.options.forEach((col, i) => {
      col.ordinal = i;

      this.applyDefaultColumnsWidths(col);

      if (col.isDefault || col.isDefault === undefined) {
        col.isDefault = true;
        component.columnState.selected.push(col);
      }

      component.columnState.state[col.field] = { visible: col.isDefault, width: col.width, minWidth: col.minWidth };
    });
  }

  private updateColumnOrder(component: IKendoGridComponent) {
    const columnsConfig = component.grid.columnList
      .toArray()
      .map(colComponent => Object.keys(colComponent)
        .filter(propName => !propName.toLowerCase().includes('template'))
        .reduce((acc, curr) => ({ ...acc, ...{ [curr]: colComponent[curr] } }), {})
      );

    component.columnState.options.forEach(col => {
      const gridCol: any = columnsConfig.find((e: any) => e.field === col.field);
      col.ordinal = gridCol.orderIndex;
    });
  }

  private updateColumnVisibility(component: IKendoGridComponent) {
    component.columnState.options.forEach(e => {
      let selected = component.columnState.selected.find(s => s.field == e.field);
      let isSelected = !!selected;
      component.columnState.state[e.field].visible = isSelected;

      if (!isSelected)
        this.removeFilter(component, e.field);
    });
  }

  private updateColumnWidths(component: IKendoGridComponent) {
    component.columnState.options.forEach(e => {
      let selected = component.columnState.selected.find(s => s.field == e.field);
      if (!selected)
        return;
      component.columnState.state[e.field].width = selected.width;
    });
  }

  private applyColumnOrder(component: IKendoGridComponent) {
    component.columnState.options = component.columnState.options.sort((a, b) => a.ordinal - b.ordinal);
  }

  private applyDefaultColumnsWidths(columm: GridColumn) {
    if (!columm.defaults || !columm.defaults.width)
      return;

    columm.minWidth = columm.defaults.minWidth || 50;
    columm.width = columm.defaults.width || columm.minWidth;
  }

  // private applyResizeHandler(component: IKendoGridComponent) {
  //   setTimeout(() => {
  //     if (!component.grid)
  //       return;

  //     component.grid.columnResize.subscribe((e: KendoColumnResizeArgs[]) => {
  //       let change = e[0];
  //       let col = component.columnState.selected.find(c => c.field == change.column.field);
  //       col.width = change.newWidth;
  //     })
  //   });
  // }

  private sanitizeFilterStrings(filter: CompositeFilterDescriptor): void {
    const sanitize = (filters: (CompositeFilterDescriptor | FilterDescriptor)[]) => {
      filters.forEach(f => {
        const descriptor = f as CompositeFilterDescriptor;
        if (descriptor.filters) {
          sanitize(descriptor.filters);
        } else {
          const descriptor = f as FilterDescriptor;
          if (typeof (descriptor.value) == 'string' && descriptor.value.includes('&'))
            descriptor.value = encodeURIComponent(descriptor.value);
        }
      })
    };

    if (filter && filter.filters) {
      sanitize(filter.filters);
    }
  }
}
