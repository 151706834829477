import { Component, OnInit, Input, Output, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'pt-drag-multiselect',
  templateUrl: './drag-multiselect.component.html',
  styleUrls: ['./drag-multiselect.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DragMultiselectComponent implements OnInit {
  @Input() data: any[] = [];
  @Input() unselectedTitle: string
  @Input() selectedTitle: string;
  @Input() textField: string;
  @Output() valueChanged: Subject<any> = new Subject();

  unselectedItems: MultiselectItem[] = [];
  selectedItems: MultiselectItem[] = [];

  constructor() { }

  ngOnInit() {
    this.selectedItems = this.data.map(e => {
      let item = new MultiselectItem();
      item.data = e;
      item.selected = false;
      return item;
    })
  }

  onUnselectedDragEnd($event) {
    console.log($event);
  }

  onSelectedDragEnd($event) {
    console.log($event);
  }
}

class MultiselectItem {
  data: any;
  selected: boolean;
}
