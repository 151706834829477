import { Injectable, ErrorHandler, Injector } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AppConfig } from '../../app.config';
import * as StackTrace from 'stacktrace-js';
import { ActivatedRoute } from '@angular/router';
import { StackFrame } from 'stacktrace-js';

@Injectable()
export class PtErrorHandler extends ErrorHandler {

  constructor(private injector: Injector) {
    // The true paramter tells Angular to rethrow exceptions, so operations like 'bootstrap' will result in an error
    // when an error happens. If we do not rethrow, bootstrap will always succeed.
    super();
  }

  handleError(error: any): void {
    let http = this.injector.get(HttpClient);

    if (error instanceof HttpErrorResponse) {
      const httpError = error as HttpErrorResponse;
      if (httpError.status == 412)
        return; //Don't throw 412 errors

      error = httpError.error;
    }

    const message = error.message ? error.message : error.toString();

    StackTrace.fromError(error).then(stackFrames => {
      let model = this.buildLog(message, stackFrames.map(e => `${e.functionName}() - ${e.fileName}:${e.lineNumber}`));

      http.post(`${AppConfig.settings.apiUrl}/error/send`, model).subscribe();
    });

    console.error(error);
  }

  private buildLog(message: string, stackTrace: string[]) {
    let error: ErrorEmailModel = {
      time: new Date().toISOString(),
      origin: window.location.origin,
      url: window.location.pathname + window.location.search,
      message: message,
      stackTrace: stackTrace,
      buildNumber: AppConfig.settings.version
    };

    return error;
  }
}

export interface ErrorEmailModel {
    time: string;
    message: string;
    stackTrace: string[];
    url: string;
    origin: string;
    buildNumber: string;
}
