import { OnInit, Input, Directive, Output, EventEmitter, OnDestroy } from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import { Observable ,  Subject ,  of } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import {GridComponent, GridDataResult, PagerSettings, PopupCloseEvent, SinglePopupService} from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor, FilterDescriptor, State, toDataSourceRequestString } from '@progress/kendo-data-query';
import { KendoColumnResizeArgs } from '../../../shared/utils/kendo.utils';
import { JsonParseService } from '../../../shared/services/json-parse.service';
import { serialize } from '../../../shared/utils/object.utils';
import {
  KendoGridColumn,
  KendoGridDataConfig,
  KendoGridSettings,
  ResetGridOptions,
  UpdateGridOptions
} from '../../../shared/models/grid.models';

const defaultState: State = { skip: 0, take: 20 };
const defaultPageSettings: PagerSettings = { type: 'input', pageSizes: [5, 10, 15, 20] };

@Directive({
  selector: '[ptKendoGrid]',
  exportAs: 'ptKendoGrid'
})
export class PtKendoGridDirective implements OnInit, OnDestroy {
  /** The full API URL to call to get grid data */
  @Input() apiUrl: string;
  /** The name of the grid. Used for storing and retrieving cached grid state */
  @Input() gridName: string;
  /** Whether the grid should cache the current state when the data is updated */
  @Input() cache: boolean = true;
  /** Stops initial call to get data. If this is set to true you must manually call updateGrid() */
  @Input() disableDataInit: boolean = false;
  /** Other filters that are not included in the grid itself */
  @Input() filters: any;
  /**
   * The Kendo data result that is displayed in the grid.
   * Can be used with a two way binding.
   */
  @Input() data: GridDataResult;
  @Input() pageable: PagerSettings | boolean;
  @Input() columns: KendoGridColumn[] = [];
  @Input() state: State = defaultState;
  @Input() skip: number;
  @Input() pageSize: number;
  @Output() columnsChange = new EventEmitter<KendoGridColumn[]>();
  @Output() dataChange = new EventEmitter<GridDataResult>();
  @Output() stateChange = new EventEmitter<State>();
  @Output() init = new EventEmitter<GridDataResult>();

  private readonly stateSuffix: string = '-grid-state';
  private readonly filtersSuffix: string = '-grid-filters';
  /** A copy of the original column configuration to allow resetting column state */
  private originalColumns: KendoGridColumn[] = [];
  /** A copy of the original state configuration to allow resetting sort and filter state */
  private originalState: State;
  /** Used to ensure the columns' orderIndex property is populated as the initial load from the kendo grid is not */
  private cachedColumns: any[] = null;
  private cachedColumnsUsed = false;
  /** Used to work out when filters have changed so the page number can be reset to 0 */
  private previousState: State;
  private destroy = new Subject();

  constructor(
    public grid: GridComponent,
    private jsonParse: JsonParseService,
    private http: HttpClient,
    private singlePopupService: SinglePopupService
  ) {
    this.monitorGridFilterMenuForClickOutside();
  }

  ngOnInit() {
    if (!this.apiUrl) {
      throw new Error('ptKendoGrid requires [apiUrl] to be set');
    }

    if (!this.gridName) {
      throw new Error('ptKendoGrid requires [gridName] to be set');
    }

    if (!this.pageable) {
      this.pageable = defaultPageSettings;
    }

    if (this.skip === undefined || this.pageSize === undefined) {
      throw new Error('ptKendoGrid requires both [skip] and [pageSize] to be set for pager to work properly. ' +
        'Use state.skip and state.pageSize');
    }

    this.grid.pageable = this.pageable;
    this.originalColumns = cloneDeep(this.columns);
    this.originalState = cloneDeep(this.state);

    setTimeout(() => {
      this.grid.dataStateChange
        .pipe(takeUntil(this.destroy))
        .subscribe((state: State) => {
          if (state.take === undefined) {
            state.take = defaultState.take;
          }
          this.setState(state);
          this.stateChange.emit(state);
          this.updateGrid().subscribe();
        });

      this.grid.columnReorder
        .pipe(takeUntil(this.destroy))
        .subscribe(result => {
          setTimeout(() => this.cacheGridSettings());
        });

      this.grid.columnResize
        .pipe(takeUntil(this.destroy))
        .subscribe(result => {
          setTimeout(() => this.cacheGridSettings());
        });

      this.grid.columnVisibilityChange
        .pipe(takeUntil(this.destroy))
        .subscribe(result => {
          setTimeout(() => this.cacheGridSettings());
        });

      if (this.disableDataInit) {
        this.init.emit(null);
        return;
      }

      if (this.cache) {
        this.applyCachedSettings();
      }

      this
        .updateGrid({ preventDataUpdate: true })
        .subscribe(res => {
          this.init.emit(res);
          this.data = res;
          this.dataChange.emit(res);
        });
    });
  }

  ngOnDestroy() {
    this.destroy.complete();
    this.destroy.unsubscribe();
  }

  /** Updates the grid's data using the current configuration */
  updateGrid(options?: UpdateGridOptions): Observable<GridDataResult> {
    options = options || { preventDataUpdate: false };

    // Fix contains filter
    if (this.state.filter) {
      for (let i = this.state.filter.filters.length - 1; i >= 0; i--) {
        const filter = this.state.filter.filters[i] as FilterDescriptor;
        if (filter.operator === 'contains' && filter.value === '') {
          this.state.filter.filters.splice(i, 1);
        }
      }
    }

    this.cacheGridSettings();

    const stateClone = cloneDeep(this.state);

    if (this.previousState && this.previousState.filter !== stateClone.filter) {
      this.state.skip = 0;
      stateClone.skip = 0;
    }

    this.sanitizeFilterStrings(stateClone.filter);

    const stateQuery = toDataSourceRequestString(stateClone);
    const filterQuery = !this.filters ? '' : serialize(this.filters);
    const query = `${stateQuery}&${filterQuery}`;

    this.grid.loading = true;

    return this.http
      .get<GridDataResult>(`${this.apiUrl}/grid/data?${query}`)
      .pipe(switchMap((res: GridDataResult) => {
        this.grid.loading = false;
        if (!options.preventDataUpdate) {
          this.data = res;
          this.dataChange.emit(res);
        }
        return of(res);
      }));
  }

  /** Resets the grids state and data to the original configuration depending on the options parameter.
   *  If no options are specified then all options will apply.
   *  If options are specified then they are all opt-in.
   * */
  resetGrid(options?: ResetGridOptions): Observable<GridDataResult> {
    if (!options || options.state) {
      this.setState(this.originalState);
    }

    if (!options || options.otherFilters) {
      // ToDo: This should probably reinitialise the default filters object on the component rather than just create a new anonymous object
      this.filters = !!this.filters ? {} : null;
    }

    if (!options || options.columns) {
      this.columns = cloneDeep(this.originalColumns);
      this.columnsChange.emit(this.columns);
      setTimeout(() => this.cacheGridSettings());
      return of();
    }

    return options && options.update === false ? of() : this.updateGrid();
  }

  resized(changes: KendoColumnResizeArgs[]) {
    // this.columnResize$.emit(changes);
  }

  /** Caches the current grid columns, state and filters */
  cacheGridSettings(): void {
    if (!this.cache) {
      return;
    }

    const columns =
      this.cachedColumns && !this.cachedColumnsUsed
        ? (this.cachedColumns || [])
        : this.grid.columns.toArray();

    if (this.cachedColumns) {
      this.cachedColumnsUsed = true;
    }

    const cols = columns
      .filter((col) => (col as object).hasOwnProperty('field'))
      .map((col) =>
        Object.keys(col)
          .filter(
            (propName) =>
              !propName.toLowerCase().includes('template') &&
              !propName.toLowerCase().includes('ngcontext')
          )
          .reduce((acc, curr) => ({ ...acc, ...{ [curr]: col[curr] } }), {})
      );

    // update columns - or the export won't have updated col order until user reloads page
    this.columns = cols;

    const gridConfig: KendoGridSettings = {
      state: this.state,
      columnsConfig: cols,
    };

    if (gridConfig.state) {
      localStorage.setItem(this.gridName + this.stateSuffix, JSON.stringify(gridConfig));
    }

    if (this.filters) {
      localStorage.setItem(this.gridName + this.filtersSuffix, JSON.stringify(this.filters));
    }
  }

  /** Retrieves and applies the current stored grid columns, state and filters */
  applyCachedSettings(): void {
    const configJson = localStorage.getItem(this.gridName + this.stateSuffix);
    const customFiltersJson = localStorage.getItem(this.gridName + this.filtersSuffix);

    if (configJson) {
      const config = JSON.parse(configJson);
      const stateObj = config.state;
      let columnsConfig: any[] = config.columnsConfig
        ? config.columnsConfig
        : [];

      if (columnsConfig.some(col => col.orderIndex !== 0)) {
        columnsConfig = columnsConfig.sort((a, b) => a.orderIndex - b.orderIndex);
      }

      if (!this.cachedColumnsUsed) {
        this.cachedColumns = columnsConfig;
      }

      this.jsonParse.parseDates(stateObj);
      this.setState(stateObj);
      localStorage.removeItem(this.gridName + this.stateSuffix);
      this.columns = columnsConfig;
      this.columnsChange.emit(columnsConfig);
    } else {
      this.setState(this.state);
    }

    if (customFiltersJson) {
      const customFiltersObj = JSON.parse(customFiltersJson);
      this.jsonParse.parseDates(customFiltersObj);
      this.filters = customFiltersObj;
      localStorage.removeItem(this.gridName + this.filtersSuffix);
    }
  }

  /** Updates the current grid configuration */
  setDataConfig(config: KendoGridDataConfig, applyCachedSettings: boolean = false) {
    this.gridName = config.name;
    this.apiUrl = config.url;

    if (config.cache !== undefined) {
      this.cache = config.cache;
    }

    if (applyCachedSettings) {
      this.applyCachedSettings();
    }
  }

  public exportGridData(): Observable<HttpResponse<Blob>> {
    let headers = new HttpHeaders();
    headers = headers.set(
      'Accept',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    );

    const url = `${this.apiUrl}/grid/data/export?${this.getGridQueryString()}`;

    return new Observable<any>((subscriber) =>
      // observe: 'response' to return the HttpResponse instead of just the content, so we can access the filename
      this.http
        .post(url, this.columns, {
          headers,
          observe: 'response',
          responseType: 'blob',
        })
        .subscribe({
          next: (res) => {
            const filename = res.headers.get('file-name');
            const file = new Blob([res.body], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
            const fileURL = URL.createObjectURL(file);

            const a = document.createElement('a');
            a.href = fileURL;
            a.download = filename;

            // Must be done like this (rather than a.Click()) to support browsers other than Chrome
            a.dispatchEvent(
              new MouseEvent('click', {
                bubbles: true,
                cancelable: true,
                view: window,
              })
            );
            a.remove();

            subscriber.next(res);
          },
          error: (err) => {
            subscriber.error(err);
          },
        })
    );
  }

  /** Sets the current page of the grid */
  setPage(number: number) {
    this.state.skip = this.state.take * number;
    this.setState(this.state);
  }

  /** Runs Kendo filter values through encodeURIComponent() to fix request issues */
  private sanitizeFilterStrings(filter: CompositeFilterDescriptor): void {
    const sanitize = (filters: (CompositeFilterDescriptor | FilterDescriptor)[]) => {
      filters.forEach(f => {
        const compositeFilterDescriptor = f as CompositeFilterDescriptor;
        if (compositeFilterDescriptor.filters) {
          sanitize(compositeFilterDescriptor.filters);
        } else {
          const filterDescriptor = f as FilterDescriptor;
          if (typeof (filterDescriptor.value) === 'string' && filterDescriptor.value.includes('&')) {
            filterDescriptor.value = encodeURIComponent(filterDescriptor.value);
          }
        }
      });
    };

    if (filter && filter.filters) {
      sanitize(filter.filters);
    }
  }

  // stops the grid filter popup from closing when highlighting text inside the filter input, and moving the cursor outside the popup
  private monitorGridFilterMenuForClickOutside() {
    this.singlePopupService.onClose.subscribe((e: PopupCloseEvent) => {
      const selection = window.getSelection();
      const textSelected = selection ? selection.toString() : '';

      if (textSelected.length > 0) {
        e.preventDefault();
      }
    });
  }

  /** Sets the state of the grid */
  private setState(state: State) {
    this.state = cloneDeep(state);
    this.grid.sort = this.state.sort;
    this.grid.filter = this.state.filter;
    this.grid.skip = this.state.skip;
    this.grid.pageSize = this.state.take;
  }

  private getGridQueryString() {
    const stateClone = cloneDeep(this.state);

    if (this.previousState && this.previousState.filter !== stateClone.filter) {
      this.state.skip = 0;
      stateClone.skip = 0;
    }

    this.sanitizeFilterStrings(stateClone.filter);

    const stateQuery = toDataSourceRequestString(stateClone);
    const filterQuery = !this.filters ? '' : serialize(this.filters);
    return `${stateQuery}&${filterQuery}`;
  }
}
