
import {of as observableOf,  Observable } from 'rxjs';

import {mergeMap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { User } from "../models/security.models";
import { AppConfig } from "../../app.config";

@Injectable()
export class SecurityService {
  private user: User;

  constructor(
    private http: HttpClient
  ) { }

  getUser(): Observable<User> {
    if (!!this.user)
      return observableOf(this.user);

    return this.http.get<User>(`${AppConfig.settings.apiUrl}/security/user`).pipe(
      mergeMap(user => {
        this.user = user;
        return observableOf(this.user);
      }));
  }
}
