import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../../shared/modules/shared.modules';
import { SourceGridComponent } from '../components/source-grid/source-grid.component';
import { SourceEditComponent } from '../components/source-edit/source-edit.component';
import { SourcesRouterModule } from './sources.router.module';
import { SourceService } from '../services/source.service';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { GridModule, ExcelModule } from '@progress/kendo-angular-grid';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { FormsModule } from '@angular/forms';
import { ContactEditorComponent } from '../components/contact-editor/contact-editor.component';
import { ContactSummaryComponent } from '../components/contact-summary/contact-summary.component';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { PtKendoModule } from '../../../kendo/modules/pt.kendo.module';
import { ContactSaveDialogComponent } from '../components/contact-save-dialog/contact-save-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    InputsModule,
    DropDownsModule,
    GridModule,
    ExcelModule,
    ButtonsModule,
    DialogModule,
    SharedModule,
    PtKendoModule
  ],
  declarations: [
    SourceGridComponent,
    SourceEditComponent,
    ContactEditorComponent,
    ContactSummaryComponent,
    ContactSaveDialogComponent
  ],
  providers: [
    SourceService
  ],
  exports: [
    SourcesRouterModule
  ]
})
export class SourcesModule { }