import {of as observableOf, forkJoin as observableForkJoin,  Observable } from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../app.config';
import { GridDataService } from '../../shared/services/grid-data.service';
import { ExternalTransmittalEditorModel, TransmittalExternalOptions, VoidTransmittalModel } from '../models/transmittal.models';
import { ProjectModel, SourceModel } from '../../setup/sources/models/source.models';
import { ValidationResultModel } from '../../shared/models/validation.models';
import { KendoGridDataConfig } from '../../shared/models/grid.models';

@Injectable()
export class TransmittalExternalService {
  readonly baseUrl: string = `${AppConfig.settings.apiUrl}/transmittals/external`;
  readonly gridName: string = 'transmittals-external';

  constructor(
    private http: HttpClient,
    private gridData: GridDataService
  ) { }

  get(id: number): Observable<ExternalTransmittalEditorModel> {
    const url = !!id ? `${this.baseUrl}/${id}/edit` : `${this.baseUrl}/edit`;
    return this.http.get<ExternalTransmittalEditorModel>(url);
  }

  getGridDataConfig(): KendoGridDataConfig {
    return {
      name: this.gridName,
      url: this.baseUrl
    };
  }

  void(model: VoidTransmittalModel): Observable<any> {
    return this.http.post(`${this.baseUrl}/${model.id}/void`, model);
  }

  delete(id: number): Observable<any> {
    return this.http.post(`${this.baseUrl}/${id}/delete`, null);
  }

  update(editor: ExternalTransmittalEditorModel) {
    const url = !!editor.id ? `${this.baseUrl}/${editor.id}/edit` : `${this.baseUrl}/edit`;
    return this.http.post<ValidationResultModel>(url, editor);
  }

  getSourceOptions(editor: ExternalTransmittalEditorModel): Observable<SourceModel[]> {
    const url = `${this.baseUrl}/edit/sourceoptions`;
    return this.http.post<SourceModel[]>(url, editor);
  }

  getAdditionalSourceOptions(editor: ExternalTransmittalEditorModel, searchText?: string): Observable<SourceModel[]> {
    let url = `${this.baseUrl}/edit/sourceoptions`;
    if (!!searchText) {
      url += `?searchText=${searchText}`;
    }
    return this.http.post<SourceModel[]>(url, editor);
  }

  getProjectOptions(editor: ExternalTransmittalEditorModel): Observable<ProjectModel[]> {
    const url = `${this.baseUrl}/edit/projectoptions`;
    return this.http.post<ProjectModel[]>(url, editor);
  }

  getAdditionalProjectOptions(editor: ExternalTransmittalEditorModel, searchText?: string): Observable<ProjectModel[]> {
    let url = `${this.baseUrl}/edit/projectoptions`;
    if (!!searchText) {
      url += `?searchText=${searchText}`;
    }
    return this.http.post<ProjectModel[]>(url, editor);
  }

  getTransmittalEditorOptions(editor: ExternalTransmittalEditorModel): Observable<TransmittalExternalOptions> {
    const options = new TransmittalExternalOptions();
    return observableForkJoin(
      this.getSourceOptions(editor),
      this.getProjectOptions(editor)
    ).pipe(mergeMap(results => {
      options.sourceOptions = results[0];
      options.projectOptions = results[1];
      return observableOf(options);
    }));
  }
}
