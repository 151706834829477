
import {finalize} from 'rxjs/operators';
import { Component, OnInit, Output, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { DocumentService } from '../../services/document.service';

@Component({
  selector: 'app-document-link',
  templateUrl: './document-link.component.html',
  styleUrls: ['./document-link.component.scss']
})
export class DocumentLinkComponent implements OnInit {
  @Output() success: Subject<any> = new Subject();
  @Output() cancelled: Subject<any> = new Subject();
  @ViewChild('dialogContent', {static: false}) content;
  @ViewChild('dialogActions', {static: false}) actions;

  hasLoaded: boolean;
  loading: boolean;
  succeeded: boolean;
  ids: number[] = [];

  private dialogRef: DialogRef;

  constructor(
    private dialogService: DialogService,
    private documentService: DocumentService
  ) { }

  ngOnInit() {
  }

  open(ids: number[] = []) {
    this.dialogRef = this.dialogService.open({
      title: 'Link Documents',
      content: this.content,
      actions: this.actions
    });

    setTimeout(() => {
      $('kendo-dialog-actions button').last().focus();
    })

    this.ids = ids;
  }

  submit() {
    this.loading = true;
    this.documentService.link(this.ids).pipe(
      finalize(() => this.loading = false))
      .subscribe(res => {
        this.succeeded = this.succeeded = true;
        this.success.next();
      }, err => { })
  }

  cancel() {
    this.cancelled.next();
    this.close();
  }

  close() {
    this.succeeded = false;
    this.dialogRef.close();
    this.dialogRef.dialog.destroy();
  }
}
