
import {finalize} from 'rxjs/operators';
import { Component, OnInit, Output, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { DeletedEvent } from '../../../transmittals/models/transmittal.models';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { HistoricalDocumentService } from '../../services/historical-document.service';
import { HistoricalImportGridRowModel } from '../../models/historical-document.models';

@Component({
  selector: 'app-historical-document-delete-all-import',
  templateUrl: './historical-document-delete-all-import.component.html',
  styleUrls: ['./historical-document-delete-all-import.component.scss']
})
export class HistoricalDocumentDeleteAllImportComponent implements OnInit {
  @Output() onSuccess: Subject<DeletedEvent> = new Subject();
  @ViewChild('dialogContent', {static: false}) content;
  @ViewChild('dialogActions', {static: false}) actions;

  hasLoaded: boolean;
  loading: boolean;
  succeeded: boolean;
  title: string;
  subtitle: string;
  importId: number;
  importFilename: string;
  importDate: string;
  importCount: number;
  chosen: boolean = false;
  gridData: HistoricalImportGridRowModel;
  private dialogRef: DialogRef;

  constructor(
    private dialogService: DialogService,
    private historicalDocumentService: HistoricalDocumentService
  ) { }

  ngOnInit() {
  }

  open() {
    this.chosen = false;
    this.dialogRef = this.dialogService.open({
      title: 'Delete Import',
      content: this.content,
      actions: this.actions,
      width: 750
    });

    this.historicalDocumentService.getImportData()
      .subscribe(result => {
        this.gridData = result.data;
      })

    setTimeout(() => {
      $('kendo-dialog-actions button').last().focus();
    })

    this.succeeded = false;
  }

  deleteAll(dataItem: any) {
    this.loading = true;
    this.hasLoaded = false;
    this.chosen = true;


    this.importId = dataItem.id;
    this.importFilename = dataItem.fileName;
    this.importDate = (new Date(dataItem.date)).toString();

    this.historicalDocumentService.getImportTotalRowCount(dataItem.id).subscribe(importCount => {
      this.importCount = importCount;
      this.loading = false;
      this.hasLoaded = true;
    });
  }

  submit() {
    this.loading = true;
    this.historicalDocumentService.deleteAllImport(this.importId).pipe(
      finalize(() => this.loading = false))
      .subscribe(res => {
        this.succeeded = true;
        this.onSuccess.next({ id: this.importId });
      }, err => { })
  }

  close() {
    this.dialogRef.close();
    this.dialogRef.dialog.destroy();
  }
}
