import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { RoleService } from '../../services/role.service';
import { RoleEditorModel, RoleInstancePermissionsModel, PermissionItemBaseModel, UserSimpleGridRowModel, RoleUserLinkModel } from '../../models/role.models';
import { PermissionModel, UserModel } from '../../../users/models/user.models';
import { RolePermissions } from '../../../../shared/models/permissions.models';
import { element } from 'protractor';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';
import { ViewUsersComponent } from '../view-users/view-users.component';
import { AddedUsersGridComponent } from '../added-users-grid/added-users-grid.component';
import { HttpErrorResponse } from '@angular/common/http';
import { ValidationResultModel } from '../../../../shared/models/validation.models';

@Component({
  selector: 'app-role-edit',
  templateUrl: './role-edit.component.html',
  styleUrls: ['./role-edit.component.scss']
})
export class RoleEditComponent implements OnInit {
  @ViewChild('usersGrid', {static: false}) userGrid: AddedUsersGridComponent;

  validationResult: ValidationResultModel;
  hasLoaded: boolean = false;
  loading: boolean = false;
  title: string;
  updated: boolean = false;
  role: RoleEditorModel = new RoleEditorModel;
  permissionsInstance: RoleInstancePermissionsModel;
  isAdminRole: boolean;
  userSearchOptions: UserModel[] = [];
  addedUsers: RoleUserLinkModel[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private roleService: RoleService
  ) { }

  ngOnInit() {
    this.loading = true;
    this.role = new RoleEditorModel;
    this.route.params.subscribe(params => {
      let id = params['id'] as number;

      this.roleService.getRole(id)
        .subscribe(role => {
          this.role = role;
          let index = this.role.permissionsAll.findIndex(e => e.id == "accessDocuments");
          this.role.permissionsAll[index].selected = true;
          this.addedUsers = role.users;
          if (role.name == "Administrator")
            this.isAdminRole = true;
          this.title = id ? (this.role.permissionsInstance.canEdit ? 'Edit' : 'View') + ' Role' : 'Role Setup';
          this.applySelectedPermissions();
          this.updateUserOptions();
          this.hasLoaded = true;
          this.loading = false;
        }, err => { });
    })
  }

  applySelectedPermissions() {
    this.role.permissions.forEach(element => {
      let i = this.role.permissionsAll.findIndex(e => e.id == element.id);
      if (i != -1)
        this.role.permissionsAll[i].selected = true;
    });
  }

  userSelected(user: UserSimpleGridRowModel) {
    if (!user)
      return;
    let index = this.userSearchOptions.findIndex(e => e.id == user.id);
    this.userSearchOptions.splice(index, 1);
    this.userGrid.addUser(user);
  }

  onUserFilterChanged(searchText: string) {
    this.roleService.getUserOptions(this.role, searchText).subscribe(users => {
      this.userSearchOptions = users;
    });
  }

  itemRemovedReceived(item: RoleUserLinkModel){
    this.updateUserOptions();
  }

  updateUserOptions(){
    this.roleService.getUserOptions(this.role).subscribe(users => {
      this.userSearchOptions = users;
    });
  }

  submit($event: any) {
    this.validationResult = null;
    this.role.permissions.length = 0;
    this.role.permissionsAll.forEach(element => {
      if (element.selected == true) {
        let i = this.role.permissions.findIndex(e => e.id == element.id);
        if (i == -1)
          this.role.permissions.push(element);
      }
    });
    this.roleService.updateRole(this.role).subscribe(res => {
      this.updated = true;
      this.scroll();
      setTimeout(() => {
        this.router.navigateByUrl('/setup/roles');
      }, 1500);
    }, err => {
      if (err instanceof HttpErrorResponse && err.status == 412) {
        this.validationResult = err.error;
      }
    })
  }

  cancel(){
    this.router.navigateByUrl("/setup/roles");
  }

  filterRolesByGroupId(groupId){
    return this.role.permissionsAll.filter(e => e.groupId == groupId);
  }

  private scroll(): void {
    setTimeout(() => {
      window.scrollToTop();
    });
  }
}
