import { OnInit, Input, Component, Output, EventEmitter, ViewChild } from '@angular/core';
import { GridComponent} from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import { KendoColumnResizeArgs } from '../../../shared/utils/kendo.utils';
import { JsonParseService } from '../../../shared/services/json-parse.service';
// import { ColumnSetting } from '../../../shared/models/grid.models';

@Component({
  selector: 'pt-kendo-grid',
  templateUrl: './pt-kendo-grid.component.html',
})
export class PtKendoGridComponent implements OnInit {
  @Input() baseApiUrl: string;
  @Input() gridName: string;
  @Input() cache = true;
  @Input() filters: any;
  // @Input() columns: ColumnSetting[] = [];
  @Output() columnsChange = new EventEmitter();
  state: State;

  @ViewChild('grid', {static: false}) grid: GridComponent;

  private readonly stateSuffix: string = '-grid-state';
  private readonly filtersSuffix: string = '-grid-filters';
  private readonly columnsSuffix: string = '-grid-columns';

  constructor(
    private jsonParse: JsonParseService
  ) {
  }

  ngOnInit() {
    if (!this.baseApiUrl) {
      throw new Error('pt-kendo-grid requires [baseApiUrl] to be set');
    }

    if (!this.gridName) {
      throw new Error('pt-kendo-grid requires [gridName] to be set');
    }

    if (this.cache) {
      this.applyGridSettings();
    }



    this.grid.dataStateChange.subscribe(state => {
      this.state = state;

      if (this.cache) {
        this.saveGridSettings();
      }
    });
  }

  resized(changes: KendoColumnResizeArgs[]) {
    // this.columnResize$.emit(changes);
  }

  saveGridSettings(): void {
    const columns = this.grid.columns;

    const gridConfig = {
      state: this.state,
      columnsConfig: columns.toArray().map(item =>
        Object.keys(item)
          .filter(propName => !propName.toLowerCase().includes('template'))
          .reduce((acc, curr) => ({ ...acc, ...{ [curr]: item[curr] } }), {})
      )
    };

    if (gridConfig.state) {
      localStorage.setItem(this.gridName + this.stateSuffix, JSON.stringify(gridConfig));
    }

    if (this.filters) {
      localStorage.setItem(this.gridName + this.filtersSuffix, JSON.stringify(this.filters));
    }
  }

  private applyGridSettings(): void {
    const configJson = localStorage.getItem(this.gridName + this.stateSuffix);
    const customFiltersJson = localStorage.getItem(this.gridName + this.filtersSuffix);

    if (configJson) {
      const config = JSON.parse(configJson);
      const stateObj = config.state;
      const columnsConfig = config.columnsConfig
        ? config.columnsConfig.sort((a, b) => a.orderIndex - b.orderIndex)
        : [];
      this.jsonParse.parseDates(stateObj);
      this.state = stateObj;
      this.columnsChange.emit(columnsConfig);
      localStorage.removeItem(this.gridName + this.stateSuffix);
    }

    if (customFiltersJson) {
      const customFiltersObj = JSON.parse(customFiltersJson);
      this.jsonParse.parseDates(customFiltersObj);
      this.filters = customFiltersObj;
      localStorage.removeItem(this.gridName + this.filtersSuffix);
    }
  }
}

interface GridSettings {
  state: State;
  columnsConfig: any[];
}
