
import {finalize} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { ProjectService } from '../../services/project.service';
import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import { ILoadable } from '../../../../shared/components/loading/loading.models';
import { IKendoGridComponent, KendoColumnResizeArgs } from '../../../../shared/utils/kendo.utils';
import { DialogService, DialogCloseResult } from '@progress/kendo-angular-dialog';
import { ProjectGridRowModel } from '../../models/project.models';
import { GridDataService } from '../../../../shared/services/grid-data.service';
import { GridColumnState, GridColumn } from '../../../../shared/models/grid.models';
import { SystemPermissions } from "../../../../shared/models/permissions.models";
import { PermissionService } from "../../../../shared/services/permission.service";

@Component({
  selector: 'app-project-grid',
  templateUrl: './project-grid.component.html',
  styleUrls: ['./project-grid.component.scss']
})
export class ProjectGridComponent implements OnInit, ILoadable, IKendoGridComponent {
  hasLoaded: boolean;
  loading: boolean;
  systemPermissions: SystemPermissions;
  filters: any;
  state: State = {
    skip: 0,
    take: 10
  };
  pageSizes: number[] = [5, 10, 20, 50, 100];
  gridData: GridDataResult;

  columnState: GridColumnState = new GridColumnState([
    { name: 'Project Number', field: 'number', defaults: { width: 210, sort: { dir: 'asc' } } },
    { name: 'Project Name', field: 'name', defaults: { width: 210 } },
    { name: 'Area', field: 'area', defaults: { width: 210 } },
    { name: 'Vessel(s)', field: 'vesselNames', defaults: { width: 210 } },
    { name: 'Client', field: 'mainSourceName', defaults: { width: 210 } },
    { name: 'Active', field: 'active', defaults: { width: 210 } },
  ]);

  constructor(
    private dialogService: DialogService,
    private projectService: ProjectService,
    private gridDataService: GridDataService,
    private permissionService: PermissionService
  ) { }

  ngOnInit() {
    this.loading = true;
    this.systemPermissions = this.permissionService.getPermissions();

    this.gridDataService.initColumnState(this);

    this.gridDataService.applyCachedState(this.projectService.gridName, this);

    this.updateGrid();
  }

  dataStateChange(state: DataStateChangeEvent): void {
    this.loading = true;
    this.state = state;
    this.updateGrid();
  }

  resetGrid() {
    this.loading = true;
    this.gridDataService.resetGrid(this.projectService.gridName, this.projectService.baseUrl, this)
      .subscribe(data => {
        this.gridData = data;
        this.loading = false;
      });
  }

  archive(item: ProjectGridRowModel) {
    this.showActionDialog('Archive', () => {
      this.projectService.archive(item.id).subscribe(
        () => {
          this.updateGrid();
          this.showAlert('Done', 'Archived successfully')
        },
        () => this.showAlert('Warning', 'It looks like something went wrong')
      )
    });
  }

  delete(item: ProjectGridRowModel) {
    this.showActionDialog('Delete', () => {
      this.projectService.delete(item.id).subscribe(
        () => {
          this.updateGrid();
          this.showAlert('Done', 'Deleted successfully')
        },
        () => this.showAlert('Warning', 'It looks like something went wrong')
      )
    });
  }

  changePageSizes(pageSize: number){
    this.state.take = pageSize;
    this.updateGrid();
  }

  resetColumnState() {
    this.gridDataService.resetColumnState(this.projectService.gridName, this);
  }
  
  resized(changes: KendoColumnResizeArgs[]) {
    let change = changes[0];
    let col = this.columnState.selected.find(c => c.field == change.column.field);
    col.width = change.newWidth;
    this.gridDataService.saveColumnState(this.projectService.gridName, this, true);
  }

  updateGrid() {
    this.projectService.getGridData(this.state).pipe(
      finalize(() => this.hasLoaded = true))
      .subscribe(data => {
        this.gridData = data;
        this.loading = false;
      })
  }

  private showActionDialog(action: string, handler: () => void) {
    const dialog = this.dialogService.open({
      title: action,
      content: `Are you sure you want to ${action.toLowerCase()} this project?`,
      actions: [
        { text: 'Cancel' },
        { text: 'Continue', primary: true, handler: handler },
      ]
    });

    setTimeout(() => {
      $('kendo-dialog-actions button').last().focus();
    })

    dialog.result.subscribe((result: any) => {
      if (result instanceof DialogCloseResult) {
        console.log("close");
      } else {
        if (result.handler)
          result.handler();
      }
    });
  }

  private showAlert(title: string, content: string) {
    const dialog = this.dialogService.open({
      title: title,
      content: content,
      actions: [
        { text: 'OK', primary: true }
      ]
    });

    setTimeout(() => {
      $('kendo-dialog-actions button').last().focus();
    })
  }
}
