import { Component, OnInit, Input, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { State } from '@progress/kendo-data-query';
import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { DialogService } from '@progress/kendo-angular-dialog';
import { DocumentService } from '../../services/document.service';
import { IRevisionAddedEvent } from '../../models/revision.models';
import { DocumentGridRowModel } from '../../models/document.models';
import { DeletedEvent } from '../../../shared/models/events';
import { BriefcaseComponent } from '../briefcase/briefcase/briefcase.component';
import { Router } from '@angular/router';
import { KendoDialogRef } from '../../../shared/utils/kendo.utils';
import { DocumentLinksGridComponent } from '../document-links-grid/document-links-grid.component';
import { SystemPermissions } from "../../../shared/models/permissions.models";
import { PermissionService } from "../../../shared/services/permission.service";

@Component({
  selector: 'app-document-ims-children-grid',
  templateUrl: './document-ims-children-grid.component.html',
  styleUrls: ['./document-ims-children-grid.component.scss']
})
export class DocumentImsChildrenGridComponent implements OnInit {
  @Output() success: Subject<any> = new Subject();
  @Input() documentId: number;
  @Input() briefcase: BriefcaseComponent;

  hasLoaded: boolean;
  loading: boolean;
  systemPermissions: SystemPermissions;
  state: State = {
    skip: 0,
    take: 5
  };
  gridData: GridDataResult;

  constructor(
    private router: Router,
    private dialogService: DialogService,
    private documentService: DocumentService,
    private permissionService: PermissionService
  ) { }

  ngOnInit() {
    this.updateGrid();
  }

  dataStateChange(state: DataStateChangeEvent): void {
    this.loading = true;
    this.systemPermissions = this.permissionService.getPermissions();
    this.state = state;
    this.updateGrid();
  }

  edit(item: DocumentGridRowModel) {
    let url = `/documents/${item.id}/edit`;
    if (item.isExternal)
      url += `/${item.categoryId}`;
    this.router.navigateByUrl(url);
  }

  onRevisionAdded($event: IRevisionAddedEvent) {
    this.updateGrid();
  }

  onVoided() {
    this.updateGrid();
  }

  onDeleted($event: DeletedEvent) {
    if (this.briefcase.contains($event.id))
      this.briefcase.toggleItems([{ id: $event.id } as any]);

    this.updateGrid();
  }

  openLinked(item: DocumentGridRowModel) {
    let dialogRef: KendoDialogRef<DocumentLinksGridComponent> = this.dialogService.open({
      title: `${item.identifier} | ${item.latestRevisionTitle}`,
      content: DocumentLinksGridComponent,
      actions: [
        { text: 'Close', primary: true }
      ],
      width: 1300
    });

    setTimeout(() => {
      $('kendo-dialog-actions button').last().focus();
    })

    dialogRef.content.instance.documentId = item.id;
  }

  updateGrid() {
    // this.documentId = this.documentId;
    // this.documentService.getImsChildGridData(this.state, this.documentId)
    //   .finally(() => {
    //     this.hasLoaded = true
    //   })
    //   .subscribe(data => {
    //     this.gridData = data;
    //     this.loading = false;
    //   });
  }
}
