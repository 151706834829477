import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import { IValidatable, ValidationResultModel } from '../../../shared/models/validation.models';
import { ILoadable } from '../../../shared/components/loading/loading.models';
import { Observable } from 'rxjs';
import { TransmittalEditorModel } from '../../models/transmittal.models';
import { TransmittalService } from '../../services/transmittal.service';
import { TransmittalEditorService } from '../../services/transmittal-editor.service';
import { HttpErrorResponse } from '@angular/common/http';
import { TransmittalPermissions } from '../../../shared/models/permissions.models';

@Component({
  selector: 'app-transmittal-saved-form',
  templateUrl: './transmittal-saved-form.component.html',
  styleUrls: ['./transmittal-saved-form.component.scss']
})
export class TransmittalSavedFormComponent implements OnInit, IValidatable, ILoadable {
  @ViewChild('pdfPreviewForm', {static: false}) pdfPreviewForm: ElementRef;
  @Input('permissions') permissions: any;

  loading: boolean;
  hasLoaded: boolean;
  succeeded: boolean;
  validationResult: ValidationResultModel;
  identifier: string;
  pdfDownloadUrl: string;
  editorJson: string;
  editor: TransmittalEditorModel;

  constructor(
    private transmittalService: TransmittalService,
    private transmittalEditorService: TransmittalEditorService,
  ) { }

  ngOnInit() {
    this.hasLoaded = true;
    this.setPdfUrl(this.editor.id);
    // Do something
  }

  getPdf() {
    if (this.editor.issued) {
      this.editorJson = JSON.stringify(this.editor);
      setTimeout(() => {
        this.pdfPreviewForm.nativeElement.submit();
      })
    }
    else {
      let tabWindowId = window.open("about:blank", "_blank");
      setTimeout(() => {
        tabWindowId.location.href = this.pdfDownloadUrl;
      });
    }
  }

  private setPdfUrl(id?: number) {
    if (!this.editor.issued)
      this.pdfDownloadUrl = this.transmittalService.getPreviewLink(this.editor.id);
    else
      this.pdfDownloadUrl = this.transmittalService.getDownloadLink(this.editor.id);
  }
}
