
import {finalize} from 'rxjs/operators';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ILoadable } from '../../../shared/components/loading/loading.models';
import { IKendoGridComponent, KendoGridDataResult, KendoColumnResizeArgs } from '../../../shared/utils/kendo.utils';
import { State } from '@progress/kendo-data-query';
import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { GridColumn, GridColumnState } from '../../../shared/models/grid.models';
import { HistoricalDocumentGridRowModel } from '../../models/historical-document.models';
import { GridDataService } from '../../../shared/services/grid-data.service';
import { HistoricalDocumentService } from '../../services/historical-document.service';
import { Router } from '@angular/router';
import * as Moment from 'moment';
import { HistoricalDocumentDeleteComponent } from '../historical-document-delete/historical-document-delete.component';
import { SystemPermissions } from "../../../shared/models/permissions.models";
import { PermissionService } from "../../../shared/services/permission.service";
import { HistoricalDocumentDeleteAllImportComponent } from '../historical-document-delete-all-import/historical-document-delete-all-import.component';

@Component({
  selector: 'app-historical-documents-grid',
  templateUrl: './historical-documents-grid.component.html',
  styleUrls: ['./historical-documents-grid.component.scss']
})
export class HistoricalDocumentsGridComponent implements OnInit, ILoadable, IKendoGridComponent {
  @ViewChild('deleteDialog', {static: false}) deleteDialog: HistoricalDocumentDeleteComponent;
  @ViewChild('deleteAllImportDialog', {static: false}) deleteAllImportDialog: HistoricalDocumentDeleteAllImportComponent;

  systemPermissions: SystemPermissions;
  state: State = {
    skip: 0,
    take: 20
  };
  pageSizes: number[] = [5, 10, 20, 50, 100];
  filters?: any = [];
  gridData: KendoGridDataResult<HistoricalDocumentGridRowModel>;
  columnState: GridColumnState = new GridColumnState([
    { name: 'Classification', field: 'categoryName', defaults: { width: 144 } },
    { name: 'Project Name', field: 'projectName', defaults: { width: 144 } },
    { name: 'Type', field: 'type', defaults: { width: 180 } },
    { name: 'Control Number', field: 'controlNumber', defaults: { width: 130 } },
    { name: 'Doc No.', field: 'identifier', defaults: { width: 130 } },
    { name: 'Reference', field: 'reference', defaults: { width: 130 } },
    { name: 'External Reference', field: 'externalReference', defaults: { width: 130 } },
    { name: 'External Doc No.', field: 'externalDocumentNumber', defaults: { width: 130 } },
    { name: 'Received Filename', field: 'receivedFilename', defaults: { width: 130 } },
    { name: 'Filename', field: 'filename', defaults: { width: 130 } },
    { name: 'File Path', field: 'filePath', defaults: { width: 130 } },
    { name: 'Source', field: 'source', defaults: { width: 130 } },
    { name: 'Vessels', field: 'vessels', defaults: { width: 130 } },
    { name: 'Revision Number', field: 'revisionIdentifier', defaults: { width: 130 } },
    { name: 'Revision Title', field: 'revisionTitle', defaults: { width: 130 } },
    { name: 'Import File', field: 'importFileName', defaults: { width: 200 } },
    { name: 'Import Date', field: 'importDate', defaults: { width: 170, sort: { dir: 'desc' } } }
  ]);
  hasLoaded: boolean;
  loading: boolean;
  disabled: boolean;
  importToDelete: string;
  importData: string;

  constructor(
    private router: Router,
    private gridDataService: GridDataService,
    private historicalDocService: HistoricalDocumentService,
    private permissionService: PermissionService
  ) { }

  ngOnInit() {
    this.loading = true;
    this.disabled = true;
    this.systemPermissions = this.permissionService.getPermissions();

    this.gridDataService.initColumnState(this);
    this.gridDataService.applyCachedState(this.historicalDocService.gridName, this);


    this.historicalDocService.getImportData()
    .subscribe(result => {
      this.importData = result;
    })

    this.filters.displayDate = this.filters.date ? new Date(this.filters.date) : null;

    this.updateGrid();
  }

  importSelected(event: any){
    this.importToDelete = event;
  }

  dateFilterChanged(value: Date) {
    this.loading = true;
    this.state.skip = 0;
    this.filters.date = this.filters.displayDate ? Moment(this.filters.displayDate).format() : null;
    this.updateGrid();
  }

  clearDateFilter() {
    this.filters.displayDate = null;
    this.dateFilterChanged(null);
  }

  dataStateChange(state: DataStateChangeEvent): void {
    this.loading = true;
    this.state = state;
    this.updateGrid();
  }

  changePageSizes(pageSize: number){
    this.state.take = pageSize;
    this.updateGrid();
  }

  columnStateChanged($event: GridColumn[]) {
    this.gridDataService.saveColumnState(this.historicalDocService.gridName, this);
  }

  resetColumnState() {
    this.gridDataService.resetColumnState(this.historicalDocService.gridName, this);
  }

  resized(changes: KendoColumnResizeArgs[]) {
    let change = changes[0];
    let col = this.columnState.selected.find(c => c.field == change.column.field);
    col.width = change.newWidth;
    this.gridDataService.saveColumnState(this.historicalDocService.gridName, this, true);
  }

  updateGrid(): void {
    this.gridDataService.getGridData(this.historicalDocService.gridName, this.historicalDocService.baseUrl, this.state, this.filters).pipe(
      finalize(() => {
        this.hasLoaded = true
      }))
      .subscribe(data => {
        this.gridData = data;
        this.loading = false;
      });
  }

  resetGrid() {
    this.loading = true;
    this.gridDataService.resetGrid(this.historicalDocService.gridName, this.historicalDocService.baseUrl, this)
      .subscribe(data => {
        this.gridData = data;
        this.loading = false;
      });
  }

  create(row: HistoricalDocumentGridRowModel) {
    this.router.navigate([`/documents/edit`], { queryParams: { historical: row.id } });
  }

  view(row: HistoricalDocumentGridRowModel){

  }

  edit(row: HistoricalDocumentGridRowModel) {
    if (!row.documentId)
      return;

    this.router.navigate([`/documents/${row.documentId}/edit`], { queryParams: { historical: true } });
  }

  delete(row: HistoricalDocumentGridRowModel) {
    this.deleteDialog.open(row.id);
  }

  deleteAll(){
    this.deleteAllImportDialog.open();
  }

  onDeleted(event: any) {
    this.updateGrid();
  }
}
