import {of as observableOf, forkJoin as observableForkJoin,  Subject ,  Observable } from 'rxjs';
import {finalize, mergeMap} from 'rxjs/operators';
import {Component, OnInit, Input, Output, ViewChild, EventEmitter} from '@angular/core';
import { ILoadable } from '../../../shared/components/loading/loading.models';
import { IKendoGridComponent, KendoGridDataResult } from '../../../shared/utils/kendo.utils';
import { State, process } from '@progress/kendo-data-query';
import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import {
  TransmittalEditorModel, TransmittalDocumentLinkEditorModel,
  TransmittalInstancePermissionsModel
} from '../../models/transmittal.models';
import { TransmittalEditorService } from '../../services/transmittal-editor.service';
import { DocumentModel } from '../../../documents/models/document.models';
import { RevisionReasonModel } from '../../../setup/revision-reasons/models/revision-reason.models';
import { RevisionModel } from '../../../documents/models/revision.models';
import documentSearchGridConfig from './transmittal-document-search-grid.config';
import {ReorderListActions, ReorderListPermissions} from '../../../shared/models/reorder-list.models';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {TransmittalDocumentReorderListComponent} from '../transmittal-document-reorder-list/transmittal-document-reorder-list.component';

@Component({
  selector: 'app-transmittal-document-search-grid',
  templateUrl: './transmittal-document-search-grid.component.html',
  styleUrls: ['./transmittal-document-search-grid.component.scss'],
  inputs: ['gridData'],
})
export class TransmittalDocumentSearchGridComponent implements OnInit, ILoadable {
  @Input() editor: TransmittalEditorModel;
  @Output() change: Subject<TransmittalDocumentLinkEditorModel[]> = new Subject();
  @Output() orderChange: EventEmitter<void> = new EventEmitter();
  @ViewChild('documentReorderList', null) documentReorderList: TransmittalDocumentReorderListComponent;

  documentSearchGridConfig = documentSearchGridConfig;
  reorderListPermissions: ReorderListPermissions;
  reorderListActions: ReorderListActions = {hasEditButton: false, hasDeleteButton: true, hasArchiveButton: false, hasViewButton: false};
  hasLoaded: boolean;
  loading: boolean;
  permissions: TransmittalInstancePermissionsModel;
  state: State = {
    skip: 0,
    take: 5000,
    sort: [
      { dir: 'desc', field: 'documentId' }
    ]
  };
  gridData: KendoGridDataResult<TransmittalDocumentLinkEditorModel>;
  reasonOptions: RevisionReasonModel[] = [];
  defaultReason: RevisionReasonModel;

  constructor(
    private transmittalService: TransmittalEditorService
  ) {
    this.reorderListPermissions = {
      canViewListItem: true,
      canEditListItem: true,
      canArchiveListItem: true,
      canDeleteListItem: true,
    };
  }

  ngOnInit() {
    this.loading = true;
    this.gridData = process(this.editor.documentLinks, this.state);
    this.permissions = this.editor.permissions;
    this.reorderListPermissions.canEditListItem = this.permissions.canEdit;
    this.defaultReason = new RevisionReasonModel;
    this.defaultReason.name = 'Choose Reason';
    this.defaultReason.acronym = 'CR';
    this.defaultReason.guid = null;
    this.defaultReason.id = -1;

    this.refreshGridRevisionOptions();

    this.transmittalService.getReasonOptions(this.editor).subscribe(options => this.reasonOptions = options);
  }

  refreshGridRevisionOptions() {
    return this.transmittalService.getBulkRevisionOptions(this.editor, this.gridData.data.map(d => d.revisionDocumentId))
      .subscribe(options => {
        this.gridData.data.forEach(link => {
          const index = options.findIndex(o => o.documentId === link.revisionDocumentId);
          link.revisionOptions =  this.filterRevisionOptions(link.revisionDocumentId, options[index].revisionOptions, link.revisionId);
        });

        this.hasLoaded = true;
        return observableOf(true);
      });

    // const obs = this.gridData.data.map(link => {
    //   return this.transmittalService
    //     .getRevisionOptions(this.editor, link.revisionDocumentId).pipe(
    //     mergeMap(options => {
    //       link.revisionOptions = this.filterRevisionOptions(link.revisionDocumentId, options, link.revisionId);
    //       return observableOf(true);
    //     }));
    // });

    // observableForkJoin(obs).pipe(finalize(() => {
    //   this.hasLoaded = true;
    // })).subscribe();
  }

  add(document: DocumentModel, editor: TransmittalEditorModel): Observable<TransmittalDocumentLinkEditorModel> {
    return this.addDoc(document, editor).pipe(mergeMap(res => {
      this.change.next(this.editor.documentLinks);
      this.gridData = process(this.editor.documentLinks, this.state);
      this.loading = false;
      this.refreshGridRevisionOptions();
      return observableOf(res);
    }));
  }

  addMany(documents: DocumentModel[], editor: TransmittalEditorModel) {
    this.loading = true;
    const obs = documents.map(doc => this.addDoc(doc, editor));
    return observableForkJoin(obs).pipe(
      finalize(() => {
        this.change.next(this.editor.documentLinks);
        this.gridData = process(this.editor.documentLinks, this.state);
        this.loading = false;
      }));
  }

  dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.gridData = process(this.editor.documentLinks, this.state);
  }

  remove(row: TransmittalDocumentLinkEditorModel) {
    const i = this.editor.documentLinks.indexOf(row);
    this.editor.documentLinks.splice(i, 1);
    this.gridData = process(this.editor.documentLinks, this.state);
    this.updateSelectedDocumentRevisionOptions(row.revisionDocumentId, row.revisionOptions);
    this.orderChange.emit();
  }

  private addDoc(document: DocumentModel, editor: TransmittalEditorModel): Observable<TransmittalDocumentLinkEditorModel> {
    return this.transmittalService.getRevisionOptions(editor, document.id).pipe(mergeMap(options => {
      const link = new TransmittalDocumentLinkEditorModel();

      link.revisionDocumentId = document.id;
      link.revisionDocumentIdentifier = document.identifier;
      link.revisionOptions = this.filterRevisionOptions(document.id, options);
      link.revisionId = link.revisionOptions[0].id;

      this.documentReorderList.onRevisionValueChange(link.revisionId, link);

      this.editor.documentLinks.push(link);
      return observableOf(link);
    }));
  }

  private updateSelectedDocumentRevisionOptions(documentId: number, options: RevisionModel[]) {
    this.editor.documentLinks
      .filter(e => e.revisionDocumentId === documentId)
      .forEach(l => {
        this.transmittalService.getRevisionOptions(this.editor, documentId).subscribe(revOptions => {
          l.revisionOptions = this.filterRevisionOptions(documentId, revOptions, l.revisionId);
        });
      });
  }

  private filterRevisionOptions(documentId: number, options: RevisionModel[], selectedRevisionId?: number): RevisionModel[] {
    const docSelected = this.editor.documentLinks.some(e => e.revisionDocumentId === documentId);
    const filtered = !docSelected ? options : options
      .filter(option => !this.editor.documentLinks.map(d => d.revisionId).includes(option.id));

    if (!!selectedRevisionId && !filtered.some(e => e.id === selectedRevisionId)) {
      filtered.unshift(options.find(e => e.id === selectedRevisionId));
    }

    return filtered;
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.editor.documentLinks, event.previousIndex, event.currentIndex);
    this.orderChange.emit();
  }
}
