import {finalize} from 'rxjs/operators';
import {Component, OnInit, ViewChild} from '@angular/core';
import {UserService} from '../../services/user.service';
import {DataStateChangeEvent, GridComponent, GridDataResult} from '@progress/kendo-angular-grid';
import {State} from "@progress/kendo-data-query";
import {PermissionModel, RoleModel, UserGridFiltersModel} from '../../models/user.models';
import {ILoadable} from '../../../../shared/components/loading/loading.models';
import {IKendoGridComponent, KendoColumnResizeArgs} from '../../../../shared/utils/kendo.utils';
import {GridDataService} from '../../../../shared/services/grid-data.service';
import {GridColumnState} from '../../../../shared/models/grid.models';
import {Router} from "@angular/router";
import {SystemPermissions} from "../../../../shared/models/permissions.models";
import {PermissionService} from "../../../../shared/services/permission.service";

@Component({
  selector: 'app-user-grid',
  templateUrl: './user-grid.component.html',
  styleUrls: ['./user-grid.component.scss']
})
export class UserGridComponent implements OnInit, ILoadable, IKendoGridComponent {
  @ViewChild('grid', {static: false}) grid?: GridComponent;

  hasLoaded: boolean = false;
  loading: boolean = false;
  importUsersLoading: boolean = false;

  gridData: GridDataResult;
  state: State = {
    skip: 0,
    take: 10
  };

  columnState: GridColumnState = new GridColumnState([
    { name: 'Login ', field: 'login', defaults: { width: 250 } },
    { name: 'Username', field: 'username', defaults: { width: 250, sort: { dir: 'asc' } } },
    { name: 'Forename', field: 'forename', defaults: { width: 250 } },
    { name: 'Surname', field: 'surname', defaults: { width: 250 } },
    { name: 'Active', field: 'active', defaults: { width: 170 } },
    { name: 'Creation Time', field: 'activeDirectoryCreationTime', defaults: { width: 275 } },
    { name: 'Roles Time', field: 'rolesText', defaults: { width: 364 } },
  ]);

  filters: UserGridFiltersModel = new UserGridFiltersModel();
  roleOptions: RoleModel[] = [];
  permissionOptions: PermissionModel[] = [];
  systemPermissions: SystemPermissions;

  constructor(
    private userService: UserService,
    private router: Router,
    private gridDataService: GridDataService,
    private permissionService: PermissionService
  ) { }

  ngOnInit() {
    this.loading = true;

    this.userService.getFilterRoleOptions().subscribe(roles => this.roleOptions = roles);
    this.userService.getPermissionOptions().subscribe(permissions => this.permissionOptions = permissions);

    this.gridDataService.initColumnState(this);
    this.gridDataService.applyCachedState(this.userService.gridName, this);

    this.permissionService.loadPermissions().subscribe(() => {
      this.systemPermissions = this.permissionService.getPermissions();
    });

    this.updateGrid();
  }

  dataStateChange(state: DataStateChangeEvent): void {
    this.loading = true;
    this.state = state;
    this.updateGrid();
  }

  resetColumnState() {
    this.gridDataService.resetColumnState(this.userService.gridName, this);
  }

  resized(changes: KendoColumnResizeArgs[]) {
    let change = changes[0];
    let col = this.columnState.selected.find(c => c.field == change.column.field);
    col.width = change.newWidth;
    this.gridDataService.saveColumnState(this.userService.gridName, this, true);
  }


  roleOptionChanged(roles: PermissionModel[]) {
    this.loading = true;
    this.state.skip = 0;
    this.updateGrid();
  }

  permissionOptionChanged(permissions: PermissionModel[]) {
    this.loading = true;
    this.state.skip = 0;
    this.updateGrid();
  }

  resetGrid() {
    this.loading = true;
    this.gridDataService.resetGrid(this.userService.gridName, this.userService.baseUrl, this)
      .subscribe(data => {
        this.gridData = data;
        this.loading = false;
      });
  }

  updateGrid() {
    this.userService.getGridData(this.state, this.filters).pipe(
      finalize(() => {
        this.hasLoaded = true;
      })).subscribe(gridData => {
      this.gridData = gridData;
      this.loading = false;
    });
  }

  addUser() {
    this.router.navigate(['setup/users/create']);
  }
}
