import { Injectable } from '@angular/core';

@Injectable()
export class JsonParseService {

    constructor() { }

    parseDates(model: any) {
        for (let i in model) {
            if (typeof (model[i]) == "object") {
                this.parseDates(model[i]);
                continue;
            }
            else if (typeof (model[i]) != "string" || (<string>model[i]).indexOf(':') == -1) {
                continue;
            }

            let date = Date.parse(model[i]);
            if (!isNaN(date))
                model[i] = new Date(model[i]);
        }
    }
}
