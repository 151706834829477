import {catchError, map, tap} from 'rxjs/operators';
import {
  HttpErrorResponse,
  HttpHandler,
  HttpHeaderResponse,
  HttpInterceptor,
  HttpProgressEvent,
  HttpRequest,
  HttpResponse,
  HttpSentEvent,
  HttpUserEvent
} from '@angular/common/http';
import {Observable, throwError} from "rxjs";

import {Router} from '@angular/router';
import {Injectable, Injector} from '@angular/core';
import {AuthenticationResult, PopupRequest} from "@azure/msal-browser";
import {MsalService} from "@azure/msal-angular";
import {AppConfig} from "../../app.config";
import {ToastrService} from "ngx-toastr";
import {AuthService} from "./auth.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private authService: AuthService;

  constructor(
    private router: Router,
    private injector: Injector,
    private toastr: ToastrService,
    private msalService: MsalService
  ) {
    this.authService = injector.get(AuthService);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
    return next.handle(this.transformRequest(req)).pipe(
      catchError(err => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            if (req && req.url.includes('auth/token')) {
              // if refresh token url has errored, then refresh token is invalid, and they must re-login
              return this.logoutAndRedirect();
            } else if (req && req.url.includes('auth') && req.method === 'POST') {
              // if 401 on the login page, it's because they failed to log in - this gets handled in the login component so just rethrow
              return throwError(() => err);
            } else if (
              err.error &&
              err.error.includes &&
              err.error.includes('Session token invalid')
            ) {
              return this.logoutAndRedirect();
            } else {
              return this.handleUnauthorised();
            }
          } else if (err.status === 403) {
            return this.logoutAndRedirect();
          } else if (err.status === 500) {
            this.toastr.warning('Internal Server Error - contact a system administrator for assistance.', 'Something went wrong');
            return throwError(err);
          } else {
            return throwError(err);
          }
        }
      }));
  }
  private logoutAndRedirect()
    : Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {

    const request: PopupRequest = {
      scopes: [AppConfig.settings.azureADScope],
      prompt: 'consent',
    };
    const authRequestObservable$: Observable<AuthenticationResult> = this.msalService.loginPopup(request);

    return authRequestObservable$
      .pipe(
        tap((result: AuthenticationResult) => {
            if (result) {
              console.log(result);
              this.authService.saveToken(result.accessToken);
            }
          }
        ),
        map(() => null),
      );
  }


  private handleUnauthorised() {
    return this.logoutAndRedirect().pipe(
      tap(() => {
        window.location.reload();
      })
    );
  }
  private transformRequest(request: HttpRequest<any>, force: boolean = false) {
    request = this.addAuthHeaders(request, force);

    return request;
  }

  private addAuthHeaders(request: HttpRequest<any>, force: boolean = false): HttpRequest<any> {
    // If it's not being sent to our endpoint, don't add the auth headers
    if (!request.url.includes(AppConfig.settings.apiUrl)) {
      return request;
    }

    if (request.headers.has('Authorization') && !force) {
      return request;
    } else {
      const authHeader = this.authService.getAuthHeader();
      return request.clone({ setHeaders: { Authorization: authHeader }, withCredentials: true });
    }
  }
}
