import { Component, OnInit, Input } from '@angular/core';
import { IValidatable, ValidationResultModel } from '../../../shared/models/validation.models';
import { ILoadable } from '../../../shared/components/loading/loading.models';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-document-saved-form',
  templateUrl: './document-saved-form.component.html',
  styleUrls: ['./document-saved-form.component.scss']
})
export class DocumentSavedFormComponent implements OnInit, IValidatable, ILoadable {
  loading: boolean;
  hasLoaded: boolean;
  succeeded: boolean;
  validationResult: ValidationResultModel;
  filename: string;
  otherFilenames: string[] = [];
  
  constructor(
  ) { }

  ngOnInit() {
    this.hasLoaded = true;
    // Do something
  }
}
