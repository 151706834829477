import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from "@angular/router";
import { KendoDialogRef } from "../../../shared/utils/kendo.utils";
import { DialogService } from "@progress/kendo-angular-dialog";
import { DocumentImportResponseModel } from "../../models/document.models";

@Component({
  selector: 'app-document-import-confirmation',
  templateUrl: './document-import-confirmation.component.html',
  styleUrls: ['./document-import-confirmation.component.scss']
})
export class DocumentImportConfirmationComponent implements OnInit {
  @ViewChild('dialogContent', {static: false}) content;
  @ViewChild('dialogActions', {static: false}) actions;

  constructor(
    private router: Router,
    private dialogService: DialogService
  ) { }

  response: DocumentImportResponseModel;

  private dialogRef: KendoDialogRef<DocumentImportConfirmationComponent>;

  ngOnInit() {
  }

  open(response: DocumentImportResponseModel) {
    this.response = response;

    this.dialogRef = this.dialogService.open({
      title: 'Bulk Import Complete',
      content: this.content,
      actions: this.actions
    });

    setTimeout(() => {
      $('kendo-dialog-actions button').last().focus();
    });
  }

  close() {
    this.dialogRef.close();
    this.dialogRef.dialog.destroy();
  }

  continue() {
    close();
    this.router.navigateByUrl('/documents');
  }
}
