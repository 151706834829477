
import {finalize} from 'rxjs/operators';
import { Component, OnInit, Output, Input, ViewEncapsulation, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { State } from '@progress/kendo-data-query';
import { KendoGridDataResult } from '../../../shared/utils/kendo.utils';
import { DocumentGridRowModel, GetDocumentListRequestModel } from '../../models/document.models';
import { DocumentService } from '../../services/document.service';
import { DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { Router } from '@angular/router';
import { DocumentBatchSaveConfirmationComponent } from '../document-batch-save-confirmation/document-batch-save-confirmation.component';
import { GridColumnState, GridColumn } from '../../../shared/models/grid.models';
import { DocumentEditorService } from '../../services/document-editor.service';

let _batchDocumentId;

@Component({
  selector: 'app-duplication-batch-grid',
  templateUrl: './duplication-batch-grid.component.html',
  styleUrls: ['./duplication-batch-grid.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DocumentDuplicationBatchGridComponent implements OnInit {
  @Output() updated: Subject<DocumentGridRowModel[]> = new Subject();
  @Output() saveSelected: Subject<number> = new Subject();
  @Input() batchId: number;
  @Input() documentIds: number[] = [];
  @Input() documentId: number;
  @Input() saved: boolean;
  @ViewChild('duplicateSaveDialog', {static: false}) saveDialog: DocumentBatchSaveConfirmationComponent;

  hasLoaded: boolean;
  loading: boolean;
  state: State = {
    skip: 0,
    take: 100,
    sort: [
      { dir: "asc", field: "controlNumber" }
    ]
  };
  filters?: any;
  gridData: KendoGridDataResult<DocumentGridRowModel>;

  constructor(
    private router: Router,
    private documentService: DocumentService,
    private documentEditorService: DocumentEditorService
  ) { }

  ngOnInit() {
    _batchDocumentId = this.documentId;
    this.updateGrid();
  }

  dataStateChange(state: DataStateChangeEvent): void {
    this.loading = true;
    this.state = state;
    this.updateGrid();
  }

  updateGrid() {
    let request = new GetDocumentListRequestModel();
    request.documentIds = this.documentIds;
    this.documentService.getDocumentList(this.state, request).pipe(
      finalize(() => {
        this.hasLoaded = true
        this.loading = false;
      }))
      .subscribe(data => {
        this.gridData = data;
        // this.setEditedFlags(this.gridData.data);
        this.updated.next(data.data);
      });
  }

  updateBatchId(newId: number) {
    _batchDocumentId = newId;
  }

  edit(row: DocumentGridRowModel) {
    this.navigate(row.id);
  }

  onSaveAndContinue(nextId: number) {
    this.saveSelected.next(nextId);
  }

  rowClass(row: any) {
    return { selected: row.dataItem.id == _batchDocumentId, edited: row.dataItem.edited };
  }

  navigate(id: number) {
    this.router.navigate([`/documents/${id}/edit`], { queryParams: { duplicate: true } });
  }

  // private setEditedFlags(data: DocumentGridRowModel[]) {
  //   if (!this.edited)
  //     return;

  //   data.forEach(row => {
  //     (<any>row).edited = this.edited.some(id => row.id == id);
  //   });
  // }
}
