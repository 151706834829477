import { ApiEntityModel } from '../../shared/models/apiEntity.model';
import { DepartmentModel } from '../../setup/document-types/models/document-type.models';
import { ProjectModel, SourceModel } from '../../setup/sources/models/source.models';
import { VesselModel, VesselClassModel, VesselOptionModel } from '../../setup/vessels/models/vessel.models';
import { IBriefcaseItem } from "./briefcase.models";
import { TransmittalExternalGridRowModel, TransmittalGridRowModel } from "../../transmittals/models/transmittal.models";

export class DocumentInstancePermissionsModel {
    canView: boolean;
    canEdit: boolean;
    canEditProjects: boolean;
    canEditVessels: boolean;
    canApprove: boolean;
    canUpRev: boolean;
    canLink: boolean;
    canDuplicate: boolean;
    canVoid: boolean;
    canDelete: boolean;
}

export class DocumentGridRowModel implements IBriefcaseItem {
    id: number;
    creationTime: string;
    identifier: string;
    filename: string;
    filePath: string;
    categoryProjectsCombined: string;
    categoryId: string;
    categorySuperName: string;
    vesselNames: string;
    typeAcronym: string;
    sourceAcronym: string;
    controlNumber: number;
    controlNumberString: string;
    reference: string;
    externalReference: string;
    latestRevisionId: number;
    latestRevisionTitle: string;
    latestRevisionIdentifier: string;
    approved: boolean;
    voided: boolean;
    hasLink: boolean;
    hasParentIms: boolean;
    isParentIms: boolean;
    isExternal: boolean;
    permissions: DocumentInstancePermissionsModel;
    selected: boolean = false;
    externalDocNumber: string;
    documentNumberIdentifierCombined: string;
}

export class DocumentEditorModel {
    id: number | null;
    categoryId: string | null;
    prefix: string;
    projectIds: number[] = [];
    receivedFileName: string;
    fileName: string;
    vesselIds: number[] = [];
    vesselClassIds: number[] = [];
    typeId: number | null;
    controlNumber: number;
    controlNumberSuffix: string;
    sourceId: number;
    parentId: number | null;
    attachmentNumber: number | null;
    reference: string;
    filePath: string;
    externalReference: string;
    externalDocNumber: string;
    revisionId: number;
    revisionTitle: string;
    revisionNumber: string;
    revisionDate: Date | null;
    revisionExternalTransmittalId: number | null;
    expiryDate: Date | null;
    approved: boolean;
    canUpRev: boolean;
    voided: boolean;
    historicalDocumentId?: number | null;
    permissions: DocumentInstancePermissionsModel;
}

export class DocumentTransmittalsModel {
    hasTransmittals: boolean;
    seajacksTransmittals: TransmittalGridRowModel;
    externalTransmittals: TransmittalExternalGridRowModel;
}

export class DocumentEditorHelper {
    categoryId: string;
    projectId: number;
    selectedVessels: VesselOptionModel[] = [];
    selectedVessel: VesselOptionModel;
    selectedProjects: ProjectModel[] = [];
    approved: boolean;
    originalParentId: number;
    originalParentAttachmentNumber: number;

    get vesselLabel(): string {
        return this.categoryIsCor ? 'Class' :
            this.categoryIsProj ? 'Applicable Vessel' :
                this.isExternal ? 'Relevant Vessels' :
                    this.categoryIsNewVessel ? 'Vessel' : 'Vessel/Class';
    };
    get disablePrefixInput(): boolean {
        return !this.categoryIsCor && !this.categoryIsVessel && !this.isExternalSeajacks;
    };

    get isExternal(): boolean { return this.isExternalNonSeajacks || this.isExternalSeajacks };
    get isExternalNonSeajacks(): boolean { return this.categoryId == 'externalNonSeajacks' };
    get isExternalSeajacks(): boolean { return this.categoryId == 'externalSeajacks' };
    get categoryIsCor(): boolean { return this.categoryId == 'corporate' };
    get categoryIsIms(): boolean { return this.categoryId == 'ims' };
    get categoryIsProj(): boolean { return this.categoryId == 'project' };
    get categoryIsVessel(): boolean { return this.categoryId == 'vessel' || this.categoryId == 'newVessel' };
    get categoryIsNewVessel(): boolean { return this.categoryId == 'newVessel'; }

    getTitle(): string {
        return !this.isExternal ? 'Cadeler Document' : this.isExternalNonSeajacks ? 'External Document' : 'External Document (SJ Filename)'
    };
}

export class DocumentModel extends ApiEntityModel<number> {
    title: string;
    identifier: string;
    sourceId: number;
}

export interface DocumentCategoryModel extends ApiEntityModel<string> {
    acronym: string;
    name: string;
}

export interface DocumentTypeModel extends ApiEntityModel<number> {
    name: string;
    acronym: string;
    description: string;
    department: DepartmentModel;
    archived: boolean;
}

export class DocumentEventGridRowModel {
    revisionIdentifier: string;
    time: Date;
    typeName: string;
    userName: string;
    description: string;
}

export interface IDocumentFilenameModel {
    readonly filename: string;
}

export class DocumentIdentifierModel {
    identifier: string;
}

export class DocumentControlNumberModel {
    controlNumber: number;
}

export class DocumentParentDetailsModel {
    nextAttachmentNumber: number;
}

export class ExternalTransmittalOptionModel extends ApiEntityModel<number> {
    identifier: string;
}

export class DocumentEditorOptions {
    categoryOptions: DocumentCategoryModel[] = [];
    projectOptions: ProjectModel[] = [];
    sourceOptions: SourceModel[] = [];
    additionalSourceOptions: SourceModel[] = [];
    typeOptions: DocumentTypeModel[] = [];
    vesselOptions: VesselOptionModel[] = [];
    parentOptions: DocumentModel[] = [];
    transmittalOptions: ExternalTransmittalOptionModel[] = [];
}

export class VoidDocumentModel {
    id: number;
    reason: string;
}

export class DocumentDuplicateModel {
    count: number;
}

export interface DocumentDuplicateResponseModel {
    batchId: number;
}

export interface DocumentCanLinkResponseModel {
    canLink: boolean;
}

export class DocumentImportInstancePermissionsModel {
    canDelete: boolean;
}


export interface DocumentImportGridRowModel {
    id: number;
    date: string;
    fileName: string;
    userName: string;
    documentCount: number;
    permissions: DocumentImportInstancePermissionsModel;
}

export interface DocumentImportResponseModel {
    documentCount: number;
    documents: DocumentGridRowModel[];
}

export class DocumentDeleteImportResponse {
    readonly canDelete: boolean;
    nonDeleteableDocuments: DocumentGridRowModel[] = [];
}

export class GetDocumentListRequestModel
{
    documentIds: number[] = [];
}
