export class DeletedEvent {
  id: number;
}

export class SaveEvent {
  action: SaveAction;
}

export enum SaveAction {
  Back = 1,
  Continue = 2,
  New = 3,
  Duplicate = 4
}