import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../app.config';
import { RevisionEditorModel, RevisionGridFiltersModel, RevisionModel, RevisionEditResponseModel } from '../models/revision.models';
import { GridDataService } from '../../shared/services/grid-data.service';
import { State } from '@progress/kendo-data-query';
import { Observable } from 'rxjs';
import { ValidationResultModel } from '../../shared/models/validation.models';
import { RevisionReasonModel } from '../../setup/revision-reasons/models/revision-reason.models';
import { DocumentTransmittalsModel } from '../models/document.models';

@Injectable()
export class RevisionService {
  readonly baseUrl: string = `${AppConfig.settings.apiUrl}/revisions`;
  readonly gridName: string = `revisions`;

  constructor(
    private http: HttpClient,
    private gridData: GridDataService
  ) { }

  getGridData(state: State, filters?: RevisionGridFiltersModel) {
    return this.gridData.getGridData(this.gridName, this.baseUrl, state, filters);
  }

  get(documentId: number, revisionId?: number): Observable<RevisionEditorModel> {
    let url = `${this.baseUrl}/${documentId}/edit`;

    url += `/${revisionId}`;

    return this.http.get<RevisionEditorModel>(url);
  }

  edit(editor: RevisionEditorModel): Observable<RevisionEditResponseModel> {
    return this.http.post<RevisionEditResponseModel>(`${this.baseUrl}/edit`, editor);
  }

  getPreviousForDocument(documentId: number): Observable<RevisionModel> {
    return this.http.get<RevisionModel>(`${this.baseUrl}/${documentId}/previous`);
  }

  getReasonOptions(): Observable<RevisionReasonModel[]> {
    return this.http.get<RevisionReasonModel[]>(`${this.baseUrl}/add/reasonoptions`);
  }

  archive(id: number): Observable<RevisionModel> {
    return this.http.post<RevisionModel>(`${this.baseUrl}/${id}/archive`, null);
  }

  delete(id: number): Observable<RevisionModel> {
    return this.http.post<RevisionModel>(`${this.baseUrl}/${id}/delete`, null);
  }

  getTransmittals(id: number) {
    return this.http.get<DocumentTransmittalsModel>(`${this.baseUrl}/${id}/transmittals`);
  }

}
