import { Component, OnInit, Input } from '@angular/core';
import { VoidDocumentModel } from '../../models/document.models';
import { ILoadable } from '../../../shared/components/loading/loading.models';

@Component({
  selector: 'app-document-void-form',
  templateUrl: './document-void-form.component.html',
  styleUrls: ['./document-void-form.component.scss']
})
export class DocumentVoidFormComponent implements OnInit, ILoadable {
  @Input('editor') editor: VoidDocumentModel = new VoidDocumentModel();
  @Input('title') title: string;
  @Input('invalid') invalid: boolean;

  hasLoaded: boolean;
  loading: boolean;
  succeeded: boolean;

  constructor() { }

  ngOnInit() {
  }
}
