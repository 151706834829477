import { Component, OnInit, Output, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';

@Component({
  selector: 'app-document-batch-save-confirmation',
  templateUrl: './document-batch-save-confirmation.component.html',
  styleUrls: ['./document-batch-save-confirmation.component.scss']
})
export class DocumentBatchSaveConfirmationComponent implements OnInit {
  @Output() saveSelected: Subject<number> = new Subject();
  @Output() discardSelected: Subject<any> = new Subject();
  @Output() cancelSelected: Subject<any> = new Subject();
  @ViewChild('dialogContent', {static: false}) content;
  @ViewChild('dialogActions', {static: false}) actions;

  private dialogRef: DialogRef;
  private nextId: number;

  constructor(
    private dialogService: DialogService
  ) { }

  ngOnInit() {
  }

  open(nextId: number) {
    this.dialogRef = this.dialogService.open({
      title: 'Unsaved Document',
      content: this.content,
      actions: this.actions
    });

    this.nextId =  nextId;

    setTimeout(() => {
      $('kendo-dialog-actions button').last().focus();
    })
  }

  submit() {
    this.saveSelected.next(this.nextId);
    this.dialogRef.close();
  }

  discard() {
    this.discardSelected.next(this.nextId);
    this.dialogRef.close();
  }

  cancel() {
    this.dialogRef.close();
  }
}
