import { ApiTypeEntityModel, ApiEntityModel } from '../../../shared/models/apiEntity.model';

export interface VesselInstancePermissionsModel {
  canView: boolean;
  canEdit: boolean;
  canEditAcronym: boolean;
  canArchive: boolean;
  canDelete: boolean;
}

export interface VesselGridRowModel {
  id: number;
  name: string;
  acronym: string;
  className: string;
  statusName: string;
  permissions: VesselInstancePermissionsModel;
}

export class VesselEditorModel {
  id: number | null;
  name: string;
  acronym: string;
  classId: number;
  statusId: number;
  active: boolean = true;
  permissions: VesselInstancePermissionsModel;
}

 export class VesselStatusModel extends ApiTypeEntityModel<number> {}

 export class VesselModel extends ApiEntityModel<number> {
   name: string;
 }

 export class VesselClassModel extends ApiEntityModel<number> {
  name: string;
}

export class VesselOptionModel extends ApiEntityModel<number> {
  name: string;
  isVessel: boolean;
  isVesselClass: boolean;
}
