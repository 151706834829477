
import {forkJoin as observableForkJoin,  Observable } from 'rxjs';

import {mergeMap, finalize} from 'rxjs/operators';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ILoadable } from '../../../../shared/components/loading/loading.models';
import { ActivatedRoute, Router } from '@angular/router';
import { IValidatable, ValidationResultModel } from '../../../../shared/models/validation.models';
import {
  SourceEditorModel, CountryModel, ContactModel, ContactEditorModel, ProjectModel,
  SourceInstancePermissionsModel
} from '../../models/source.models';
import { SourceService } from '../../services/source.service';
import { ProjectService } from '../../../projects/services/project.service';
import { HttpErrorResponse } from '@angular/common/http';
import * as $ from 'jquery';
import { IDirtyRecordGuard } from '../../../../shared/modules/dirty-record/guards/dirty-record-guard';
import { DirtyRecordDirective } from '../../../../shared/modules/dirty-record/directives/dirty-record.directive';
import { DraftRecordControlsComponent } from '../../../../shared/modules/draft-record/components/draft-record-controls/draft-record-controls.component';
import { element } from 'protractor';

@Component({
  selector: 'app-source-edit',
  templateUrl: './source-edit.component.html',
  styleUrls: ['./source-edit.component.scss']
})
export class SourceEditComponent implements OnInit, ILoadable, IValidatable, IDirtyRecordGuard {
  @ViewChild('contactInput', {static: false}) contactInputEl: any;
  @ViewChild(DirtyRecordDirective, {static: false}) dirtyRecordDirective: DirtyRecordDirective;
  @ViewChild('draftRecord', {static: false}) draftRecord: DraftRecordControlsComponent;

  validationResult: ValidationResultModel;
  hasLoaded: boolean = false;
  loading: boolean = false;
  title: string;
  updated: boolean = false;
  isEditMode: boolean = false;
  editor: SourceEditorModel = new SourceEditorModel();
  permissions: SourceInstancePermissionsModel;
  countryOptions: CountryModel[] = [];
  filteredCountryOptions: CountryModel[] = [];
  contactOptions: ContactModel[] = [];
  projectOptions: ProjectModel[] = [new ProjectModel()];
  filteredProjectOptions: ProjectModel[] = [new ProjectModel()];

  selectedProjects: ProjectModel[] = [];

  showContactEditor: boolean = false;
  contactEditor: ContactEditorModel;

  dirty: boolean;
  draftSaved: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private sourceService: SourceService,
    private projectService: ProjectService
  ) { }

  ngOnInit() {
    this.loading = true;

    this.route.params.subscribe(params => {
      const id = params['id'];

      if (id)
        this.isEditMode = true;

      this.sourceService.get(id).pipe(
        finalize(() => {
          this.hasLoaded = true;
          this.loading = false;
        }))
        .subscribe(editor => {
          this.editor = editor;
          this.permissions = editor.permissions;

          this.title = id ? (this.permissions.canEdit ? 'Edit' : 'View') + ' Source' : 'Source Setup';

          this.getAndApplyEditorOptions(this.editor).subscribe();
        })
    });

    this.sourceService.getCountryOptions().subscribe(options => this.filteredCountryOptions = this.countryOptions = options);
  }

  getAndApplyEditorOptions(editor: SourceEditorModel) {
    return observableForkJoin(
      this.sourceService.getContactOptions(),
      this.sourceService.getProjectOptions(editor)
    ).pipe(mergeMap(results => {
      let contactOptions = results[0];
      let projectOptions = results[1];

      this.contactOptions = contactOptions;

      this.projectOptions = projectOptions;
      this.selectedProjects = this.projectOptions.filter(o => editor.projectIds.includes(o.id));

      this.filteredProjectOptions = this.projectOptions;

      setTimeout(() => this.dirtyRecordDirective.watch());

      return results;
    }))
  }

  onContactFilterChanged(searchText: string = '') {
    this.sourceService.getContactOptions(searchText).subscribe(results => {
      this.contactOptions = results;
    })
  }

  onProjectsFilterChanged(searchText: string) {
    this.filteredProjectOptions = this.projectOptions.filter(e => (e.name + ' ' + e.number).toLowerCase().indexOf(searchText.toLowerCase()) >= 0);
  }

  editContact(editor: ContactEditorModel) {
    if (!editor) {
      editor = new ContactEditorModel();
      editor.isAdd = true;
      editor.company = this.editor.name;
      editor.tempId = Math.floor(Math.random() * -999) - 1;
    }

    this.contactEditor = editor;
    this.openContactEditor();
  }

  onContactCancel() {
    this.showContactEditor = false;
  }

  onContactSubmit(editor: ContactEditorModel) {
    if (editor.isAdd) {
      delete editor.isAdd;
      this.editor.contacts.push(editor);
      this.dirty = true;
    }

    this.showContactEditor = false;
  }

  onContactFilterClosed($event: any) {
    this.onContactFilterChanged();
  }

  handleCountryFilter(value) {
    this.filteredCountryOptions = this.countryOptions.filter((s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  contactValueChanged(contact: ContactModel) {
    this.contactInputEl.reset();

    if (typeof (contact) == "undefined" || this.editor.contacts.some(e => e.id == contact.id))
      return;

    let contactEditor = new ContactEditorModel();
    Object.assign(contactEditor, contact);
    this.editor.contacts.push(contactEditor);
    this.dirty = true;
  }

  submit($event: any) {
    this.validationResult = null;
    this.loading = true;
    this.editor.projectIds = this.selectedProjects.map(o => o.id);

    this.sourceService.update(this.editor).pipe(
      finalize(() => this.loading = false))
      .subscribe(() => {
        this.updated = true;
        this.scroll();
        setTimeout(() => {
          if (!!this.draftRecord)
            this.draftRecord.remove();
          this.dirtyRecordDirective.reset();
          this.router.navigateByUrl('/setup/sources');
        }, 1500);
      }, err => {
        if (err instanceof HttpErrorResponse && err.status == 412) {
          this.validationResult = err.error;
        }
      })
  }

  cancel(){
    this.router.navigateByUrl("/setup/sources")
  }

  applyEditor = (editor: SourceEditorModel) => {
    this.editor = editor;
    this.getAndApplyEditorOptions(this.editor).subscribe();
  }

  afterDraftSave = () => {
    window.scrollTo(0,0);
    this.dirtyRecordDirective.reset();
    this.draftSaved = true;
    setTimeout(() => {
      this.cancel();
    }, 2000);
  }

  onContactDeleted(){
    this.dirty = true;
  }

  private scroll(): void {
    setTimeout(() => {
      window.scrollToTop();
    });
  }

  private openContactEditor() {
    this.showContactEditor = true;

    setTimeout(() => {
      window.scrollTo(0, document.body.scrollHeight);
      this.contactInputEl.toggle(false);
    });
  }
}
