import {of as observableOf,  Observable } from 'rxjs';
import {finalize, mergeMap} from 'rxjs/operators';
import { Component, OnInit, ViewChild, Output, EventEmitter, Input, ElementRef, SimpleChanges, OnChanges } from '@angular/core';
import { Location } from '@angular/common';
import { ILoadable } from '../../../shared/components/loading/loading.models';
import { IValidatable, ValidationResultModel } from '../../../shared/models/validation.models';
import { ActivatedRoute, Router } from '@angular/router';
import { TransmittalEditorService } from '../../services/transmittal-editor.service';
import {
  TransmittalCategoryModel, TransmittalEditorModel, TransmittalTypeModel, TransmittalModel, TransmittalEditorOptions,
  TransmittalEditorHelper, TransmittalGridRowModel, TransmittalFormatModel, TransmittalContactLinkEditorModel,
  TransmittalSourceWithContactsModel, TransmittalDocumentLinkEditorModel,
  TransmittalInstancePermissionsModel
} from '../../models/transmittal.models';
import { VesselModel, VesselClassModel, VesselOptionModel } from '../../../setup/vessels/models/vessel.models';
import { ProjectModel } from '../../../setup/sources/models/source.models';


import { HttpErrorResponse } from '@angular/common/http';
import { TransmittalService } from '../../services/transmittal.service';
import * as Clipboard from 'clipboard';
import { TransmittalDocumentSearchGridComponent } from '../transmittal-document-search-grid/transmittal-document-search-grid.component';
import { DocumentModel } from '../../../documents/models/document.models';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';
import { TemplateModel } from '../../../setup/templates/models/template.models';
import { uniqBy as _uniqBy } from 'lodash';
import { SaveEvent, SaveAction } from '../../../shared/models/events';
import { TransmittalSavedComponent } from '../transmittal-saved/transmittal-saved.component';
import * as Moment from 'moment';
import { element } from 'protractor';
import { TransmittalEditIssueWarningComponent } from '../transmittal-edit-issue-warning/transmittal-edit-issue-warning.component';
import { RevisionModel } from '../../../documents/models/revision.models';
import { TransmittalConfirmSaveDialogComponent } from '../transmittal-confirm-save-dialog/transmittal-confirm-save-dialog.component';
import { IDirtyRecordGuard } from '../../../shared/modules/dirty-record/guards/dirty-record-guard';
import { DirtyRecordDirective } from '../../../shared/modules/dirty-record/directives/dirty-record.directive';

@Component({
  selector: 'app-transmittal-edit',
  templateUrl: './transmittal-edit.component.html',
  styleUrls: ['./transmittal-edit.component.scss'],
})
export class TransmittalEditComponent implements OnInit, ILoadable, IValidatable, IDirtyRecordGuard {
  @ViewChild('documentGrid', { static: false}) documentGrid: TransmittalDocumentSearchGridComponent;
  @ViewChild('documentInput', { static: false}) documentInput: DropDownListComponent;
  @ViewChild('sourceInput', { static: false}) sourceInput: DropDownListComponent;
  @ViewChild('savedDialog', { static: false}) savedDialog: TransmittalSavedComponent;
  @ViewChild('pdfPreviewForm', { static: false}) pdfPreviewForm: ElementRef;
  @ViewChild('issuedWarningDialog', { static: false}) issuedWarningDialog: TransmittalEditIssueWarningComponent;
  @ViewChild('confirmSaveDialog', { static: false}) confirmDialog: TransmittalConfirmSaveDialogComponent;
  @ViewChild(DirtyRecordDirective, { static: false}) dirtyRecordDirective: DirtyRecordDirective;

  validationResult: ValidationResultModel;
  hasLoaded: boolean;
  loading: boolean;
  updated: boolean;
  isEditMode: boolean = false;
  title: string;
  identifier: string = '';
  pdfDownloadUrl: string;
  editorJson: string;
  editor: TransmittalEditorModel;
  permissions: TransmittalInstancePermissionsModel;
  helper: TransmittalEditorHelper = new TransmittalEditorHelper();
  categoryOptions: TransmittalCategoryModel[] = [];
  projectOptions: ProjectModel[] = [];
  sourceOptions: TransmittalSourceWithContactsModel[] = [];
  docSearchOptions: DocumentModel[] = [];
  templateOptions: TemplateModel[] = [];
  formatOptions: TransmittalFormatModel[] = [];
  selectedSources: TransmittalSourceWithContactsModel[] = [];
  documentIds: number[] = [];
  anyDocuments: boolean = false;

  dirty: boolean;
  draftSaved: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private transmittalEditorService: TransmittalEditorService,
    private transmittalService: TransmittalService
  ) { }

  ngOnInit() {
    this.loading = true;
    this.route.params.subscribe(params => {
      const id = params['id'] || null;

      this.transmittalEditorService.get(id).pipe(mergeMap(editor => {
        this.editor = editor;
        this.permissions = editor.permissions;

        if (id) {
          this.isEditMode = true;
          this.title = (this.permissions.canEdit ? 'Edit' : 'View') + ' Transmittal';
          this.orderDocumentLinks();
        } else {
          this.title = 'Create Transmittal';
        }

        return this.getAndApplyEditorOptions(this.editor);
      }), finalize(() => {
        this.hasLoaded = true;
        this.loading = false;
      })).subscribe(options => {

      }, err => {
        console.error('error on Transmittal Edit', err);
      });
    }
    );
    this.route.queryParams.subscribe(queryParams => {
      let transmittalIds = queryParams['transmittalid'] || null;
      if (transmittalIds) {
        transmittalIds = transmittalIds.slice(0, - 1); // remove the last ' ' from the url
        this.documentIds = transmittalIds.split([' ']); // split ids from url into array
      }
    });
  }

  getAndApplyEditorOptions(editor: TransmittalEditorModel): Observable<any> {
    this.editor.displayDate = this.editor.date ? new Date(editor.date) : null;
    this.setPdfUrl(this.editor.id);

    return this.transmittalEditorService
      .getTransmittalEditorOptions(editor).pipe(
      mergeMap(options => {
        this.projectOptions = options.projectOptions;
        this.docSearchOptions = options.documentOptions;
        this.templateOptions = options.templateOptions;
        this.formatOptions = options.formatOptions;
        this.sourceOptions = options.selectedSourceOptions;
        this.categoryOptions = options.categoryOptions;

        if (!this.editor.notes)
          this.editor.notes = ''; // need to define notes here so that keydown enter will work (see html for notes textarea section)

        // if routed from transmit button, documentIds will have data
        if (this.documentIds) {
          // remove duplicate documents from documentIds
          this.editor.documentLinks.forEach(element => {
            const index = this.documentIds.findIndex(e => e == element.revisionDocumentId);
            if (index != -1)
              this.documentIds.splice(index, 1);
          });

          this.transmittalEditorService.getDocuments(this.documentIds).subscribe(documents => {
            this.multipleDocSelected(documents);
          });
        }

        this.applySourcesToSelected();
        this.sourceOptions = options.sourceOptions;

        if (this.editor.id) {
          this.categoryChanged(this.editor.typeId);
          this.updateLocation();
        }

        setTimeout(() => this.dirtyRecordDirective.watch());

        return observableOf(options);
      }));
  }

  categoryChanged(typeId: string) {
    if (!typeId)
      return;

    const item = this.categoryOptions.find(e => e.id == typeId);
    this.helper.typeAcronym = item.acronym;
    this.helper.typeId = item.id;

    if (this.helper.typeId != 'project') {
      this.editor.projectId = null;
      this.projectChanged(this.editor.projectId);
    }

    this.transmittalEditorService.getIdentifier(this.editor.id, this.editor).subscribe(model => this.identifier = model.identifier);
  }

  projectChanged(projectId: number) {
    this.transmittalEditorService.getIdentifier(this.editor.id, this.editor).subscribe(model => this.identifier = model.identifier);

    this.transmittalEditorService.getDefaultTemplate(this.editor).subscribe(template => {
      this.editor.templateId = !!template ? template.id : null;
    });
  }

  onProjectFilterChanged(searchText: string) {
    this.transmittalEditorService.getAdditionalProjectOptions(this.editor, searchText).subscribe(options => this.projectOptions = options);
  }

  docSelected(document: DocumentModel) {
    if (!document)
      return;

    if (this.editor.sourceIds.indexOf(document.sourceId) == -1)
      this.editor.sourceIds.push(document.sourceId);

    this.transmittalEditorService.getSelectedSourceOptions(this.editor).subscribe(options => {
      this.sourceOptions = options;
      this.updateSelectedSource(document.sourceId);
    });

    this.documentGrid.add(document, this.editor).subscribe(l => {
      this.transmittalEditorService.getDocumentOptions(this.editor).subscribe(options => this.docSearchOptions = options);
      this.setDocumentOrdinals();
    });
    this.documentInput.writeValue(null);
  }

  updateSelectedSource(id?: number, withRemove: boolean = false) {
    if (!id)
      return;

    this.updateSelectedSources(id, withRemove);

    if (this.sourceInput)
      this.sourceInput.writeValue(null);

    this.transmittalEditorService.getSourceOptions(this.editor).subscribe(options => {
      setTimeout(() => this.sourceOptions = options, 500);
    });
  }

  onSourceFilterChanged(searchText: string) {
    this.transmittalEditorService
      .getSourceOptions(this.editor, searchText)
      .subscribe(options => this.sourceOptions = options);
  }

  getSourceContactsAvailableText(source: TransmittalSourceWithContactsModel) {
    const l = source && source.contacts ? source.contacts.length : 0;
    return `${l} ${l == 1 ? 'contact' : 'contacts'} available`;
  }

  onDocumentFilterChanged(searchText: string) {
    this.transmittalEditorService.getDocumentOptions(this.editor, searchText).subscribe(options => this.docSearchOptions = options);
  }

  documentGridChanged($event: TransmittalGridRowModel[]) {
    this.anyDocuments = $event.length > 0;

    this.transmittalEditorService.getSelectedSourceOptions(this.editor).subscribe(options => this.sourceOptions = options);
  }

  applySourcesToSelected() {
    this.editor.sourceIds.forEach(id => {
      if (!this.selectedSources.some(source => source.id == id)) {
        this.selectedSources.push(this.sourceOptions.find(e => e.id == id));
      }
    });
    this.editor.contactLinks.forEach(link => {
      if (!this.selectedSources.some(source => source.id == link.sourceId)) {
        this.selectedSources.push(this.sourceOptions.find(e => e.id == link.sourceId));
      }
      const selected = this.selectedSources.find(s => s.id == link.sourceId);
      const contact = selected.contacts.find(c => c.id == link.contactId);

      if (!!contact) {
        contact.selected = true;
        contact.receiptStateId = link.receiptStateId;
      }
    });
  }

  applySourcesToEditor() {
    this.editor.contactLinks = [];
    this.selectedSources.forEach(source => {
      source.contacts
        .filter(contact => contact.selected)
        .forEach(contact => {
          const model = new TransmittalContactLinkEditorModel();
          model.sourceId = source.id;
          model.contactId = contact.id;
          model.receiptStateId = contact.receiptStateId;
          this.editor.contactLinks.push(model);
        });
    });
  }

  issuedChanged() {
    //checks issued on point of click, not the new value
    if (this.editor.issued == false) {
      if (!this.editor.displayDate) {
        this.editor.displayDate = new Date();
      }
      this.applySourcesToEditor();
      let keepSearching: boolean;
      for (let i = 0, keepSearching = true; i < this.editor.sourceIds.length; i++) {
        const index = this.editor.contactLinks.findIndex(
          e => e.sourceId == this.editor.sourceIds[i]
        );
        // if any source has an unticked contact, or has no contacts
        if (index == -1 && keepSearching) {
          keepSearching = false;
          this.issuedWarningDialog.open(this.editor.contactLinks.length);
        }
      }
    }
    else if (this.editor.issued == true) {
      if (this.editor.displayDate) {
        this.editor.displayDate = null;
      }
    }
  }

  completeEditor = () => {
    this.editor.date = this.editor.displayDate ? Moment(this.editor.displayDate).format() : null;
    this.applySourcesToEditor();
    this.removeSourcesWithNoContacts();
    this.setDocumentOrdinals();
  }

  submit(getPdfAfterSave?: boolean) {
    this.validationResult = null;
    this.updated = false;
    this.loading = true;

    this.completeEditor();

    this.transmittalEditorService.update(this.editor).pipe(
      finalize(() => this.loading = false))
      .subscribe(result => {
        this.updated = true;
        this.editor.id = result.entityId;
        this.setPdfUrl(this.editor.id);
        this.transmittalEditorService.getIdentifier(this.editor.id, this.editor).subscribe(model => {
          this.identifier = model.identifier;
          setTimeout(() => {
            this.dirtyRecordDirective.reset();
            this.savedDialog.open(this.editor);
          });
        });

        if (getPdfAfterSave == true)
          this.getPdf();
      }, err => {
        if (err instanceof HttpErrorResponse && err.status == 412) {
          this.validationResult = err.error;
        }
      });
  }

  cancel() {
    this.router.navigateByUrl('/transmittals');
  }

  onSaved(event: SaveEvent) {
    switch (event.action) {
      case SaveAction.Back:
        this.router.navigateByUrl('/transmittals');
        break;
      case SaveAction.Continue:
        this.updateLocation();
        window.scrollToTop();
        break;
      case SaveAction.New:
        this.router.navigateByUrl('/transmittals/edit');
        window.location.reload();
        break;
    }
  }

  openConfirmDialog() {
    this.confirmDialog.open();
  }

  onSaveAndContinue($event: SaveEvent) {
    this.submit(true);
  }

  getPdf() {
    this.setPdfUrl(this.editor.id);
    if (this.editor.issued) {
      this.editorJson = JSON.stringify(this.editor);
      setTimeout(() => {
        this.pdfPreviewForm.nativeElement.submit();
      });
    }
    else {
      const tabWindowId = window.open('about:blank', '_blank');
      this.transmittalEditorService.update(this.editor).pipe(
        finalize(() => this.loading = false))
        .subscribe(() => {
          this.updated = true;
          setTimeout(() => {
            tabWindowId.location.href = this.pdfDownloadUrl;
          });
        }, err => {
          if (err instanceof HttpErrorResponse && err.status == 412) {
            this.validationResult = err.error;
          }
        });
    }
  }

  applyEditor = (editor: TransmittalEditorModel) => {
    this.editor = editor;
    this.getAndApplyEditorOptions(this.editor).subscribe();
  }

  beforeDraftSave = () => {
    this.completeEditor();
  }

  afterDraftSave = () => {
    window.scrollTo(0, 0);
    this.dirtyRecordDirective.reset();
    this.draftSaved = true;
    setTimeout(() => {
      this.cancel();
    }, 2000);
  }

  setDocumentOrdinals() {
    for (let i = 0; i < this.editor.documentLinks.length; i++) {
      this.editor.documentLinks[i].ordinal = i;
    }
  }

  private updateSelectedSources(id?: number, withRemove: boolean = false) {
    if (!id)
      return;

    if (!this.selectedSources.some(source => source.id == id)) {
      const source = this.sourceOptions.find(e => e.id == id);
      source.contacts.forEach(c => c.selected = false);
      this.selectedSources.push(source);
    } else if (withRemove) {
      const existing = this.selectedSources.find(source => source.id == id);
      const i = this.selectedSources.indexOf(existing);
      this.selectedSources.splice(i, 1);
    }

    this.editor.sourceIds = _uniqBy(this.selectedSources, s => s.id).map(s => s.id);
  }

  private multipleDocSelected(documents: DocumentModel[]) {
    documents.forEach(document => {
      if (this.editor.sourceIds.indexOf(document.sourceId) == -1)
        this.editor.sourceIds.push(document.sourceId);
    });

    this.transmittalEditorService.getSelectedSourceOptions(this.editor).subscribe(options => {
      this.sourceOptions = options;
      documents.forEach(document => this.updateSelectedSources(document.sourceId));
      this.transmittalEditorService.getSourceOptions(this.editor).subscribe(options => {
        setTimeout(() => this.sourceOptions = options, 500);
      });
    });

    this.documentGrid.addMany(documents, this.editor).subscribe(l => {
      this.transmittalEditorService.getDocumentOptions(this.editor).subscribe(options => this.docSearchOptions = options);
    });
  }

  private removeSourcesWithNoContacts() {
    // adding a check that there is at least 1 contact link will prevent the sources from being removed from the editor if the user saves and hits the 'No contacts selected' validation
    if (this.editor.issued == true && this.editor.contactLinks.length > 0) {
      const sourcesToRemove: number[] = [];
      for (let i = 0; i < this.editor.sourceIds.length; i++) {
        const j = this.editor.contactLinks.findIndex(
          e => e.sourceId == this.editor.sourceIds[i]
        );
        if (j == -1) {
          sourcesToRemove.push(this.editor.sourceIds[i]);
        }
      }

      // need to remove these sources in their own loop instead of in the previous one as a workaround for part of bug (SJD-588)
      sourcesToRemove.forEach(element => {
        // remove unselected sources from editor (which gets saved)
        const i = this.editor.sourceIds.findIndex(
          e => e == element
        );
        this.editor.sourceIds.splice(i, 1);
        // remove unselected sources from the ngModel (which gets displayed)   // don't know why these are different variables but i'm not going to change it now
        const k = this.selectedSources.findIndex(
          e => e.id == element
        );
        this.selectedSources.splice(k, 1);
      });
    }
  }

  private updateLocation() {
    this.isEditMode = true;
    const url = `/transmittals/${this.editor.id}/edit`;
    // if (this.helper.isExternal)
    //   url += `/${this.editor.categoryId.toLocaleLowerCase()}`;
    this.location.replaceState(url);
  }

  private setPdfUrl(id?: number) {
    if (!this.editor.issued)
      this.pdfDownloadUrl = this.transmittalService.getPreviewLink(this.editor.id);
    else
      this.pdfDownloadUrl = this.transmittalService.getDownloadLink(this.editor.id);
  }

  private orderDocumentLinks() {
    this.editor.documentLinks.sort((function (a, b) {
      // sort ascending
      return a.ordinal - b.ordinal;
    }));

    this.setDocumentOrdinals();
  }
}
