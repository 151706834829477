import { ApiEntityModel } from '../../../shared/models/apiEntity.model';
import { UserModel } from '../../users/models/user.models';

export interface RoleInstancePermissionsModel {
  canView: boolean;
  canEdit: boolean;
  canEditUser: boolean;
}

export interface RoleGridRowModel {
  id: number;
  name: string;
  creationTime: Date;
  modificationTime: Date;  
  permissions: RoleInstancePermissionsModel;
  active: boolean;
  users: RoleUserLinkModel[];
  userCount: number;
}

export class RoleGridFiltersModel {
  permissionIds: number[] = [];
}

export class RoleEditorModel {
  id: number;
  name: string;
  tag: string;
  permissionsInstance: RoleInstancePermissionsModel;
  permissions: any[];  
  permissionsAll: any[];
  active: boolean;
  users: RoleUserLinkModel[];
}

export class RoleModel extends ApiEntityModel<number> {
  name: string;
}

export class PermissionItemBaseModel {
  name: string;
  id: string;
}

export class RoleUserLinkModel {
  id: number;
  username: string;
  roles: RoleModel[];
  rolesText: string;
}

export class UserSimpleGridRowModel {
  id: number;
  username: string;
  rolesText: string;
  active: boolean;
}
