import { BriefcaseComponent } from '../components/briefcase/briefcase/briefcase.component';
import { BriefcaseTransmitWarningComponent } from '../components/briefcase/briefcase-transmit-warning/briefcase-transmit-warning.component';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { CommonModule } from '@angular/common';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DialogModule, DialogService, DialogsModule } from '@progress/kendo-angular-dialog';
import { DocumentAddRevisionComponent } from '../components/document-add-revision/document-add-revision.component';
import { DocumentAddRevisionFormComponent } from '../components/document-add-revision-form/document-add-revision-form.component';
import { DocumentApproveComponent } from '../components/document-approve/document-approve.component';
import { DocumentBatchSaveConfirmationComponent } from '../components/document-batch-save-confirmation/document-batch-save-confirmation.component';
import { DocumentDeleteComponent } from '../components/document-delete/document-delete.component';
import { DocumentDuplicateDialogComponent } from '../components/duplicate-dialog/duplicate-dialog.component';
import { DocumentDuplicateFormComponent } from '../components/duplicate-form/duplicate-form.component';
import { DocumentDuplicationBatchGridComponent } from '../components/duplication-batch-grid/duplication-batch-grid.component';
import { DocumentEditComponent } from '../components/document-edit/document-edit.component';
import { DocumentEditorService } from '../services/document-editor.service';
import { DocumentGridComponent } from '../components/document-grid/document-grid.component';
import { DocumentHistoryGridComponent } from '../components/document-history-grid/document-history-grid.component';
import { DocumentImsChildrenGridComponent } from '../components/document-ims-children-grid/document-ims-children-grid.component';
import { DocumentLinkComponent } from '../components/document-link/document-link.component';
import { DocumentLinksGridComponent } from '../components/document-links-grid/document-links-grid.component';
import { DocumentRevisionGridComponent } from '../components/document-revision-grid/document-revision-grid.component';
import { DocumentRouterModule } from './document.router.module';
import { DocumentSavedComponent } from '../components/document-saved/document-saved.component';
import { DocumentSavedFormComponent } from '../components/document-saved-form/document-saved-form.component';
import { DocumentService } from '../services/document.service';
import { DocumentVoidComponent } from '../components/document-void/document-void.component';
import { DocumentVoidFormComponent } from '../components/document-void-form/document-void-form.component';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { ExcelModule, GridModule } from '@progress/kendo-angular-grid';
import { FormsModule } from '@angular/forms';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LayoutModule, TabStripModule } from '@progress/kendo-angular-layout';
import { NgModule } from '@angular/core';
import { PtKendoModule } from '../../kendo/modules/pt.kendo.module';
import { RevisionArchiveComponent } from '../components/revision-archive/revision-archive.component';
import { RevisionDeleteComponent } from '../components/revision-delete/revision-delete.component';
import { RevisionService } from '../services/revision.service';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../shared/modules/shared.modules';
import { BriefcaseExportComponent } from '../components/briefcase/briefcase-export/briefcase-export.component';
import { DocumentIssuedWarningComponent } from "../components/document-issued-warning/document-issued-warning.component";
import { DocumentImportComponent } from "../components/document-import/document-import.component";
import { UploadModule } from "@progress/kendo-angular-upload";
import { DocumentImportService } from "../services/document-import.service";
import { DocumentImportConfirmationComponent } from "../components/document-import-confirmation/document-import-confirmation.component";
import { DocumentImportDeleteComponent } from "../components/document-import-delete/document-import-delete.component";
import { DocumentExternalTransmittalGridComponent } from '../components/document-external-transmittal-grid/document-external-transmittal-grid.component';
import { DocumentTransmittalGridComponent } from '../components/document-transmittal-grid/document-transmittal-grid.component';
import { BriefcaseTransmitDialogComponent } from '../components/briefcase/briefcase-transmit-dialog/briefcase-transmit-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    LayoutModule,
    GridModule,
    ButtonsModule,
    InputsModule,
    DateInputsModule,
    DropDownsModule,
    DialogsModule,
    TabStripModule,
    SharedModule,
    PtKendoModule,
    ExcelModule,
    UploadModule,
  ],
  declarations: [
    DocumentGridComponent,
    BriefcaseComponent,
    BriefcaseTransmitWarningComponent,
    BriefcaseExportComponent,
    DocumentEditComponent,
    DocumentHistoryGridComponent,
    DocumentRevisionGridComponent,
    DocumentLinksGridComponent,
    DocumentAddRevisionComponent,
    DocumentAddRevisionFormComponent,
    DocumentDeleteComponent,
    DocumentVoidComponent,
    DocumentVoidFormComponent,
    RevisionArchiveComponent,
    RevisionDeleteComponent,
    DocumentApproveComponent,
    DocumentLinkComponent,
    DocumentSavedComponent,
    DocumentSavedFormComponent,
    DocumentImsChildrenGridComponent,
    DocumentIssuedWarningComponent,
    DocumentDuplicationBatchGridComponent,
    DocumentDuplicateDialogComponent,
    DocumentDuplicateFormComponent,
    DocumentBatchSaveConfirmationComponent,
    DocumentImportComponent,
    DocumentImportConfirmationComponent,
    DocumentImportDeleteComponent,
    DocumentExternalTransmittalGridComponent,
    DocumentTransmittalGridComponent,
    BriefcaseTransmitDialogComponent
  ],
  entryComponents: [
    DocumentAddRevisionFormComponent,
    DocumentDeleteComponent,
    DocumentVoidFormComponent,
    DocumentSavedComponent,
    DocumentSavedFormComponent,
    DocumentLinksGridComponent,
    DocumentImsChildrenGridComponent,
    DocumentIssuedWarningComponent,
    DocumentDuplicateFormComponent,
    DocumentBatchSaveConfirmationComponent,
    DocumentImportConfirmationComponent,
    DocumentImportDeleteComponent
  ],
  providers: [
    DocumentService,
    DocumentEditorService,
    DocumentImportService,
    RevisionService,
    DialogService
  ],
  exports: [
    DocumentRouterModule,
    DocumentAddRevisionComponent
  ]
})
export class DocumentModule { }
