import { Component, OnInit, Input } from '@angular/core';
import { VoidTransmittalModel } from '../../models/transmittal.models';
import { ILoadable } from '../../../shared/components/loading/loading.models';

@Component({
  selector: 'app-transmittal-void-form',
  templateUrl: './transmittal-void-form.component.html',
  styleUrls: ['./transmittal-void-form.component.scss']
})
export class TransmittalVoidFormComponent implements OnInit, ILoadable {
  @Input('editor') editor: VoidTransmittalModel = new VoidTransmittalModel();
  @Input('title') title: string;
  @Input('invalid') invalid: boolean;

  hasLoaded: boolean;
  loading: boolean;
  succeeded: boolean;

  constructor() { }

  ngOnInit() {
  }
}
