import { Injectable } from '@angular/core';
import { AppConfig } from '../../../app.config';
import { HttpClient } from '@angular/common/http';
import { GridDataService } from '../../../shared/services/grid-data.service';
import { State } from '@progress/kendo-data-query';
import { TemplateEditorModel } from '../models/template.models';
import { IKendoGridComponent } from '../../../shared/utils/kendo.utils';
import { ValidationResultModel } from '../../../shared/models/validation.models';
import { HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()
export class TemplateService {
  readonly baseUrl: string = `${AppConfig.settings.apiUrl}/templates`;
  readonly gridName: string = 'templates';

  constructor(
    private http: HttpClient,
    private gridData: GridDataService
  ) { }

  getGridData(state: State, filters?: any) {
    return this.gridData.getGridData(this.gridName, this.baseUrl, state, filters);
  }

  get(id: number) {
    const url = !!id ? `${this.baseUrl}/${id}/edit` : `${this.baseUrl}/edit`;
    return this.http.get<TemplateEditorModel>(url);
  }

  archive(id: number) {
    return this.http.post(`${this.baseUrl}/${id}/archive`, null);
  }

  delete(id: number) {
    return this.http.post(`${this.baseUrl}/${id}/delete`, null);
  }

  update(documentType: TemplateEditorModel) {
    const url = !!documentType.id ? `${this.baseUrl}/${documentType.id}/edit` : `${this.baseUrl}/edit`;
    return this.http.post<ValidationResultModel>(url, documentType);
  }

  postPreview(documentType: TemplateEditorModel) {
    const url = `${this.baseUrl}/preview`;
    var headers = new HttpHeaders();
    return this.http.post(url, documentType, { responseType: 'blob' }).pipe(map(
      (res) => {
        return new Blob([res], { type: 'application/pdf' })
      }));
  }
}
