import { Component, OnInit, Input, Output } from '@angular/core';
import { ContactEditorModel, SourceEditorModel } from '../../models/source.models';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-contact-summary',
  templateUrl: './contact-summary.component.html',
  styleUrls: ['./contact-summary.component.scss']
})
export class ContactSummaryComponent implements OnInit {
  @Input('editor') editor: ContactEditorModel;
  @Input('editable') editable = true;
  @Input('sourceEditor') sourceEditorModel: SourceEditorModel;
  @Input('mode') mode: string = 'view';
  @Output('deleted') onContactDeleted: Subject<any> = new Subject();
  @Output('edit') editEvent: Subject<ContactEditorModel> = new Subject();

  get isEditMode() { return this.mode == 'edit' };

  constructor() { }

  ngOnInit() {
  }

  edit() {
    this.editEvent.next(this.editor);
  }

  delete() {
    let tmpContacts: ContactEditorModel[] = [];
    this.sourceEditorModel.contacts.forEach(e => {
      if(e.id !== this.editor.id || e.tempId !== this.editor.tempId){
        tmpContacts.push(e);
      }
    });

    this.sourceEditorModel.contacts = tmpContacts;

    this.onContactDeleted.next();
  }

}
