import { Component, OnInit, ViewChild, Output, Input } from '@angular/core';
import { DialogService, DialogRef } from '@progress/kendo-angular-dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { KendoDialogRef } from '../../../shared/utils/kendo.utils';
import { ILoadable } from '../../../shared/components/loading/loading.models';
import { RevisionModel } from '../../../documents/models/revision.models';
import { Observable } from 'rxjs';
import { HistoricalDocumentDetailModel } from '../../models/historical-document.models';
import { HistoricalDocumentService } from '../../services/historical-document.service';

@Component({
  selector: 'app-view-details',
  templateUrl: './view-details.component.html',
  styleUrls: ['./view-details.component.scss']
})
export class ViewDetailsComponent implements OnInit, ILoadable {
  @ViewChild('viewDetails', {static: false}) content;
  @ViewChild('dialogActions', {static: false}) actions;

  private dialogRef: DialogRef;
  hasLoaded: boolean;
  loading: boolean;
  editor: HistoricalDocumentDetailModel[];
  gridData: HistoricalDocumentDetailModel[] = [];

  constructor(
    private dialogService: DialogService,
    private historicalDocumentService: HistoricalDocumentService
  ) { }

  ngOnInit() {
  }

  open(id: number) {
    this.loading = true;
    this.historicalDocumentService.get(id).subscribe(editor => {
      this.editor = editor;
      this.gridData = editor;

      this.dialogRef = this.dialogService.open({
        title: 'Details',
        content: this.content,
        actions: this.actions
      });
      this.loading = false;
      this.hasLoaded = true;
    });
  }

  close() {
    this.dialogRef.close();
    this.dialogRef.dialog.destroy();
  }
}
