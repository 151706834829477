import {environment} from '../environments/environment';
import {HttpBackend, HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';

@Injectable()
export class AppConfig {
  static settings: IAppConfig;

  constructor(private handler: HttpBackend) {
  }

  // settings are kept in config.json, so that we can change them in octopus deploy, after the app is built
  public load(): Promise<void> {
    const jsonFile = environment.config;

    return new Promise<void>((resolve, reject) => {
      const httpClient = new HttpClient(this.handler);
      httpClient
        .get<IAppConfig>(jsonFile)
        .toPromise()
        .then((response: IAppConfig) => {
          AppConfig.settings = response;

          this.clearLocalStorage();

          resolve(null);
        })
        .catch((response: any) => {
          reject(`Unable to load file ${jsonFile}: ${JSON.stringify(response)}`);
        });
    });
  }

  private clearLocalStorage() {
    const v = localStorage['version'];
    if (v !== AppConfig.settings.version) {
      localStorage.clear();
      localStorage['version'] = AppConfig.settings.version;
      // alert('Persisted grid filters and briefcase have been cleared for development purposes');
    }
  }
}

export interface IAppConfig {
  appName: string;
  production: boolean;
  apiUrl: string;
  name: string;
  version: string;
  azureADClientId: string;
  azureADAuthority: string;
  azureADScope: string;
}
