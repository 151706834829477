import {of as observableOf, forkJoin as observableForkJoin,  Observable } from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AppConfig } from '../../app.config';
import { HttpClient } from '@angular/common/http';
import { State } from '@progress/kendo-data-query';
import { GridDataService } from '../../shared/services/grid-data.service';
import { TransmittalEditorModel, TransmittalCategoryModel, TransmittalTypeModel, TransmittalModel, TransmittalEditorOptions, TransmittalParentDetailsModel, TransmittalFormatModel, TransmittalIdentifierModel, TransmittalSourceWithContactsModel } from '../models/transmittal.models';
import { ProjectModel, SourceModel } from '../../setup/sources/models/source.models';
import { VesselOptionModel } from '../../setup/vessels/models/vessel.models';
import { serialize } from '../../shared/utils/object.utils';
import { ValidationResultModel } from '../../shared/models/validation.models';
import { DocumentModel } from '../../documents/models/document.models';
import {BulkRevisionModel, RevisionModel} from '../../documents/models/revision.models';
import { TemplateModel } from '../../setup/templates/models/template.models';
import { RevisionReasonModel } from "../../setup/revision-reasons/models/revision-reason.models";

@Injectable()
export class TransmittalEditorService {
  readonly baseUrl: string = `${AppConfig.settings.apiUrl}/transmittals`;

  constructor(
    private http: HttpClient
  ) { }

  get(id: number, externalCategoryId: string = null): Observable<TransmittalEditorModel> {
    let url = !!id ? `${this.baseUrl}/${id}/edit` : `${this.baseUrl}/edit`;
    if (externalCategoryId)
      url += `/${externalCategoryId}`;
    return this.http.get<TransmittalEditorModel>(url);
  }

  update(editor: TransmittalEditorModel) {
    const url = !!editor.id ? `${this.baseUrl}/${editor.id}/edit` : `${this.baseUrl}/edit`;
    return this.http.post<ValidationResultModel>(url, editor);
  }

  getCategoryOptions(editor: TransmittalEditorModel): Observable<TransmittalCategoryModel[]> {
    return this.http.post<TransmittalCategoryModel[]>(`${this.baseUrl}/edit/typeoptions`, editor);
  }

  getDocumentOptions(editor: TransmittalEditorModel, searchText: string = ''): Observable<DocumentModel[]> {
    let url = `${this.baseUrl}/edit/documentoptions`;
    url += `?searchtext=${searchText}`;
    return this.http.post<DocumentModel[]>(url, editor);
  }

  getRevisionOptions(editor: TransmittalEditorModel, documentId: number): Observable<RevisionModel[]> {
    const url = `${this.baseUrl}/edit/revisionoptions/${documentId}`;
    return this.http.post<RevisionModel[]>(url, editor);
  }

  getBulkRevisionOptions(editor: TransmittalEditorModel, documentIds: number[]): Observable<BulkRevisionModel[]> {
    const url = `${this.baseUrl}/edit/revisionoptions/bulk`;
    return this.http.post<BulkRevisionModel[]>(url, {editor: editor, documentIds: documentIds});
  }

  getProjectOptions(editor: TransmittalEditorModel, searchText?: string): Observable<ProjectModel[]> {
    let url = `${this.baseUrl}/edit/projectoptions`;
    if (!!searchText)
      url += `?searchtext=${searchText}`;
    return this.http.post<ProjectModel[]>(url, editor);
  }

  getAdditionalProjectOptions(editor: TransmittalEditorModel, searchText?: string): Observable<ProjectModel[]> {
    let url = `${this.baseUrl}/edit/projectoptions`;
    if (!!searchText)
      url += `?searchText=${searchText}`;
    return this.http.post<ProjectModel[]>(url, editor);
  }

  getSelectedSourceOptions(editor: TransmittalEditorModel): Observable<TransmittalSourceWithContactsModel[]> {
    let url = `${this.baseUrl}/edit/selectedsourceoptions`;
    return this.http.post<TransmittalSourceWithContactsModel[]>(url, editor);
  }

  getSourceOptions(editor: TransmittalEditorModel, searchText?: string): Observable<TransmittalSourceWithContactsModel[]> {
    let url = `${this.baseUrl}/edit/sourceoptions`;
    if (!!searchText)
      url += `?searchText=${searchText}`;
    return this.http.post<TransmittalSourceWithContactsModel[]>(url, editor);
  }

  getTemplateOptions(editor: TransmittalEditorModel): Observable<TemplateModel[]> {
    let url = `${this.baseUrl}/edit/templateoptions`;
    return this.http.post<TemplateModel[]>(url, editor);
  }

  getDefaultTemplate(editor: TransmittalEditorModel) {
    let url = `${this.baseUrl}/edit/defaulttemplateoption`;
    if (!!editor.projectId)
      url += `/${editor.projectId}`;
    return this.http.get<TemplateModel>(url);
  }

  getFormatOptions(editor: TransmittalEditorModel): Observable<TransmittalFormatModel[]> {
    let url = `${this.baseUrl}/edit/formatoptions`;
    return this.http.post<TransmittalFormatModel[]>(url, editor);
  }

  getReasonOptions(editor: TransmittalEditorModel): Observable<RevisionReasonModel[]> {
    let url = `${this.baseUrl}/edit/reasonoptions`;
    return this.http.post<RevisionReasonModel[]>(url, editor);
  }

  getIdentifier(id?: number, editor?: TransmittalEditorModel): Observable<TransmittalIdentifierModel> {
    const url = !!id ? `${this.baseUrl}/${id}/identifier` : `${this.baseUrl}/identifier`;
    return !!id? this.http.get<TransmittalIdentifierModel>(url) : this.http.post<TransmittalIdentifierModel>(url, editor);
  }

  getDocument(documentId: number) {
    let url = `${this.baseUrl}/edit/getdocument/${documentId}`;
    return this.http.get<DocumentModel>(url);
  }

  getDocuments(documentIds: number[]) {
    let url = `${this.baseUrl}/edit/getdocuments`;
    return this.http.post<DocumentModel[]>(url, { ids: documentIds });
  }

  getTransmittalEditorOptions(editor: TransmittalEditorModel): Observable<TransmittalEditorOptions> {
    const options = new TransmittalEditorOptions();
    return observableForkJoin<any>(
      this.getProjectOptions(editor),
      this.getCategoryOptions(editor),
      this.getDocumentOptions(editor),
      this.getSelectedSourceOptions(editor),
      this.getSourceOptions(editor),
      this.getTemplateOptions(editor),
      this.getFormatOptions(editor)
    ).pipe(mergeMap(results => {
      options.projectOptions = results[0];
      options.categoryOptions = results[1];
      options.documentOptions = results[2];
      options.selectedSourceOptions = results[3];
      options.sourceOptions = results[4];
      options.templateOptions = results[5];
      options.formatOptions = results[6];

      return observableOf(options);
    }))
  }
}
