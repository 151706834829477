import { Injectable } from '@angular/core';
import { AppConfig } from '../../../app.config';
import { HttpClient } from '@angular/common/http';
import { GridDataService } from '../../../shared/services/grid-data.service';
import { State } from '@progress/kendo-data-query';
import { ProjectEditorModel } from '../models/project.models';
import { TemplateModel } from '../../templates/models/template.models';
import { SourceModel } from '../../sources/models/source.models';
import { VesselModel } from '../../vessels/models/vessel.models';
import { IKendoGridComponent } from '../../../shared/utils/kendo.utils';

@Injectable()
export class ProjectService {
  readonly baseUrl: string = `${AppConfig.settings.apiUrl}/projects`;
  readonly gridName: string = 'projects';

  constructor(
    private http: HttpClient,
    private gridData: GridDataService
  ) { }

  getGridData(state: State, filters?: any) {
    return this.gridData.getGridData(this.gridName, this.baseUrl, state, filters);
  }

  get(id: number) {
    const url = !!id ? `${this.baseUrl}/${id}/edit` : `${this.baseUrl}/edit`
    return this.http.get<ProjectEditorModel>(url);
  }

  update(editor: ProjectEditorModel) {
    const url = !!editor.id ? `${this.baseUrl}/${editor.id}/edit` : `${this.baseUrl}/edit`;
    return this.http.post(url, editor);
  }

  archive(id: number) {
    return this.http.post(`${this.baseUrl}/${id}/archive`, null);
  }

  delete(id: number) {
    return this.http.post(`${this.baseUrl}/${id}/delete`, null);
  }

  getTemplateOptions(editor: ProjectEditorModel) {
    return this.http.post<TemplateModel[]>(`${this.baseUrl}/edit/templateoptions`, editor);
  }

  getDefaultTemplate(editor: ProjectEditorModel) {
    let url = `${this.baseUrl}/edit/defaulttemplateoption`;
    if (!!editor.id)
      url += `/${editor.id}`;
    return this.http.get<TemplateModel>(url);
  }

  getMainSourceOptions(editor: ProjectEditorModel, searchText?: string){
    let url = `${this.baseUrl}/edit/mainsourceoptions`;
    if (!!searchText)
      url += '?';
    if (!!searchText)
      url += `searchText=${searchText}&`;
    return this.http.post<SourceModel[]>(url, editor);
  }

  getOtherSourceOptions(editor: ProjectEditorModel, searchText?: string){
    let url = `${this.baseUrl}/edit/othersourceoptions`;
    if (!!searchText)
      url += '?';
    if (!!searchText)
      url += `searchText=${searchText}&`;
    return this.http.post<SourceModel[]>(url, editor);
  }

  getVesselOptions(searchText?: string) {
    let url = `${this.baseUrl}/edit/vesseloptions`;
    if (!!searchText)
      url += `?searchText=${searchText}`;
    return this.http.get<VesselModel[]>(url);
  }

}
