import { Component, OnInit, ViewChild, Output, Input } from '@angular/core';
import { DialogService } from '@progress/kendo-angular-dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { KendoDialogRef } from '../../../shared/utils/kendo.utils';
import { Subject } from 'rxjs';
import { SaveEvent, SaveAction } from '../../../shared/models/events';
import { TransmittalSavedFormComponent } from '../transmittal-saved-form/transmittal-saved-form.component';
import { TransmittalEditorModel } from '../../models/transmittal.models';

@Component({
  selector: 'app-transmittal-saved',
  templateUrl: './transmittal-saved.component.html',
  styleUrls: ['./transmittal-saved.component.scss']
})
export class TransmittalSavedComponent implements OnInit {
  @Input() identifier: string;
  @Input() pdfDownloadUrl: string;
  @Input() editor: TransmittalEditorModel;
  @Input('permissions') permissions: any;
  @Output('saved') onSaved: Subject<SaveEvent> = new Subject();
  @ViewChild('savedDialogActions', {static: false}) actions;
  @ViewChild('savedFormComponent', {static: false}) savedFormComponent: TransmittalSavedFormComponent;

  succeeded: boolean;

  private dialogRef: KendoDialogRef<TransmittalSavedFormComponent>;
  private formComponent: TransmittalSavedFormComponent;

  constructor(
    private dialogService: DialogService
  ) { }

  ngOnInit() { }

  updateFileName(identifier: string) {
    this.identifier = identifier;
    this.formComponent.identifier = identifier;
  }

  close() {
    this.dialogRef.close();
    this.dialogRef.dialog.destroy();
    this.succeeded = false;
    this.onSaved.next({ action : SaveAction.Continue });
  }

  open(editor: TransmittalEditorModel) {
    this.dialogRef = this.dialogService.open({
      title: 'Transmittal Saved',
      content: TransmittalSavedFormComponent,
      actions: this.actions
    });
    setTimeout(() => {
      $('kendo-dialog-actions button').last().focus();
    })

    this.formComponent = this.dialogRef.content.instance;
    this.formComponent.identifier = this.identifier;
    this.formComponent.pdfDownloadUrl = this.pdfDownloadUrl;
    this.formComponent.editor = editor;
  }

  back() {
    this.formComponent.validationResult = null;
    this.formComponent.loading = true;
    this.onSaved.next({ action : SaveAction.Back });
    this.dialogRef.close();
  }

  newTransmittal() {
    this.formComponent.validationResult = null;
    this.formComponent.loading = true;
    this.onSaved.next({ action : SaveAction.New });
    this.dialogRef.close();
  }
}
