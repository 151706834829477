
import {of as observableOf,  Observable ,  Subscription } from 'rxjs';
import { Component, OnInit, Output, OnDestroy, ViewChild, EventEmitter, Input } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

import { IBriefcaseItem } from '../../../models/briefcase.models';
import { DocumentService } from '../../../services/document.service';
import { ILoadable } from '../../../../shared/components/loading/loading.models';
import { DocumentApproveComponent } from '../../document-approve/document-approve.component';
import { BriefcaseTransmitWarningComponent } from '../briefcase-transmit-warning/briefcase-transmit-warning.component';
import { BriefcaseExportComponent } from '../briefcase-export/briefcase-export.component';
import { KendoGridColumn } from '../../../../shared/models/grid.models';
import { SystemPermissions } from '../../../../shared/models/permissions.models';
import { PermissionService } from '../../../../shared/services/permission.service';
import { BriefcaseTransmitDialogComponent } from '../briefcase-transmit-dialog/briefcase-transmit-dialog.component';
import { SaveEvent } from '../../../../shared/models/events';

@Component({
  selector: 'app-briefcase',
  templateUrl: './briefcase.component.html',
  styleUrls: ['./briefcase.component.scss'],
  animations: [
    trigger('openState', [
      state('open', style({
        transform: 'translateY(0)',
        height: '*',
        opacity: 1,
        display: 'block'
      })),
      state('closed', style({
        transform: 'translateY(-100px)',
        height: 0,
        opacity: 0,
        display: 'none',
      })),
      transition('open => closed', [animate('200ms ease-in-out')]),
      transition('closed => open', [animate('300ms ease-in-out')])
    ])
  ]
})
export class BriefcaseComponent implements OnInit, OnDestroy, ILoadable {
  @Input() exportColumns: KendoGridColumn[] = [];
  @Output('change') briefcaseChange: EventEmitter<IBriefcaseEvent> = new EventEmitter();
  @ViewChild('approveDialog', {static: false}) approveDialog: DocumentApproveComponent;
  @ViewChild('linkDialog', {static: false}) linkDialog: DocumentApproveComponent;
  @ViewChild('briefcaseTransmitWarning', {static: false}) briefcaseTransmitWarning: BriefcaseTransmitWarningComponent;
  @ViewChild('briefcaseExport', {static: false}) briefcaseExport: BriefcaseExportComponent;
  @ViewChild('transmitDialog', {static: false}) transmitDialog: BriefcaseTransmitDialogComponent;

  hasLoaded: boolean;
  loading: boolean;
  systemPermissions: SystemPermissions;
  isOpen = false;
  briefcaseButtonDisabled: boolean;
  linkButtonDisabled: boolean;
  approveButtonDisabled: boolean;
  transmitButtonDisabled: boolean;
  canTransmit: boolean;
  items: IBriefcaseItem[] = [];
  pulse: boolean;

  get state() {
    return this.isOpen ? 'open' : 'closed';
  }

  private readonly itemsKey: string = 'briefcase-items';
  private onChangeSubscription: Subscription;

  constructor(
    private documentService: DocumentService,
    private permissionService: PermissionService
  ) {
  }

  ngOnInit() {
    this.systemPermissions = this.permissionService.getPermissions();
    let timeout;
    this.getItems();
    this.updateButtonState();
    this.onChangeSubscription = this.briefcaseChange.subscribe(e => {
      this.updateButtonState();
      this.pulse = true;
      if (timeout) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(() => {
        this.pulse = false;
        timeout = null;
      }, 900);
    });
  }

  ngOnDestroy() {
    if (this.onChangeSubscription) {
      this.onChangeSubscription.unsubscribe();
    }
  }

  toggleState() {
    this.isOpen = !this.isOpen;
    if (this.isOpen) {
      this.reloadItems();
      if (this.items.length === 0) {
        this.isOpen = !this.isOpen;
      }
    }
  }

  updateButtonState() {
    this.documentService.canlink(this.items.map(e => e.id)).subscribe(canLinkResponse => {
      this.linkButtonDisabled =
        !canLinkResponse.canLink ||
        this.items.length < 2 ||
        this.items.filter(e => e.approved).length < 2 ||
        this.items.some(e => e.voided);
    });

    this.briefcaseButtonDisabled = this.items.length === 0;
    this.approveButtonDisabled = !this.items.some(e => e.permissions.canApprove);
    this.transmitButtonDisabled =
      !this.systemPermissions.transmittals.canCreate ||
      this.items.every(e => !e.approved) ||
      this.items.every(e => e.voided);


    this.canTransmit = !this.items.every(e => e.approved) || !this.items.every(e => !e.voided);
  }

  reloadItems() {
    this.getItems().subscribe(items => {
      this.items = items;
      this.briefcaseChange.next({ items: this.items, selectedChanged: true });
    });
  }

  empty() {
    this.items = [];
    this.saveItems(this.items);
    this.toggleState();
  }

  getItems(): Observable<IBriefcaseItem[]> {
    const items = JSON.parse(localStorage.getItem(this.itemsKey)) as IBriefcaseItem[] || [];
    this.items = items;
    return observableOf(items);
  }

  toggleItems(updates: IBriefcaseItem[], options?: IBriefcaseOptions): void {
    const defaults: IBriefcaseOptions = { updateAll: false };
    const o = Object.assign(defaults, options);

    this.getItems().subscribe(briefcase => {
      updates.forEach(update => {
        const existing = this.items.find(i => i.id === update.id);

        if (o.updateAll) {
          if (o.addAll && !existing) {
            this.add(update);
          } else if (!o.addAll && !!existing) {
            this.remove(existing);
          }
        } else if (!existing) {
          this.add(update);
        } else {
          this.remove(existing);
        }
      });
      this.saveItems(this.items);
    });
    if (this.items.length === 0) {
      this.toggleState();
    }
  }

  updateItemValues(updates: IBriefcaseItem[]) {
    updates.forEach(update => {
      const item = this.items.find(e => e.id === update.id);

      if (!item) {
        return;
      }

      item.latestRevisionTitle = update.latestRevisionTitle;
      item.identifier = update.identifier;
      item.approved = update.approved;
      item.voided = update.voided;
      item.permissions = update.permissions;
    });
    this.saveItems(this.items);
  }

  approve() {
    const idsToApprove: number[] = [];

    this.items.forEach(item => {
      if (item.permissions.canApprove) {
        idsToApprove.push(item.id);
      }
    });

    this.approveDialog.open(idsToApprove);
  }

  link() {
    this.linkDialog.open(this.items.map(e => e.id));
  }

  onActionComplete() {
    this.briefcaseChange.next({ items: this.items, dataChanged: true });
  }

  contains(id: number): boolean {
    return this.items.some(i => i.id === id);
  }

  export() {
    this.briefcaseExport.ids = this.items.map(e => e.id);
    this.briefcaseExport.export();
  }

  transmitCheck() {
    // If any of the options are Unapproved or voided warn them.
    if (this.items.every(i => i.approved) && this.items.every(i => !i.voided)) {
      this.transmit();
    } else {
      this.briefcaseTransmitWarning.open();
    }
  }

  onTransmitWarningConfirm($event: SaveEvent) {
    this.transmit();
  }

  transmit() {
    // Filter out the unapproved
    const transmitableDocuments = this.items.filter(i => i.approved && !i.voided)
    this.transmitDialog.open(transmitableDocuments.map(e => e.id), transmitableDocuments);
  }

  private add(item: IBriefcaseItem): void {
    this.items.push(item);
  }

  private remove(item: IBriefcaseItem): void {
    this.items.splice(this.items.indexOf(item), 1);
  }

  private saveItems(items: IBriefcaseItem[]): void {
    localStorage.setItem(this.itemsKey, JSON.stringify(items));
    this.reloadItems();
  }
}

export interface IBriefcaseEvent {
  items: IBriefcaseItem[];
  selectedChanged?: boolean;
  dataChanged?: boolean;
}

interface IBriefcaseOptions {
  updateAll: boolean;
  addAll?: boolean;
}
