import {Component, OnInit, ViewChild, Output, Input, EventEmitter} from '@angular/core';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-transmittal-confirm-save-dialog',
  templateUrl: './transmittal-confirm-save-dialog.component.html',
  styleUrls: ['./transmittal-confirm-save-dialog.component.scss']
})
export class TransmittalConfirmSaveDialogComponent implements OnInit {
  @Output() saveSelected: EventEmitter<any> = new EventEmitter();
  @Output() cancelSelected: EventEmitter<any> = new EventEmitter();
  @Input() isDownload: boolean;
  @ViewChild('dialogContent', {static: false}) content;
  @ViewChild('dialogActions', {static: false}) actions;

  private dialogRef: DialogRef;

  constructor(
    private dialogService: DialogService
  ) { }

  ngOnInit() {
  }

  open() {
    this.dialogRef = this.dialogService.open({
      title: 'Warning',
      content: this.content,
      actions: this.actions
    });
  }

  submit() {
    this.saveSelected.emit();
    this.dialogRef.close();
  }

  cancel() {
    this.cancelSelected.emit();
    this.dialogRef.close();
  }
}
