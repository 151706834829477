import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from "../../../shared/utils/auth.guard";
import { RoleGridComponent } from '../components/role-grid/role-grid.component';
import { RoleEditComponent } from '../components/role-edit/role-edit.component';

const routes: Routes = [
  {
    path: 'setup',
    canActivate: [AuthGuard],    
    children: [
      { path: 'roles', component: RoleGridComponent },
      { path: 'roles/edit', component: RoleEditComponent },      
      { path: 'roles/:id/edit', component: RoleEditComponent }
    ]
  }
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RoleRouterModule { }
