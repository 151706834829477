import { Component, OnInit, ViewChild } from '@angular/core';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { TransmittalEditorModel } from '../../../../transmittals/models/transmittal.models';
import { RoleUserLinkModel, RoleGridRowModel } from '../../models/role.models';
import { element } from 'protractor';

@Component({
  selector: 'app-view-users',
  templateUrl: './view-users.component.html',
  styleUrls: ['./view-users.component.scss']
})
export class ViewUsersComponent implements OnInit {
  @ViewChild('viewUsers', {static: false}) content;
  @ViewChild('dialogActions', {static: false}) actions;

  private dialogRef: DialogRef;
  hasLoaded: boolean;
  loading: boolean;
  editor: TransmittalEditorModel;
  gridData: RoleUserLinkModel[] = [];

  constructor(
    private dialogService: DialogService,
  ) { }

  ngOnInit() {
  }

  open(data: RoleGridRowModel) {
    this.loading = true;

    data.users.forEach(element => {
      var concatNames = "";
      element.roles.sort((a, b) => {
        if (a.name < b.name) return -1;
        else if (a.name > b.name) return 1;
        else return 0;
      })
      element.roles.forEach(role => {
        concatNames += role.name;
        if (element.roles.length > 1)
          concatNames += ", "
      });
      if (concatNames.substr(concatNames.length-1) == " ")
        concatNames = concatNames.substr(0, concatNames.length - 2);
        element.rolesText = concatNames;
    });

    this.gridData = data.users;

    this.dialogRef = this.dialogService.open({
      title: data.name + ' - Associated Users',
      content: this.content,
      actions: this.actions,
      width: 1000
    });
    this.loading = false;
  }

  close() {
    this.dialogRef.close();
    this.dialogRef.dialog.destroy();
  }
}
