
import {of as observableOf,  Observable } from 'rxjs';

import {mergeMap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { SystemPermissions } from "../models/permissions.models";
import { AppConfig } from '../../app.config';

@Injectable()
export class PermissionService {
  private permissions: SystemPermissions;
  private permissionsTask: Observable<boolean>;

  constructor(
    private http: HttpClient
  ) { }

  getPermissions(): SystemPermissions {
    return this.permissions;
  }

  loadPermissions(): Observable<boolean> {
    if (this.permissionsTask)
      return this.permissionsTask;

    return this.permissionsTask = this.http.get<SystemPermissions>(`${AppConfig.settings.apiUrl}/security/permissions`).pipe(
      mergeMap((permissions => {
        this.permissions = permissions;
        return observableOf(true);
    })));
  }
}
