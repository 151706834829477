import { NgModule } from '@angular/core';
import { AuditLogComponent } from '../components/audit-log/audit-log.component';
import { RouterModule } from '@angular/router';
import { GridModule, ExcelModule } from '@progress/kendo-angular-grid';
import { AuditService } from '../services/audit.service';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../shared/modules/shared.modules';
import { AuditRouterModule } from './audit.router.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    GridModule,
    SharedModule,
    ExcelModule
  ],
  declarations: [
    AuditLogComponent
  ],
  providers: [
    AuditService
  ],
  exports: [
    AuditRouterModule,
    AuditLogComponent
  ]
})
export class AuditModule { }