import { Component, OnInit, HostListener, Output, OnDestroy, ViewChild } from '@angular/core';
import { trigger, state, style, transition, animate, group } from '@angular/animations';
import { BriefcaseItem } from '../../../models/briefcase.models';
import { of as observableOf, Observable ,  Subject ,  Subscription } from 'rxjs';
import { TransmittalService } from '../../../services/transmittal.service';
import { ILoadable } from '../../../../shared/components/loading/loading.models';

@Component({
  selector: 'app-briefcase',
  templateUrl: './briefcase.component.html',
  styleUrls: ['./briefcase.component.scss'],
  animations: [
    trigger('openState', [
      state('open', style({
        transform: 'translateY(0)',
        height: '*',
        opacity: 1,
        display: 'block'
      })),
      state('closed', style({
        transform: 'translateY(-100px)',
        height: 0,
        opacity: 0,
        display: 'none',
      })),
      transition('open => closed', [animate('200ms ease-in-out')]),
      transition('closed => open', [animate('300ms ease-in-out')])
    ])
  ]
})
export class BriefcaseComponent implements OnInit, OnDestroy, ILoadable {
  @Output('change') onChange: Subject<IBriefcaseEvent> = new Subject();

  hasLoaded: boolean;
  loading: boolean;
  isOpen: boolean = false;
  items: BriefcaseItem[] = [];
  pulse: boolean;
  get state() { return this.isOpen ? 'open' : 'closed' };

  private readonly itemsKey: string = 'briefcase-items';
  private onChangeSubscription: Subscription;

  constructor(
    private transmittalService: TransmittalService
  ) { }

  ngOnInit() {
    let timeout;
    this.onChangeSubscription = this.onChange.subscribe(e => {
      this.pulse = true;
      if (timeout)
        clearTimeout(timeout);
      timeout = setTimeout(() => {
        this.pulse = false;
        timeout = null;
      }, 900);
    })
  }

  ngOnDestroy() {
    if (this.onChangeSubscription)
      this.onChangeSubscription.unsubscribe();
  }

  toggleState() {
    this.isOpen = !this.isOpen;

    if (this.isOpen)
      this.reloadItems();
  }

  reloadItems() {
    this.getItems().subscribe(items => {
      this.items = items
      this.onChange.next({ items: this.items, selectedChanged: true });
    });
  }

  empty() {
    this.items = [];
    this.saveItems(this.items);
    this.toggleState();
  }

  getItems(): Observable<BriefcaseItem[]> {
    let items = JSON.parse(localStorage.getItem(this.itemsKey)) as BriefcaseItem[] || [];
    this.items = items;
    return observableOf(items);
  }

  toggleItems(updates: BriefcaseItem[], options?: IBriefcaseOptions): void {
    const defaults: IBriefcaseOptions = { updateAll: false };
    let o = Object.assign(defaults, options);

    this.getItems().subscribe(briefcase => {
      updates.forEach(update => {
        let existing = this.items.find(i => i.id == update.id);

        if (o.updateAll) {
          if (o.addAll && !existing)
            this.add(update);
          else if (!o.addAll && !!existing)
            this.remove(existing);
        } else if (!existing) {
          this.add(update);
        } else {
          this.remove(existing);
        }
      })
      this.saveItems(this.items);
    });
  }

  onActionComplete() {
    this.onChange.next({ items: this.items, dataChanged: true });
  }

  contains(id: number): boolean {
    return this.items.some(i => i.id == id);
  }

  private add(item: BriefcaseItem): void {
    this.items.push(item);
  }

  private remove(item: BriefcaseItem): void {
    this.items.splice(this.items.indexOf(item), 1);
  }

  private saveItems(items: BriefcaseItem[]): void {
    localStorage.setItem(this.itemsKey, JSON.stringify(items));
    this.reloadItems();
  }
}

export interface IBriefcaseEvent {
  items: BriefcaseItem[];
  selectedChanged?: boolean;
  dataChanged?: boolean;
}

interface IBriefcaseOptions {
  updateAll: boolean;
  addAll?: boolean;
}
