import { ApiEntityModel } from '../../../shared/models/apiEntity.model';

export interface SourceInstancePermissionsModel {
  canView: boolean;
  canEdit: boolean;
  canEditAcronym: boolean;
  canArchive: boolean;
  canDelete: boolean;
}

export interface SourceGridRowModel {
  id: number;
  acronym: string;
  name: string;
  documentControlEmailAddress: string;
  countryName: string;
  isSubContractor: boolean;
  projectNames: string;
  active: boolean;
  permissions: SourceInstancePermissionsModel;
}

export class SourceEditorModel {
  id: number | null;
  name: string;
  acronym: string;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  postcode: string;
  countryId: number;
  documentControlEmailAddress: string;
  isSubContractor: boolean;
  active: boolean = true;
  contacts: ContactEditorModel[] = [];
  projectIds: number[] = [];
  permissions: SourceInstancePermissionsModel;
}

export class SourceModel extends ApiEntityModel<number> {
  name: string;
  acronym: string;

  selected: boolean;
}

export class ContactEditorModel {
  id: number | null;
  forename: string;
  surname: string;
  position: string;
  company: string;
  emailAddress: string;

  isAdd: boolean;
  tempId: number;
}

export class ContactModel extends ApiEntityModel<number> {
  forename: string;
  surname: string;
  position: string;
  company: string;
  emailAddress: string;

  selected: boolean;

  get fullName() { return `${this.forename} $${this.surname}`.trim() }
}

export interface CountryModel extends ApiEntityModel<number> {
  name: string;
  nativeName: string;
  twoLetterCode: string;
  threeLetterCode: string;
}

export class ProjectModel extends ApiEntityModel<number> {
  name: string;
  number: string;
}
