import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { AppConfig } from '../../../app.config';
import { AuditEventGridFiltersModel } from '../models/audit.models';
import { State, toDataSourceRequestString } from '@progress/kendo-data-query';
import { GridDataService } from '../../../shared/services/grid-data.service';
import { IKendoGridComponent } from '../../../shared/utils/kendo.utils';

@Injectable()
export class AuditService {
  readonly baseUrl: string = `${AppConfig.settings.apiUrl}/audit`;
  readonly gridName: string = 'audit';
  readonly gridNameUserFilter: string = 'audit-user-filter';

  constructor(private gridData: GridDataService) { }

  getGridData(state: State, filters: AuditEventGridFiltersModel,  gridName?: string) {
    if (gridName != null) {
      return this.gridData.getGridData(gridName, this.baseUrl, state, filters);
    }

    return this.gridData.getGridData(this.gridName, this.baseUrl, state, filters);
  }
}
