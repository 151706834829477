
import {finalize} from 'rxjs/operators';
import { Component, OnInit, Output, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { KendoDialogRef } from '../../../shared/utils/kendo.utils';
import { DialogService, DialogRef } from '@progress/kendo-angular-dialog';
import { ILoadable } from '../../../shared/components/loading/loading.models';
import { DeletedEvent } from '../../../shared/models/events';
import { HistoricalDocumentService } from '../../services/historical-document.service';

@Component({
  selector: 'app-historical-document-delete',
  templateUrl: './historical-document-delete.component.html',
  styleUrls: ['./historical-document-delete.component.scss']
})
export class HistoricalDocumentDeleteComponent implements OnInit, ILoadable {
  @Output() onSuccess: Subject<DeletedEvent> = new Subject();
  @ViewChild('dialogContent', {static: false}) content;
  @ViewChild('dialogActions', {static: false}) actions;

  hasLoaded: boolean;
  loading: boolean;
  succeeded: boolean;
  title: string;
  subtitle: string;
  id: number;

  private dialogRef: DialogRef;

  constructor(
    private dialogService: DialogService,
    private historicalDocumentService: HistoricalDocumentService
  ) { }

  ngOnInit() {
  }

  open(id: number) {
    this.dialogRef = this.dialogService.open({
      title: 'Delete Imported Document',
      content: this.content,
      actions: this.actions
    });

    setTimeout(() => {
      $('kendo-dialog-actions button').last().focus();
    })

    this.succeeded = false;
    this.id = id;
  }

  submit() {
    this.loading = true;
    this.historicalDocumentService.delete(this.id).pipe(
      finalize(() => this.loading = false))
      .subscribe(res => {
        this.succeeded = true;
        this.onSuccess.next({ id: this.id });
      }, err => { })
  }

  close() {
    this.dialogRef.close();
    this.dialogRef.dialog.destroy();
  }
}
