import { Component, OnInit, Directive, ElementRef, EventEmitter, Output, HostListener, ViewEncapsulation } from '@angular/core';
import { ActionSheetService } from './action-sheet.service';

@Component({
  selector: 'app-action-sheet',
  templateUrl: './action-sheet.component.html',
  styleUrls: ['./action-sheet.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ActionSheetComponent implements OnInit {
  isOpen: boolean = false;

  constructor(private service: ActionSheetService) { }

  ngOnInit() {
  }

  toggleState() {
    this.isOpen = ! this.isOpen;

    this.service.toggle(this);
  }

  close() {
    this.isOpen = false;
  }

}