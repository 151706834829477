import {AppComponent} from './app.component';
import {AppRouterModule} from './app.router.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BrowserModule} from '@angular/platform-browser';
import {DocumentModule} from './documents/modules/document.module';
import {HttpClientModule} from '@angular/common/http';
import {APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import {SetupModule} from './setup/setup.module';
import {TransmittalsModule} from './transmittals/modules/transmittals.module';
import {AppConfig} from './app.config';
import {ImportModule} from './import/modules/import.module';
import {PtErrorHandler} from './shared/utils/pt-error-handler';
import {MSAL_INSTANCE, MsalModule, MsalService} from "@azure/msal-angular";
import {MSALFactory} from "./msalFactory";
import {ToastrModule} from "ngx-toastr";
import {ModalModule} from "ngx-bootstrap";

declare global {
  export interface Window {
    scrollToTop: Function;
  }
}

export const initializeApp = (appConfig: AppConfig) => () => appConfig.load();

window.scrollToTop = () => {
  var timeOut;
  (function s() {
    if (document.body.scrollTop != 0 || document.documentElement.scrollTop != 0) {
      window.scrollBy(0, -50);
      timeOut = setTimeout(() => {
        s();
      }, 10);
    } else clearTimeout(timeOut);
  })();
};

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRouterModule,
    SetupModule,
    DocumentModule,
    TransmittalsModule,
    ImportModule,
    MsalModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right'
    }),
    ModalModule.forRoot(),

  ],
  providers: [
    AppConfig,
    {provide: APP_INITIALIZER, useFactory: initializeApp, deps: [AppConfig], multi: true},
    {provide: LOCALE_ID, useValue: 'en-GB'},
    {provide: ErrorHandler, useClass: PtErrorHandler},
    {provide: MSAL_INSTANCE, useFactory: MSALFactory},
    MsalService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
