import { VesselModel } from '../../vessels/models/vessel.models';
import { SourceModel } from '../../sources/models/source.models';
import { TemplateModel } from '../../templates/models/template.models';
export interface ProjectInstancePermissionsModel {
  canView: boolean;
  canEdit: boolean;
  canEditNumber: boolean;
  canArchive: boolean;
  canDelete: boolean;
}

export interface ProjectGridRowModel {
  id: number;
  name: string;
  number: string;
  archived: boolean;
  active: boolean;
  area: string;
  mainSourceName: string;
  vesselNames: string;
  permissions: ProjectInstancePermissionsModel;
}

export class ProjectEditorModel {
  id: number | null;
  name: string;
  number: string;
  active: boolean = true;
  area: string;
  transmittalIdentifierTemplate: string;
  mainSourceId: SourceModel | null;
  otherSourceIds: number[] = [];
  templateId: TemplateModel | null;
  vesselIds: number[] = [];
  permissions: ProjectInstancePermissionsModel;
}
