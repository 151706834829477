import { KendoGridConfig } from '../../../shared/models/grid.models';

const gridConfig: KendoGridConfig = {
  defaultSort: [{ dir: 'desc', field: 'date' }],
  defaultFilter: { logic: 'and', filters: [{ field: 'voided', operator: 'eq', value: false }] },
  columns: [
    {
      title: 'Trans No',
      field: 'identifier',
      _width: 180
    },
    {
      title: 'Proj No\'s',
      field: 'projectNumber',
      _width: 180
    },
    {
      title: 'Issued on Date',
      field: 'date',
      _width: 170,
      filter: 'date',
      format: '{0:dd/MM/yyyy}',
      pipe: 'dd/MM/yyyy'
    },
    {
      title: 'No of Docs',
      field: 'documentCount',
      _width: 120,
      filter: 'numeric',
      format: '{0:n}'
    },
    {
      title: 'Sent to Sources',
      field: 'sourceNames',
      _width: 210
    },
    {
      title: 'Template',
      field: 'template',
      _width: 125
    },
    {
      title: 'Issue Format',
      field: 'formatOfIssue',
      _width: 125
    },
    {
      title: 'From Email Address',
      field: 'fromEmail',
      _width: 160
    },
    {
      title: 'Filename',
      field: 'filename',
      _width: 125
    },
    {
      title: 'File Path',
      field: 'filePath',
      _width: 125
    },
    {
      title: 'Issued?',
      field: 'issued',
      _width: 120,
      filter: 'boolean'
    },
    {
      title: 'Created on Date',
      field: 'createDate',
      _width: 140,
      filter: 'date',
      format: 'dd/MM/yyyy'
    },
    {
      title: 'Creator Name',
      field: 'creatorName',
      _width: 120
    },
    {
      title: 'Acknowledged?',
      field: 'acknowledgementStateId',
      _width: 130,
      filter: 'numeric',
      sortable: false
    },
    {
      title: 'Voided',
      field: 'voided',
      hidden: true,
      filter: 'boolean',
      _width: 125
    },
    {
      title: 'Voided Reason',
      field: 'reasonForIssue',
      hidden: true,
      _width: 125
    },
    {
      title: '',
      field: '_actions',
      filterable: false,
      sortable: false,
      _width: 90
    }
  ]
};

export default gridConfig;
