
import {finalize} from 'rxjs/operators';
import { Component, OnInit, Output, ViewChild } from '@angular/core';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { DocumentImportService } from "../../services/document-import.service";
import { DocumentGridRowModel, DocumentImportGridRowModel } from "../../models/document.models";
import { ILoadable } from "../../../shared/components/loading/loading.models";
import { filter as _filter } from "lodash";

@Component({
  selector: 'app-document-import-delete',
  templateUrl: './document-import-delete.component.html',
  styleUrls: ['./document-import-delete.component.scss']
})
export class DocumentImportDeleteComponent implements OnInit, ILoadable {
  @ViewChild('dialogContent', {static: false}) content;
  @ViewChild('dialogActions', {static: false}) actions;

  hasLoaded: boolean;
  loading: boolean;
  succeeded: boolean;

  gridData: DocumentImportGridRowModel[];
  import: DocumentImportGridRowModel;
  undeleteableDocuments: DocumentGridRowModel[];
  chosen: boolean = false;

  private dialogRef: DialogRef;

  constructor(
    private dialogService: DialogService,
    private documentImportService: DocumentImportService
  ) { }

  ngOnInit() { }

  open() {
    this.loading = true;
    this.hasLoaded = false;
    this.chosen = false;

    this.dialogRef = this.dialogService.open({
      title: 'Delete Import',
      content: this.content,
      actions: this.actions,
      width: 750
    });

    this.documentImportService.getImportsGridData().subscribe(result => {
      this.gridData = result.data;
      this.loading = false;
      this.hasLoaded = true;
    });

    setTimeout(() => {
      $('kendo-dialog-actions button').last().focus();
    });

    this.succeeded = false;
  }

  close() {
    this.dialogRef.close();
    this.dialogRef.dialog.destroy();
  }

  choose(dataItem: any) {
    this.loading = true;
    this.hasLoaded = false;
    this.import = dataItem;

    this.documentImportService.getImportDocuments(this.import.id).pipe(
      finalize(() => {
        this.loading = false;
        this.hasLoaded = true;
      }))
      .subscribe(documents => {
        this.undeleteableDocuments = _filter(documents, document => !document.permissions.canDelete);
        this.chosen = true;
      });
  }

  delete(voidUsedDocuments: boolean) {
    this.loading = true;

    this.documentImportService
      .deleteImport(this.import.id, voidUsedDocuments).pipe(
      finalize(() => {
        this.loading = false;
      }))
      .subscribe(res => {
        this.succeeded = true;
      });
  }
}
