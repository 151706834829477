
import {finalize} from 'rxjs/operators';
import { Component, OnInit, Output, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { KendoDialogRef } from '../../../shared/utils/kendo.utils';
import { TransmittalService } from '../../services/transmittal.service';
import { DialogService, DialogRef } from '@progress/kendo-angular-dialog';
import { ILoadable } from '../../../shared/components/loading/loading.models';
import { DeletedEvent } from '../../models/transmittal.models';
import { TransmittalExternalService } from '../../services/transmittal-external.service';

@Component({
  selector: 'app-transmittal-external-delete',
  templateUrl: './transmittal-external-delete.component.html',
  styleUrls: ['./transmittal-external-delete.component.scss']
})
export class TransmittalExternalDeleteComponent implements OnInit, ILoadable {
  @Output('deleted') onDeleted: Subject<DeletedEvent> = new Subject();
  @ViewChild('dialogContent', {static: false}) content;
  @ViewChild('dialogActions', {static: false}) actions;

  hasLoaded: boolean;
  loading: boolean;
  succeeded: boolean;
  filename: string;
  identifier: string;
  id: number;

  private dialogRef: DialogRef;

  constructor(
    private dialogService: DialogService,
    private transmittalExternalService: TransmittalExternalService
  ) { }

  ngOnInit() {
  }

  open(id: number, identifer: string, filename: string) {
    this.dialogRef = this.dialogService.open({
      title: 'Delete Transmittal',
      content: this.content,
      actions: this.actions
    });

    this.succeeded = false;
    this.id = id;
    this.identifier = identifer;
    this.filename = filename;
  }

  submit() {
    this.loading = true;
    this.transmittalExternalService.delete(this.id).pipe(
      finalize(() => this.loading = false))
      .subscribe(res => {
        this.succeeded = true;
        this.onDeleted.next({ id: this.id });
      }, err => { })
  }

  close() {
    this.dialogRef.close();
    this.dialogRef.dialog.destroy();
  }
}
