
import {finalize} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { ILoadable } from '../../../../shared/components/loading/loading.models';
import { ActivatedRoute, Router } from '@angular/router';
import { DocumentTypeService } from '../../services/document-type.service';
import {
  DocumentTypeEditorModel, DepartmentModel,
  DocumentTypeInstancePermissionsModel
} from '../../models/document-type.models';
import { HttpErrorResponse } from '@angular/common/http';
import { ValidationResultModel, IValidatable } from '../../../../shared/models/validation.models';
import { PermissionService } from "../../../../shared/services/permission.service";
import { SystemPermissions } from "../../../../shared/models/permissions.models";

@Component({
  selector: 'app-document-type-edit',
  templateUrl: './document-type-edit.component.html',
  styleUrls: ['./document-type-edit.component.scss']
})
export class DocumentTypeEditComponent implements OnInit, ILoadable, IValidatable {
  validationResult: ValidationResultModel;
  hasLoaded: boolean = false;
  loading: boolean = false;
  title: string;
  updated: boolean = false;
  editor: DocumentTypeEditorModel = new DocumentTypeEditorModel();
  permissions: DocumentTypeInstancePermissionsModel;
  departmentOptions: DepartmentModel[] = [];
  isEditMode: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private docTypeService: DocumentTypeService
  ) { }

  ngOnInit() {
    this.loading = true;

    this.route.params.subscribe(params => {
      const id = params['id'];

      if(id)
        this.isEditMode = true;

      this.docTypeService.get(params['id']).pipe(
        finalize(() => {
          this.hasLoaded = true;
          this.loading = false;
        }))
        .subscribe(editor => {
          this.editor = editor;
          this.permissions = editor.permissions;

          if (!this.editor.description)
            this.editor.description = ""; // need to define description here so that keydown enter will work (see html for description section)

          this.title = id ? (this.permissions.canEdit ? 'Edit' : 'View') + ' Document Type' : 'Document Type Setup';
        });
    });

    this.docTypeService.getDepartmentOptions().subscribe(options => this.departmentOptions = options);
  }

  onDepartmentFilterChanged(searchTerm: any){
    this.docTypeService.getDepartmentOptions(searchTerm)
      .subscribe(options => {      
        this.departmentOptions = options;
      });
  }

  submit($event: any) {
    this.validationResult = null;
    this.loading = true;
    this.docTypeService.update(this.editor).pipe(
      finalize(() => this.loading = false))
      .subscribe(() => {
        this.updated = true;
        setTimeout(() => {
          this.router.navigateByUrl('/setup/document/type');
        }, 1500);
      }, err => {
        if (err instanceof HttpErrorResponse && err.status == 412) {
          this.validationResult = err.error;
        }
      })
  }

  cancel(){
    this.router.navigateByUrl("/setup/document/type");
  }
}
