import { CommonModule } from '@angular/common';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { GridModule } from '@progress/kendo-angular-grid';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/modules/shared.modules';
import { DocumentTypeGridComponent } from '../components/document-type-grid/document-type-grid.component';
import { RouterModule } from '@angular/router';
import { DocumentTypeRouterModule } from './document-type.router.module';
import { FormsModule } from '@angular/forms';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DocumentTypeEditComponent } from '../components/document-type-edit/document-type-edit.component';
import { DocumentTypeService } from '../services/document-type.service';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    GridModule,
    DropDownsModule,
    DialogModule,
    ButtonsModule,
    SharedModule
  ],
  providers: [
    DocumentTypeService
  ],
  declarations: [
    DocumentTypeGridComponent,
    DocumentTypeEditComponent
  ],
  exports: [
    DocumentTypeRouterModule
  ]
})
export class DocumentTypeModule { }