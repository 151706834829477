import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HistoricalDocumentsGridComponent } from '../components/historical-documents-grid/historical-documents-grid.component';
import { ImportDocumentsComponent } from '../components/import-documents/import-documents.component';
import { AuthGuard } from "../../shared/utils/auth.guard";

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    children: [
      { path: 'historical/documents', component: HistoricalDocumentsGridComponent },
      { path: 'historical/documents/import', component: ImportDocumentsComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ImportRouterModule { }
