import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JsonParseService } from '../../../../services/json-parse.service';

@Component({
  selector: 'app-draft-record-controls',
  templateUrl: './draft-record-controls.component.html',
  styleUrls: ['./draft-record-controls.component.scss']
})
export class DraftRecordControlsComponent implements OnInit {
  @Output() draftApply: EventEmitter<any> = new EventEmitter();
  @Output() draftRemove: EventEmitter<any> = new EventEmitter();
  @Output() modelChange: EventEmitter<any> = new EventEmitter();

  @Input() cssClass: string = '';
  @Input() buttonCssClass: string = '';
  @Input() showHeader: boolean = true;
  @Input() convertParsedDates: boolean = true;
  @Input() beforeSave: Function;
  @Input() afterSave: Function;

  @Input()
  get model(): any { return this.modelValue; };
  set model(val: any) {
    this.modelValue = val;
    this.modelChange.emit(this.modelValue);
  }

  modelValue: any;
  showClearButton: boolean;
  saving: boolean;
  hasDraft: boolean;

  get saveText(): string { return this.saving ? 'Saved!' : 'Save Draft' };

  private key: string;
  private url: string;

  constructor(
    private router: Router,
    private jsonParse: JsonParseService
  ) {
    this.setKey();
  }

  ngOnInit() {
    if (!this.model)
      return console.warn('DraftRecordControlsComponent requires [model] or [(model)]');

    this.applyCurrent();
  }

  save() {
    if (!!this.beforeSave)
      this.beforeSave();

    setTimeout(() => {
      this.saving = true;

      localStorage.setItem(this.key, JSON.stringify(this.model));

      this.hasDraft = true;
      setTimeout(() => {
        this.saving = false;
        this.afterSave();
      }, 500);
    });
  }

  remove() {
    localStorage.removeItem(this.key);
    this.hasDraft = false;
    this.draftRemove.emit();
  }

  private removeAndRefresh() {
    this.remove();
    window.location.href = this.url; //Ugly but works!
  }

  private applyCurrent() {
    let storedValue = localStorage.getItem(this.key);

    this.hasDraft = !!storedValue;

    if (!storedValue)
      return;

    let draft = JSON.parse(storedValue);

    if (this.convertParsedDates) {
      this.jsonParse.parseDates(draft);
    }

    setTimeout(() => {
      this.model = draft;
      this.draftApply.emit(this.model);
    })
  }

  private setKey() {
    this.url = this.router.routerState.snapshot.url;

    const url = `${this.url}?`;
    const key = url.substring(0, url.indexOf('?'));

    this.key = 'draft-' + key;
  }
}
