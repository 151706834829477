import { Component, OnInit, ViewChild } from '@angular/core';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { TransmittalService } from '../../../../transmittals/services/transmittal.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-contact-save-dialog',
  templateUrl: './contact-save-dialog.component.html',
  styleUrls: ['./contact-save-dialog.component.scss']
})
export class ContactSaveDialogComponent implements OnInit {

  constructor(
    private dialogService: DialogService,
  ) { }

  private dialogRef: DialogRef;
  @ViewChild('dialogContent', {static: false}) content;
  @ViewChild('dialogActions', {static: false}) actions;

  hasLoaded: boolean;
  loading: boolean;
  succeeded: boolean;
  title: string;
  subtitle: string;
  documentId: number;

  ngOnInit() {
  }

  open() {
    this.dialogRef = this.dialogService.open({
      title: 'Warning!',
      content: this.content,
      actions: this.actions
    });
    this.succeeded = false;
    this.loading = false;
  }

  close() {
    this.dialogRef.close();
    this.dialogRef.dialog.destroy();
  }
}
