
import {finalize} from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ILoadable } from '../../../../shared/components/loading/loading.models';
import { IValidatable, ValidationResultModel } from '../../../../shared/models/validation.models';
import { TemplateEditorModel, TemplateInstancePermissionsModel } from '../../models/template.models';
import { TemplateService } from '../../services/template.service';
import { SystemPermissions } from "../../../../shared/models/permissions.models";
import { PermissionService } from "../../../../shared/services/permission.service";

@Component({
  selector: 'app-template-edit',
  templateUrl: './template-edit.component.html',
  styleUrls: ['./template-edit.component.scss']
})
export class TemplateEditComponent implements OnInit, ILoadable, IValidatable {
  @ViewChild('pdfPreviewForm', {static: false}) pdfPreviewForm: ElementRef;

  isEditMode: boolean = false;
  validationResult: ValidationResultModel;
  hasLoaded: boolean = false;
  loading: boolean = false;
  title: string;
  updated: boolean = false;
  editor: TemplateEditorModel = new TemplateEditorModel();
  systemPermissions: SystemPermissions;
  permissions: TemplateInstancePermissionsModel;
  pdfDownloadUrl: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private permissionService: PermissionService,
    private templateService: TemplateService
  ) { }

  ngOnInit() {
    this.loading = true;
    this.systemPermissions = this.permissionService.getPermissions();

    this.route.params.subscribe(params => {
      const id = params['id'];

      if (id)
        this.isEditMode = true;

      this.templateService.get(id).pipe(
        finalize(() => {
          this.hasLoaded = true;
          this.loading = false;
        }))
        .subscribe(editor => {
          this.editor = editor;
          if (!this.editor.legalText)
            this.editor.legalText = ""; // need to define legal text here so that keydown enter will work (see html for legal text section)
          if (!editor.id)
            editor.active = true;

          this.permissions = editor.permissions;
          this.title = id ? (this.permissions.canEdit ? 'Edit' : 'View') + ' Template' : 'Template Setup';
        });
    });
  }

  submit($event: any) {
    this.validationResult = null;
    this.loading = true;
    this.templateService.update(this.editor).pipe(
      finalize(() => this.loading = false))
      .subscribe(() => {
        this.updated = true;
        setTimeout(() => {
          this.router.navigateByUrl('/setup/templates');
        }, 1500);
      }, err => {
        if (err instanceof HttpErrorResponse && err.status == 412) {
          this.validationResult = err.error;
        }
      })
  }

  cancel(){
    this.router.navigateByUrl("/setup/templates");
  }

  getPreview() {
    this.loading = true;

    if (!this.systemPermissions.templates.canManage) {
      this.loading = false;
      return;
    }

    this.templateService.postPreview(this.editor)
      .pipe(finalize(() => this.loading = false))
      .subscribe(res => {
        var fileURL = URL.createObjectURL(res);
        window.open(fileURL, '_blank');
      });
  }
}
