
import {finalize} from 'rxjs/operators';
import { Component, OnInit, Output, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { KendoDialogRef } from '../../../shared/utils/kendo.utils';
import { TransmittalService } from '../../services/transmittal.service';
import { DialogService, DialogRef } from '@progress/kendo-angular-dialog';
import { ILoadable } from '../../../shared/components/loading/loading.models';
import { DeletedEvent } from '../../models/transmittal.models';

@Component({
  selector: 'app-transmittal-delete',
  templateUrl: './transmittal-delete.component.html',
  styleUrls: ['./transmittal-delete.component.scss']
})
export class TransmittalDeleteComponent implements OnInit, ILoadable {
  @Output('deleted') onDeleted: Subject<DeletedEvent> = new Subject();
  @ViewChild('dialogContent', {static: false}) content;
  @ViewChild('dialogActions', {static: false}) actions;

  hasLoaded: boolean;
  loading: boolean;
  succeeded: boolean;
  title: string;
  subtitle: string;
  documentId: number;

  private dialogRef: DialogRef;

  constructor(
    private dialogService: DialogService,
    private transmittalService: TransmittalService
  ) { }

  ngOnInit() {
  }

  open(documentId: number, title: string, subtitle: string) {
    this.dialogRef = this.dialogService.open({
      title: 'Delete Transmittal',
      content: this.content,
      actions: this.actions
    });

    this.succeeded = false;
    this.documentId = documentId;
    this.title = title;
    this.subtitle = subtitle;
  }

  submit() {
    this.loading = true;
    this.transmittalService.delete(this.documentId).pipe(
      finalize(() => this.loading = false))
      .subscribe(res => {
        this.succeeded = true;
        this.onDeleted.next({ id: this.documentId });
      }, err => { })
  }

  close() {
    this.dialogRef.close();
    this.dialogRef.dialog.destroy();
  }
}
