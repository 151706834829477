import { KendoGridConfig } from '../../../shared/models/grid.models';

const gridConfig: KendoGridConfig = {
  defaultSort: [{ dir: 'desc', field: 'creationTime' }],
  defaultFilter: { logic: 'and', filters: [{ field: 'voided', operator: 'eq', value: false }] },
  columns: [
    {
      title: '',
      field: '_icons',
      _width: 32,
      filterable: false,
      sortable: false
    },
    {
      title: 'Created',
      field: 'creationTime',
      _width: 130,
      filter: 'date',
      format: 'dd/MM/yyyy'
    },
    {
      title: 'Classification',
      field: 'categorySuperName',
      _width: 155
    },
    {
      title: 'ID/Project No\'s',
      field: 'categoryProjectsCombined',
      _width: 165
    },
    {
      title: 'Vessels',
      field: 'vesselNames',
      _width: 140
    },
    {
      title: 'Doc Type',
      field: 'typeAcronym',
      _width: 135
    },
    {
      title: 'Source',
      field: 'sourceAcronym',
      _width: 130
    },
    {
      title: 'Control No',
      field: 'controlNumber',
      _width: 145,
      filter: 'numeric',
      format: '{0:n}'
    },
    {
      title: 'Title',
      field: 'latestRevisionTitle',
      _width: 150
    },
    {
      title: 'Rev',
      field: 'latestRevisionIdentifier',
      _width: 110
    },
    {
      title: 'Reference',
      field: 'reference',
      hidden: true,
      _width: 150
    },
    {
      title: 'External Reference',
      field: 'externalReference',
      hidden: true,
      _width: 185
    },
    {
      title: 'Approved',
      field: 'approved',
      _width: 140,
      filter: 'boolean'
    },
    {
      title: 'Document Number',
      field: 'documentNumberIdentifierCombined',
      hidden: true,
      _width: 190
    },
    {
      title: 'External Doc No',
      field: 'externalDocNumber',
      hidden: true,
      _width: 190
    },
    {
      title: 'Filename',
      field: 'filename',
      hidden: true,
      _width: 200
    },
    {
      title: 'File Path',
      field: 'filePath',
      hidden: true,
      _width: 200
    },
    {
      title: 'Voided',
      field: 'voided',
      hidden: true,
      filter: 'boolean',
      _width: 125
    },
    {
      title: '',
      field: '_actions',
      filterable: false,
      sortable: false,
      _width: 90
    }
  ]
};

export default gridConfig;
