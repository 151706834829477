
import {finalize} from 'rxjs/operators';
import { Component, OnInit, Output, ViewChild, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { KendoDialogRef } from '../../../shared/utils/kendo.utils';
import { TransmittalVoidFormComponent } from '../transmittal-void-form/transmittal-void-form.component';
import { TransmittalGridRowModel } from '../../models/transmittal.models';
import { TransmittalService } from '../../services/transmittal.service';
import { DialogService } from '@progress/kendo-angular-dialog';
import { TransmittalExternalService } from '../../services/transmittal-external.service';

@Component({
  selector: 'app-transmittal-void',
  templateUrl: './transmittal-void.component.html',
  styleUrls: ['./transmittal-void.component.scss']
})
export class TransmittalVoidComponent implements OnInit {
  @Input() external: boolean = false;
  @Output('voided') onVoided: Subject<any> = new Subject();
  @ViewChild('dialogActions', {static: false}) actions;

  succeeded: boolean;

  private dialogRef: KendoDialogRef<TransmittalVoidFormComponent>;
  private formComponent: TransmittalVoidFormComponent;

  constructor(
    private dialogService: DialogService,
    private transmittalService: TransmittalService,
    private externalTransmittalService: TransmittalExternalService,
  ) { }

  ngOnInit() {
  }

  open(documentId: number, title: string) {
    this.dialogRef = this.dialogService.open({
      title: 'Void Transmittal',
      content: TransmittalVoidFormComponent,
      actions: this.actions
    });

    this.formComponent = this.dialogRef.content.instance;
    this.formComponent.editor.id = documentId;
    this.formComponent.title = title;

    this.succeeded = this.formComponent.succeeded = false;
  }

  submit() {
    if (!this.formComponent.editor.reason) {
      this.formComponent.invalid = true;
      return;
    }

    this.formComponent.loading = true;
    let editor = this.dialogRef.content.instance.editor;

    const onVoid = this.external ? this.externalTransmittalService.void(editor) : this.transmittalService.void(editor);

    onVoid.pipe(
      finalize(() => this.formComponent.loading = false))
      .subscribe(res => {
        this.succeeded = this.formComponent.succeeded = true;
        this.onVoided.next();
      }, err => { })
  }

  close() {
    this.dialogRef.close();
    this.dialogRef.dialog.destroy();
  }
}
