
import {finalize} from 'rxjs/operators';
import { Component, OnInit, Input, Output } from '@angular/core';
import { ILoadable } from '../../../shared/components/loading/loading.models';
import { RevisionService } from '../../services/revision.service';
import { IKendoGridComponent, KendoColumnResizeArgs } from '../../../shared/utils/kendo.utils';
import { State } from '@progress/kendo-data-query';
import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { RevisionGridFiltersModel, IRevisionAddedEvent, RevisionGridRowModel, IRevisionDeletedEvent, IRevisionArchivedEvent } from '../../models/revision.models';
import { Subject } from 'rxjs';
import { DeletedEvent } from '../../../shared/models/events';
import { DocumentEditComponent } from '../document-edit/document-edit.component';
import { DocumentEditorService } from '../../services/document-editor.service';
import { GridDataService } from '../../../shared/services/grid-data.service';
import { GridColumnState } from '../../../shared/models/grid.models';

@Component({
  selector: 'app-document-revision-grid',
  templateUrl: './document-revision-grid.component.html',
  styleUrls: ['./document-revision-grid.component.scss']
})
export class DocumentRevisionGridComponent implements OnInit, ILoadable, IKendoGridComponent {
  @Input('documentId') documentId: number;
  @Input('currentTitle') currentTitle: string;
  @Input('canUpRev') canUpRev: boolean;
  @Output('added') onAdded: Subject<IRevisionAddedEvent> = new Subject();
  @Output('archived') archived: Subject<IRevisionArchivedEvent> = new Subject();
  @Output('deleted') deleted: Subject<IRevisionDeletedEvent> = new Subject();
  @Output('countChange') countChange: Subject<number> = new Subject();

  hasLoaded: boolean;
  loading: boolean;
  state: State = {
    take: 5,
    skip: 0,
    sort: [
      {dir: 'desc', field: 'revisionDate'},
      {dir: 'desc', field: 'timeAdded'}
    ]
  };
  filters?: RevisionGridFiltersModel = new RevisionGridFiltersModel();
  gridData: GridDataResult;
  columnState: GridColumnState = new GridColumnState([
    { name: 'Time Added', field: 'timeAdded', defaults: { width: 113 } },
    { name: 'Revision Date', field: 'revisionDate', defaults: { width: 113 } },
    { name: 'User', field: 'addedByUserName', defaults: { width: 113 } },
    { name: 'Reason', field: 'reason', defaults: { width: 113 } },
    { name: 'Revision', field: 'identifier', defaults: { width: 113 } },
    { name: 'SJ Trans', field: 'transmittals', defaults: { width: 133 } },
    { name: 'Ext Trans', field: 'externalTransmittals', defaults: { width: 133 } },
    { name: 'Title', field: 'latestRevisionTitle', defaults: { width: 133 } },
  ]);

  constructor(
    private revisionService: RevisionService,
    private documentEditorService: DocumentEditorService,
    private gridDataService: GridDataService
  ) { }

  ngOnInit() {
    this.gridDataService.initColumnState(this);

    this.gridDataService.applyCachedState(this.revisionService.gridName, this);

    this.updateGrid();
  }

  onArchived(item: IRevisionArchivedEvent) {
    this.updateGrid();
    this.archived.next(item);

  }

  onDeleted(item: IRevisionDeletedEvent) {
    this.updateGrid();
    this.deleted.next(item);
  }

  dataStateChange(state: DataStateChangeEvent): void {
    this.loading = true;
    this.state = state;
    this.updateGrid();
  }

  onRevisionAdded($event: IRevisionAddedEvent) {
    this.updateGrid();
    this.onAdded.next($event);
  }

  resetColumnState() {
    this.gridDataService.resetColumnState(this.revisionService.gridName, this);
  }

  resized(changes: KendoColumnResizeArgs[]) {
    const change = changes[0];
    const col = this.columnState.selected.find(c => c.field == change.column.field);
    col.width = change.newWidth;
    this.gridDataService.saveColumnState(this.revisionService.gridName, this, true);
  }

  updateGrid() {
    this.loading = true;
    this.filters.documentId = this.documentId;
    this.revisionService.getGridData(this.state, this.filters).pipe(
      finalize(() => {
        this.hasLoaded = true;
      }))
      .subscribe(data => {
        this.gridData = data;
        this.loading = false;
        this.countChange.next(data.total);
      });
  }
}
