import { KendoGridConfig } from '../../../shared/models/grid.models';

const gridConfig: KendoGridConfig = {
  defaultSort: [{ dir: 'desc', field: 'date' }],
  defaultFilter: { logic: 'and', filters: [{ field: 'voided', operator: 'eq', value: false }] },
  columns: [
    {
      title: 'Trans No',
      field: 'identifier',
      _width: 180
    },
    {
      title: 'Proj No\'s',
      field: 'projectNumber',
      _width: 180
    },
    {
      title: 'No of Docs',
      field: 'documentCount',
      _width: 120,
      filter: 'numeric',
      format: '{0:n}'
    },
    {
      title: 'Source',
      field: 'sourceName',
      _width: 200
    },
    {
      title: 'Sub Con',
      field: 'sourceIsSubContractor',
      _width: 140,
      filter: 'boolean'
    },
    {
      title: 'Received on Date',
      field: 'date',
      _width: 200,
      filter: 'date',
      format: '{0:dd/MM/yyyy}',
      pipe: 'dd/MM/yyyy'
    },
    {
      title: 'Filename',
      field: 'fileName',
      _width: 125
    },
    {
      title: 'File Path',
      field: 'filePath',
      _width: 125
    },
    {
      title: 'Reason for Issue',
      field: 'issueReason',
      _width: 200
    },
    {
      title: 'Created on Date',
      field: 'createDate',
      _width: 140,
      filter: 'date',
      format: 'dd/MM/yyyy'
    },
    {
      title: 'Created By',
      field: 'creatorName',
      _width: 120
    },
    {
      title: 'Voided',
      field: 'voided',
      hidden: true,
      filter: 'boolean',
      _width: 125
    },
    {
      title: '',
      field: '_actions',
      filterable: false,
      sortable: false,
      _width: 90
    }
  ]
};

export default gridConfig;
