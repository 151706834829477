import { Component, OnInit } from '@angular/core';
import { PermissionService } from "../../../services/permission.service";
import { SystemPermissions } from "../../../models/permissions.models";
import { SecurityService } from "../../../services/security.service";
import { User } from "../../../models/security.models";
import { environment } from '../../../../../environments/environment';
import {AppConfig} from '../../../../app.config';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {
  user: User;
  systemPermissions: SystemPermissions;
  environmentLabel: string = AppConfig.settings.name;

  constructor(
    private permissionService: PermissionService,
    private securityService: SecurityService
  ) { }

  ngOnInit() {
    this.securityService.getUser().subscribe(user =>{
      this.user = user;
    });

    this.permissionService.loadPermissions().subscribe(() => {
      this.systemPermissions = this.permissionService.getPermissions();
    });
  }

  get canAccessSetup(): boolean {
    return (
      this.systemPermissions.auditing.canAccess ||
      this.systemPermissions.documentTypes.canAccess ||
      this.systemPermissions.projects.canAccess ||
      this.systemPermissions.sources.canAccess ||
      this.systemPermissions.templates.canAccess ||
      this.systemPermissions.users.canAccess ||
      this.systemPermissions.roles.canAccess ||
      this.systemPermissions.revisionReasons.canAccess ||
      this.systemPermissions.documentTypes.canAccess ||
      this.systemPermissions.auditing.canAccess ||
      this.systemPermissions.vessels.canAccess
    );
  }
}
