
import {of as observableOf, combineLatest as observableCombineLatest,  Observable ,  Subscriber ,  Subscription } from 'rxjs';

import {mergeMap, finalize, take} from 'rxjs/operators';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Location } from '@angular/common';
import { ILoadable } from '../../../shared/components/loading/loading.models';
import { IValidatable, ValidationResultModel } from '../../../shared/models/validation.models';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { DocumentEditorService } from '../../services/document-editor.service';
import {
  DocumentCategoryModel, DocumentEditorModel, DocumentTypeModel, DocumentModel, DocumentEditorHelper,
  DocumentEditorOptions, ExternalTransmittalOptionModel,
  DocumentInstancePermissionsModel
} from '../../models/document.models';
import { SaveEvent, SaveAction } from '../../../shared/models/events';
import { VesselModel, VesselClassModel, VesselOptionModel } from '../../../setup/vessels/models/vessel.models';
import { SourceModel, ProjectModel } from '../../../setup/sources/models/source.models';

import { HttpErrorResponse } from '@angular/common/http';
import { IRevisionAddedEvent, IRevisionDeletedEvent, IRevisionArchivedEvent } from '../../models/revision.models';
import { DocumentService } from '../../services/document.service';
import * as Clipboard from 'clipboard';
import { DocumentHistoryGridComponent } from '../document-history-grid/document-history-grid.component';
import { DocumentSavedComponent } from '../document-saved/document-saved.component';
import { DocumentLinksGridComponent } from '../document-links-grid/document-links-grid.component';
import { DocumentRevisionGridComponent } from '../document-revision-grid/document-revision-grid.component';
import { HistoricalDocumentService } from '../../../import/services/historical-document.service';
import { DocumentDuplicateDialogComponent } from '../duplicate-dialog/duplicate-dialog.component';
import { DocumentDuplicationBatchGridComponent } from '../duplication-batch-grid/duplication-batch-grid.component';
import { NgForm } from '@angular/forms';
import { DocumentGridRowModel } from '../../models/document.models';
import { SystemPermissions } from '../../../shared/models/permissions.models';
import { PermissionService } from '../../../shared/services/permission.service';
import { DocumentIssuedWarningComponent } from "../document-issued-warning/document-issued-warning.component";
import { IDirtyRecordGuard } from '../../../shared/modules/dirty-record/guards/dirty-record-guard';
import { DialogService } from '@progress/kendo-angular-dialog';
import { DirtyRecordDirective } from '../../../shared/modules/dirty-record/directives/dirty-record.directive';
import { DraftRecordControlsComponent } from '../../../shared/modules/draft-record/components/draft-record-controls/draft-record-controls.component';
import 'rxjs-compat/add/operator/mergeMap';

@Component({
  selector: 'app-document-edit',
  templateUrl: './document-edit.component.html',
  styleUrls: ['./document-edit.component.scss']
})
export class DocumentEditComponent implements OnInit, ILoadable, IValidatable, IDirtyRecordGuard {
  @ViewChild('historyGrid', { static: false}) historyGrid: DocumentHistoryGridComponent;
  @ViewChild('revisionsGrid', { static: false}) revisionsGrid: DocumentRevisionGridComponent;
  @ViewChild('linksGrid', { static: false}) linksGrid: DocumentLinksGridComponent;
  @ViewChild('batchGrid', { static: false}) batchGrid: DocumentDuplicationBatchGridComponent;
  @ViewChild('savedDialog', { static: false}) savedDialog: DocumentSavedComponent;
  @ViewChild('issuedWarningDialog', { static: false}) issuedWarningDialog: DocumentIssuedWarningComponent;
  @ViewChild('duplicateDialog', { static: false}) duplicateDialog: DocumentDuplicateDialogComponent;
  @ViewChild(DirtyRecordDirective, { static: false}) dirtyRecordDirective: DirtyRecordDirective;
  @ViewChild('draftRecord', { static: false}) draftRecord: DraftRecordControlsComponent;

  validationResult: ValidationResultModel;
  hasLoaded: boolean;
  loading: boolean;
  updated: boolean;
  draftSaved: boolean;
  draftApplied: boolean;
  dirtyForm: boolean;
  dirty: boolean;
  formValuesSubscription: Subscription;
  isEditMode: boolean = false;
  isExternalSeajacks: boolean = false;
  title: string;
  defaultDocumentTypeItem = { id: '', name: 'None' };
  defaultSourceItem = { id: null, name: 'None' };
  defaultTransmittalItem = { id: null, identifier: 'None' };
  defaultImsItem = { id: null, identifier: 'None' };
  systemPermissions: SystemPermissions;
  batchId: number;
  editedBatchIds: number[] = [];
  batchDocuments: DocumentGridRowModel[] = [];
  get isBatchEdit() { return !!this.batchId };

  get editorCanDuplicate() { return this.isEditMode && this.dialogCanDuplicate };
  get dialogCanDuplicate() { return !this.isBatchEdit && !this.editor.parentId && !this.helper.categoryIsIms && this.permissions.canDuplicate }

  editor: DocumentEditorModel;
  permissions: DocumentInstancePermissionsModel;
  helper: DocumentEditorHelper = new DocumentEditorHelper();
  options: DocumentEditorOptions;
  categoryOptions: DocumentCategoryModel[] = [];
  projectOptions: ProjectModel[] = [];
  sourceOptions: SourceModel[] = [];
  typeOptions: DocumentTypeModel[] = [];
  filteredTypeOptions: DocumentTypeModel[] = [];
  vesselOptions: VesselOptionModel[] = [];
  parentOptions: DocumentModel[] = [];
  transmittalOptions: ExternalTransmittalOptionModel[] = [];
  filename: string;
  isViaTransmittal: boolean = false;
  isViaHistorical: boolean = false;
  isDuplicate: boolean = false;
  isDuplicateEdit: boolean = false;
  historicalValue: number | boolean;
  importedProjectId: number;
  importedVesselIds: number[] = [];
  importedVesselClassIds: number[] = [];
  historyGridCount: number | null;
  revisionGridCount: number | null;
  linksGridCount: number | null;
  transmittalGridCount: number | null;
  externalTransmittalGridCount: number | null;
  duplicateDocumentIds: number[] = [];
  get historyTabTitle(): string { return 'History' + (this.historyGridCount != null && this.isEditMode ? ` (${this.historyGridCount})` : '') }
  get revisionTabTitle(): string { return 'Revisions' + (this.revisionGridCount != null && this.isEditMode ? ` (${this.revisionGridCount})` : '') }
  get linksTabTitle(): string { return 'Links' + (this.linksGridCount != null && this.isEditMode ? ` (${this.linksGridCount})` : '') }
  get transmittalTabTitle(): string { return 'Transmittals' + (this.transmittalGridCount != null && this.isEditMode ? ` (${this.transmittalGridCount})` : '') }
  get externalTransmittalTabTitle(): string { return 'External Transmittals' + (this.externalTransmittalGridCount != null && this.isEditMode ? ` (${this.externalTransmittalGridCount})` : '') }

  get documentNumberPreview() {
    return this.isExternalSeajacks ? 'N/A' : (this.filename || 'Example will appear here')
  }

  private transmittalId?: number;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private documentEditorService: DocumentEditorService,
    private documentService: DocumentService,
    private historicalDocumentService: HistoricalDocumentService,
    private permissionService: PermissionService
  ) { }

  ngOnInit() {
    this.systemPermissions = this.permissionService.getPermissions();
    let clipboard = new Clipboard('#button-copy-filename');
    let lastId: number;
    observableCombineLatest([this.route.params, this.route.queryParams], (params, queryParams) => ({ params, queryParams }))
      .subscribe(result => {
        //Ugly but this is to fix an issue where if both params and queryparams change when navigating by url
        //this subscription is called twice, meaning the reset method was originally firing twice.
        //Once with a different id and once with different queryparams but the old id. NASTY!
        if (!lastId || result.params.id && +result.params.id != lastId) {
          this.reset(result.params, result.queryParams);
          lastId = +result.params.id;
        }
      })
  }

  reset(params: Params = {}, queryParams: Params = {}) {
    window.scrollToTop();

    this.updated = false;
    this.hasLoaded = false;
    this.loading = true;

    this.editor = new DocumentEditorModel();
    this.helper = new DocumentEditorHelper();

    if (this.dirtyRecordDirective) {
      this.dirtyRecordDirective.stopWatching();
      this.dirtyRecordDirective.reset();
    }

    if (queryParams['batch']) {
      this.batchId = queryParams['batch'];
      let batchEdited = queryParams['batchEdited'] || [];
      this.editedBatchIds = typeof (batchEdited) == "string" ? [+batchEdited] : batchEdited.map(id => +id) || [];
    }

    let id = params['id'] || null;
    if (id) {
      this.isEditMode = true;
      this.documentService.getFileName(id).subscribe(res => {
        this.filename = res.filename;
      });
      this.editor.id = id;
      if (this.hasLoaded) {
        setTimeout(() => {
          this.historyGrid.updateGrid();
          this.revisionsGrid.updateGrid();
          this.linksGrid.updateGrid();
        })
      }
    }

    let externalType = params['externalType'];
    if (externalType) {
      if (externalType == "externalnonseajacks") {
        this.isExternalSeajacks = true;
      }
      this.helper.categoryId = externalType;
    }

    let historicalParam = queryParams['historical'];
    this.historicalValue = historicalParam ? Number.parseInt(historicalParam) || historicalParam == "true" : null;
    this.isViaHistorical = !!this.historicalValue;

    this.isDuplicate = !!queryParams['duplicate'];
    this.isViaTransmittal = !!queryParams['transmittal'] && queryParams['transmittal'] != NaN;
    if (this.isDuplicate) {
      if (id)
        this.isDuplicateEdit = true;

      this.duplicateDocumentIds = this.documentEditorService.getDuplicateDocumentIds();
    }
    else {
      this.duplicateDocumentIds = [];
      this.documentEditorService.clearDuplicateDocumentIds();
    }
    this.getEditorSubscription(id).pipe(mergeMap(editor => {
      editor.revisionDate = editor.revisionDate ? new Date(editor.revisionDate) : null;
      editor.expiryDate = editor.expiryDate ? new Date(editor.expiryDate) : null;
      this.editor = editor;
      this.helper.originalParentId = editor.parentId;
      this.helper.originalParentAttachmentNumber = editor.attachmentNumber;
      this.importedProjectId = editor.projectIds[0];
      this.importedVesselIds = editor.vesselIds;
      this.importedVesselClassIds = editor.vesselClassIds;
      this.permissions = editor.permissions;
      if (this.isDuplicate) {
        this.editor.attachmentNumber++;
      }
      return this.getAndApplyEditorOptions();
    })).pipe(
      finalize(() => {
        this.hasLoaded = true;
        this.loading = false;
      })
    ).subscribe(options => {
      if (this.isViaTransmittal) {
        if (queryParams['transmittal'] != null) {
          this.transmittalId = +queryParams['transmittal'];
          this.editor.revisionExternalTransmittalId = this.transmittalId;
          this.documentEditorService.getTransmittalOptions(this.editor).subscribe(options => this.transmittalOptions = options);
        }
      } else if (this.isViaHistorical) {
        this.editor.historicalDocumentId = +this.historicalValue;
      }
    }, err => {
      console.error('forkJoin error on Document Edit', err)
    });
  }

  getEditorSubscription(id: number) {
    if (this.isViaHistorical && typeof (this.historicalValue) != "boolean" && !id)
      return this.historicalDocumentService.create(this.historicalValue)
    else if (this.isDuplicate && !this.isDuplicateEdit) {
      return observableOf(this.documentEditorService.duplicate());
    }
    else
      return this.documentEditorService.get(id, this.helper.categoryId);
  }

  getAndApplyEditorOptions(draft: boolean = false) {
    return this.documentEditorService
      .getDocumentEditorOptions(this.editor).pipe(
      mergeMap(options => {
        this.options = options;
        this.categoryOptions = options.categoryOptions;
        this.projectOptions = options.projectOptions;
        this.sourceOptions = options.sourceOptions;
        this.typeOptions = options.typeOptions;
        this.filteredTypeOptions = options.typeOptions;
        this.parentOptions = options.parentOptions;
        this.vesselOptions = options.vesselOptions;
        this.transmittalOptions = options.transmittalOptions;

        this.helper.selectedProjects = this.projectOptions.filter(o => this.editor.projectIds.includes(o.id));

        if (this.isDuplicate)
          this.populateHelperFromEditor();

        this.categorySelectionChange(this.editor.categoryId, true, draft);

        if (this.editor.id)
          this.switchToEditMode();
        if (!this.helper.isExternal) {
          this.helper.selectedVessel = this.helper.selectedVessels[0];
        }
        if (this.editor.projectIds.length) {
          if (this.isViaHistorical)
            this.helper.projectId = this.importedProjectId;
          else
            this.helper.projectId = this.editor.projectIds[0];
        }
        if (!this.helper.categoryIsCor)
          this.documentEditorService.getVesselOptions(this.editor, null).subscribe(options => {
            this.vesselOptions = options;
            this.populateHelperVessels();
          });

        this.helper.approved = this.editor.approved;

        setTimeout(() => {
          if (this.dirtyRecordDirective)
            this.dirtyRecordDirective.watch();
        })

        return observableOf(options);
      }));
  }

  updateIdentifier() {
    if (!this.isEditMode) {
      this.populateEditorFromHelper();
      this.documentEditorService.getDocumentIdentifier(this.editor).subscribe(result =>
        this.filename = result.identifier);
    }
  }

  updateImsControlNumber() {
    if (!this.helper.categoryIsIms)
      return;

    if (this.editor.controlNumber === 0)
      this.editor.controlNumber = null;

    if (this.isEditMode)
      return;

    this.documentEditorService.getNextImsControlNumber(this.editor).subscribe(model => {
      this.editor.controlNumber = model.controlNumber;
      this.updateIdentifier();
    });
  }

  applyEditor(editor: DocumentEditorModel) {
    if (this.draftApplied)
      return;
    this.draftApplied = true;
    this.editor = editor;
    this.importedVesselIds = editor.vesselIds;
    this.importedVesselClassIds = editor.vesselClassIds;

    this.populateHelperFromEditor();
    this.getAndApplyEditorOptions(true).subscribe();
  }

  onVesselValueChanged(option: VesselOptionModel) {
    this.updateIdentifier();
    this.updateImsControlNumber();
  }

  onVesselFilterChanged(searchText: string) {
    this.documentEditorService.getVesselOptions(this.editor, searchText).subscribe(options => this.vesselOptions = options);
  }

  onParentFilterChanged(searchText: string) {
    this.documentEditorService.getParentOptions(this.editor, searchText).subscribe(options => this.parentOptions = options);
  }

  parentValueChanged(id: number) {
    if (!id)
      return;
    if (id == this.helper.originalParentId) {
      this.editor.attachmentNumber = this.helper.originalParentAttachmentNumber;
      return;
    }
    this.documentEditorService.getParentDetails(id).subscribe(e => {
      this.editor.attachmentNumber = e.nextAttachmentNumber;
    })
  }

  categorySelectionChange(id: string, isInitial: boolean = false, draft: boolean = false) {
    if (id != this.helper.categoryId && !draft)
      this.helper.selectedVessels = [];

    this.helper.categoryId = id;

    this.setTitle();

    if (!this.helper.isExternal && !isInitial) {
      let category = this.categoryOptions.find(c => c.id == id);
      this.editor.prefix = !!category ? category.acronym : null;

      if (this.helper.categoryIsProj) {
        this.helper.projectId = null;
        this.editor.projectIds = [];
      }

      if (!this.helper.categoryIsIms && !this.editor.historicalDocumentId) {
        this.editor.controlNumber = 0;
        this.editor.controlNumberSuffix = '';
      }
    }

    if (!isInitial) {
      this.documentEditorService.getVesselOptions(this.editor).subscribe(options => this.vesselOptions = options);
    }

    if (!this.isDuplicate && !this.isEditMode && (this.helper.categoryIsCor || this.helper.categoryIsVessel) && !draft) {
      this.editor.prefix = null;
    }

    this.updateIdentifier();
    this.updateImsControlNumber();
  }

  onDocumentTypeFilterChanged(searchText: string) {
    this.filteredTypeOptions = this.typeOptions.filter(e => (e.name + " " + e.acronym).toLowerCase().indexOf(searchText.toLowerCase()) >= 0);
  }

  onDocumentTypeValueChanged() {
    this.updateIdentifier();
    this.updateImsControlNumber();
  }

  onSourceFilterChanged(searchText: string) {
    this.documentEditorService.getSourceOptions(this.editor, searchText).subscribe(options => this.sourceOptions = options);
  }

  onTransmittalFilterChanged(searchText: any) {
    this.documentEditorService.getTransmittalOptions(this.editor, searchText).subscribe(options => this.transmittalOptions = options);
  }

  projectSelectionChanged(id: number) {
    this.helper.projectId = id;
    if (this.helper.categoryIsProj) {
      this.editor.projectIds = [];
      this.editor.projectIds.push(this.helper.projectId);
    }
    this.documentEditorService.getVesselOptions(this.editor, null).subscribe(options => {
      this.vesselOptions = options;
      this.helper.selectedVessels.forEach(element => {
        let index = options.findIndex(e => e.id == element.id && e.name == element.name)
        if (index == -1) {
          let index2 = this.helper.selectedVessels.findIndex(e => e.id == element.id && e.name == element.name);
          this.helper.selectedVessels.splice(index2, 1);
          var temp = this.helper.selectedVessels.splice(index2, 1);
          this.helper.selectedVessels = temp;
        }
      });
    });
    this.updateIdentifier();
  }

  onProjectFilterChanged(event: any) {
    this.documentEditorService.getProjectOptions(this.editor, event).subscribe(options => this.projectOptions = options);
  }

  historyGridCountChanged(count: number) {
    this.historyGridCount = count;
  }

  revisionGridCountChanged(count: number) {
    this.revisionGridCount = count;
  }

  linksGridCountChanged(count: number) {
    this.linksGridCount = count;
  }

  transmittalGridCountChanged(count: number) {
    this.transmittalGridCount = count;
  }

  externalTransmittalGridCountChanged(count: number) {
    this.externalTransmittalGridCount = count;
  }

  submit(isFinishBatch: boolean) {
    this.saveWithWarnings().subscribe(result => {
      if (!result)
        return;

      this.documentService.getFileName(result.entityId).subscribe(res => {
        this.filename = res.filename;
        let batchFilenames = this.batchDocuments
          .filter(d => d.id != this.editor.id)
          .map(d => d.filename);
          if (isFinishBatch || !this.isDuplicate) {
            this.savedDialog.open(isFinishBatch);
            this.savedDialog.updateFileName(res.filename, batchFilenames);
          }
          else {
            this.batchGrid.updateGrid();
            window.scrollToTop();
          }
      });
    })
  }

  batchGridUpdated(data: any[]) {
    this.batchDocuments = data;
  }

  onRevisionAdded($event: IRevisionAddedEvent) {
    this.historyGrid.updateGrid();
    this.editor.revisionId = $event.latestRevision.id;
    this.editor.revisionTitle = $event.latestRevision.title;
    this.editor.revisionDate = $event.latestRevision.revisionDate;
    if (this.editor.revisionNumber != null)
      this.editor.revisionNumber = $event.latestRevision.revisionNumber;
    this.route.params.subscribe(params => {
      let id = params['id'] || null;
      if (id) {
        this.isEditMode = true;
        this.documentService.getFileName(id).subscribe(res => {
          this.filename = res.filename;
        });
      }
    });
  }

  onRevisionArchived($event: IRevisionArchivedEvent) {
    this.historyGrid.updateGrid();
    this.editor.revisionTitle = $event.latestRevision.title;

    this.route.params.subscribe(params => {
      let id = params['id'] || null;
      if (id) {
        this.isEditMode = true;
        this.documentService.getFileName(id).subscribe(res => {
          this.filename = res.filename;
        });
      }
    });
  }

  onRevisionDeleted($event: IRevisionDeletedEvent) {
    this.historyGrid.updateGrid();
    this.editor.revisionTitle = $event.latestRevision.title;
    this.editor.revisionId = $event.latestRevision.id;
    this.editor.revisionDate = $event.latestRevision.revisionDate;
    if (this.editor.revisionNumber != null)
      this.editor.revisionNumber = $event.latestRevision.identifier;
  }

  onDocumentUnlinked() {
    this.historyGrid.updateGrid();
  }

  onReceivedFilenameChanged(fileName: string) {
    this.editor.fileName = fileName;
  }

  onSaved(event: SaveEvent) {
    switch (event.action) {
      case SaveAction.Back:
        this.goBack();
        break;
      case SaveAction.Continue:
        this.switchToEditMode();
        window.scrollToTop();
        setTimeout(() => {
          if (this.historyGrid)
            this.historyGrid.updateGrid();
          if (this.batchGrid)
            this.batchGrid.updateGrid();
          if (this.revisionsGrid)
            this.revisionsGrid.updateGrid();
        })
        break;
      case SaveAction.New:
        this.router.navigateByUrl(this.getUrlUpdate(true));
        let params = Object.assign({}, this.route.snapshot.params);
        delete params['id'];
        this.reset(params);
        break;
      case SaveAction.Duplicate:
        this.duplicate();
        break;
    }
  }
  seajacksDocOnClick() {
    if (this.systemPermissions.documents.canCreateSeajacks == true) {
      const url = '/documents/edit';

      if (`/${this.route.snapshot.url.join('/')}` == url)
        return;

      this.hasLoaded = false;
      this.loading = true;
      setTimeout(() => {
        this.router.navigateByUrl(url);
      }, 100);
    }
  }
  externalDocOnClick() {
    if (this.systemPermissions.documents.canCreateExternalNonSeajacks == true) {
      const url = '/documents/edit/externalnonseajacks';

      if (`/${this.route.snapshot.url.join('/')}` == url)
        return;

      this.hasLoaded = false;
      this.loading = true;
      setTimeout(() => {
        this.router.navigateByUrl(url);
      }, 100);
    }
  }

  externalDocRefOnClick() {
    if (this.systemPermissions.documents.canCreateExternalSeajacks == true) {
      const url = '/documents/edit/externalseajacks';

      if (`/${this.route.snapshot.url.join('/')}` == url)
        return;

      this.hasLoaded = false;
      this.loading = true;
      setTimeout(() => {
        this.router.navigateByUrl(url);
      }, 100);
    }
  }

  goBack() {
    let url = this.isViaTransmittal
      ? `/transmittals/${this.transmittalId}/add-documents`
      : this.isViaHistorical
        ? '/historical/documents'
        : '/documents';

    if (this.isViaTransmittal && this.editor.id)
      url += `?addedDocument=${this.editor.id}`;

    this.router.navigateByUrl(url);
  }

  duplicate() {
    this.save().subscribe(res => {
      this.documentEditorService.duplicate(this.editor);
      this.documentEditorService.saveDuplicateDocumentId(res.entityId);
      if (this.isViaTransmittal) {
        window.location.href = `/documents/edit?duplicate=true&transmittal=${this.transmittalId}`;
      }
      else {
        window.location.href = '/documents/edit?duplicate=true';
      }
    })
  }

  // onDuplicateComplete(batchId: number) {
  //   this.addIdToBatchEdited(this.editor.id);
  //   this.batchId = batchId;
  //   this.switchToEditMode();
  //   window.scrollToTop();
  //   setTimeout(() => {
  //     this.dirtyForm = false;
  //   });
  // }

  populateEditorFromHelper = () => {
    this.populateVesselIds();
    this.populateProjectId();
  }

  populateHelperFromEditor = () => {
    this.helper.categoryId = this.editor.categoryId;
    this.helper.projectId = this.editor.projectIds && this.editor.projectIds.length ? this.editor.projectIds[0] : null;
    this.helper.selectedProjects = this.projectOptions.filter(o => this.editor.projectIds.includes(o.id));
    this.populateHelperVessels();
  }

  beforeDraftSave = () => {
    this.populateEditorFromHelper();
  }

  afterDraftSave = () => {
    window.scrollTo(0, 0);
    this.dirtyRecordDirective.reset();
    this.draftSaved = true;
    setTimeout(() => {
      this.goBack();
    }, 2000);
  }

  saveAndFinishBatch() {
    this.submit(true);
  }

  checkEditSubmit() {
    if (this.isEditMode) {
      this.submit(false);
    }
  }

  private saveWithWarnings(): Observable<ValidationResultModel> {
    if (!this.editor.id)
      return this.save();

    return this.documentService.getTransmittals(this.editor.id).pipe(mergeMap((result) => {
      if (!result.hasTransmittals)
        return this.save();

      return this.issuedWarningDialog
        .open(result,true)
        .pipe(take(1)).pipe( //Stops susbsribe being called multiple times if saved multiple times
        mergeMap((continued) => {
          return continued ? this.save() : observableOf(null);
        }));
    }));
  }

  private save(): Observable<ValidationResultModel> {
    this.validationResult = null;
    this.updated = false;
    this.loading = true;

    if (!this.helper.categoryIsProj)
      this.editor.projectIds = this.helper.selectedProjects.map(o => o.id);

    this.editor.approved = this.helper.approved;

    if (this.editor.categoryId != "externalNonSeajacks")
      this.editor.fileName = null;
    if (this.editor.revisionNumber == "" || this.editor.revisionNumber == " ")
      this.editor.revisionNumber = null;

    this.populateEditorFromHelper();

    return Observable.create((observer: Subscriber<ValidationResultModel>) => {
      this.documentEditorService.update(this.editor)
        .subscribe(result => {
          if (!!this.draftRecord)
            this.draftRecord.remove();
          if(this.isDuplicate && result.entityId != null)
            this.isDuplicateEdit = true;
          this.getEditorSubscription(result.entityId)
            .pipe(finalize(() => {
              this.loading = false;
              this.dirtyRecordDirective.reset();
            }))
            .subscribe((editor) => {
              this.updated = true;
              this.editor.approved = editor.approved;
              this.editor.id = editor.id;
              this.editor.revisionId = editor.revisionId;
              this.editor.controlNumber = editor.controlNumber;

              if (this.isDuplicate){
                this.duplicateDocumentIds.push(this.editor.id);
                this.batchGrid.updateBatchId(this.editor.id);
                this.documentEditorService.saveDuplicateDocumentId(this.editor.id);
              }
              if (this.isBatchEdit)
                this.addIdToBatchEdited(this.editor.id);

              if (this.isViaTransmittal) {
                this.documentEditorService.addToExternalTransmittal(this.transmittalId, editor.revisionId).subscribe(res => {
                  observer.next(result);
                });
              }
              else {
                observer.next(result);
              }
            });
        }, err => {
          this.loading = false;
          this.updated = false;
          // this.dirtyRecordDirective.reset();
          if (err instanceof HttpErrorResponse && err.status == 412) {
            this.validationResult = err.error;
          }

          observer.error(err);
        });
    });
  }

  private addIdToBatchEdited(id: number) {
    if (!this.editedBatchIds.some(id => id == this.editor.id))
      this.editedBatchIds.push(this.editor.id);
  }

  private populateVesselIds() {
    this.editor.vesselIds = this.helper.selectedVessels.filter(v => v.isVessel).map(v => v.id);
    this.editor.vesselClassIds = this.helper.selectedVessels.filter(v => v.isVesselClass).map(v => v.id);
  }

  private populateHelperVessels() {
    let vessels = this.importedVesselIds
      .map(id => this.vesselOptions.find(e => e.id == id && e.isVessel))
      .filter(e => !!e);
    let classes = this.importedVesselClassIds
      .map(id => this.vesselOptions.find(e => e.id == id && e.isVesselClass))
      .filter(e => !!e);
    this.helper.selectedVessels = vessels.concat(classes);
  }

  private populateProjectId() {
    if (!this.helper.isExternal)
      return;
    if (this.helper.categoryIsProj) {
      this.editor.projectIds = [];
      this.editor.projectIds.push(this.helper.projectId);
    } else {
      this.editor.projectIds = this.helper.selectedProjects.map(o => o.id);
    }
  }

  private setTitle() {
    this.title = this.helper.getTitle();;
  }

  private switchToEditMode() {
    this.isEditMode = true;
    this.location.replaceState(this.getUrlUpdate(false));
  }

  private getUrlUpdate(newDoc: boolean): string {
    let id = !!this.editor && !!this.editor.id ? this.editor.id : '';
    let url = newDoc || !id ? `/documents/edit` : `/documents/${id}/edit`;
    if (this.helper.isExternal)
      url += `/${this.editor.categoryId.toLocaleLowerCase()}`;
    if (this.isViaTransmittal)
      url += `?transmittal=${this.transmittalId}`;
    if (this.isDuplicate && !this.isViaTransmittal)
      url += `?duplicate=true`;
    if (this.isDuplicate && this.isViaTransmittal)
      url += `?duplicate=true?transmittal=${this.transmittalId}`;
    return url;
  }
}
