import { ApiEntityModel } from '../../shared/models/apiEntity.model';
import { DepartmentModel } from '../../setup/document-types/models/document-type.models';
import { ProjectModel, SourceModel, ContactEditorModel, ContactModel } from '../../setup/sources/models/source.models';
import { VesselModel, VesselClassModel, VesselOptionModel } from '../../setup/vessels/models/vessel.models';
import { RevisionModel } from '../../documents/models/revision.models';
import { TemplateModel } from '../../setup/templates/models/template.models';
import { DocumentModel } from '../../documents/models/document.models';
import { RevisionReasonModel } from "../../setup/revision-reasons/models/revision-reason.models";

export class TransmittalFiltersModel {
    date?: string | null;
    acknowledgementStateId: string | null;
    displayDate?: Date;
}

export class ExternalTransmittalFiltersModel {
    date?: string | null;
    displayDate?: Date;
}

export class TransmittalInstancePermissionsModel {
  canView: boolean;
  canEdit: boolean;
  canIssue: boolean;
  canAcknowledge: boolean;
  canDownload: boolean;
  canVoid: boolean;
  canDelete: boolean;
  canPreview: boolean;
}

export class TransmittalGridRowModel {
    id: number;
    identifier: string;
    date: string;
    issued: boolean;
    documentCount: number;
    acknowledgedCount: number;
    unacknowledgedCount: number;
    contactCount: number;
    projectNumber: string;
    sourceNames: string;
    voided: boolean;
    selected: boolean = false;
    formatOfIssue: string;
    template: string;
    fromEmail: string;
    filename: string;
    filePath: string;
    reasonForIssue: string;
    permissions: TransmittalInstancePermissionsModel;
    creatorName: string;
    createDate: string;
}

export class TransmittalExternalInstancePermissionsModel {
    canView: boolean;
    canEdit: boolean;
    canVoid: boolean;
    canDelete: boolean;
}

export class TransmittalExternalGridRowModel {
    id: number;
    identifier: string;
    filename: string;
    filePath: string;
    issueReason: string;
    projNo: string;
    source: string;
    sourceIsSubContractor: boolean;
    date: string;
    noDocs: string;
    categoryId: string;
    isExternal: boolean;
    voided: boolean;
    permissions: TransmittalExternalInstancePermissionsModel;
    creatorName: string;
    createDate:  string;

    selected: boolean = false;
}

export class TransmittalEditorModel {
    id: number | null;
    typeId: string | null;
    projectId: number | null
    templateId: number | null;
    formatId: string | null;
    date: string;
    senderEmailAddress: string;
    returnPreviousCopy: boolean;
    destroyPreviousCopy: boolean;
    fileName: string;
    filePath: string;
    issued: boolean;
    notes: string;
    sourceIds: number[] = [];
    documentLinks: TransmittalDocumentLinkEditorModel[] = [];
    contactLinks: TransmittalContactLinkEditorModel[] = [];
    permissions: TransmittalInstancePermissionsModel;

    displayDate: Date;
}

export class TransmittalContactLinkEditorModel {
    id: number | null;
    sourceId: number;
    sourceName: string;
    contactId: number;
    contactForename: string;
    contactSurname: string;
    receiptStateId: string;
    receiptDate: string | null;

    displayReceiptDate: Date | null;
}

export class TransmittalContactModel extends ContactModel {
    receiptStateId: string;
}

export class TransmittalSourceWithContactsModel extends SourceModel {
    contacts: TransmittalContactModel[] = [];

    collapse: boolean;

    get contactsAvailableText(): string { return `${this.contacts.length} ${this.contacts.length == 1 ? 'contact' : 'contacts'} available` }
}

export class TransmittalIdentifierModel {
    identifier: string;
}

export class TransmittalReceiptStateModel extends ApiEntityModel<string> {
    name: string;
    isPending: boolean;
}

export class TransmittalAcknowledgeEditorModel {
    transmittalId: number;
    contactLinks: TransmittalContactLinkEditorModel[] = [];
}

export class TransmittalEditorHelper {
    typeId: string;
    typeAcronym: string;
    projectId: number;
}

export class ExternalTransmittalInstancePermissionsModel {
  canView: boolean;
  canEdit: boolean;
  canVoid: boolean;
  canDelete: boolean;
}

export class ExternalTransmittalEditorModel {
    id: number | null;
    identifier: string;
    date: string;
    fileName: string;
    filePath: string;
    issueReason: string;
    notes: string;
    projectId: number;
    sourceId: number;
    permissions: ExternalTransmittalInstancePermissionsModel;

    displayDate: Date;
}

export class ExternalTransmittalDocumentsEditorModel {
    transmittalId: number;
    identifier: string;
    sourceName: string;
    documentLinks: ExternalTransmittalDocumentLinkEditorModel[] = [];
    permissions: ExternalTransmittalInstancePermissionsModel;
}

export class TransmittalModel extends ApiEntityModel<number> {
    title: string;
    identifier: string;
}

export interface TransmittalCategoryModel extends ApiEntityModel<string> {
    acronym: string;
    name: string;
}

export interface TransmittalTypeModel extends ApiEntityModel<number> {
    name: string;
    description: string;
    department: DepartmentModel;
    archived: boolean;
}

export interface TransmittalFormatModel extends ApiEntityModel<string> {
    acronym: string;
    name: string;
}

export class TransmittalEventGridRowModel {
    revisionTitle: string;
    time: Date;
    typeName: string;
    username: string;
    description: string;
}

export interface ITransmittalFilenameModel {
    readonly filename: string;
}

export class TransmittalParentDetailsModel {
    nextAttachmentNumber: number;
}

export class TransmittalDocumentLinkEditorModel {
    id: number | null;
    ordinal: number;
    revisionId: number | null;
    reasonId: number | null;
    revisionDocumentId: number;
    revisionDocumentIdentifier: string;

    revisionOptions: RevisionModel[] = [];
    revisionNumber: string;
}

export class ExternalTransmittalDocumentLinkEditorModel {
    id: number | null;
    revisionId: number | null;
    reasonId: string | null;
    documentId: number;
    revisionDocumentIdentifier: string;

    revisionOptions: RevisionModel[] = [];
}

export class TransmittalEditorOptions {
    projectOptions: ProjectModel[] = [];
    selectedSourceOptions: TransmittalSourceWithContactsModel[] = [];
    sourceOptions: TransmittalSourceWithContactsModel[] = [];
    categoryOptions: TransmittalCategoryModel[] = [];
    documentOptions: DocumentModel[] = [];
    templateOptions: TemplateModel[] = [];
    formatOptions: TransmittalFormatModel[] = [];
    reasonOptions: RevisionReasonModel[] = [];
}

export class TransmittalExternalOptions {
    projectOptions: ProjectModel[] = [];
    sourceOptions: SourceModel[] = [];
}

export class VoidTransmittalModel {
    id: number;
    reason: string;
}

export class DeletedEvent {
    id: number;
}
