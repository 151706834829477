import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DocumentTypeGridComponent } from '../components/document-type-grid/document-type-grid.component';
import { DocumentTypeEditComponent } from '../components/document-type-edit/document-type-edit.component';
import { AuthGuard } from "../../../shared/utils/auth.guard";

const routes: Routes = [
  {
    path: 'setup',
    canActivate: [AuthGuard],
    children: [
      { path: 'document/type', component: DocumentTypeGridComponent },
      { path: 'document/type/edit', component: DocumentTypeEditComponent },
      { path: 'document/type/:id/edit', component: DocumentTypeEditComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DocumentTypeRouterModule { }
