import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TransmittalsGridComponent } from '../components/transmittals-grid/transmittals-grid.component';
import { TransmittalEditComponent } from '../components/transmittal-edit/transmittal-edit.component';
import { DocumentEditComponent } from '../../documents/components/document-edit/document-edit.component';
import { TransmittalAcknowledgeComponent } from '../components/transmittal-acknowledge/transmittal-acknowledge.component';
import { TransmittalsExternalGridComponent } from '../components/transmittal-external-grid/transmittals-external-grid.component';
import { TransmittalExternalComponent } from '../components/transmittal-external/transmittal-external.component';
import { TransmittalExternalAddDocumentComponent } from '../components/transmittal-external-add-document/transmittal-external-add-document.component';
import { AuthGuard } from '../../shared/utils/auth.guard';
import { DirtyRecordGuard } from '../../shared/modules/dirty-record/guards/dirty-record-guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    children: [
      { path: 'transmittals', component: TransmittalsGridComponent },

      { path: 'transmittals/edit', component: TransmittalEditComponent, canDeactivate: [DirtyRecordGuard] },
      { path: 'transmittals/:id/edit', component: TransmittalEditComponent, canDeactivate: [DirtyRecordGuard] },
      { path: 'transmittals/edit/:externalType', component: TransmittalEditComponent, canDeactivate: [DirtyRecordGuard] },
      { path: 'transmittals/:id/edit/:externalType', component: TransmittalEditComponent, canDeactivate: [DirtyRecordGuard] },

      { path: 'transmittals/external', component: TransmittalExternalComponent, canDeactivate: [DirtyRecordGuard] },
      { path: 'transmittals/:id/external', component: TransmittalExternalComponent, canDeactivate: [DirtyRecordGuard] },
      { path: 'transmittals/external/:id/edit', component: TransmittalExternalComponent, canDeactivate: [DirtyRecordGuard] },

      { path: 'transmittals/:id/add-documents', component: TransmittalExternalAddDocumentComponent },

      { path: 'transmittals/acknowledge', component: TransmittalAcknowledgeComponent },
      { path: 'transmittals/:id/acknowledge', component: TransmittalAcknowledgeComponent },
      { path: 'transmittals/:TransTitle/acknowledge', component: TransmittalAcknowledgeComponent },
      { path: 'transmittals/acknowledge/:externalType', component: TransmittalAcknowledgeComponent },
      { path: 'transmittals/:TransTitle/acknowledge/:externalType', component: TransmittalAcknowledgeComponent },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TransmittalRouterModule { }
