import { Injectable } from '@angular/core';
import { AppConfig } from '../../app.config';
import { HttpClient } from '@angular/common/http';
import { State } from '@progress/kendo-data-query';
import { GridDataService } from '../../shared/services/grid-data.service';
import { Observable } from 'rxjs';
import { ExternalTransmittalDocumentsEditorModel, TransmittalCategoryModel, TransmittalTypeModel, TransmittalModel, TransmittalEditorOptions, TransmittalParentDetailsModel, TransmittalEditorModel, TransmittalDocumentLinkEditorModel } from '../models/transmittal.models';
import { ProjectModel, SourceModel } from '../../setup/sources/models/source.models';
import { VesselOptionModel } from '../../setup/vessels/models/vessel.models';
import { serialize } from '../../shared/utils/object.utils';
import { ValidationResultModel } from '../../shared/models/validation.models';
import { DocumentModel } from '../../documents/models/document.models';
import { RevisionModel } from '../../documents/models/revision.models';

@Injectable()
export class TransmittalExternalAddDocumentService {
  readonly baseUrl: string = `${AppConfig.settings.apiUrl}/transmittals/external`;

  constructor(
    private http: HttpClient
  ) { }

  get(id: number): Observable<ExternalTransmittalDocumentsEditorModel> {
    let url = !!id ? `${this.baseUrl}/${id}/documents/edit` : `${this.baseUrl}/documents/edit`;
    return this.http.get<ExternalTransmittalDocumentsEditorModel>(url);
  }

  update(editor: ExternalTransmittalDocumentsEditorModel) {
    const url = !!editor.transmittalId ? `${this.baseUrl}/${editor.transmittalId}/documents/edit` : `${this.baseUrl}/documents/edit`;
    return this.http.post<ValidationResultModel>(url, editor);
  }

  getDocumentOption(documentId: number): Observable<DocumentModel> {
    let url = `${this.baseUrl}/documents/edit/documentoption/${documentId}`;
    return this.http.get<DocumentModel>(url);
  }

  getDocumentOptions(editor: ExternalTransmittalDocumentsEditorModel, searchText: string = ''): Observable<DocumentModel[]> {
    let url = `${this.baseUrl}/documents/edit/documentoptions`;
    url += `?searchtext=${searchText}`;
    return this.http.post<DocumentModel[]>(url, editor);
  }

  getRevisionOptions(documentId: number, transmittalId: number): Observable<RevisionModel[]> {
    let url = `${this.baseUrl}/${documentId}/documents/edit/revisionoptions/${transmittalId}`;
    return this.http.get<RevisionModel[]>(url);
  }
}
