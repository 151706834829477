
import {finalize} from 'rxjs/operators';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ILoadable } from '../../../shared/components/loading/loading.models';
import { IKendoGridComponent, KendoColumnResizeArgs } from '../../../shared/utils/kendo.utils';
import { State } from '@progress/kendo-data-query';
import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { DocumentService } from '../../services/document.service';
import { DocumentEventGridRowModel } from '../../models/document.models';
import { GridDataService } from '../../../shared/services/grid-data.service';
import { GridColumnState } from '../../../shared/models/grid.models';

@Component({
  selector: 'app-document-history-grid',
  templateUrl: './document-history-grid.component.html',
  styleUrls: ['./document-history-grid.component.scss']
})
export class DocumentHistoryGridComponent implements OnInit, ILoadable, IKendoGridComponent {
  @Input('documentId') documentId: number;
  @Output('countChange') countChange: EventEmitter<number> = new EventEmitter();

  hasLoaded: boolean;
  loading: boolean;
  state: State = {
    skip: 0,
    take: 20,
    sort: [      
      {dir: "desc", field: "time"}
    ]
  };
  gridData: GridDataResult;
  pageSizes: number[] = [5, 10, 20, 50, 100];
  
  columnState: GridColumnState = new GridColumnState([
    { name: 'Date', field: 'time', defaults: { width: 140 } },    
    { name: 'User', field: 'userName', defaults: { width: 140 } },
    { name: 'Change', field: 'typeName', defaults: { width: 140 } },
    { name: 'Detail', field: 'description', defaults: { width: 140 } },
    { name: 'Revision', field: 'revisionIdentifier', defaults: { width: 140 } },
  ]);

  constructor(
    private documentService: DocumentService,
    private gridDataService: GridDataService
  ) { }

  ngOnInit() {
    this.gridDataService.initColumnState(this);
    this.gridDataService.applyCachedState("documentHistory", this);
    
    this.updateGrid();
  }

  dataStateChange(state: DataStateChangeEvent): void {
    this.loading = true;
    this.state = state;
    this.updateGrid();
  }

  resetColumnState() {
    this.gridDataService.resetColumnState("documentHistory", this);
  }

  changePageSizes(pageSize: number) {
    this.state.take = pageSize;
    this.updateGrid();
  }
  
  resized(changes: KendoColumnResizeArgs[]) {
    let change = changes[0];
    let col = this.columnState.selected.find(c => c.field == change.column.field);
    col.width = change.newWidth;
    this.gridDataService.saveColumnState("documentHistory", this, true);
  }

  updateGrid() {
    this.documentId = this.documentId;
    this.documentService.getEventGridData(this.state, this.documentId).pipe(
      finalize(() => { 
        this.hasLoaded = true
      }))
      .subscribe(data => {
        this.gridData = data;
        this.loading = false;
        this.countChange.next(data.total);
      });
  }
}