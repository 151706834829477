import {finalize} from 'rxjs/operators';
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {UserService} from '../../services/user.service';
import {RoleModel, UserEditorModel, UserInstancePermissionsModel} from '../../models/user.models';
import {ValidationResultModel} from "../../../../shared/models/validation.models";
import {HttpErrorResponse} from "@angular/common/http";

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent implements OnInit {
  hasLoaded: boolean = false;
  loading: boolean = false;
  title: string;
  user: UserEditorModel = new UserEditorModel();
  permissions: UserInstancePermissionsModel;
  roles: RoleModel[] = [];
  rolesSelected: number[] = [];
  updated: boolean = false;
  isCreate: boolean;

  validationResult: ValidationResultModel;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService
  ) {
  }

  ngOnInit() {
    this.loading = true;

    this.route.params.subscribe(params => {
      let id = params['id'] as number;

      if (!!id) {
        this.userService.getUser(id)
          .subscribe(user => {
            this.user = user;
            this.rolesSelected = user.roles;
            this.permissions = user.permissions;

            this.title = id ? (this.permissions.canEdit ? 'Edit' : 'View') + ' User' : 'User Setup';

            this.hasLoaded = true;

            this.userService.getRoleOptions().pipe(
              finalize(() => this.loading = false))
              .subscribe(roles => {
                this.roles = roles;
                this.rolesSelected.forEach(selectedRoleId => {
                  let index = this.roles.findIndex(e => e.id == selectedRoleId);
                  if (index != -1)
                    this.roles[index].selected = true;
                })
              }, err => {
              })
          }, err => {
          });
      } else {
        this.isCreate = true;
        this.title = 'User Setup';
        this.hasLoaded = true;
        this.loading = false;
        this.user = new UserEditorModel();
        this.user.username = '';
        this.user.active = true;
        this.permissions = new UserInstancePermissionsModel();
        this.permissions.canEdit = true;
        this.permissions.canView = true;

        this.userService.getRoleOptions().pipe(
          finalize(() => this.loading = false))
          .subscribe(roles => {
            this.roles = roles;
            this.rolesSelected.forEach(selectedRoleId => {
              let index = this.roles.findIndex(e => e.id == selectedRoleId);
              if (index != -1)
                this.roles[index].selected = true;
            })
          }, err => {
          })
      }
    })
  }
  submit($event: any) {
    this.user.roles = this.roles.filter(role => role.selected).map(role => role.id);
    this.validationResult = null;

    if (this.isCreate) {
      this.userService.createUser(this.user).subscribe(res => {
        this.updated = true;
        setTimeout(() => {
          this.router.navigateByUrl('/setup/users');
        }, 1500);
      }, err => {
        if (err instanceof HttpErrorResponse && err.status == 412) {
          this.validationResult = err.error;
        }

      });

    } else {
      this.userService.updateUser(this.user).subscribe(res => {
        this.updated = true;
        setTimeout(() => {
          this.router.navigateByUrl('/setup/users');
        }, 1500);
      }, err => {
      });
    }
  }
}
