export interface DocumentTypeFiltersModel {
  active: boolean | null;
}

export interface DocumentTypeInstancePermissionsModel {
  canView: boolean;
  canEdit: boolean;
  canEditAcronym: boolean;
  canArchive: boolean;
  canDelete: boolean;
}

export interface DocumentTypeGridRowModel {
  id: number;
  acronym: string;
  name: string;
  departmentName: string;
  active: boolean;
  permissions: DocumentTypeInstancePermissionsModel;
}

export class DocumentTypeEditorModel {
  id: number;
  acronym: string;
  name: string;
  description: string;
  departmentId: number;
  active: boolean = true;
  permissions: DocumentTypeInstancePermissionsModel;
}

export class DepartmentModel {
  id: number;
  name: string;
 }
