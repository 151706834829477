import { Component, OnInit, ViewChild, NgModule, Output, EventEmitter, Input } from '@angular/core';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { Router } from '@angular/router';
import { TransmittalService } from '../../services/transmittal.service';
import { TransmittalExternalAddDocumentComponent } from '../transmittal-external-add-document/transmittal-external-add-document.component';

@Component({
  selector: 'app-transmittal-external-save',
  templateUrl: './transmittal-external-save.component.html',
  styleUrls: ['./transmittal-external-save.component.scss']
})
export class TransmittalExternalSaveComponent implements OnInit {
  @ViewChild('dialogContent', {static: false}) content;
  @ViewChild('dialogActions', {static: false}) actions;
  @ViewChild('externalAddDocument', {static: false}) externalAddDocument: TransmittalExternalAddDocumentComponent;
  @Input('transmittalId') transmittalId: number;
  @Output() notify: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private dialogService: DialogService,
    private transmittalService: TransmittalService,
    private router: Router,
  ) { }

  private dialogRef: DialogRef;

  hasLoaded: boolean;
  loading: boolean;
  succeeded: boolean;
  title: string;
  subtitle: string;
  documentId: number;
  documentsAdded: boolean = false;
  documentsAddedCount: number;

  ngOnInit() {}

  open(hasDocumentsToAdd?: boolean, documentCount?: number) {
    if (hasDocumentsToAdd != null && hasDocumentsToAdd == true)
      this.documentsAdded = true;
    if (documentCount != null)
      this.documentsAddedCount = documentCount;

    this.dialogRef = this.dialogService.open({
      title: 'Transmittal Saved!',
      content: this.content,
      actions: this.actions
    });
    this.succeeded = false;
  }

  close() {
    setTimeout(() => {
      this.router.navigateByUrl('/transmittals');
    }, 100);
  }

  submit(){
    setTimeout(() => {
      this.router.navigateByUrl(`/transmittals/${this.transmittalId}/add-documents`);
    }, 100);  }
}
