
import {finalize} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { RoleService } from '../../services/role.service';
import { GridDataService } from '../../../../shared/services/grid-data.service';
import { PermissionModel } from '../../../users/models/user.models';
import { RoleGridFiltersModel, RoleGridRowModel } from '../../models/role.models';
import { GridColumn, GridColumnState } from '../../../../shared/models/grid.models';
import { State } from '@progress/kendo-data-query';
import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { IKendoGridComponent, KendoColumnResizeArgs } from '../../../../shared/utils/kendo.utils';
import { ILoadable } from '../../../../shared/components/loading/loading.models';
import { SystemPermissions } from '../../../../shared/models/permissions.models';
import { PermissionService } from '../../../../shared/services/permission.service';
import { DialogService, DialogCloseResult } from '@progress/kendo-angular-dialog';

@Component({
  selector: 'app-role-grid',
  templateUrl: './role-grid.component.html',
  styleUrls: ['./role-grid.component.scss']
})
export class RoleGridComponent implements OnInit, ILoadable, IKendoGridComponent {
  hasLoaded: boolean = false;
  loading: boolean = false;
  gridData: GridDataResult;
  state: State = {
    skip: 0,
    take: 10
  };
  pageSizes: number[] = [5, 10, 20, 50, 100];  
  columnState = new GridColumnState([
    { name: "Role Name", field: "name", defaults: { sort: { dir: 'asc' }, width: 500 } },
    { name: 'No of Users', field: 'userCount', defaults: { width: 120 } },
    // { name: "Creation Time", field: 'creationTime', defaults: { width: 275 } },
    { name: "Active", field: 'active', defaults: { width: 207 } },
  ])

  filters: RoleGridFiltersModel = new RoleGridFiltersModel();
  permissionOptions: PermissionModel[] = [];
  systemPermissions: SystemPermissions;
  
  constructor(
    private roleService: RoleService,
    private gridDataService: GridDataService,
    private permissionService: PermissionService,
    private dialogService: DialogService    
  ) { }

  ngOnInit() {
    this.loading = true;
    this.systemPermissions = this.permissionService.getPermissions();
    
    this.gridDataService.initColumnState(this);
    this.gridDataService.applyCachedState(this.roleService.gridName, this);

    this.updateGrid();
  }

  dataStateChange(state: DataStateChangeEvent): void {
    this.loading = true;
    this.state = state;
    this.updateGrid();
  }

  columnStateChanged($event: GridColumn[]) {
    this.gridDataService.saveColumnState(this.roleService.gridName, this);
  }

  permissionOptionChanged(permissions: PermissionModel[]) {
    this.loading = true;
    this.state.skip = 0;
    this.updateGrid();
  }

  resized(changes: KendoColumnResizeArgs[]) {
    let change = changes[0];
    let col = this.columnState.selected.find(c => c.field == change.column.field);
    col.width = change.newWidth;
    this.gridDataService.saveColumnState(this.roleService.gridName, this, true);
  }

  resetGrid() {
    this.loading = true;
    this.gridDataService.resetGrid(this.roleService.gridName, this.roleService.baseUrl, this)
      .subscribe(data => {
        this.gridData = data;
        this.loading = false;
      });
  }

  changePageSizes(pageSize: number) {
    this.state.take = pageSize;
    this.updateGrid();
  }

  resetColumnState() {
    this.gridDataService.resetColumnState(this.roleService.gridName, this);
  }

  updateGrid() {
    this.roleService.getGridData(this.state, this.filters).pipe(
      finalize(() => {
        this.hasLoaded = true;
      })).subscribe(gridData => {
        this.gridData = gridData;
        this.loading = false;
      });
  }

  archive(item: RoleGridRowModel) {
    this.showActionDialog('Archive', () => {
      this.roleService.archive(item.id).subscribe(
        () => {
          this.updateGrid();
          this.showAlert('Done', 'Archived successfully')
        },
        () => this.showAlert('Warning', 'It looks like something went wrong')
      )
    });
  }

  delete(item: RoleGridRowModel) {
    this.showActionDialog('Delete', () => {
      this.roleService.delete(item.id).subscribe(
        () => {
          this.updateGrid();
          this.showAlert('Done', 'Deleted successfully')
        },
        () => this.showAlert('Warning', 'It looks like something went wrong')
      )
    });
  }

  private showActionDialog(action: string, handler: () => void) {
    const dialog = this.dialogService.open({
      title: action,
      content: `Are you sure you want to ${action.toLowerCase()} this role?`,
      actions: [
        { text: 'Cancel' },
        { text: 'Continue', primary: true, handler: handler },
      ]
    });

    setTimeout(() => {
      $('kendo-dialog-actions button').last().focus();
    })

    dialog.result.subscribe((result: any) => {
      if (result instanceof DialogCloseResult) {
        console.log("close");
      } else {
        if (result.handler)
          result.handler();
      }
    });
  }

  private showAlert(title: string, content: string) {
    const dialog = this.dialogService.open({
      title: title,
      content: content,
      actions: [
        { text: 'OK', primary: true }
      ]
    });

    setTimeout(() => {
      $('kendo-dialog-actions button').last().focus();
    })
  }
}
