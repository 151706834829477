import { NgModule } from '@angular/core';
import { SourcesModule } from '../setup/sources/modules/sources.module';
import { TemplatesModule } from '../setup/templates/modules/templates.module';
import { UsersModule } from './users/modules/users.modules';
import { VesselsModule } from '../setup/vessels/modules/vessels.module';
import { PdfModule } from '../setup/pdf/modules/pdf.module';
import { SharedModule } from '../shared/modules/shared.modules';
import { AuditModule } from './audit/modules/audit.module';
import { DocumentTypeModule } from './document-types/modules/document-type.module';
import { ProjectsModule } from './projects/modules/projects.module';
import { RevisionReasonsModule } from './revision-reasons/modules/revision-reasons.module';
import { RolesModule } from './roles/modules/role.module';

@NgModule({
  exports: [
    SharedModule,
    UsersModule,
    RolesModule,
    AuditModule,
    DocumentTypeModule,
    SourcesModule,
    TemplatesModule,
    ProjectsModule,
    VesselsModule,
    RevisionReasonsModule,
    PdfModule
  ]
})
export class SetupModule { }