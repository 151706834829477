import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../shared/modules/shared.modules';
import { RouterModule } from '@angular/router';
import { ProjectsRouterModule } from './projects.router.module';
import { ProjectService } from '../services/project.service';
import { ProjectGridComponent } from '../components/project-grid/project-grid.component';
import { ProjectEditComponent } from '../components/project-edit/project-edit.component';
import { FormsModule } from '@angular/forms';
import { GridModule, ExcelModule } from '@progress/kendo-angular-grid';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { DialogModule } from '@progress/kendo-angular-dialog';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    GridModule,
    ExcelModule,
    InputsModule,
    ButtonsModule,
    DialogModule,
    DropDownsModule,
    SharedModule
  ],
  declarations: [
    ProjectGridComponent,
    ProjectEditComponent
  ],
  providers: [
    ProjectService
  ],
  exports: [
    ProjectsRouterModule
  ]
})
export class ProjectsModule { }