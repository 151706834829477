import { Directive } from '@angular/core';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';
import { Key } from 'ts-keycode-enum';

@Directive({
  selector: '[ptDisableClosedDropdownKeyboard]'
})
export class DisableClosedDropdownKeyboardDirective {

  constructor(private input: DropDownListComponent) {
    let __this = this;
    let keydownWrap = (fn: Function) => {
      return function (event: KeyboardEvent) {
        if (__this.input.isOpen || event.altKey) {
          if (event.keyCode == Key.Escape) {
            __this.input.toggle(false);
            __this.input.reset();
            __this.input.focus();
            return;
          }
          return fn.apply(this, arguments);
        }
      }
    };
    this.input.keydown = keydownWrap(this.input.keydown);

    let blurWrap = (fn: Function) => {
      let __this = this;
      return function () {
        __this.input.reset();
        return fn.apply(this, arguments);
      };
    }
    this.input.blurComponent = blurWrap(this.input.blurComponent);
  }
}