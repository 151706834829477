import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ReorderListActions, ReorderListPermissions} from '../../../shared/models/reorder-list.models';
import {TransmittalDocumentLinkEditorModel} from '../../models/transmittal.models';

@Component({
  selector: 'app-transmittal-document-reorder-list',
  templateUrl: './transmittal-document-reorder-list.component.html',
  styleUrls: ['./transmittal-document-reorder-list.component.scss']
})
export class TransmittalDocumentReorderListComponent implements OnInit {
  @Input() data: any;
  @Input() gridConfig: any;
  @Input() gridHeightPx: number;
  @Input() gridWidthPx: number;
  @Input() useColumnWidthPx: boolean;
  @Input() permissions: ReorderListPermissions;
  @Input() actionOptions: ReorderListActions;
  @Input() loading: boolean;
  @Input() initLoadComplete: boolean;
  @Input() noDataText: string;
  @Input() hideInactiveRows: boolean;
  @Input() reasonOptions: any;
  @Input() defaultReason: any;
  @Output() dropEvent = new EventEmitter<any>();
  @Output() deleteEvent = new EventEmitter<any>();

  displayActive: boolean = true;

  constructor() {
  }

  ngOnInit() {
  }

  calculateColumnWidth(columns: any[], columnWidth: number): number {
    if (this.useColumnWidthPx) {
      if (columns && columns.length > 0) {
        return columnWidth;
      }
    } else {
      if (columns && columns.length > 0) {
        const sumColumnWidths = this.sumColumnWidths(columns);
        const widthPercent = (columnWidth / sumColumnWidths) * 100;

        return widthPercent;
      }
    }
  }

  sumColumnWidths(columns: any[]) {
    let sum = 0;
    columns.forEach(column => {
      if (column._width) {
        sum += parseFloat(column._width);
      }
    });

    return sum;
  }

  drop(event: any) {
    if (event.previousIndex !== event.currentIndex) {
      this.dropEvent.emit(event);
    }
  }

  deleteItem(item: any) {
    this.deleteEvent.emit(item);
  }

  onRevisionValueChange(revisionId: number, link: TransmittalDocumentLinkEditorModel) {
    const revision = link.revisionOptions.find(r => r.id === revisionId);

    link.reasonId = null;
    if (revision.reasonId && !revision.reasonIsSystem) {
      link.reasonId = revision.reasonId;
    }
  }
}
