import {BrowserCacheLocation, PublicClientApplication} from '@azure/msal-browser';
import {AppConfig} from "./app.config";

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export function MSALFactory() {
  return new PublicClientApplication({
    auth: {
      clientId: AppConfig.settings.azureADClientId,
      redirectUri: window.location.href.includes('localhost') ?`http://${window.location.host}` : `https://${window.location.host}`,
      authority: AppConfig.settings.azureADAuthority,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE,
    },
    system: {}
  });
}
