import { Injectable } from '@angular/core';
import { AppConfig } from '../../app.config';
import { HttpClient } from '@angular/common/http';
import { State } from '@progress/kendo-data-query';
import { GridDataService } from '../../shared/services/grid-data.service';
import { Observable } from 'rxjs';
import { ProjectModel, SourceModel } from '../../setup/sources/models/source.models';
import { VesselOptionModel } from '../../setup/vessels/models/vessel.models';
import { serialize } from '../../shared/utils/object.utils';
import { ValidationResultModel } from '../../shared/models/validation.models';
import { TransmittalAcknowledgeEditorModel, TransmittalIdentifierModel, TransmittalReceiptStateModel } from '../models/transmittal.models';

@Injectable()
export class TransmittalAcknowledgeService {
  readonly baseUrl: string = `${AppConfig.settings.apiUrl}/transmittals`;
  readonly baseUrlExternal: string = `${AppConfig.settings.apiUrl}/transmittals/external`;

  constructor(
    private http: HttpClient
  ) { }

  get(id: number): Observable<TransmittalAcknowledgeEditorModel> {
    const url = `${this.baseUrl}/${id}/acknowledge`;
    return this.http.get<TransmittalAcknowledgeEditorModel>(url);
  }

  getReceiptStateOptions(): Observable<TransmittalReceiptStateModel[]> {
    const url = `${this.baseUrl}/acknowledge/receiptstateoptions`;
    return this.http.get<TransmittalReceiptStateModel[]>(url);
  }

  getTransmittalIdentifier(id: number): Observable<TransmittalIdentifierModel> {
    const url = `${this.baseUrl}/${id}/identifier`;
    return this.http.get<TransmittalIdentifierModel>(url);
  }

  getExternalTransmittalIdentifier(id: number): Observable<TransmittalIdentifierModel> {
    const url = `${this.baseUrlExternal}/${id}/externalidentifier`;
    return this.http.get<TransmittalIdentifierModel>(url);
  }

  save(editor: TransmittalAcknowledgeEditorModel){
    const url = `${this.baseUrl}/${editor.transmittalId}/acknowledge`;
    return this.http.post<ValidationResultModel>(url, editor);
  }
}
