
import {finalize} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { ValidationResultModel } from '../../../../shared/models/validation.models';
import {
  VesselEditorModel, VesselStatusModel, VesselClassModel,
  VesselInstancePermissionsModel
} from '../../models/vessel.models';
import { ActivatedRoute, Router } from '@angular/router';
import { VesselService } from '../../services/vessel.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-vessel-edit',
  templateUrl: './vessel-edit.component.html',
  styleUrls: ['./vessel-edit.component.scss']
})
export class VesselEditComponent implements OnInit {
  validationResult: ValidationResultModel;
  hasLoaded: boolean = false;
  loading: boolean = false;
  title: string;
  updated: boolean = false;
  editor: VesselEditorModel = new VesselEditorModel();
  permissions: VesselInstancePermissionsModel;
  classOptions: VesselClassModel[] = [];
  filteredClassOptions: VesselClassModel[] = [];
  statusOptions: VesselStatusModel[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private vesselService: VesselService
  ) { }

  ngOnInit() {
    this.loading = true;

    this.route.params.subscribe(params => {
      const id = params['id'];

      this.vesselService.get(id).pipe(
        finalize(() => {
          this.hasLoaded = true;
          this.loading = false;
        }))
        .subscribe(editor => {
          this.editor = editor;
          this.permissions = editor.permissions;

          this.title = id ? (this.permissions.canEdit ? 'Edit' : 'View') + ' Vessel' : 'Vessel Setup';
        });
    });

    this.vesselService.getClassOptions().subscribe(options => {
      this.classOptions = options;
      this.filteredClassOptions = this.classOptions;
    });

    this.vesselService.getStatusOptions().subscribe(options => this.statusOptions = options);
  }

  onClassFilterChanged(searchText: string) {
      this.filteredClassOptions = this.classOptions.filter(e => e.name.toLowerCase().indexOf(searchText.toLowerCase()) >= 0);
  }

  submit($event: any) {
    this.validationResult = null;
    this.loading = true;
    this.vesselService.update(this.editor).pipe(
      finalize(() => this.loading = false))
      .subscribe(() => {
        this.updated = true;
        setTimeout(() => {
          this.router.navigateByUrl('/setup/vessels');
        }, 1500);
      }, err => {
        if (err instanceof HttpErrorResponse && err.status == 412) {
          this.validationResult = err.error;
        }
      })
  }

  cancel(){
    this.router.navigateByUrl("/setup/vessels");
  }
}
