import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { GridModule } from '@progress/kendo-angular-grid';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../shared/modules/shared.modules';
import { VesselGridComponent } from '../components/vessel-grid/vessel-grid.component';
import { VesselEditComponent } from '../components/vessel-edit/vessel-edit.component';
import { VesselService } from '../services/vessel.service';
import { VesselsRouterModule } from './vessels.router.module';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    GridModule,
    ButtonsModule,
    InputsModule,
    DropDownsModule,
    SharedModule
  ],
  declarations: [
    VesselGridComponent,
    VesselEditComponent
  ],
  providers: [
    VesselService
  ],
  exports: [
    VesselsRouterModule
  ]
})
export class VesselsModule { }