import { Component, OnInit, Input } from '@angular/core';
import { ILoadable } from '../../../shared/components/loading/loading.models';

@Component({
  selector: 'app-document-duplicate-form',
  templateUrl: './duplicate-form.component.html',
  styleUrls: ['./duplicate-form.component.scss']
})
export class DocumentDuplicateFormComponent implements OnInit, ILoadable {
  @Input('invalid') invalid: boolean;

  hasLoaded: boolean;
  loading: boolean;
  succeeded: boolean;

  title: string;
  count: number = 1;

  constructor() { }

  ngOnInit() {
  }

}
