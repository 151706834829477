
import {finalize} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { ILoadable } from '../../../../shared/components/loading/loading.models';
import { IKendoGridComponent, KendoColumnResizeArgs } from '../../../../shared/utils/kendo.utils';
import { State } from '@progress/kendo-data-query';
import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { VesselService } from '../../services/vessel.service';
import { GridDataService } from '../../../../shared/services/grid-data.service';
import { GridColumnState, GridColumn } from '../../../../shared/models/grid.models';
import { SystemPermissions } from "../../../../shared/models/permissions.models";
import { PermissionService } from "../../../../shared/services/permission.service";

@Component({
  selector: 'app-vessel-grid',
  templateUrl: './vessel-grid.component.html',
  styleUrls: ['./vessel-grid.component.scss']
})
export class VesselGridComponent implements OnInit, ILoadable, IKendoGridComponent {
  hasLoaded: boolean;
  loading: boolean;
  systemPermissions: SystemPermissions;
  filters: any;
  state: State = {
    skip: 0,
    take: 10
  };
  gridData: GridDataResult;

  columnState: GridColumnState = new GridColumnState([
    { name: 'Name', field: 'name', defaults: { width: 310, sort: { dir: 'asc' } } },
    { name: 'Acronym', field: 'acronym', defaults: { width: 210 } },
    { name: 'Class', field: 'className', defaults: { width: 327 } },
    { name: 'Status', field: 'statusName', defaults: { width: 327 } },
    { name: 'Active', field: 'active', defaults: { width: 147 } },
  ]);

  constructor(
    private vesselService: VesselService,
    private gridDataService: GridDataService,
    private permissionService: PermissionService
  ) { }

  ngOnInit() {
    this.loading = true;
    this.systemPermissions = this.permissionService.getPermissions();

    this.gridDataService.initColumnState(this);
    this.gridDataService.applyCachedState(this.vesselService.gridName, this);

    this.updateGrid();
  }

  dataStateChange(state: DataStateChangeEvent): void {
    this.loading = true;
    this.state = state;
    this.updateGrid();
  }

  resetColumnState() {
    this.gridDataService.resetColumnState(this.vesselService.gridName, this);
  }
  
  resized(changes: KendoColumnResizeArgs[]) {
    let change = changes[0];
    let col = this.columnState.selected.find(c => c.field == change.column.field);
    col.width = change.newWidth;
    this.gridDataService.saveColumnState(this.vesselService.gridName, this, true);
  }

  resetGrid() {
    this.loading = true;
    this.gridDataService.resetGrid(this.vesselService.gridName, this.vesselService.baseUrl, this)
      .subscribe(data => {
        this.gridData = data;
        this.loading = false;
      });
  }

  updateGrid() {
    this.vesselService.getGridData(this.state).pipe(
      finalize(() => this.hasLoaded = true))
      .subscribe(data => {
        this.gridData = data;
        this.loading = false;
      })
  }
}
