
import {forkJoin as observableForkJoin,  Observable } from 'rxjs';

import {mergeMap, finalize} from 'rxjs/operators';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ProjectEditorModel, ProjectInstancePermissionsModel } from '../../models/project.models';
import { ILoadable } from '../../../../shared/components/loading/loading.models';
import { IValidatable, ValidationResultModel } from '../../../../shared/models/validation.models';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectService } from '../../services/project.service';
import { HttpErrorResponse } from '@angular/common/http';
import { TemplateModel } from '../../../templates/models/template.models';
import { SourceModel } from '../../../sources/models/source.models';
import { VesselModel } from '../../../vessels/models/vessel.models';
import { IDirtyRecordGuard } from '../../../../shared/modules/dirty-record/guards/dirty-record-guard';
import { DirtyRecordDirective } from '../../../../shared/modules/dirty-record/directives/dirty-record.directive';
import { DraftRecordControlsComponent } from '../../../../shared/modules/draft-record/components/draft-record-controls/draft-record-controls.component';

@Component({
  selector: 'app-project-edit',
  templateUrl: './project-edit.component.html',
  styleUrls: ['./project-edit.component.scss']
})
export class ProjectEditComponent implements OnInit, ILoadable, IValidatable, IDirtyRecordGuard {
  @ViewChild(DirtyRecordDirective, {static: false}) dirtyRecordDirective: DirtyRecordDirective;
  @ViewChild('draftRecord', {static: false}) draftRecord: DraftRecordControlsComponent;

  dirty: boolean;
  draftSaved: boolean;
  validationResult: ValidationResultModel;
  hasLoaded: boolean = false;
  loading: boolean = false;
  title: string;
  updated: boolean = false;
  editor: ProjectEditorModel = new ProjectEditorModel();
  permissions: ProjectInstancePermissionsModel;
  isEditMode: boolean = false;
  templateOptions: TemplateModel[] = [];
  filteredTemplateOptions: TemplateModel[] = [];
  mainSourceOptions: SourceModel[] = [];
  otherSourceOptions: SourceModel[] = [];
  selectedOtherSourceOptions: SourceModel[] = [];
  vesselOptions: VesselModel[] = [];
  selectedVesselOptions: VesselModel[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private projectService: ProjectService
  ) { }

  ngOnInit() {
    this.loading = true;

    this.route.params.subscribe(params => {
      const id = params['id'];

      if (id)
        this.isEditMode = true;

      this.projectService.get(id)
        .subscribe(editor => {
          this.editor = editor;
          this.permissions = editor.permissions;

          this.title = id ? (this.permissions.canEdit ? 'Edit' : 'View') + ' Project' : 'Project Setup';

          this.getAndApplyEditorOptions(this.editor).pipe(
            finalize(() => {
              this.hasLoaded = true;
              this.loading = false;
            }))
            .subscribe();
        })
    });
  }

  getAndApplyEditorOptions(editor: ProjectEditorModel) {
    let requests: Observable<any>[] = [
      this.projectService.getMainSourceOptions(this.editor),
      this.projectService.getOtherSourceOptions(this.editor),
      this.projectService.getTemplateOptions(this.editor),
      this.projectService.getVesselOptions()
    ];

    if (!this.editor.templateId) {
      requests.push(this.projectService.getDefaultTemplate(this.editor))
    }

    return observableForkJoin(requests).pipe(
      mergeMap(results => {
        this.mainSourceOptions = results[0];
        this.otherSourceOptions = results[1];
        this.templateOptions = results[2];
        this.filteredTemplateOptions = this.templateOptions;
        this.vesselOptions = results[3];

        if (!this.editor.templateId) {
          let template = results[4];
          this.editor.templateId = !!template ? template.id : null;
        }

        this.selectedOtherSourceOptions = this.otherSourceOptions.filter(o => this.editor.otherSourceIds.includes(o.id));
        this.selectedVesselOptions = this.vesselOptions.filter(o => this.editor.vesselIds.includes(o.id));

        setTimeout(() => this.dirtyRecordDirective.watch());

        return results;
      }));
  }

  onTransmittalTemplateFilterChanged(searchText: string) {
    this.filteredTemplateOptions = this.templateOptions.filter(e => e.name.toLowerCase().indexOf(searchText.toLowerCase()) >= 0);
  }

  submit($event: any) {
    this.validationResult = null;
    this.loading = true;
    this.editor.otherSourceIds = this.selectedOtherSourceOptions.map(o => o.id);
    this.editor.vesselIds = this.selectedVesselOptions.map(o => o.id);
    this.projectService.update(this.editor).pipe(
      finalize(() => this.loading = false))
      .subscribe(() => {
        this.updated = true;
        setTimeout(() => {
          if (!!this.draftRecord)
            this.draftRecord.remove();
          this.dirtyRecordDirective.reset();
          this.router.navigateByUrl('/setup/projects');
        }, 1500);
      }, err => {
        if (err instanceof HttpErrorResponse && err.status == 412) {
          this.validationResult = err.error;
        }
      })
  }

  cancel(){
    this.router.navigateByUrl("/setup/projects")
  }

  mainSourceValueChanged(selectedId: number) {
    this.selectedOtherSourceOptions = this.selectedOtherSourceOptions.filter(option => option.id != selectedId);
    this.projectService.getMainSourceOptions(this.editor).subscribe(options => this.mainSourceOptions = options);
    this.projectService.getOtherSourceOptions(this.editor).subscribe(options => this.otherSourceOptions = options);
  }

  mainSourceFilterChanged($event: string) {
    this.projectService.getMainSourceOptions(this.editor, $event).subscribe(options => this.mainSourceOptions = options);
  }

  otherSourceValueChanged() {
    this.projectService.getOtherSourceOptions(this.editor).subscribe(options => this.otherSourceOptions = options);
  }

  otherSourceFilterChanged($event: string) {
    this.projectService.getOtherSourceOptions(this.editor, $event).subscribe(options => this.otherSourceOptions = options);
  }

  vesselFilterChanged($event: string) {
    this.projectService.getVesselOptions($event).subscribe(options => this.vesselOptions = options);
  }

  vesselOnClick() {
    setTimeout(() => {
      this.router.navigateByUrl('/setup/vessels/edit');
    }, 100);
  }

  sourceOnClick() {
    setTimeout(() => {
      this.router.navigateByUrl('/setup/sources/edit');
    }, 100);
  }

  transmittalOnClick() {
    setTimeout(() => {
      this.router.navigateByUrl('transmittals/edit');
    }, 100);
  }

  applyEditor = (editor: ProjectEditorModel) => {
    this.editor = editor;
    this.getAndApplyEditorOptions(this.editor).subscribe();
  }

  beforeDraftSave = () => {
    this.editor.vesselIds = this.selectedVesselOptions.map(o => o.id);
  }

  afterDraftSave = () => {
    window.scrollTo(0,0);
    this.dirtyRecordDirective.reset();
    this.draftSaved = true;
    setTimeout(() => {
      this.cancel();
    }, 2000);
  }
}
