
import {finalize} from 'rxjs/operators';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { DocumentService } from '../../services/document.service';
import { DocumentEventGridRowModel } from '../../models/document.models';
import { State } from '@progress/kendo-data-query';
import { PermissionService } from '../../../shared/services/permission.service';
import { GridDataService } from '../../../shared/services/grid-data.service';
import { TransmittalExternalService } from '../../../transmittals/services/transmittal-external.service';
import { ExternalTransmittalFiltersModel, TransmittalGridRowModel } from '../../../transmittals/models/transmittal.models';
import { GridColumnState, GridColumn } from '../../../shared/models/grid.models';
import { SystemPermissions } from '../../../shared/models/permissions.models';
import { DeletedEvent } from '../../../shared/models/events';
import { Router } from '@angular/router';
import * as Moment from 'moment';
import { KendoColumnResizeArgs } from '../../../shared/utils/kendo.utils';

@Component({
  selector: 'app-document-external-transmittal-grid',
  templateUrl: './document-external-transmittal-grid.component.html',
  styleUrls: ['./document-external-transmittal-grid.component.scss']
})
export class DocumentExternalTransmittalGridComponent implements OnInit {
  @Input('documentId') documentId: number;
  @Output('countChange') countChange: EventEmitter<number> = new EventEmitter();

  initialLoad: boolean = true;
  systemPermissions: SystemPermissions;
  hasLoaded: boolean;
  loading: boolean;
  state: State = {
    skip: 0,
    take: 20
  };
  pageSizes: number[] = [5, 10, 20, 50, 100];
  filters?: ExternalTransmittalFiltersModel = new ExternalTransmittalFiltersModel();
  gridData: GridDataResult;
  allSelected: boolean;
  columnState: GridColumnState = new GridColumnState([
    { name: 'Trans No', field: 'identifier', defaults: { width: 180 }  },
    { name: 'FileName', field: 'fileName', defaults: { width: 180 }  },
    { name: 'Proj No\'s', field: 'projectNumber', defaults: { width: 180 }  },
    { name: 'Source', field: 'sourceName', defaults: { width: 180 }  },
    { name: 'Sub Con', field: 'sourceIsSubContractor', defaults: { width: 120 }  },
    { name: 'Received on Date', field: 'date', defaults: { width: 170, sort: { dir: 'desc' } } },
    { name: 'No of Docs', field: 'documentCount', defaults: { width: 110 }  },
    { name: 'FilePath', field: 'filePath', defaults: { width: 180 } , isDefault: false },
    { name: 'Reason For Issue', field: 'issueReason', defaults: { width: 180 } , isDefault: false },
    { name: 'Voided', field: 'voided', defaults: { width: 180 } , isDefault: false },
    { name: 'Create Date', field: 'createDate', defaults: { width: 180 }  },
    { name: 'Created By', field: 'creatorName', defaults: { width: 180 }  }
  ]);

  constructor(
    private router: Router,
    private documentService: DocumentService,
    private gridDataService: GridDataService,
    private transmittalExternalService: TransmittalExternalService,
    private permissionService: PermissionService
  ) { }

  ngOnInit() {
    this.systemPermissions = this.permissionService.getPermissions();

    this.gridDataService.initColumnState(this);

    this.gridDataService.applyCachedState(this.transmittalExternalService.gridName, this);

    this.filters.displayDate = this.filters.date ? new Date(this.filters.date) : null;

    this.updateGrid();
  }

  dataStateChange(state: DataStateChangeEvent): void {
    this.loading = true;
    this.state = state;
    this.updateGrid();
  }

  columnStateChanged($event: GridColumn[]) {
    this.gridDataService.saveColumnState(this.transmittalExternalService.gridName, this);
  }

  resetGrid() {
    this.loading = true;
    this.gridDataService.resetGrid(this.transmittalExternalService.gridName, this.transmittalExternalService.baseUrl, this)
      .subscribe(() => {
        this.documentService.getExternalTransmittalLinkedGridData(this.state, this.documentId).subscribe(data => {
          this.gridData = data;
          this.loading = false;
        })
      });
  }

  resetColumnState() {
    this.gridDataService.resetColumnState(this.transmittalExternalService.gridName, this);
  }

  resized(changes: KendoColumnResizeArgs[]) {
    let change = changes[0];
    let col = this.columnState.selected.find(c => c.field == change.column.field);
    col.width = change.newWidth;
    this.gridDataService.saveColumnState(this.transmittalExternalService.gridName, this, true);
  }

  changePageSizes(pageSize: number) {
    this.state.take = pageSize;
    this.updateGrid();
  }

  updateGrid() {
    this.documentId = this.documentId;
    this.documentService.getExternalTransmittalLinkedGridData(this.state, this.documentId, this.filters).pipe(
      finalize(() => {
        this.hasLoaded = true
      }))
      .subscribe(data => {
        this.gridData = data;
        this.loading = false;
        if (this.initialLoad == true){
          this.countChange.next(data.total);
          this.initialLoad = false;
        }
      });
  }

  onVoided() {
    this.updateGrid();
  }

  onDeleted($event: DeletedEvent) {
    this.initialLoad = false;
    this.updateGrid();
  }

  edit(item: TransmittalGridRowModel) {
    let url = `/transmittals/external/${item.id}/edit`;
    this.router.navigateByUrl(url);
  }

  addDocuments(item: TransmittalGridRowModel) {
    this.router.navigateByUrl(`/transmittals/${item.id}/add-documents`);
  }
}
