import { ApiEntityModel } from '../../../shared/models/apiEntity.model';

export interface RevisionReasonInstancePermissionsModel {
  canView: boolean;
  canEdit: boolean;
  canArchive: boolean;
  canDelete: boolean;
}

export interface RevisionReasonGridRowModel {
  id: number;
  acronym: string;
  name: string;
  active: boolean;
  permissions: RevisionReasonInstancePermissionsModel;
}

export class RevisionReasonEditorModel {
  id: number | null;
  acronym: string;
  name: string;
  description: string;
  active: boolean = true;
  permissions: RevisionReasonInstancePermissionsModel;
}

export class RevisionReasonModel extends ApiEntityModel<number> {
  acronym: string;
  name: string;
}
