
import {finalize} from 'rxjs/operators';
import { Component, OnInit, ViewChild, Output, Input, ViewContainerRef } from '@angular/core';
import { Subject } from 'rxjs';
import { KendoDialogRef } from '../../../shared/utils/kendo.utils';
import { DocumentDuplicateFormComponent } from '../duplicate-form/duplicate-form.component';
import { DialogService } from '@progress/kendo-angular-dialog';
import { DocumentService } from '../../services/document.service';

@Component({
  selector: 'app-document-duplicate-dialog',
  templateUrl: './duplicate-dialog.component.html',
  styleUrls: ['./duplicate-dialog.component.scss']
})
export class DocumentDuplicateDialogComponent implements OnInit {
  @Output() onComplete: Subject<number> = new Subject();
  @ViewChild('dialogActions', {static: false}) actions;
  @ViewChild('duplicateDialogContainer', { static: false, read: ViewContainerRef }) duplicateDialogContainer;

  succeeded: boolean;

  private dialogRef: KendoDialogRef<DocumentDuplicateFormComponent>;
  private formComponent: DocumentDuplicateFormComponent;
  private documentId: number;

  constructor(
    private dialogService: DialogService,
    private documentService: DocumentService
  ) { }

  ngOnInit() {
  }

  open(documentId: number, title: string) {
    this.dialogRef = this.dialogService.open({
      title: 'Duplicate Document',
      content: DocumentDuplicateFormComponent,
      actions: this.actions,
      appendTo: this.duplicateDialogContainer
    });

    setTimeout(() => {
      $('kendo-dialog-actions button').last().focus();
    })

    this.formComponent = this.dialogRef.content.instance;
    this.formComponent.title = title;
    this.documentId = documentId;

    this.succeeded = this.formComponent.succeeded = false;
  }

  submit() {
    if (!this.formComponent.count) {
      this.formComponent.invalid = true;
      return;
    }

    this.formComponent.loading = true;

    this.documentService.duplicate(this.documentId, { count: this.formComponent.count }).pipe(
      finalize(() => this.formComponent.loading = false))
      .subscribe(res => {
        this.succeeded = this.formComponent.succeeded = true;
        this.onComplete.next(res.batchId);
      }, err => { })
  }

  close() {
    this.dialogRef.close();
    this.dialogRef.dialog.destroy();
  }
}
