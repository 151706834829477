
import {finalize} from 'rxjs/operators';
import { Component, OnInit, Output, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { KendoDialogRef } from '../../../shared/utils/kendo.utils';
import { DocumentService } from '../../services/document.service';
import { DialogService, DialogRef } from '@progress/kendo-angular-dialog';
import { ILoadable } from '../../../shared/components/loading/loading.models';
import { DeletedEvent } from '../../../shared/models/events';
@Component({
  selector: 'app-document-delete',
  templateUrl: './document-delete.component.html',
  styleUrls: ['./document-delete.component.scss']
})
export class DocumentDeleteComponent implements OnInit, ILoadable {
  @Output('deleted') onDeleted: Subject<DeletedEvent> = new Subject();
  @ViewChild('dialogContent', {static: false}) content;
  @ViewChild('dialogActions', {static: false}) actions;

  hasLoaded: boolean;
  loading: boolean;
  succeeded: boolean;
  title: string;
  subtitle: string;
  documentId: number;

  private dialogRef: DialogRef;

  constructor(
    private dialogService: DialogService,
    private documentService: DocumentService
  ) { }

  ngOnInit() {
  }

  open(documentId: number, title: string, subtitle: string) {
    this.dialogRef = this.dialogService.open({
      title: 'Delete Document',
      content: this.content,
      actions: this.actions
    });

    setTimeout(() => {
      $('kendo-dialog-actions button').last().focus();
    })

    this.succeeded = false;
    this.documentId = documentId;
    this.title = title;
    this.subtitle = subtitle;
  }

  submit() {
    this.loading = true;
    this.documentService.delete(this.documentId).pipe(
      finalize(() => this.loading = false))
      .subscribe(res => {
        this.succeeded = true;
        this.onDeleted.next({ id: this.documentId });
      }, err => { })
  }

  close() {
    this.dialogRef.close();
    this.dialogRef.dialog.destroy();
  }
}
