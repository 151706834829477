import { ApiEntityModel } from '../../shared/models/apiEntity.model';

export class RevisionEditorModel {
  id: number | null;
  documentId: number;
  reasonId: number | null;
  reasonText: string;
  title: string;
  revisionNumber: string;
  revisionDate: Date | null;
  archived: boolean;
}

export interface RevisionGridRowModel {
  id: number;
  timeAdded: Date;
  addedByUserName: string;
  reason: string;
  identifier: string;
  title: string;
  transmittals: string;
  externalTransmittal: string;
  archived: boolean;
  permissions: RevisionInstancePermissionsModel;
}

export interface RevisionInstancePermissionsModel {
  canEdit: boolean;
  canArchive: boolean;
  canDelete: boolean;
}

export class RevisionModel extends ApiEntityModel<number> {
  reasonId: number;
  reasonIsSystem: boolean;
  title: string;
  identifier: string;
  revisionNumber: string;
  revisionDate: Date | null;
}

export class BulkRevisionModel {
  revisionOptions: RevisionModel[];
  documentId: number;
}

export class RevisionGridFiltersModel {
  documentId?: number;
}

export interface RevisionEditResponseModel
{
    entityId: number | null;

    latestRevisionModel: RevisionModel;
}

export interface IRevisionAddedEvent {
  id: number;
  documentId: number;
  title: string;
  number: string;
  latestRevision: RevisionModel;
}

export interface IRevisionArchivedEvent {
  latestRevision: RevisionModel;
}

export interface IRevisionDeletedEvent {
  latestRevision: RevisionModel;
}
