import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { GridDataResult, DataStateChangeEvent, GridComponent } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import { DialogService } from '@progress/kendo-angular-dialog';
import { ILoadable } from '../../../shared/components/loading/loading.models';
import { PermissionService } from '../../../shared/services/permission.service';
import { SystemPermissions } from '../../../shared/models/permissions.models';
import { PtKendoGridDirective } from '../../../kendo/directives/pt-kendo-grid/pt-kendo-grid.directive';
import { TransmittalGridRowModel, ExternalTransmittalFiltersModel } from '../../models/transmittal.models';
import { DeletedEvent } from '../../models/transmittal.models';
import { TransmittalExternalService } from '../../services/transmittal-external.service';
import gridConfig from './transmittals-external-grid.config';

@Component({
  selector: 'app-transmittals-external-grid',
  templateUrl: './transmittals-external-grid.component.html',
  styleUrls: ['./transmittals-external-grid.component.scss']
})
export class TransmittalsExternalGridComponent implements OnInit, ILoadable {
  @ViewChild('grid', {static: false}) grid: GridComponent;
  @ViewChild('ptGrid', {static: false}) ptGrid: PtKendoGridDirective;

  loading: boolean = false;
  hasLoaded: boolean = true;
  systemPermissions: SystemPermissions;
  filters?: ExternalTransmittalFiltersModel = new ExternalTransmittalFiltersModel();
  gridData: GridDataResult;
  gridBaseUrl: string;
  gridName: string;
  columns = gridConfig.columns;
  pageSizes: number[] = [5, 10, 20, 50, 100];
  state: State = {
    skip: 0,
    take: 10,
    sort: gridConfig.defaultSort,
    filter: gridConfig.defaultFilter
  };

  constructor(
    private router: Router,
    private dialogService: DialogService,
    private transmittalExternalService: TransmittalExternalService,
    private permissionService: PermissionService
  ) {
    const gridDataConfig = transmittalExternalService.getGridDataConfig();
    this.gridBaseUrl = gridDataConfig.url;
    this.gridName = gridDataConfig.name;
  }

  ngOnInit() {
    this.loading = true;
    this.systemPermissions = this.permissionService.getPermissions();

    this.filters.displayDate = this.filters.date ? new Date(this.filters.date) : null;
  }

  dataStateChange(state: DataStateChangeEvent): void {
    this.loading = true;
    this.state = state;
    this.updateGrid();
  }

  resetGrid() {
    this.loading = true;
    this.ptGrid.resetGrid()
      .subscribe(data => {
        this.gridData = data;
        this.loading = false;
      });
  }

  resetColumnState() {
    this.ptGrid
      .resetGrid({ columns: true })
      .subscribe();
  }

  updateGrid() {
    this.ptGrid
      .updateGrid()
      .subscribe();
  }

  onVoided() {
    this.updateGrid();
  }

  onDeleted($event: DeletedEvent) {
    this.updateGrid();
  }

  edit(item: TransmittalGridRowModel) {
    this.router.navigateByUrl(`/transmittals/external/${item.id}/edit`);
  }

  viewDocumentsInGrid(dataItemId: number){
    this.router.navigate([`/documents`], { queryParams: { externalTransmittal: dataItemId } });
  }
}
