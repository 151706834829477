import { Injectable } from '@angular/core';
import { AppConfig } from '../../app.config';
import { HttpClient } from '@angular/common/http';
import { State } from '@progress/kendo-data-query';
import { Observable } from 'rxjs';
import {
  VoidDocumentModel,
  IDocumentFilenameModel,
  DocumentGridRowModel,
  DocumentDuplicateModel,
  DocumentDuplicateResponseModel,
  DocumentCanLinkResponseModel,
  DocumentTransmittalsModel,
  GetDocumentListRequestModel
} from '../models/document.models';
import { KendoGridDataResult } from '../../shared/utils/kendo.utils';
import { KendoGridDataConfig } from '../../shared/models/grid.models';
import { GridDataService } from '../../shared/services/grid-data.service';

@Injectable()
export class DocumentService {
  readonly baseUrl: string = `${AppConfig.settings.apiUrl}/documents`;
  readonly gridName: string = 'documents';

  constructor(
    private http: HttpClient,
    private gridData: GridDataService
  ) {
  }

  getGridData(): KendoGridDataConfig {
    return {
      name: this.gridName,
      url: this.baseUrl
    };
  }

  getLinkedGridDataConfig(id: number, includeId: boolean): KendoGridDataConfig {
    const gridName = (includeId) ? `${id}/linkedincludeid` : `${id}/linkedexcludeid`;
    return {
      name: (includeId) ? `${id}/linkedincludeid` : `${id}/linkedexcludeid`,
      url: `${this.baseUrl}/${gridName}`
    };
  }

  getLinkedGridData(state: State, id: number, includeId: boolean) {
    const gridName = (includeId) ? `${id}/linkedincludeid` : `${id}/linkedexcludeid`;
    return this.gridData.getGridData(gridName, `${this.baseUrl}/${gridName}`, state);
  }

  getDocumentList(state: State, filters: GetDocumentListRequestModel) {
    return this.gridData.getGridData('duplicateDocuments', `${this.baseUrl}/list`, state, filters);
  }

  getEventGridData(state: State, id: number) {
    const gridName = `${id}/events`;
    return this.gridData.getGridData(gridName, `${this.baseUrl}/${gridName}`, state);
  }

  getImsChildGridDataConfig(id: number): KendoGridDataConfig {
    const gridName = `${id}/imschildren`;

    return {
      name: gridName,
      url: `${this.baseUrl}/${gridName}`
    };
  }

  // used in showing linked documents to a transmittal
  getTransmittalLinksGridDataConfig(id: number): KendoGridDataConfig {
    const gridName = `${id}/transmittallinks`;
    return {
      name: gridName,
      url: `${this.baseUrl}/${gridName}`
    };
  }

  // used in showing linked documents to an external transmittal
  getExternalTransmittalLinksGridDataConfig(id: number): KendoGridDataConfig {
    const gridName = `${id}/externaltransmittallinks`;
    return {
      name: gridName,
      url: `${this.baseUrl}/${gridName}`
    };
  }

  // used in showing linked external transmittals to a document
  getExternalTransmittalLinkedGridData(state: State, id: number, filters?: any) {
    const gridName = `${id}/externaltransmittallinked`;
    return this.gridData.getGridData(gridName, `${this.baseUrl}/${gridName}`, state, filters);
  }

  // used in showing linked SJ transmittals to a document
  getSeajacksTransmittalLinkedGridData(state: State, id: number, filters?: any) {
    const gridName = `${id}/seajackstransmittallinked`;
    return this.gridData.getGridData(gridName, `${this.baseUrl}/${gridName}`, state, filters);
  }

  void(model: VoidDocumentModel): Observable<any> {
    return this.http.post(`${this.baseUrl}/${model.id}/void`, model);
  }

  delete(id: number): Observable<any> {
    return this.http.post(`${this.baseUrl}/${id}/delete`, null);
  }

  approve(ids: number[]): Observable<any> {
    return this.http.post(`${this.baseUrl}/bulkapprove`, ids);
  }

  link(ids: number[]): Observable<any> {
    return this.http.post(`${this.baseUrl}/link`, ids);
  }

  canlink(ids: number[]): Observable<DocumentCanLinkResponseModel> {
    return this.http.post<DocumentCanLinkResponseModel>(`${this.baseUrl}/canlink`, ids);
  }

  unlink(ids: number[]): Observable<any> {
    return this.http.post(`${this.baseUrl}/unlink`, ids);
  }

  getFileName(documentId: number): Observable<IDocumentFilenameModel> {
    return this.http.get<IDocumentFilenameModel>(`${this.baseUrl}/${documentId}/filename`);
  }

  getFileNames(documentIds: number[]): Observable<IDocumentFilenameModel[]> {
    return this.http.post<IDocumentFilenameModel[]>(`${this.baseUrl}/filename`, documentIds);
  }

  getDuplicatesGridData(state: State, batchId: number): Observable<KendoGridDataResult<DocumentGridRowModel>> {
    const gridName = `duplicates/${batchId}`;
    return this.gridData.getGridData(gridName, `${this.baseUrl}/${gridName}`, state);
  }

  duplicate(id: number, model: DocumentDuplicateModel) {
    return this.http.post<DocumentDuplicateResponseModel>(`${this.baseUrl}/${id}/duplicate`, model);
  }

  getExportData(ids: number[]) {
    return this.http.post<KendoGridDataResult<DocumentGridRowModel>>(`${this.baseUrl}/export/data`, ids);
  }

  getTransmittals(id: number) {
    return this.http.get<DocumentTransmittalsModel>(`${this.baseUrl}/${id}/transmittals`);
  }

  getSeajacksTransmittalOptions(searchText?: string) {
    let url = `${this.baseUrl}/transmittaloptions`;
    if (!!searchText) {
      url += `?searchText=${searchText}`;
    }
    return this.http.get<any[]>(url);
  }

  getExternalTransmittalOptions(searchText?: string) {
    let url = `${this.baseUrl}/externaltransmittaloptions`;
    if (!!searchText) {
      url += `?searchText=${searchText}`;
    }
    return this.http.get<any[]>(url);
  }

  bulkAddToExternalTransmittal(documentRevisionIds: number[], transmittalId: number) {
    const url = `${this.baseUrl}/bulkaddexternaltransmittal`;
    const data = { 'documentRevisionIds': documentRevisionIds, 'transmittalId': transmittalId }
    return this.http.post<any>(url, data);
  }
}
