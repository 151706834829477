import { of as observableOf } from 'rxjs';
import {finalize, mergeMap} from 'rxjs/operators';
import { Component, OnInit, ViewChild, NgModule } from '@angular/core';
import { Location } from '@angular/common';
import { ILoadable } from '../../../shared/components/loading/loading.models';
import { IValidatable, ValidationResultModel } from '../../../shared/models/validation.models';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ExternalTransmittalEditorModel,
  ExternalTransmittalInstancePermissionsModel,
  TransmittalExternalOptions
} from '../../models/transmittal.models';
import { VesselModel, VesselClassModel, VesselOptionModel } from '../../../setup/vessels/models/vessel.models';
import { SourceModel, ProjectModel } from '../../../setup/sources/models/source.models';
import { Observable } from 'rxjs';

import { HttpErrorResponse } from '@angular/common/http';
import { TransmittalExternalService } from '../../services/transmittal-external.service';
import { TransmittalExternalSaveComponent } from '../transmittal-external-save/transmittal-external-save.component';
import * as Moment from 'moment';
import { DocumentService } from '../../../documents/services/document.service';
import { IDirtyRecordGuard } from '../../../shared/modules/dirty-record/guards/dirty-record-guard';
import { DirtyRecordDirective } from '../../../shared/modules/dirty-record/directives/dirty-record.directive';

@Component({
  selector: 'app-transmittal-external',
  templateUrl: './transmittal-external.component.html',
  styleUrls: ['./transmittal-external.component.scss'],
})
export class TransmittalExternalComponent implements OnInit, ILoadable, IValidatable, IDirtyRecordGuard {
  @ViewChild(DirtyRecordDirective, { static: false}) dirtyRecordDirective: DirtyRecordDirective;
  @ViewChild('saveDialog', { static: false}) saveDialog: TransmittalExternalSaveComponent;

  validationResult: ValidationResultModel;
  hasLoaded: boolean;
  loading: boolean;
  updated: boolean;
  isEditMode: boolean = false;
  title: string;
  todayDate: Date = new Date;
  create: ExternalTransmittalEditorModel;
  projectOptions: ProjectModel[];
  sourceOptions: SourceModel[] = [];
  editor: ExternalTransmittalEditorModel;
  permissions: ExternalTransmittalInstancePermissionsModel;
  saveSuccess: boolean;
  addDocuments: boolean;
  transmittalId: number;
  defaultProjectItem = { id: null, name: 'None' };
  documentRevisionIds: number[] = [];
  hasDocumentsToAdd: boolean = false;

  dirty: boolean;
  draftSaved: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private transmittalExternalService: TransmittalExternalService,
    private documentService: DocumentService
  ) { }

  ngOnInit() {
    this.loading = true;

    this.route.params.subscribe(params => {
      let id = params['id'] || null;

      this.transmittalExternalService.get(id).pipe(mergeMap(editor => {
        this.editor = editor;
        this.permissions = editor.permissions;

        if (id) {
          this.isEditMode = true;
          this.title = (this.permissions.canEdit ? 'Edit' : 'View') + ' External Transmittal';
        }
        else {
          this.title = 'Create External Transmittal';
        }

        this.editor.displayDate = this.editor.date ? new Date(editor.date) : null;
        return this.getAndApplyEditorOptions(this.editor);
      })).subscribe(options => {
        this.hasLoaded = true;
        this.loading = false;
      }, err => {
        console.error('error on External Transmittal Edit', err)
      });
    });

    this.route.queryParams.subscribe(queryParams => {
      if (queryParams['transmittalid'] != null) {
        this.hasDocumentsToAdd = true;
        let transmittalIds = queryParams['transmittalid'] || null;
        if (transmittalIds) {
          transmittalIds = transmittalIds.slice(0, - 1); // remove the last ' ' from the url
          this.documentRevisionIds = transmittalIds.split([' ']); // split ids from url into array
        }
      }
    });
  }

  getAndApplyEditorOptions(editor: ExternalTransmittalEditorModel): Observable<TransmittalExternalOptions> {
    return this.transmittalExternalService
      .getTransmittalEditorOptions(this.editor).pipe(
      mergeMap(options => {
        if (!this.editor.notes)
          this.editor.notes = ""; // need to define notes here so that keydown enter will work (see html for notes textarea section)
        this.sourceOptions = options.sourceOptions;
        this.projectOptions = options.projectOptions;

        setTimeout(() => this.dirtyRecordDirective.watch());

        return observableOf(options);
      }))
  }

  submit() {
    this.validationResult = null;
    this.loading = true;

    this.completeEditor();

    this.transmittalExternalService.update(this.editor).pipe(
      finalize(() => this.loading = false))
      .subscribe(result => {
        this.transmittalId = result.entityId;
        this.updated = true;
        if (this.hasDocumentsToAdd == true) {
          this.bulkAddToExternalTransmittal();
          this.dirtyRecordDirective.reset();
          this.saveDialog.open(this.hasDocumentsToAdd, this.documentRevisionIds.length);
        }
        else {
          this.dirtyRecordDirective.reset();
          this.saveDialog.open();
        }
      }, err => {
        if (err instanceof HttpErrorResponse && err.status == 412) {
          this.validationResult = err.error;
        }
      })
  }

  cancel() {
    this.router.navigateByUrl("/transmittals");
  }

  onSourceFilterChanged(searchText: string) {
    this.transmittalExternalService.getAdditionalSourceOptions(this.editor, searchText).subscribe(options => this.sourceOptions = options);
  }

  onProjectFilterChanged(searchText: string) {
    this.transmittalExternalService.getAdditionalProjectOptions(this.editor, searchText).subscribe(options => this.projectOptions = options);
  }

  sourceSelected() {
    if (!this.editor.sourceId) {
      this.editor.projectId = null;
    }
    this.transmittalExternalService.getTransmittalEditorOptions(this.editor)
      .subscribe(options => {
        this.projectOptions = options.projectOptions;
      }, err => {
        console.error('error on External Transmittal Edit', err)
      });
  }

  searchDocuments(number: any): void {
    this.addDocuments = true;
  }

  completeEditor = () => {
    this.editor.date = this.editor.displayDate ? Moment(this.editor.displayDate).format() : null;
  }

  applyEditor = (editor: ExternalTransmittalEditorModel) => {
    this.editor = editor;
    this.getAndApplyEditorOptions(this.editor).subscribe();
  }

  beforeDraftSave = () => {
    this.completeEditor();
  }

  afterDraftSave = () => {
    window.scrollTo(0, 0);
    this.dirtyRecordDirective.reset();
    this.draftSaved = true;
    setTimeout(() => {
      this.cancel();
    }, 2000);
  }

  private bulkAddToExternalTransmittal() {
    this.documentService.bulkAddToExternalTransmittal(this.documentRevisionIds, this.transmittalId).subscribe(options => {
    });
  }
}
