import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DatePipe} from '@angular/common';
import {TransmittalsGridComponent} from '../components/transmittals-grid/transmittals-grid.component';
import {RouterModule} from '@angular/router';
import {TransmittalRouterModule} from './transmittal.router.module';
import {PtKendoModule} from '../../kendo/modules/pt.kendo.module';
import {FormsModule} from '@angular/forms';
import {LayoutModule} from '@progress/kendo-angular-layout';
import {GridModule, ExcelModule, BodyModule} from '@progress/kendo-angular-grid';
import {ButtonsModule} from '@progress/kendo-angular-buttons';
import {InputsModule} from '@progress/kendo-angular-inputs';
import {DateInputsModule} from '@progress/kendo-angular-dateinputs';
import {DropDownsModule} from '@progress/kendo-angular-dropdowns';
import {DialogModule, DialogService} from '@progress/kendo-angular-dialog';
import {SharedModule} from '../../shared/modules/shared.modules';
import {TransmittalService} from '../services/transmittal.service';
import {BriefcaseComponent} from '../components/briefcase/briefcase/briefcase.component';
import {TransmittalDeleteComponent} from '../components/transmittal-delete/transmittal-delete.component';
import {TransmittalEditComponent} from '../components/transmittal-edit/transmittal-edit.component';
import {TransmittalVoidComponent} from '../components/transmittal-void/transmittal-void.component';
import {TransmittalVoidFormComponent} from '../components/transmittal-void-form/transmittal-void-form.component';
import {TransmittalEditorService} from '../services/transmittal-editor.service';
import {TransmittalAcknowledgeComponent} from '../components/transmittal-acknowledge/transmittal-acknowledge.component';
import {TransmittalAcknowledgeService} from '../services/transmittal-acknowledge.service';
import {TransmittalsExternalGridComponent} from '../components/transmittal-external-grid/transmittals-external-grid.component';
import {TransmittalExternalComponent} from '../components/transmittal-external/transmittal-external.component';
import {TransmittalExternalService} from '../services/transmittal-external.service';
import {
  TransmittalExternalAddDocumentComponent
} from '../components/transmittal-external-add-document/transmittal-external-add-document.component';
import {TransmittalExternalAddDocumentService} from '../services/transmittal-external-add-document.service';
import {
  TransmittalDocumentSearchGridComponent
} from '../components/transmittal-document-search-grid/transmittal-document-search-grid.component';
import {CollapseModule} from 'ngx-bootstrap/collapse';
import {TransmittalExternalSaveComponent} from '../components/transmittal-external-save/transmittal-external-save.component';
import {TransmittalSavedComponent} from '../components/transmittal-saved/transmittal-saved.component';
import {TransmittalSavedFormComponent} from '../components/transmittal-saved-form/transmittal-saved-form.component';
import {
  TransmittalExternalAddDocumentGridComponent
} from '../components/transmittal-external-add-document-grid/transmittal-external-add-document-grid.component';
import {TransmittalExternalDeleteComponent} from '../components/transmittal-external-delete/transmittal-external-delete.component';
import {DocumentModule} from '../../documents/modules/document.module';
import {TransmittalViewDocumentsComponent} from '../components/transmittal-view-documents/transmittal-view-documents.component';
import {TransmittalEditIssueWarningComponent} from '../components/transmittal-edit-issue-warning/transmittal-edit-issue-warning.component';
import {
  TransmittalConfirmSaveDialogComponent
} from '../components/transmittal-confirm-save-dialog/transmittal-confirm-save-dialog.component';
import {
  TransmittalDocumentReorderListComponent
} from '../components/transmittal-document-reorder-list/transmittal-document-reorder-list.component';
import {DragDropModule} from '@angular/cdk/drag-drop';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    PtKendoModule,
    FormsModule,
    LayoutModule,
    GridModule,
    ButtonsModule,
    InputsModule,
    DateInputsModule,
    DropDownsModule,
    DialogModule,
    ExcelModule,
    CollapseModule,
    SharedModule,
    DocumentModule,
    BodyModule,
    DragDropModule,
  ],
  declarations: [
    TransmittalsGridComponent,
    TransmittalsExternalGridComponent,
    TransmittalDocumentSearchGridComponent,
    BriefcaseComponent,
    TransmittalDeleteComponent,
    TransmittalEditComponent,
    TransmittalSavedComponent,
    TransmittalSavedFormComponent,
    TransmittalVoidComponent,
    TransmittalVoidFormComponent,
    TransmittalAcknowledgeComponent,
    TransmittalExternalComponent,
    TransmittalExternalAddDocumentComponent,
    TransmittalExternalSaveComponent,
    TransmittalExternalAddDocumentGridComponent,
    TransmittalExternalDeleteComponent,
    TransmittalViewDocumentsComponent,
    TransmittalEditIssueWarningComponent,
    TransmittalConfirmSaveDialogComponent,
    TransmittalDocumentReorderListComponent
  ],
  entryComponents: [
    TransmittalDeleteComponent,
    TransmittalVoidFormComponent,
    TransmittalSavedFormComponent
  ],
  providers: [
    TransmittalService,
    TransmittalEditorService,
    TransmittalAcknowledgeService,
    TransmittalExternalService,
    TransmittalExternalAddDocumentService,
    [DatePipe],
    DialogService
  ],
  exports: [
    TransmittalRouterModule,
    TransmittalsGridComponent,
    TransmittalsExternalGridComponent
  ]
})
export class TransmittalsModule {
}
