import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RevisionReasonGridComponent } from '../components/revision-reason-grid/revision-reason-grid.component';
import { RevisionReasonEditComponent } from '../components/revision-reason-edit/revision-reason-edit.component';
import { AuthGuard } from "../../../shared/utils/auth.guard";

const routes: Routes = [
  {
    path: 'setup',
    canActivate: [AuthGuard],
    children: [
      { path: 'revisionreason', component: RevisionReasonGridComponent },
      { path: 'revisionreason/edit', component: RevisionReasonEditComponent },
      { path: 'revisionreason/:id/edit', component: RevisionReasonEditComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RevisionReasonsRouterModule { }
