import { Injectable } from '@angular/core';
import { AppConfig } from '../../../app.config';
import { HttpClient } from '@angular/common/http';
import { GridDataService } from '../../../shared/services/grid-data.service';
import { State } from '@progress/kendo-data-query';
import { IKendoGridComponent } from '../../../shared/utils/kendo.utils';
import { RoleEditorModel, UserSimpleGridRowModel } from '../models/role.models';
import { UserModel } from '../../users/models/user.models';
import { Observable } from 'rxjs';
import { User } from '../../../shared/models/security.models';

@Injectable()
export class RoleService {
  readonly baseUrl: string = `${AppConfig.settings.apiUrl}/roles`;
  readonly gridName: string = 'roles';

  constructor(
    private http: HttpClient,
    private gridData: GridDataService
  ) { }

  getGridData(state: State, filters?: any) {
    return this.gridData.getGridData(this.gridName, this.baseUrl, state, filters);
  }

  getRole(id: number){
    return this.http.get<RoleEditorModel>(`${this.baseUrl}/${id}/edit`);
  }

  updateRole(role: RoleEditorModel) {
    return this.http.post(`${this.baseUrl}/${role.id}/edit`, role);
  }

  archive(id: number) {
    return this.http.post(`${this.baseUrl}/${id}/archive`, null);
  }

  delete(id: number) {
    return this.http.post(`${this.baseUrl}/${id}/delete`, null);
  }

  getUserOptions(role: RoleEditorModel, searchText: string = ''): Observable<UserModel[]> {
    let url = `${this.baseUrl}/edit/useroptions`;
    url += `?searchtext=${searchText}`;
    return this.http.post<UserModel[]>(url, role);
  }

  getUser(id: number) {
    return this.http.get<UserSimpleGridRowModel>(`${this.baseUrl}/edit/${id}/getuser`);
  }
}
