import {KendoGridConfig} from '../../../shared/models/grid.models';

const documentSearchGridConfig: KendoGridConfig = {
  defaultSort: [{ field: 'number', dir: 'asc' }],
  columns:
    [
      {
        field: 'ordinal',
        title: '',
        filter: 'text',
        _width: 18
      },
      {
        field: 'revisionDocumentIdentifier',
        title: 'Document Id',
        filter: 'text',
        _width: 170
      },
      {
        field: 'revisionId',
        title: 'Revision',
        _width: 100,
      },
      {
        field: 'reasonId',
        title: 'Reason',
        _width: 110
      },
      {
        field: '_actions',
        title: 'Actions',
        _width: 40
      },
    ]
};

export default documentSearchGridConfig;
