import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { UserModel } from '../../../users/models/user.models';
import { RoleEditorModel, RoleUserLinkModel, UserSimpleGridRowModel, RoleInstancePermissionsModel } from '../../models/role.models';
import { Observable } from 'rxjs';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { RoleService } from '../../services/role.service';

@Component({
  selector: 'app-added-users-grid',
  templateUrl: './added-users-grid.component.html',
  styleUrls: ['./added-users-grid.component.scss'],
  outputs: ['itemRemoved']
})
export class AddedUsersGridComponent implements OnInit {
  @Input() data: RoleUserLinkModel[];
  @Input() permissions: RoleInstancePermissionsModel[];  
  @Output() itemRemoved: EventEmitter<RoleUserLinkModel> = new EventEmitter<RoleUserLinkModel>();
  
  private dialogRef: DialogRef;
  hasLoaded: boolean;
  loading: boolean;
  gridData: RoleUserLinkModel[];

  constructor(
    private roleService: RoleService
  ) { }

  ngOnInit() {
    this.loading = true;
    this.gridData = this.data;
    this.gridData.forEach(user => {
      var concatNames = "";
      user.roles.sort((a, b) => {
        if (a.name < b.name) return -1;
        else if (a.name > b.name) return 1;
        else return 0;
      })
      user.roles.forEach(role => {
        concatNames += role.name;
        if (user.roles.length > 1)
          concatNames += ", "
      });
      if (concatNames.substr(concatNames.length-1) == " ")
        concatNames = concatNames.substr(0, concatNames.length - 2);
      user.rolesText = concatNames;
    });
    this.loading = false;
  }

  removeItem(data: RoleUserLinkModel){
    let index = this.gridData.findIndex(e => e.id == data.id);
    this.gridData.splice(index, 1); 
    this.itemRemoved.emit(data); 
  }

  setData(data: RoleUserLinkModel[]){
    this.gridData = data;
  }

  addUser(user: UserSimpleGridRowModel){
    let newUser = new RoleUserLinkModel();
    let userGridData = new UserSimpleGridRowModel();
    
    this.roleService.getUser(user.id).subscribe(data => {
      userGridData = data;

      newUser.id = userGridData.id;
      newUser.username = userGridData.username;
      newUser.rolesText = userGridData.rolesText;
  
      this.gridData.push(newUser);
    })   
  }
}
