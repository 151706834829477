import { Component, OnInit, OnChanges, Input, SimpleChanges } from '@angular/core';
import { ValidationResultModel } from '../../models/validation.models';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-validation-summary',
  templateUrl: './validation-summary.component.html',
  styleUrls: ['./validation-summary.component.scss']
})
export class ValidationSummaryComponent implements OnInit, OnChanges {
  @Input() result: ValidationResultModel | string;
  @Input() scrollOnFail: boolean = true;
  @Input() scrollOnSuccess: boolean = true;

  validation: ValidationResultModel;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.result && !changes.result.firstChange) {
      this.validation = this.parse(changes.result.currentValue);
      if (!!this.validation && (this.isInvalidCanScroll(this.validation) || this.isValidCanScroll(this.validation))) {
        this.scroll();
      }
    }
  }

  private parse(currentValue: any): ValidationResultModel {
    if (currentValue instanceof HttpErrorResponse) {
      return this.parse(currentValue.error);
    } else if (typeof (currentValue) == "string") {
      return JSON.parse(currentValue)
    } else {
      return currentValue;
    }
  }

  private isValidCanScroll(result: ValidationResultModel) {
    return this.scrollOnSuccess && result.isValid;
  }

  private isInvalidCanScroll(result: ValidationResultModel) {
    return this.scrollOnFail && !result.isValid;
  }

  private scroll(): void {
    setTimeout(() => {
      window.scrollToTop();
    }, 300);
  }
}
