
import {finalize, mergeMap} from 'rxjs/operators';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { ILoadable } from '../../../shared/components/loading/loading.models';
import { IValidatable, ValidationResultModel } from '../../../shared/models/validation.models';
import { ActivatedRoute, Router } from '@angular/router';
import {
  TransmittalAcknowledgeEditorModel, TransmittalContactLinkEditorModel,
  TransmittalInstancePermissionsModel, TransmittalReceiptStateModel
} from '../../models/transmittal.models';

import { HttpErrorResponse } from '@angular/common/http';
import { TransmittalAcknowledgeService } from '../../services/transmittal-acknowledge.service';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'app-transmittal-acknowledge',
  animations: [
    trigger('enterAnimation', [
      transition(':leave', [
        style({ opacity: 1 }),
        animate('500ms', style({ opacity: 0 }))
      ])
    ]
    ),
    trigger('leaveAnimation', [
      transition(':enter', [
        style({ opacity: 1 }),
        animate('500ms', style({ opacity: 0 }))
      ])
    ]
    )
  ],
  templateUrl: './transmittal-acknowledge.component.html',
  styleUrls: ['./transmittal-acknowledge.component.scss']
})

export class TransmittalAcknowledgeComponent implements OnInit, ILoadable, IValidatable {
  validationResult: ValidationResultModel;
  hasLoaded: boolean;
  loading: boolean;
  updated: boolean;
  title: string;
  isEditMode: boolean = false;
  transmittalName: string;
  editor: TransmittalAcknowledgeEditorModel = new TransmittalAcknowledgeEditorModel();
  receiptStateOptions: TransmittalReceiptStateModel[] = [];
  contactsPending: TransmittalContactLinkEditorModel[] = [];
  contactsConfirmed: TransmittalContactLinkEditorModel[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private transmittalAcknowledgeService: TransmittalAcknowledgeService,
  ) { }

  ngOnInit() {
    this.hasLoaded = false;
    this.loading = true;

    this.route.params.subscribe(params => {
      let id = params['id'] || null;
      if (id) {
        this.isEditMode = true;
      } else {
        this.title = params['id'] ? 'Edit Transmittal Receival' : 'Transmittal Received (Seajacks)';
      }

      this.transmittalAcknowledgeService.get(id).pipe(mergeMap(editor => {
        this.editor = editor;
        return this.transmittalAcknowledgeService.getReceiptStateOptions();
      }),finalize(() => this.hasLoaded = true),)
        .subscribe(options => {
          this.receiptStateOptions = options;

          this.editor.contactLinks.forEach(link => {
            if (link.receiptStateId == 'pending') {
              this.contactsPending.push(link)
            } else {
              link.displayReceiptDate = new Date(link.receiptDate);
              this.contactsConfirmed.push(link);
            }
          });
          // get identifier model
          this.transmittalAcknowledgeService.getTransmittalIdentifier(id).pipe(
            finalize(() => this.loading = false))
            .subscribe(data => {
              this.transmittalName = data.identifier;
            });
        }, err => {
          console.error('error on Transmittal Edit', err)
        });
    });
  }

  submit() {
    this.editor.contactLinks = this.contactsPending.concat(this.contactsConfirmed);
    this.editor.contactLinks.forEach(link => {
      link.receiptDate = link.displayReceiptDate ? link.displayReceiptDate.toUTCString() : null;
    })
    this.transmittalAcknowledgeService.save(this.editor).pipe(
      finalize(() => this.loading = false))
      .subscribe(result => {
        this.updated = true;
        setTimeout(() => {
          this.router.navigateByUrl('/transmittals');
        }, 1500);
      }, err => {
        this.updated = false;
        if (err instanceof HttpErrorResponse && err.status == 412) {
          this.validationResult = err.error;
        }
      })
  }

  viewTransmittal() {
    this.router.navigateByUrl('/transmittals/' + this.editor.transmittalId + '/edit');
  }

  statusSelected(statusId: string, contactId: number) {
    if (statusId != 'pending') {
      this.moveToAcknowledged(contactId);
    } else {
      this.moveToPending(contactId);
    }
  }

  moveToAcknowledged(contactId: number) {
    let contact = this.contactsPending.find(d => d.contactId == contactId);

    if (!contact)
      return;

    let i = this.contactsPending.indexOf(contact);
    this.contactsPending.splice(i, 1);

    contact.displayReceiptDate = new Date();
    this.contactsConfirmed.push(contact);
  }

  moveToPending(contactId: number) {
    let contact = this.contactsConfirmed.find(d => d.contactId == contactId);

    if (!contact)
      return;

    let i = this.contactsConfirmed.indexOf(contact);
    this.contactsConfirmed.splice(i, 1);

    contact.receiptDate = null;
    this.contactsPending.push(contact);
  }
}
