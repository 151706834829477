import { NgModule } from '@angular/core';
import { GridModule } from '@progress/kendo-angular-grid';
import { UserService } from '../services/user.service';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { UserEditComponent } from '../components/user-edit/user-edit.component';
import { UserGridComponent } from '../components/user-grid/user-grid.component';
import { RouterModule } from '@angular/router';
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { SharedModule } from '../../../shared/modules/shared.modules';
import { UsersRouterModule } from './users.router.modules';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    GridModule,
    ButtonsModule,
    InputsModule,
    DateInputsModule,
    DropDownsModule,
    SharedModule
  ],
  declarations: [
    UserGridComponent,
    UserEditComponent
  ],
  providers: [
    UserService
  ],
  exports: [
    UsersRouterModule
  ]
})
export class UsersModule { }