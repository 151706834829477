
import {finalize} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { IKendoGridComponent, KendoColumnResizeArgs } from '../../../../shared/utils/kendo.utils';
import { ILoadable } from '../../../../shared/components/loading/loading.models';
import { SourceService } from '../../services/source.service';
import { State } from '@progress/kendo-data-query';
import { DialogService, DialogCloseResult } from '@progress/kendo-angular-dialog';
import { SourceGridRowModel } from '../../models/source.models';
import { GridDataService } from '../../../../shared/services/grid-data.service';
import { GridColumnState, GridColumn } from '../../../../shared/models/grid.models';
import { SystemPermissions } from "../../../../shared/models/permissions.models";
import { PermissionService } from "../../../../shared/services/permission.service";

@Component({
  selector: 'app-source-grid',
  templateUrl: './source-grid.component.html',
  styleUrls: ['./source-grid.component.scss']
})
export class SourceGridComponent implements OnInit, ILoadable, IKendoGridComponent {
  hasLoaded: boolean;
  loading: boolean;
  systemPermissions: SystemPermissions;
  gridData: GridDataResult;
  filters: any;
  state: State = {
    skip: 0,
    take: 10
  };
  pageSizes: number[] = [5, 10, 20, 50, 100];

  columnState: GridColumnState = new GridColumnState([
    { name: 'Acronym', field: 'acronym', defaults: { width: 115, sort: { dir: 'asc' } } },
    { name: 'Source Name', field: 'name', defaults: { width: 144 } },
    { name: 'Document Ctrl. Email', field: 'documentControlEmailAddress', defaults: { width: 180 } },
    { name: 'Country', field: 'countryName', defaults: { width: 130 } },
    { name: 'Sub Con', field: 'isSubContractor', defaults: { width: 110 } },
    { name: 'Active Projects', field: 'projectNames', defaults: { width: 180 } },
    { name: 'Active', field: 'active', defaults: { width: 110 } },    
  ]);

  constructor(
    private dialogService: DialogService,
    private sourceService: SourceService,
    private gridDataService: GridDataService,
    private permissionService: PermissionService
  ) { }

  ngOnInit() {
    this.loading = true;
    this.systemPermissions = this.permissionService.getPermissions();

    this.gridDataService.initColumnState(this);
    this.gridDataService.applyCachedState(this.sourceService.gridName, this);

    this.updateGrid();
  }

  dataStateChange(state: DataStateChangeEvent): void {
    this.loading = true;
    this.state = state;
    this.updateGrid();
  }

  resetColumnState() {
    this.gridDataService.resetColumnState(this.sourceService.gridName, this);
  }

  resized(changes: KendoColumnResizeArgs[]) {
    let change = changes[0];
    let col = this.columnState.selected.find(c => c.field == change.column.field);
    col.width = change.newWidth;
    this.gridDataService.saveColumnState(this.sourceService.gridName, this, true);
  }

  archive(item: SourceGridRowModel) {
    this.showActionDialog('Archive', () => {
      this.sourceService.archive(item.id).subscribe(
        () => {
          this.updateGrid();
          this.showAlert('Done', 'Archived successfully')
        },
        () => this.showAlert('Warning', 'It looks like something went wrong')
      )
    });
  }

  delete(item: SourceGridRowModel) {
    this.showActionDialog('Delete', () => {
      this.sourceService.delete(item.id).subscribe(
        () => {
          this.updateGrid();
          this.showAlert('Done', 'Deleted successfully')
        },
        () => this.showAlert('Warning', 'It looks like something went wrong')
      )
    });
  }

  resetGrid() {
    this.loading = true;
    this.gridDataService.resetGrid(this.sourceService.gridName, this.sourceService.baseUrl, this)
      .subscribe(data => {
        this.gridData = data;
        this.loading = false;
      });
  }

  changePageSizes(pageSize: number){
    this.state.take = pageSize;
    this.updateGrid();
  }

  updateGrid() {
    this.sourceService.getGridData(this.state).pipe(
      finalize(() => this.hasLoaded = true))
      .subscribe(data => {
        this.gridData = data;
        this.loading = false;
      })
  }

  private showActionDialog(action: string, handler: () => void) {
    const dialog = this.dialogService.open({
      title: action,
      content: `Are you sure you want to ${action.toLowerCase()} this source?`,
      actions: [
        { text: 'Cancel' },
        { text: 'Continue', primary: true, handler: handler },
      ]
    });

    setTimeout(() => {
      $('kendo-dialog-actions button').last().focus();
    })

    dialog.result.subscribe((result: any) => {
      if (result instanceof DialogCloseResult) {
        console.log("close");
      } else {
        if (result.handler)
          result.handler();
      }
    });
  }

  private showAlert(title: string, content: string) {
    const dialog = this.dialogService.open({
      title: title,
      content: content,
      actions: [
        { text: 'OK', primary: true }
      ]
    });

    setTimeout(() => {
      $('kendo-dialog-actions button').last().focus();
    })
  }
}
