import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TemplateGridComponent } from '../components/template-grid/template-grid.component';
import { TemplateEditComponent } from '../components/template-edit/template-edit.component';
import { AuthGuard } from "../../../shared/utils/auth.guard";

const routes: Routes = [
  {
    path: 'setup',
    canActivate: [AuthGuard],
    children: [
      { path: 'templates', component: TemplateGridComponent },
      { path: 'templates/edit', component: TemplateEditComponent },
      { path: 'templates/:id/edit', component: TemplateEditComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TemplatesRouterModule { }
