import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DocumentGridComponent } from '../components/document-grid/document-grid.component';
import { DocumentEditComponent } from '../components/document-edit/document-edit.component';
import { AuthGuard } from "../../shared/utils/auth.guard";
import { DocumentImportComponent } from "../components/document-import/document-import.component";
import { DirtyRecordGuard } from '../../shared/modules/dirty-record/guards/dirty-record-guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    children: [
      { path: 'documents', component: DocumentGridComponent },
      { path: 'documents/edit', component: DocumentEditComponent, canDeactivate: [DirtyRecordGuard] },
      { path: 'documents/:id/edit', component: DocumentEditComponent, canDeactivate: [DirtyRecordGuard] },
      { path: 'documents/edit/:externalType', component: DocumentEditComponent, canDeactivate: [DirtyRecordGuard] },
      { path: 'documents/:id/edit/:externalType', component: DocumentEditComponent, canDeactivate: [DirtyRecordGuard] },
      { path: 'documents/import', component: DocumentImportComponent },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DocumentRouterModule { }
