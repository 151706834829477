
import {finalize} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import { ActivatedRoute } from '@angular/router';
import { AuditService } from '../../services/audit.service';
import { AuditEventGridFiltersModel } from '../../models/audit.models';
import { ILoadable } from '../../../../shared/components/loading/loading.models';
import { IKendoGridComponent, KendoColumnResizeArgs } from '../../../../shared/utils/kendo.utils';
import { GridColumnState, GridColumn } from '../../../../shared/models/grid.models';
import { GridDataService } from '../../../../shared/services/grid-data.service';
import { SystemPermissions } from "../../../../shared/models/permissions.models";
import { PermissionService } from "../../../../shared/services/permission.service";

@Component({
  selector: 'app-audit-log',
  templateUrl: './audit-log.component.html',
  styleUrls: ['./audit-log.component.scss']
})
export class AuditLogComponent implements OnInit, ILoadable, IKendoGridComponent {
  hasLoaded: boolean = false;
  loading: boolean = false;
  systemPermissions: SystemPermissions;
  gridData: GridDataResult;
  state: State = {
    skip: 0,
    take: 30
  };
  columnState: GridColumnState = new GridColumnState([
    { name: 'Time', field: 'time', defaults: { width: 156, sort: { dir: 'desc' } } },
    { name: 'User', field: 'userName', defaults: { width: 253 } },
    { name: 'IP Address', field: 'clientAddress', defaults: { width: 300 } },
    { name: 'Type', field: 'entityTypeDisplayName', defaults: { width: 264 } },
    { name: 'Name', field: 'entityName', defaults: { width: 264 } },
  ]);
  filters: AuditEventGridFiltersModel = new AuditEventGridFiltersModel();
  userId: number;

  constructor(
    private route: ActivatedRoute,
    private auditService: AuditService,
    private gridDataService: GridDataService,
    private permissionService: PermissionService
  ) { }

  ngOnInit() {
    this.loading = true;
    this.systemPermissions = this.permissionService.getPermissions();

    this.filters = {};

    this.route.params.subscribe(params => {
      let id = params['id'] as number;

      if (id) {
        this.userId = id;
        this.gridDataService.applyCachedState(this.auditService.gridNameUserFilter, this);
        this.filters.userId = id;
      } else {
        this.gridDataService.applyCachedState(this.auditService.gridName, this);
        this.filters = {};
      }

      this.gridDataService.initColumnState(this);

      this.updateGrid();
    });
  }

  dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.loading = true;
    this.updateGrid();
  }

  resetColumnState() {
    this.gridDataService.resetColumnState(this.userId ? this.auditService.gridNameUserFilter : this.auditService.gridName, this);
  }

  resized(changes: KendoColumnResizeArgs[]) {
    let change = changes[0];
    let col = this.columnState.selected.find(c => c.field == change.column.field);
    col.width = change.newWidth;
    this.gridDataService.saveColumnState(this.userId ? this.auditService.gridNameUserFilter : this.auditService.gridName, this, true);
  }

  resetGrid() {
    this.loading = true;
    this.gridDataService.resetGrid(this.userId ? this.auditService.gridNameUserFilter : this.auditService.gridName, this.auditService.baseUrl, this)
      .subscribe(data => {
        this.gridData = data;
        this.loading = false;
      });
  }

  updateGrid() {
    this.auditService.getGridData(this.state, this.filters, this.userId ? this.auditService.gridNameUserFilter : this.auditService.gridName).pipe(
      finalize(() => this.hasLoaded = true))
      .subscribe(e => {
        this.gridData = e;
        this.loading = false;
      });
  }
}
