import { ApiEntityModel } from '../../../shared/models/apiEntity.model';

export interface TemplateInstancePermissionsModel {
  canView: boolean;
  canEdit: boolean;
  canArchive: boolean;
  canDelete: boolean;
}

export interface TemplateGridRowModel {
  id: number;
  active: boolean;
  name: string;
  permissions: TemplateInstancePermissionsModel;
}

export class TemplateEditorModel {
  id: number | null;
  active: boolean = true;
  name: string;
  legalText: string;
  isSeajacksDefault: boolean;
  permissions: TemplateInstancePermissionsModel;
}

export class TemplateModel extends ApiEntityModel<number> {
  name: string;
}
