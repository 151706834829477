import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../../app.config';
import { GridDataService } from '../../../shared/services/grid-data.service';
import { State } from '@progress/kendo-data-query';
import { SourceEditorModel, ContactModel, CountryModel, ProjectModel } from '../models/source.models';
import { serialize } from '../../../shared/utils/object.utils';
import { IKendoGridComponent } from '../../../shared/utils/kendo.utils';

@Injectable()
export class SourceService {
  readonly baseUrl: string = `${AppConfig.settings.apiUrl}/sources`;
  readonly gridName: string = 'sources';

  constructor(
    private http: HttpClient,
    private gridData: GridDataService
  ) { }

  getGridData(state: State, filters?: any) {
    return this.gridData.getGridData(this.gridName, this.baseUrl, state, filters);
  }

  get(id: number) {
    const url = !!id ? `${this.baseUrl}/${id}/edit` : `${this.baseUrl}/edit`;
    return this.http.get<SourceEditorModel>(url);
  }

  update(source: SourceEditorModel) {
    const url = !!source.id ? `${this.baseUrl}/${source.id}/edit` : `${this.baseUrl}/edit`;
    return this.http.post(url, source);
  }

  archive(id: number) {
    return this.http.post(`${this.baseUrl}/${id}/archive`, null);
  }

  delete(id: number) {
    return this.http.post(`${this.baseUrl}/${id}/delete`, null);
  }

  getContactOptions(text: string = null) {
    let query = !!text ? `?searchText=${text}` : '';
    return this.http.get<ContactModel[]>(`${this.baseUrl}/edit/contactoptions${query}`);
  }

  getCountryOptions() {
    return this.http.get<CountryModel[]>(`${this.baseUrl}/edit/countryoptions`);
  }

  getProjectOptions(editor: SourceEditorModel) {
    return this.http.post<ProjectModel[]>(`${this.baseUrl}/edit/projectoptions`, editor);
  }
}
