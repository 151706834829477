import { NgModule } from '@angular/core';
import { SortableModule } from '@progress/kendo-angular-sortable';
import { CommonModule } from '@angular/common';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { GridModule } from '@progress/kendo-angular-grid';
import { DisableClosedDropdownKeyboardDirective } from '../directives/disable-closed-dropdown-keyboard.directive';
import { DragMultiselectComponent } from '../components/drag-multiselect/drag-multiselect.component';
import { PtKendoDropdownFilterComponent } from '../components/pt-kendo-dropdown-filter/pt-kendo-dropdown-filter.component';
import { PtKendoGridDirective } from '../directives/pt-kendo-grid/pt-kendo-grid.directive';
import { PtKendoGridComponent } from '../components/pt-kendo-grid/pt-kendo-grid.component';

@NgModule({
  imports: [
    CommonModule,
    SortableModule,
    DropDownsModule,
    GridModule
  ],
  declarations: [
    DragMultiselectComponent,
    DisableClosedDropdownKeyboardDirective,
    PtKendoDropdownFilterComponent,
    PtKendoGridDirective,
    PtKendoGridComponent
  ],
  exports: [
    DragMultiselectComponent,
    DisableClosedDropdownKeyboardDirective,
    PtKendoDropdownFilterComponent,
    PtKendoGridDirective,
    PtKendoGridComponent
  ]
})
export class PtKendoModule { }
