
import {finalize} from 'rxjs/operators';
import { Component, OnInit, Output, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { KendoDialogRef } from '../../../shared/utils/kendo.utils';
import { DialogService, DialogRef } from '@progress/kendo-angular-dialog';
import { ILoadable } from '../../../shared/components/loading/loading.models';
import { RevisionService } from '../../services/revision.service';
import { IRevisionArchivedEvent } from '../../models/revision.models';

@Component({
  selector: 'app-revision-archive',
  templateUrl: './revision-archive.component.html',
  styleUrls: ['./revision-archive.component.scss']
})
export class RevisionArchiveComponent implements OnInit, ILoadable {
  @Output('archived') onArchived: Subject<IRevisionArchivedEvent> = new Subject();
  @ViewChild('dialogContent', {static: false}) content;
  @ViewChild('dialogActions', {static: false}) actions;

  hasLoaded: boolean;
  loading: boolean;
  succeeded: boolean;
  title: string;
  subtitle: string;
  id: number;

  private dialogRef: DialogRef;

  constructor(
    private dialogService: DialogService,
    private revisionService: RevisionService
  ) { }

  ngOnInit() {
  }

  open(id: number, title: string, subtitle: string) {
    this.dialogRef = this.dialogService.open({
      title: 'Archive Revision',
      content: this.content,
      actions: this.actions
    });

    setTimeout(() => {
      $('kendo-dialog-actions button').last().focus();
    })

    this.succeeded = false;
    this.id = id;
    this.title = title;
    this.subtitle = subtitle;
  }

  submit() {
    this.loading = true;
    this.revisionService.archive(this.id).pipe(
      finalize(() => this.loading = false))
      .subscribe(res => {
        this.succeeded = true;
        this.onArchived.next({ latestRevision: res });
      }, err => { })
  }

  close() {
    this.succeeded = false;
    this.dialogRef.close();
    this.dialogRef.dialog.destroy();
  }
}
