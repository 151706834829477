import { Component, OnInit, ViewChild } from '@angular/core';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';

@Component({
  selector: 'app-transmittal-edit-issue-warning',
  templateUrl: './transmittal-edit-issue-warning.component.html',
  styleUrls: ['./transmittal-edit-issue-warning.component.scss']
})
export class TransmittalEditIssueWarningComponent implements OnInit {
  @ViewChild('viewDocuments', {static: false}) content;
  @ViewChild('dialogActions', {static: false}) actions;
  loading: boolean;
  noContactsSelected: boolean;
  partialContactsSelected: boolean;

  private dialogRef: DialogRef;

  constructor(private dialogService: DialogService
  ) { }

  ngOnInit() {
  }

  open(tickedContacts: number) {
    if (tickedContacts == 0){
      this.noContactsSelected = true;
    }
    else {
      this.partialContactsSelected = true;
    }

    this.dialogRef = this.dialogService.open({
      title: 'Warning!',
      content: this.content,
      actions: this.actions
    })
    this.loading = false;
  }

  close() {
    this.dialogRef.close();
    this.dialogRef.dialog.destroy();
  }
}
