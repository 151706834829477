import { Injectable } from '@angular/core';
import { AppConfig } from '../../../app.config';
import { HttpClient } from '@angular/common/http';
import { GridDataService } from '../../../shared/services/grid-data.service';
import { State } from '@progress/kendo-data-query';
import { RevisionReasonEditorModel } from '../models/revision-reason.models';
import { IKendoGridComponent } from '../../../shared/utils/kendo.utils';

@Injectable()
export class RevisionReasonService {
  readonly baseUrl: string = `${AppConfig.settings.apiUrl}/revisionreasons`;
  readonly gridName: string = 'revisionreasons';

  constructor(
    private http: HttpClient,
    private gridData: GridDataService
  ) { }

  getGridData(state: State, filters?: any) {
    return this.gridData.getGridData(this.gridName, this.baseUrl, state, filters);
  }

  get(id: number) {
    const url = !!id ? `${this.baseUrl}/${id}/edit` : `${this.baseUrl}/edit`;
    return this.http.get<RevisionReasonEditorModel>(url);
  }

  archive(id: number) {
    return this.http.post(`${this.baseUrl}/${id}/archive`, null);
  }

  delete(id: number) {
    return this.http.post(`${this.baseUrl}/${id}/delete`, null);
  }

  update(documentType: RevisionReasonEditorModel) {
    const url = !!documentType.id ? `${this.baseUrl}/${documentType.id}/edit` : `${this.baseUrl}/edit`;
    return this.http.post(url, documentType);
  }
}
