import { Component, OnInit, ViewChild, Output } from '@angular/core';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-briefcase-transmit-warning',
  templateUrl: './briefcase-transmit-warning.component.html',
  styleUrls: ['./briefcase-transmit-warning.component.scss']
})
export class BriefcaseTransmitWarningComponent implements OnInit {
  @Output() confirmSelected: Subject<number> = new Subject();

  @ViewChild('viewDocuments', {static: false}) content;
  @ViewChild('dialogActions', {static: false}) actions;

  loading: boolean;
  private dialogRef: DialogRef;

  constructor(
    private dialogService: DialogService,
    private router: Router

  ) { }

  ngOnInit() {
  }

  open() {
    this.dialogRef = this.dialogService.open({
      title: 'Warning!',
      content: this.content,
      actions: this.actions
    })
  }

  cancel() {
    this.dialogRef.close();
    this.dialogRef.dialog.destroy();
  }

  close() {
    this.dialogRef.close();
    this.dialogRef.dialog.destroy();

    this.confirmSelected.next();
  }
}
