
import {of as observableOf,  Subject ,  Observable ,  Subscriber } from 'rxjs';

import {take, mergeMap, finalize} from 'rxjs/operators';
import { Component, OnInit, ViewChild, Output } from '@angular/core';
import { RevisionService } from '../../services/revision.service';
import { DialogService } from '@progress/kendo-angular-dialog';
import { RevisionEditorModel, IRevisionAddedEvent, RevisionEditResponseModel } from '../../models/revision.models';
import { DocumentAddRevisionFormComponent } from '../document-add-revision-form/document-add-revision-form.component';
import { HttpErrorResponse } from '@angular/common/http';
import { KendoDialogRef } from '../../../shared/utils/kendo.utils';
import { DocumentIssuedWarningComponent } from '../document-issued-warning/document-issued-warning.component';

@Component({
  selector: 'app-document-add-revision',
  templateUrl: './document-add-revision.component.html',
  styleUrls: ['./document-add-revision.component.scss']
})
export class DocumentAddRevisionComponent implements OnInit {
  @Output('added') onAdded: Subject<IRevisionAddedEvent> = new Subject();
  @ViewChild('revisionDialogActions', {static: false}) actions;
  @ViewChild('issuedWarningDialog', {static: false}) issuedWarningDialog: DocumentIssuedWarningComponent;

  succeeded: boolean;
  loading: boolean;
  editMode: boolean = false;
  revisionId: number;

  private dialogRef: KendoDialogRef<DocumentAddRevisionFormComponent>;
  private formComponent: DocumentAddRevisionFormComponent;

  constructor(
    private dialogService: DialogService,
    private revisionService: RevisionService
  ) { }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
    this.dialogRef.dialog.destroy();
    this.succeeded = false;
  }

  open(documentId: number) {
    this.editMode = false;
    this.dialogRef = this.dialogService.open({
      title: 'Add Revision',
      content: DocumentAddRevisionFormComponent,
      actions: this.actions
    });

    setTimeout(() => {
      $('kendo-dialog-actions button').last().focus();
    })

    this.formComponent = this.dialogRef.content.instance;
    this.formComponent.editor.documentId = documentId;
  }

  edit(documentId: number, revisionId: number) {
    this.editMode = true;
    this.revisionId = revisionId;
    this.dialogRef = this.dialogService.open({
      title: 'Edit Revision',
      content: DocumentAddRevisionFormComponent,
      actions: this.actions
    });

    setTimeout(() => {
      $('kendo-dialog-actions button').last().focus();
    })

    this.formComponent = this.dialogRef.content.instance;
    this.formComponent.editor.documentId = documentId;
    this.formComponent.editor.id = revisionId;
  }

  save(editor: RevisionEditorModel): Observable<RevisionEditResponseModel> {
    return Observable.create((observer: Subscriber<RevisionEditResponseModel>) => {
      this.revisionService.edit(editor).pipe(
        finalize(() => this.formComponent.loading = this.loading = false))
        .subscribe(res => {
          this.succeeded = this.formComponent.succeeded = true;
          this.onAdded.next({ id: res.entityId, documentId: editor.documentId, title: editor.title, number: editor.revisionNumber, latestRevision: res.latestRevisionModel });
          observer.next(res)
        }, err => {
          if (err instanceof HttpErrorResponse && err.status == 412) {
            this.dialogRef.content.instance.validationResult = err.error;
          }

          observer.next(null);
        });
    });
  }

  private saveWithWarnings(): Observable<RevisionEditResponseModel> {
    this.formComponent.validationResult = null;
    this.formComponent.loading = this.loading = true;
    let editor = this.dialogRef.content.instance.editor;
    if (this.editMode == true) {
      editor.id = this.revisionId;
    }

    if (!editor.id)
      return this.save(editor);

    return this.revisionService.getTransmittals(editor.id).pipe(mergeMap((result) => {
      if (!result.hasTransmittals)
        return this.save(editor);

      return this.issuedWarningDialog
        .open(result,false).pipe(
        take(1), //Stops susbsribe being called multiple times if saved multiple times
        mergeMap((continued) => {
          return continued ? this.save(editor) : observableOf(null);
        }),);
    }));
  }

  submit() {
    this.saveWithWarnings().subscribe(res => {
      this.formComponent.loading = this.loading = false;

    });
  }
}
