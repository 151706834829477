import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { VesselGridComponent } from '../components/vessel-grid/vessel-grid.component';
import { VesselEditComponent } from '../components/vessel-edit/vessel-edit.component';
import { AuthGuard } from "../../../shared/utils/auth.guard";

const routes: Routes = [
  {
    path: 'setup',
    canActivate: [AuthGuard],
    children: [
      { path: 'vessels', component: VesselGridComponent },
      { path: 'vessels/edit', component: VesselEditComponent },
      { path: 'vessels/:id/edit', component: VesselEditComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class VesselsRouterModule { }
