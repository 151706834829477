import { Component, OnInit, Output, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { TransmittalOptionsModel, IBriefcaseItem } from '../../../models/briefcase.models';
import { DocumentService } from '../../../services/document.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-briefcase-transmit-dialog',
  templateUrl: './briefcase-transmit-dialog.component.html',
  styleUrls: ['./briefcase-transmit-dialog.component.scss']
})
export class BriefcaseTransmitDialogComponent implements OnInit {
  @Output() success: Subject<any> = new Subject();
  @Output() cancelled: Subject<any> = new Subject();
  @ViewChild('dialogContent', {static: false}) content;
  @ViewChild('dialogActions', {static: false}) actions;

  hasLoaded: boolean;
  loading: boolean;
  title: string;
  subtitle: string;
  addSucceeded: boolean;
  createdSucceeded: boolean;
  currentTab: string = "Seajack's Transmittals";
  transmitChoiceSelected: boolean = false;
  createNewSelected: boolean = false;
  createNewExternalSelected: boolean = false;
  addToExistingSelected: boolean = false;
  addToExistingExternalSelected: boolean = false;
  transmittalOptions: TransmittalOptionsModel[] = [];
  externalTransmittalOptions: TransmittalOptionsModel[] = [];
  transmittalId: number;
  externalTransmittalId: number;
  selectedItems: IBriefcaseItem[] = [];

  private dialogRef: DialogRef;

  constructor(
    private dialogService: DialogService,
    private documentService: DocumentService,
    private router: Router
  ) { }

  ngOnInit() {
  }

  open(ids: any, items: any) {
    this.selectedItems = items;

    this.documentService.getSeajacksTransmittalOptions().subscribe(options => {
      this.transmittalOptions = options
    });

    this.documentService.getExternalTransmittalOptions().subscribe(options => {
      this.externalTransmittalOptions = options
    });

    this.dialogRef = this.dialogService.open({
      title: 'Transmit',
      content: this.content,
      actions: this.actions,
      width: 500,
      height: 355
    });

  }

  cancel() {
    this.cancelled.next();
    this.close();
  }

  close() {
    this.createdSucceeded = false;
    this.addSucceeded = false;
    this.dialogRef.close();
    this.dialogRef.dialog.destroy();
    this.resetVariables();
  }

  returnToDefaultOptions() {
    this.transmittalId = null;
    this.externalTransmittalId = null;
    this.transmitChoiceSelected = false;
    this.createNewSelected = false;
    this.addToExistingSelected = false;
    this.createNewExternalSelected = false;
    this.addToExistingExternalSelected = false;
  }

  // 4 transmit options below - selecting one option will set that options' flag to true and reset all other options' flags to false
  // Additionally transmitChoiceSelected is used to determine when the confirmation button is disabled or not
  createNewSjTransmittal() {
    this.transmitChoiceSelected = true;
    this.createNewSelected = true;
    this.addToExistingSelected = false;
    this.createNewExternalSelected = false;
    this.addToExistingExternalSelected = false;
  }

  addToExistingSjTransmittal() {
    this.transmitChoiceSelected = false;
    this.createNewSelected = false;
    this.addToExistingSelected = true;
    this.createNewExternalSelected = false;
    this.addToExistingExternalSelected = false;
  }

  createNewExternalTransmittal() {
    this.transmitChoiceSelected = true;
    this.createNewSelected = false;
    this.addToExistingSelected = false;
    this.createNewExternalSelected = true;
    this.addToExistingExternalSelected = false;
  }

  addToExistingExternalTransmittal() {
    this.transmitChoiceSelected = false;
    this.createNewSelected = false;
    this.addToExistingSelected = false;
    this.createNewExternalSelected = false;
    this.addToExistingExternalSelected = true;
  }

  onTransmittalSelectFilterChanged(searchText: string) {
    this.documentService.getSeajacksTransmittalOptions(searchText).subscribe(options => {
      this.transmittalOptions = options
    });
  }

  onExternalTransmittalSelectFilterChanged(searchText: string) {
    this.documentService.getExternalTransmittalOptions(searchText).subscribe(options => {
      this.externalTransmittalOptions = options
    });
  }

  transmittalSelectionChanged(id: number) {
    this.transmitChoiceSelected = true;
  }

  onTabSelected(event: any) {
    this.currentTab = event.title;
    this.resetVariables();
  }

  submit() {
    this.loading = true;

    if (this.currentTab === "Seajack's Transmittals") {
      // seajacks transmittals
      if (this.createNewSelected == true) {
        // add to new transmittal
        var ids = "";
        this.selectedItems.forEach(item => {
          ids = ids + item.id.toString() + '+';
        });
        let url = `/transmittals/edit?transmittalid=${ids}`;
        this.router.navigateByUrl(url);
      }
      else {
        // add to existing transmittal
        var ids = "";
        this.selectedItems.forEach(item => {
          ids = ids + item.id.toString() + '+';
        });
        let url = '/transmittals/' + this.transmittalId + `/edit?transmittalid=${ids}`;
        this.router.navigateByUrl(url);
      }
    }
    else {
      // external transmittals
      if (this.createNewExternalSelected == true) {
        // add to new external transmittal
        var ids = "";
        this.selectedItems.forEach(item => {
          ids = ids + item.latestRevisionId.toString() + '+';
        });
        let url = `/transmittals/external?transmittalid=${ids}`;
        this.router.navigateByUrl(url);
      }
      else {
        // add to existing external transmittal
        let ids: number[] = [];
        this.selectedItems.forEach(item => {
          ids.push(item.latestRevisionId);
        });
        this.bulkAddToExternalTransmittal(ids);
        let url = '/transmittals/' + this.externalTransmittalId + `/add-documents`;
        this.router.navigateByUrl(url);
      }
    }
  }

  private bulkAddToExternalTransmittal(documentRevisionIds: number[]) {
    this.documentService.bulkAddToExternalTransmittal(documentRevisionIds, this.externalTransmittalId).subscribe(options => {
      this.transmittalOptions = options
    });
  }

  private resetVariables(){
    this.transmittalId = null;
    this.externalTransmittalId = null;
    this.transmitChoiceSelected = false;
    this.createNewSelected = false;
    this.createNewExternalSelected = false;
    this.addToExistingSelected = false;
    this.addToExistingExternalSelected = false;
  }
}
