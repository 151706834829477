import { Component, OnInit, OnDestroy, Input, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import * as Clipboard from 'clipboard';
declare let $;

@Component({
  selector: 'app-clipboard-button',
  templateUrl: './clipboard-button.component.html',
  styleUrls: ['./clipboard-button.component.scss']
})
export class ClipboardButtonComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('copyButton', {static: false}) copyButton: ElementRef;
  @Input() target: string;

  supported: boolean = true;

  private clipboard: Clipboard;
  private trigger: any;
  private readonly defaultTriggerId = '#button-copy';

  constructor() { }

  ngOnInit() {
    this.supported = (Clipboard as any).isSupported();
  }

  ngAfterViewInit(): void {
    this.trigger = !!this.copyButton ? this.copyButton.nativeElement : this.defaultTriggerId;

    this.clipboard = new Clipboard(this.trigger, {
      target: (trigger) => { return document.getElementById(this.target); }
    });
  }

  ngOnDestroy() {
    this.clipboard.destroy();
  }

  copy() {
    $(this.trigger).tooltip({
      trigger: 'manual',
      placement: 'right',
      title: 'Copied!'
    });
    $(this.trigger).tooltip('show');
    setTimeout(() => {
      $(this.trigger).tooltip('hide');
    }, 2000);
  }
}
