import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {
  title: string = 'Ooops!';
  message: string = 'Something went wrong'
  restrictedMessage: boolean = false;

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params["title"])
        this.title = params["title"];
      
      if (params["message"])
        if (params["message"] == 'Restricted content')
          this.restrictedMessage = true;
        else
          this.message= params["message"];
    })
  }

}
