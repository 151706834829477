import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SourceGridComponent } from '../components/source-grid/source-grid.component';
import { SourceEditComponent } from '../components/source-edit/source-edit.component';
import { AuthGuard } from "../../../shared/utils/auth.guard";
import { DirtyRecordGuard } from '../../../shared/modules/dirty-record/guards/dirty-record-guard';

const routes: Routes = [
  {
    path: 'setup',
    canActivate: [AuthGuard],
    children: [
      { path: 'sources', component: SourceGridComponent },
      { path: 'sources/edit', component: SourceEditComponent, canDeactivate: [DirtyRecordGuard] },
      { path: 'sources/:id/edit', component: SourceEditComponent, canDeactivate: [DirtyRecordGuard] }
    ]
  }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SourcesRouterModule { }
