export class AuditEventGridRowModel {
  time: string;
  userName: string;
  clientAddress: string;
  entityTypeDisplayName: string;
  entityId: number;
  entityName: string;
  actionName: string;
  displayTime: Date;
}

export class AuditEventGridFiltersModel {
  userId?: number;
}
