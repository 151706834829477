
import {of as observableOf, throwError as observableThrowError, forkJoin as observableForkJoin,  Subject ,  Observable } from 'rxjs';

import {finalize, mergeMap,  catchError } from 'rxjs/operators';
import { Component, OnInit, Input, Output, SimpleChanges } from '@angular/core';
import {
  ExternalTransmittalDocumentsEditorModel, ExternalTransmittalDocumentLinkEditorModel, TransmittalEditorModel,
  ExternalTransmittalInstancePermissionsModel
} from '../../models/transmittal.models';
import { State } from '@progress/kendo-data-query';
import { DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { DocumentModel } from '../../../documents/models/document.models';
import { TransmittalExternalAddDocumentService } from '../../services/transmittal-external-add-document.service';
import { ValidationResultModel } from '../../../shared/models/validation.models';
import { HttpErrorResponse } from '@angular/common/http';
import { IRevisionAddedEvent, RevisionGridFiltersModel, RevisionModel } from '../../../documents/models/revision.models';
import { element } from 'protractor';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'app-transmittal-external-add-document-grid',
  templateUrl: './transmittal-external-add-document-grid.component.html',
  styleUrls: ['./transmittal-external-add-document-grid.component.scss'],
  animations: [
    trigger(
      'enterAnimation', [
        transition(':enter', [
          style({ transform: 'translateX(10%)', opacity: 0 }),
          animate('500ms', style({ transform: 'translateX(0)', opacity: 1 }))
        ]),
        transition(':leave', [
          style({ opacity: 1 }),
          animate('500ms', style({  opacity: 0 }))
        ])
      ]
    )
  ],
})
export class TransmittalExternalAddDocumentGridComponent implements OnInit {
  @Input() editor: ExternalTransmittalDocumentsEditorModel;
  @Output() change: Subject<ExternalTransmittalDocumentLinkEditorModel[]> = new Subject();
  @Output('added') onAdded: Subject<IRevisionAddedEvent> = new Subject();

  permissions: ExternalTransmittalInstancePermissionsModel;
  updated: boolean;
  gridUpdated: boolean;
  validationResult: ValidationResultModel;
  hasLoaded: boolean;
  loading: boolean;
  state: State = {
    skip: 0,
    take: 10,
    sort: [
      { dir: "desc", field: "documentId" }
    ]
  };
  gridData: ExternalTransmittalDocumentLinkEditorModel[] = [];
  filters?: RevisionGridFiltersModel = new RevisionGridFiltersModel();

  constructor(
    private transmittalExternalAddDocumentService: TransmittalExternalAddDocumentService
  ) { }

  ngOnInit() {
    this.loading = true;
    this.gridData = this.editor.documentLinks;
    this.permissions = this.editor.permissions;

    this.refreshGridRevisionOptions();

  }

  refreshGridRevisionOptions() {
    let obs = this.gridData.map(rev => {
      return this.transmittalExternalAddDocumentService
        .getRevisionOptions(rev.documentId, this.editor.transmittalId).pipe(
        mergeMap(options => {
          rev.revisionOptions = this.filterRevisionOptions(rev.documentId, options, rev.revisionId);
          return observableOf(true);
        }));
    });

    observableForkJoin(obs).pipe(finalize(() => {
      this.hasLoaded = true;
    })).subscribe();
  }

  onCompleted(documentId: number) {

  }

  add(document: DocumentModel, editor: ExternalTransmittalDocumentsEditorModel) {
    this.loading = true;

    this.transmittalExternalAddDocumentService.getRevisionOptions(document.id, this.editor.transmittalId).subscribe(options => {
      let link = new ExternalTransmittalDocumentLinkEditorModel();
      link.id = document.id;
      link.documentId = document.id;
      link.revisionDocumentIdentifier = document.identifier;
      link.revisionOptions = this.filterRevisionOptions(document.id, options);
      link.revisionId = link.revisionOptions[0].id;

      this.editor.documentLinks.push(link);

      this.saveDocuments().subscribe(res => {
        this.refreshGridRevisionOptions();

        this.gridUpdated = true;

        this.change.next(this.gridData);
        setTimeout(() => {
          this.gridUpdated = false;
        }, 2000);
      });
    });
  }

  dataStateChange(state: DataStateChangeEvent): void {
    this.loading = true;
    this.state = state;
  }

  revChanged() {
    this.saveDocuments().subscribe(res => {
      this.gridUpdated = true;
      setTimeout(() => {
        this.gridUpdated = false;
      }, 2000);
    });
  }

  remove(row: ExternalTransmittalDocumentLinkEditorModel) {
    let i = this.gridData.indexOf(row);
    this.gridData.splice(i, 1);


    this.saveDocuments()

      .subscribe(res => {
        this.gridUpdated = true;
        this.change.next(this.gridData);

        if (this.editor.documentLinks.some(e => e.documentId == row.documentId)) {
          this.transmittalExternalAddDocumentService
            .getRevisionOptions(row.documentId, this.editor.transmittalId)
            .subscribe(options => {
              this.updateSelectedDocumentRevisionOptions(row.documentId, options);
            })
        }

        setTimeout(() => {
          this.gridUpdated = false;
        }, 2000);
      });
  }

  onRevisionAdded($event: IRevisionAddedEvent, document: ExternalTransmittalDocumentLinkEditorModel) {
    this.updateGrid(document);
    this.onAdded.next($event);

    this.saveDocuments().subscribe(res => {
      this.gridUpdated = true;
      setTimeout(() => {
        this.gridUpdated = false;
      }, 2000);
    });
  }

  updateGrid(document: ExternalTransmittalDocumentLinkEditorModel) {
    let i = this.gridData.indexOf(document);
    this.transmittalExternalAddDocumentService.getRevisionOptions(document.id, this.editor.transmittalId).subscribe(options => {
      let link = new ExternalTransmittalDocumentLinkEditorModel();
      link.id = document.id;
      link.revisionDocumentIdentifier = this.gridData[i].revisionDocumentIdentifier;
      link.revisionOptions = options;
      link.revisionId = link.revisionOptions[0].id;

      this.gridData[i] = link;
    });
  }

  saveDocuments(): Observable<ValidationResultModel> {
    this.validationResult = null;
    this.loading = true;
    return this.transmittalExternalAddDocumentService.update(this.editor).pipe(
      finalize(() => {
        this.loading = false;
      }),
      mergeMap(result => {
        this.updated = true;
        return observableOf(result);
      })).pipe(catchError(err => {
        if (err instanceof HttpErrorResponse && err.status == 412) {
          this.validationResult = err.error;
        }
        return observableThrowError(err);
      }))
  }

  goToDocEdit(link: ExternalTransmittalDocumentLinkEditorModel) {
    window.open(`/documents/${link.documentId}/edit`, '_blank');
  }

  private updateSelectedDocumentRevisionOptions(documentId: number, options: RevisionModel[]) {
    this.editor.documentLinks
      .filter(e => e.documentId == documentId)
      .forEach(l => {
        l.revisionOptions = this.filterRevisionOptions(documentId, options, l.revisionId);
      });
  }

  private filterRevisionOptions(documentId: number, options: RevisionModel[], selectedRevisionId?: number): RevisionModel[] {
    let docSelected = this.editor.documentLinks.some(e => e.documentId == documentId);
    let filtered = !docSelected ? options : options.filter(option => !this.editor.documentLinks.map(d => d.revisionId).includes(option.id));

    if (!!selectedRevisionId && !filtered.some(e => e.id == selectedRevisionId))
      filtered.unshift(options.find(e => e.id == selectedRevisionId));

    return filtered;
  }
}
