import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProjectGridComponent } from '../components/project-grid/project-grid.component';
import { ProjectEditComponent } from '../components/project-edit/project-edit.component';
import { AuthGuard } from "../../../shared/utils/auth.guard";
import { DirtyRecordGuard } from '../../../shared/modules/dirty-record/guards/dirty-record-guard';

const routes: Routes = [
  {
    path: 'setup',
    canActivate: [AuthGuard],
    children: [
      { path: 'projects', component: ProjectGridComponent },
      { path: 'projects/edit', component: ProjectEditComponent, canDeactivate: [DirtyRecordGuard] },
      { path: 'projects/:id/edit', component: ProjectEditComponent, canDeactivate: [DirtyRecordGuard] },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProjectsRouterModule { }
