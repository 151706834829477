
import {combineLatest as observableCombineLatest,  Observable ,  Subject } from 'rxjs';
import { Component, OnInit, ViewChild, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { GridComponent, GridDataResult } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import { DialogService } from '@progress/kendo-angular-dialog';
import { KendoDialogRef } from '../../../shared/utils/kendo.utils';
import { GridDataService } from '../../../shared/services/grid-data.service';
import { BriefcaseComponent, IBriefcaseEvent } from '../briefcase/briefcase/briefcase.component';
import { DocumentGridRowModel } from '../../models/document.models';
import { DeletedEvent } from '../../../shared/models/events';
import { DocumentVoidComponent } from '../document-void/document-void.component';
import { DocumentService } from '../../services/document.service';
import { GridColumnState, KendoGridDataConfig } from '../../../shared/models/grid.models';
import { IRevisionAddedEvent } from '../../models/revision.models';
import { SystemPermissions } from '../../../shared/models/permissions.models';
import { PermissionService } from '../../../shared/services/permission.service';
import { IBriefcaseItem } from '../../models/briefcase.models';
import { TransmittalAcknowledgeService } from '../../../transmittals/services/transmittal-acknowledge.service';
import gridConfig from './document-grid.config';
import { PtKendoGridDirective } from '../../../kendo/directives/pt-kendo-grid/pt-kendo-grid.directive';
import { finalize, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-document-grid',
  templateUrl: './document-grid.component.html',
  styleUrls: ['./document-grid.component.scss']
})
export class DocumentGridComponent implements OnInit, OnDestroy {
  @ViewChild('briefcase', {static: false}) briefcase: BriefcaseComponent;
  @ViewChild('docGrid', {static: false}) grid: GridComponent;
  @ViewChild('ptDocGrid', {static: false}) ptDocGrid: PtKendoGridDirective;

  onDestroy: Subject<any> = new Subject();

  hasLoaded = true;
  systemPermissions: SystemPermissions;
  isImsFilteredMode = false;
  isLinkedFilteredMode = false;
  isTransmittalLinksFilteredMode = false;
  isExternalTransmittalLinksFilteredMode = false;
  filteredId: number;
  transmittalId: number;
  transmittalName: string;
  title: string;
  pageSizes: number[] = [5, 10, 15, 20, 50, 100];
  filters?: any;
  gridData: GridDataResult;
  allSelected: boolean;
  columns = gridConfig.columns;
  gridBaseUrl: string;
  gridName: string;
  state: State = {
    skip: 0,
    take: 15,
    sort: gridConfig.defaultSort,
    filter: gridConfig.defaultFilter
  };
  previousSkip: number;
  exportDataLoading: boolean;

  get selectedColumnOptions(): any[] {
    return this.columns.filter(e => !e.hidden && !!e.title);
  }

  private voidDialogRef: KendoDialogRef<DocumentVoidComponent>;
  private defaultTitle = 'Documents';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dialogService: DialogService,
    private documentService: DocumentService,
    private permissionService: PermissionService,
    private transmittalAcknowledgeService: TransmittalAcknowledgeService
  ) {
    this.gridBaseUrl = documentService.baseUrl;
    this.gridName = documentService.gridName;
  }

  ngOnInit() {
    this.hasLoaded = false;
    this.title = this.defaultTitle;

    let queryParams: Params;

    observableCombineLatest([this.route.params, this.route.queryParams], (params, qParams) => ({ params, qParams }))
      .subscribe(result => {
        queryParams = result.qParams;
      });

    if (queryParams['transmittal']) {
      this.transmittalId = queryParams['transmittal'];
      this.isTransmittalLinksFilteredMode = true;

      this.transmittalAcknowledgeService
        .getTransmittalIdentifier(this.transmittalId)
        .pipe(takeUntil(this.onDestroy))
        .subscribe(data => {
          this.transmittalName = data.identifier;
          this.title = this.isTransmittalLinksFilteredMode ? this.transmittalName + ' - Added Documents' : this.defaultTitle;
          this.hasLoaded = true;
        });
    } else if (queryParams['externalTransmittal']) {
      this.transmittalId = queryParams['externalTransmittal'];
      this.isExternalTransmittalLinksFilteredMode = true;

      this.transmittalAcknowledgeService
        .getExternalTransmittalIdentifier(this.transmittalId)
        .pipe(takeUntil(this.onDestroy))
        .subscribe(data => {
          this.transmittalName = data.identifier;
          this.title = this.isExternalTransmittalLinksFilteredMode ? this.transmittalName + ' - Added Documents' : this.defaultTitle;
          this.hasLoaded = true;
        });
    } else {
      this.hasLoaded = true;
    }

    this.systemPermissions = this.permissionService.getPermissions();

    if (this.systemPermissions.documents.canViewLargeGridSize) {
      this.pageSizes = [5, 10, 15, 20, 50, 100, 500, 2000];
    }
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  gridInit(data: GridDataResult) {
    this.updateGrid();
  }

  dataChanged() {
    this.updateBriefcaseSelections();
  }

  resetGrid() {
    if (this.isTransmittalLinksFilteredMode || this.isExternalTransmittalLinksFilteredMode) {
      this.title = this.defaultTitle;
      this.transmittalName = null;
      this.isTransmittalLinksFilteredMode = false;
      this.isExternalTransmittalLinksFilteredMode = false;
      this.router.navigateByUrl('/documents');
    }

    if (this.isLinkedFilteredMode) {
      this.isLinkedFilteredMode = false;
      this.filteredId = null;
    } else if (this.isImsFilteredMode) {
      this.isImsFilteredMode = false;
      this.filteredId = null;
    }

    this.ptDocGrid.setDataConfig(this.documentService.getGridData());
    this.ptDocGrid
      .resetGrid({ state: true, otherFilters: true })
      .subscribe(data => {
        this.briefcase.getItems()
          .pipe(takeUntil(this.onDestroy))
          .subscribe(items => {
            this.updateSelected(data.data, items);
          });
      });
  }

  resetColumnState() {
    this.ptDocGrid
      .resetGrid({ columns: true })
      .subscribe();
  }

  exportGridData() {
    this.exportDataLoading = true;

    this.ptDocGrid.exportGridData().subscribe({
      next: () => {
        this.exportDataLoading = false;
      },
      error: () => {
        this.exportDataLoading = false;
      },
    });
  }

  allSelectedChanged($event: any) {
    const items = this.gridData.data.map((dataItem: DocumentGridRowModel) => {
      return dataItem;
    });
    this.briefcase.toggleItems(items, { updateAll: true, addAll: this.allSelected });
  }

  selectedChanged($event: any, dataItem: DocumentGridRowModel, all: boolean = false) {
    this.briefcase.toggleItems([dataItem]);
  }

  edit(item: DocumentGridRowModel) {
    let url = `/documents/${item.id}/edit`;
    if (item.isExternal) {
      url += `/${item.categoryId}`;
    }
    this.router.navigateByUrl(url);
  }

  onRevisionAdded($event: IRevisionAddedEvent) {
    this.updateGrid();
  }

  onVoided() {
    this.updateGrid();
  }

  onDeleted($event: DeletedEvent) {
    if (this.briefcase.contains($event.id)) {
      this.briefcase.toggleItems([{ id: $event.id } as any]);
    }

    this.updateGrid();
  }

  onBriefcaseChange($event: IBriefcaseEvent) {
    if (this.gridData) {
      this.updateSelected(this.gridData.data, $event.items);
    }

    if ($event.dataChanged) {
      this.updateGrid();
    }
  }

  showLinkedDocuments(item: DocumentGridRowModel) {
    if (!this.isLinkedFilteredMode) {
      this.previousSkip = this.state.skip;
      this.state.skip = 0;
      this.isLinkedFilteredMode = true;
      const gridConfig = this.documentService.getLinkedGridDataConfig(item.id, true);
      this.ptDocGrid.setDataConfig(gridConfig, true);
      this.ptDocGrid
        .updateGrid()
        .subscribe((data) => {
          // workaround for issue where selecting an item in the grid and then switching to linkedfiltered mode would have the
          // item be in the basket but not be selected on the grid
          this.briefcase.items.forEach(element => {
            const index = this.gridData.data.findIndex(r => r.id === element.id);
            if (index !== -1) {
              this.gridData.data[index].selected = true;
            }
          });

          this.filteredId = item.id;
        });
    } else {
      let gridConfig: KendoGridDataConfig;
      this.isLinkedFilteredMode = false;
      this.state.skip = this.previousSkip;
      this.previousSkip = 0;
      if (this.isTransmittalLinksFilteredMode) {
        gridConfig = this.documentService.getTransmittalLinksGridDataConfig(this.transmittalId);
      } else if (this.isExternalTransmittalLinksFilteredMode) {
        gridConfig = this.documentService.getExternalTransmittalLinksGridDataConfig(this.transmittalId);
      } else {
        gridConfig = this.documentService.getGridData();
      }
      this.ptDocGrid.setDataConfig(gridConfig, true);
      this.ptDocGrid
        .updateGrid()
        .subscribe((data) => {
          // workaround for issue where selecting an item in linkedfiltered mode and then returning to normal mode would have the
          // item be in the basket but not be selected on the grid
          this.briefcase.items.forEach(element => {
            const index = this.gridData.data.findIndex(r => r.id === element.id);
            if (index !== -1) {
              this.gridData.data[index].selected = true;
            }
          });

          this.filteredId = null;
        });
    }
  }

  showImsLinkedDocuments(item: DocumentGridRowModel) {
    if (!this.isImsFilteredMode) {
      this.previousSkip = this.state.skip;
      this.state.skip = 0;
      const gridConfig = this.documentService.getImsChildGridDataConfig(item.id);
      this.isImsFilteredMode = true;
      this.ptDocGrid.setDataConfig(gridConfig, true);
      this.ptDocGrid.state.skip = 0;
      this.ptDocGrid
        .updateGrid()
        .subscribe((data) => {
          // workaround for issue where selecting an item in the grid and then going into imsfitlered mode would have the
          // item be in the basket but not be selected on the grid
          this.briefcase.items.forEach(element => {
            const index = this.gridData.data.findIndex(r => r.id === element.id);
            if (index !== -1) {
              this.gridData.data[index].selected = true;
            }
          });

          this.filteredId = item.id;
        });
    } else {
      this.state.skip = this.previousSkip;
      this.previousSkip = 0;
      let gridConfig: KendoGridDataConfig;
      this.isImsFilteredMode = false;
      if (this.isTransmittalLinksFilteredMode) {
        gridConfig = this.documentService.getTransmittalLinksGridDataConfig(this.transmittalId);
      } else if (this.isExternalTransmittalLinksFilteredMode) {
        gridConfig = this.documentService.getExternalTransmittalLinksGridDataConfig(this.transmittalId);
      } else {
        gridConfig = this.documentService.getGridData();
      }
      this.ptDocGrid.setDataConfig(gridConfig);
      this.ptDocGrid.updateGrid()
        .pipe(takeUntil(this.onDestroy))
        .subscribe((data) => {
          // workaround for issue where selecting an item in imsfiltermode and then returning to normal mode would have the
          // item be in the basket but not be selected on the grid
          this.briefcase.items.forEach(element => {
            const index = this.gridData.data.findIndex(r => r.id === element.id);
            if (index !== -1) {
              this.gridData.data[index].selected = true;
            }
          });

          this.filteredId = null;
        });
    }
  }

  updateGrid() {
    let gridConfig: KendoGridDataConfig;

    if (this.isTransmittalLinksFilteredMode) {
      gridConfig = this.documentService.getTransmittalLinksGridDataConfig(this.transmittalId);
    } else if (this.isExternalTransmittalLinksFilteredMode) {
      gridConfig = this.documentService.getExternalTransmittalLinksGridDataConfig(this.transmittalId);
    } else if (this.isImsFilteredMode) {
      gridConfig = this.documentService.getImsChildGridDataConfig(this.filteredId);
    } else if (this.isLinkedFilteredMode) {
      gridConfig = this.documentService.getLinkedGridDataConfig(this.filteredId, true);
    } else {
      gridConfig = this.documentService.getGridData();
    }

    this.ptDocGrid.setDataConfig(gridConfig, true);
    this.ptDocGrid.updateGrid()
      .pipe(takeUntil(this.onDestroy))
      .pipe(finalize(() => {
        this.allSelected = false;
        this.hasLoaded = true;
      }))
      .subscribe(data => {
        this.updateBriefcaseSelections();
      });
  }

  private updateBriefcaseSelections() {
    this.briefcase.getItems().subscribe(items => {
      this.updateSelected(this.gridData.data, items);
      this.briefcase.updateItemValues(this.mapToBriefcaseItems(this.gridData.data.filter(item => item.selected)));
    });
  }

  private updateSelected(dataItems: DocumentGridRowModel[], briefcaseItems: IBriefcaseItem[]) {
    dataItems.forEach(dataItem => {
      dataItem.selected = !!briefcaseItems.find(e => e.id === dataItem.id);
    });

    if (!briefcaseItems.length || !dataItems.some(e => e.selected)) {
      this.allSelected = false;
    }
  }

  private mapToBriefcaseItems(dataItems: DocumentGridRowModel[]) {
    return dataItems
      .map(item => {
        return item;
      });
  }
}
