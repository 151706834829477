import { Component, OnInit, ViewChild } from '@angular/core';
import { ErrorEvent, FileRestrictions, SuccessEvent, UploadComponent } from '@progress/kendo-angular-upload';
import { ValidationResultModel } from '../../../shared/models/validation.models';
import { Router } from '@angular/router';
import { DocumentImportService } from '../../services/document-import.service';
import { DocumentImportConfirmationComponent } from '../document-import-confirmation/document-import-confirmation.component';
import { DocumentImportResponseModel } from '../../models/document.models';
import { HistoricalDocumentDeleteAllImportComponent } from '../../../import/components/historical-document-delete-all-import/historical-document-delete-all-import.component';
import {AppConfig} from '../../../app.config';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';

@Component({
  selector: 'app-document-import',
  templateUrl: './document-import.component.html',
  styleUrls: ['./document-import.component.scss']
})
export class DocumentImportComponent implements OnInit {
  @ViewChild('fileUploader', {static: false}) fileUploader: UploadComponent;
  @ViewChild('confirmationDialog', {static: false}) confirmationDialog: DocumentImportConfirmationComponent;
  @ViewChild('deleteDialog', {static: false}) deleteDialog: HistoricalDocumentDeleteAllImportComponent;

  hasLoaded: boolean;
  loading: boolean;

  validationResult: ValidationResultModel;

  fileUploadVisible: boolean;
  fileUploadUrl: string;
  fileUploadRestrictions: FileRestrictions = {
    allowedExtensions: ['.xlsx']
  };

  constructor(
    private router: Router,
    private documentImportService: DocumentImportService,
    private http: HttpClient
  ) { }

  ngOnInit() {
    this.fileUploadUrl = this.documentImportService.getUploadUrl();
  }

  public onDownloadClicked() {
    this.downloadFile().subscribe();
  }

  public downloadFile(): Observable<HttpResponse<Blob>> {
    let headers = new HttpHeaders();
    headers = headers.set(
      'Accept',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    );
    const url = `${AppConfig.settings.apiUrl}/documents/download/bulk-import/template`;

    return new Observable<any>((subscriber) =>
      // observe: 'response' to return the HttpResponse instead of just the content, so we can access the filename
      this.http
        .get(url, {
          headers,
          observe: 'response',
          responseType: 'blob',
        })
        .subscribe({
          next: (res) => {
            const filename = res.headers.get('file-name');
            const file = new Blob([res.body], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
            const fileURL = URL.createObjectURL(file);

            const a = document.createElement('a');
            a.href = fileURL;
            a.download = filename;

            // Must be done like this (rather than a.Click()) to support browsers other than Chrome
            a.dispatchEvent(
              new MouseEvent('click', {
                bubbles: true,
                cancelable: true,
                view: window,
              })
            );
            a.remove();

            subscriber.next(res);
          },
          error: (err) => {
            subscriber.error(err);
          },
        })
    );
  }

  onFileUploadSucceeded(e: SuccessEvent) {
    this.validationResult = null;
    this.confirmationDialog.open(e.response.body as DocumentImportResponseModel);
  }

  onFileUploadError(e: ErrorEvent) {
    this.validationResult = e.response.body as ValidationResultModel;
  }

  showFileUpload() {
    this.fileUploadVisible = true;
    setTimeout(() => this.fileUploader.fileSelect.nativeElement
      .setAttribute('accept', this.fileUploadRestrictions.allowedExtensions.join(',')));
  }

  hideFileUpload() {
    this.fileUploadVisible = false;
  }

  openDeleteDialog() {
    this.deleteDialog.open();
  }

  goBack() {
    this.router.navigateByUrl('/documents');
  }
}
