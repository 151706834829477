import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { GridModule } from '@progress/kendo-angular-grid';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../shared/modules/shared.modules';
import { TemplateGridComponent } from '../components/template-grid/template-grid.component';
import { TemplateEditComponent } from '../components/template-edit/template-edit.component';
import { TemplateService } from '../services/template.service';
import { TemplatesRouterModule } from './templates.router.module';
import { FormsModule } from '@angular/forms';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { DialogModule } from '@progress/kendo-angular-dialog';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    GridModule,
    ButtonsModule,
    DialogModule,
    InputsModule,
    SharedModule
  ],
  declarations: [
    TemplateGridComponent,
    TemplateEditComponent
  ],
  providers: [
    TemplateService
  ],
  exports: [
    TemplatesRouterModule
  ]
})
export class TemplatesModule { }