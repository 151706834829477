import { Injectable } from '@angular/core';
import { AppConfig } from "../../app.config";
import { HttpClient } from "@angular/common/http";
import { GridDataService } from "../../shared/services/grid-data.service";
import { DocumentGridRowModel, DocumentImportGridRowModel, DocumentDeleteImportResponse } from '../models/document.models';
import { Observable } from "rxjs";

@Injectable()
export class DocumentImportService {
  readonly baseUrl: string = `${AppConfig.settings.apiUrl}/documents/imports`;

  constructor(
    private http: HttpClient,
    private gridData: GridDataService) { }

  getUploadUrl(): string {
    return `${this.baseUrl}/upload`;
  }

  getImportsGridData() {
    return this.gridData.getGridData<DocumentImportGridRowModel[]>('', `${this.baseUrl}`, {});
  }

  getImportDocuments(id: number): Observable<DocumentGridRowModel[]> {
    return this.http.get<DocumentGridRowModel[]>(`${this.baseUrl}/${id}/documents`);
  }

  deleteImport(id: number, voidUsedDocuments: boolean) {
    return this.http.delete(`${this.baseUrl}/${id}/delete?voidUsedDocuments=${voidUsedDocuments}`);
  }

}
