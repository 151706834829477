import { Component, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { DialogService } from "@progress/kendo-angular-dialog";
import { KendoDialogRef } from "../../../shared/utils/kendo.utils";
import { TransmittalGridRowModel } from "../../../transmittals/models/transmittal.models";
import { Subject ,  Observable } from "rxjs";
import { DocumentTransmittalsModel } from "../../models/document.models";

@Component({
  selector: 'app-document-issued-warning',
  templateUrl: './document-issued-warning.component.html',
  styleUrls: ['./document-issued-warning.component.scss']
})
export class DocumentIssuedWarningComponent implements OnInit {
  @Output('continued') continued: Subject<boolean> = new Subject();
  @ViewChild('dialogContent', {static: false}) content;
  @ViewChild('dialogActions', {static: false}) actions;
  @ViewChild('dialogContainer', { static: false, read: ViewContainerRef }) container;

  transmittals: DocumentTransmittalsModel;
  isDocument: boolean;

  private dialogRef: KendoDialogRef<DocumentIssuedWarningComponent>;

  constructor(
    private dialogService: DialogService) { }

  ngOnInit() { }

  open(transmittals: DocumentTransmittalsModel, isDocument: boolean): Observable<boolean> {
    this.transmittals = transmittals;
    this.isDocument = isDocument;

    this.dialogRef = this.dialogService.open({
      title: (this.isDocument ? 'Document' : 'Revision') + ' Issued',
      content: this.content,
      appendTo: this.container,
      actions: this.actions
    });

    setTimeout(() => {
      $('kendo-dialog-actions button').last().focus();
    });

    return this.continued;
  }

  continue() {
    this.dialogRef.close();
    this.dialogRef.dialog.destroy();
    this.continued.next(true);
  }

  close() {
    this.dialogRef.close();
    this.dialogRef.dialog.destroy();
    this.continued.next(false);
  }
}
