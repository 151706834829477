import {ApiEntityModel} from '../../../shared/models/apiEntity.model';

export class UserInstancePermissionsModel {
  canView: boolean;
  canEdit: boolean;
}

export class UserGridRowModel {
  id: number;
  login: string;
  forename: string;
  surname: string;
  activeDirectoryCreationTime: Date;
  roles: string;
  disabled: boolean;
  permissions: UserInstancePermissionsModel;
}

export class UserGridFiltersModel {
  roleIds: number[] = [];
  permissionIds: number[] = [];
}

export class UserModel extends ApiEntityModel<number> {
  username: string;
  activeDirectoryCreationTime: Date;
  accessExpiryTime: Date | null;
  disabled: boolean;
  emailAddress: string;
}

export class UserEditorModel {
  id: number;
  username: string;
  forename: string;
  surname: string;
  active: boolean;
  roles: number[] = [];
  permissions: UserInstancePermissionsModel;
  isSelf: boolean;
}

export class RoleModel extends ApiEntityModel<number> {
  tag: string;
  name: string;

  selected: boolean;
}

export class PermissionModel extends ApiEntityModel<number> {
  name: string;
  selected: boolean;
}
