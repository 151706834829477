import { ApiEntityModel } from '../../shared/models/apiEntity.model';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { IDocumentFilenameModel, DocumentGridRowModel } from '../../documents/models/document.models';

export interface  HistoricalDocumentInstancePermissionsModel {
  canCreate: boolean;
  canDelete: boolean;
}

export interface HistoricalDocumentGridRowModel {
  id: number;
  documentId: number | null;
  importFileName: string;
  importDate: string;
  importId: number;
  categoryAcronym: string;
  categoryName: string;
  projectAcronym: string;
  projectName: string;
  type: string;
  controlNumber: string;
  source: string;
  vessels: string;
  revisionIdentifier: string;
  permissions: HistoricalDocumentInstancePermissionsModel;
  identifier: string;
  reference: string;
  externalReference: string;
  externalDocumentNumber: string;
  receivedFilename: string;
  filename: string;
  filePath: string;
  revisionTitle: string;
  revisionDate: string;  
  revisionDocumentDate: string;
}

export interface HistoricalImportGridRowModel {
  date: string;
  filename: string;
  id: number;
  username: string;
  worksheetNumber: number;
}


export class HistoricalDocumentDetailModel extends ApiEntityModel<number> {
  name: string;
  value: string;
}

export interface HistoricalDocumentImportResponseModel {
  documentCount: number;
}

export interface HistoricalDocumentWorksheetModel {
  index: number;
  name: string;
  headings: string[];
}

export interface HistoricalDocumentSaveResponseModel {
  id: number;
  worksheets: HistoricalDocumentWorksheetModel[];
}

export interface HistoricalDocumentColumnMappingModel {
  column: number;
  property: string;
}

export class HistoricalDocumentImportRequestModel {
  fileId: number;
  worksheetIndex: number;
  columnMappings: HistoricalDocumentColumnMappingModel[] = [];
  categoryId: string;
  projectId: number | null;
}