import { Component, OnInit, Input } from '@angular/core';
import { BaseFilterCellComponent, FilterService } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';

@Component({
  selector: 'pt-kendo-dropdown-filter',
  template: `
    <kendo-dropdownlist 
      class="width-100"
      [value]="selectedValue"
      [valuePrimitive]="true" 
      [data]="data" 
      [textField]="textField" 
      [valueField]="valueField"
      [defaultItem]="defaultItem"
      (valueChange)="onChange($event)">
    </kendo-dropdownlist>
  `
})
export class PtKendoDropdownFilterComponent extends BaseFilterCellComponent implements OnInit {
  @Input() filter: CompositeFilterDescriptor;
  @Input() data: any[];
  @Input() textField: string;
  @Input() valueField: string;
  @Input() filterPropertyField: string;

  get selectedValue(): any {
    const filter = this.filterByField(this.filterPropertyField);
    return filter ? filter.value : null;
  }

  get defaultItem(): any {
    return {
      [this.textField]: "(All)",
      [this.valueField]: null
    };
  }

  constructor(filterService: FilterService) {
    super(filterService);
  }

  ngOnInit() { }

  onChange(value: any): void {
    let descriptor = value === null || value === ''
      ? this.removeFilter(this.filterPropertyField)
      : this.updateFilter({
        field: this.filterPropertyField,
        operator: "eq",
        value: value
      });
  }
}
