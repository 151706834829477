import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { State } from '@progress/kendo-data-query';
import { Observable } from 'rxjs';
import { AppConfig } from '../../app.config';
import { GridDataService } from '../../shared/services/grid-data.service';
import { ApiOption } from '../../shared/models/base.models';
import { KendoGridDataConfig } from '../../shared/models/grid.models';
import { VoidTransmittalModel } from '../models/transmittal.models';

@Injectable()
export class TransmittalService {
  readonly baseUrl: string = `${AppConfig.settings.apiUrl}/transmittals`;
  readonly gridName: string = 'transmittals';

  constructor(
    private http: HttpClient,
    private gridData: GridDataService
  ) { }

  getGridDataConfig(): KendoGridDataConfig {
    return {
      name: this.gridName,
      url: this.baseUrl
    };
  }

  getAcknowledgementStateFilterOptions(): Observable<ApiOption<string>[]> {
    const url = `${this.baseUrl}/grid/data/acknowledgementstatefilteroptions`;
    return this.http.get<ApiOption<string>[]>(url);
  }

  getLinkedGridData(state: State, id: number) {
    const gridName = `${id}/linked`;
    return this.gridData.getGridData(gridName, `${this.baseUrl}/${gridName}`, state);
  }

  getEventGridData(state: State, id: number) {
    const gridName = `${id}/events`;
    return this.gridData.getGridData(gridName, `${this.baseUrl}/${gridName}`, state);
  }

  void(model: VoidTransmittalModel): Observable<any> {
    return this.http.post(`${this.baseUrl}/${model.id}/void`, model);
  }

  delete(id: number): Observable<any> {
    return this.http.post(`${this.baseUrl}/${id}/delete`, null);
  }

  approve(ids: number[]): Observable<any> {
    return this.http.post(`${this.baseUrl}/bulkapprove`, ids);
  }

  link(ids: number[]): Observable<any> {
    return this.http.post(`${this.baseUrl}/link`, ids);
  }

  unlink(ids: number[]): Observable<any> {
    return this.http.post(`${this.baseUrl}/unlink`, ids);
  }

  getDownloadLink(id: number): string {
    return !!id ? `${this.baseUrl}/${id}/download` : null;
  }

  getPreviewLink(id: number): string {
    return !!id ? `${this.baseUrl}/${id}/preview` : null;
  }
}
