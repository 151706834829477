import { Component, OnInit, ViewChild } from '@angular/core';
import { ILoadable } from '../../../shared/components/loading/loading.models';
import { UploadComponent, FileRestrictions, SelectEvent, UploadEvent, SuccessEvent } from '@progress/kendo-angular-upload';
import { HistoricalDocumentService } from '../../services/historical-document.service';
import { HistoricalDocumentSaveResponseModel, HistoricalDocumentWorksheetModel, HistoricalDocumentImportRequestModel, HistoricalDocumentColumnMappingModel } from '../../models/historical-document.models';
import { ApiOption } from '../../../shared/models/base.models';
import { DocumentEditorService } from '../../../documents/services/document-editor.service';
import { DocumentCategoryModel } from '../../../documents/models/document.models';
import { ProjectModel } from '../../../setup/sources/models/source.models';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { IValidatable, ValidationResultModel } from '../../../shared/models/validation.models';

@Component({
  selector: 'app-import-documents',
  templateUrl: './import-documents.component.html',
  styleUrls: ['./import-documents.component.scss']
})
export class ImportDocumentsComponent implements OnInit, ILoadable, IValidatable {
  @ViewChild('fileUploader', {static: false}) fileUploader: UploadComponent;

  hasLoaded: boolean;
  loading: boolean;
  succeeded: boolean;
  validationResult: ValidationResultModel;
  fileUploaded: boolean;

  fileUploadUrl: string;
  uploadRestrictions: FileRestrictions = {
    allowedExtensions: [".xlsx"]
  };

  worksheets: HistoricalDocumentWorksheetModel[] = [];
  propertyOptions: ApiOption<string>[] = [];
  categoryOptions: DocumentCategoryModel[] = [];
  projectOptions: ProjectModel[] = [];
  showProjectInput: boolean = false;

  selectedWorksheet: HistoricalDocumentWorksheetModel;
  columnMappings = [];
  editor: HistoricalDocumentImportRequestModel = new HistoricalDocumentImportRequestModel();

  constructor(
    private router: Router,
    private historicalDocService: HistoricalDocumentService
  ) { }

  ngOnInit() {
    setTimeout(() => this.fileUploader.fileSelect.nativeElement.setAttribute("accept", this.uploadRestrictions.allowedExtensions.join(",")));
    this.fileUploadUrl = this.historicalDocService.getSaveUrl();
    this.historicalDocService.getPropertyOptions().subscribe(options => this.propertyOptions = options);
    this.historicalDocService.getCategoryOptions().subscribe(options => this.categoryOptions = options);
    this.historicalDocService.getProjectOptions().subscribe(options => this.projectOptions = options);
  }

  onFileSelected(e: SelectEvent): void {
    this.fileUploaded = false;

    e.files.forEach((file) => {
      if (!file.validationErrors) {
        let reader = new FileReader();
        reader.onload = (ev) => {
          let image = {
            src: (<any>ev.target).result,
            uid: file.uid
          };
          // that.imagePreviews.unshift(image);
        };

        reader.readAsDataURL(file.rawFile);
      }
    });
  }

  onFileUploadSucceeded(e: SuccessEvent) {
    let result = e.response.body as HistoricalDocumentSaveResponseModel;
    console.log(result);

    this.worksheets = result.worksheets;

    this.editor.fileId = result.id;

    if (!this.editor.categoryId) {
      this.editor.categoryId = this.categoryOptions[0].id;
      this.categoryChanged(this.editor.categoryId);
    }

    this.fileUploaded = true;
  }

  onFileUploaded(e: UploadEvent) { }

  onFileCompleted(e: any) { }

  onFileUploadFailed(e: any){
    if(e.response.status == 412){
      throw new Error('No worksheets found within file, check that file type is correct.');
    }
  }

  categoryChanged(categoryId: string) {
    this.showProjectInput = categoryId == "project";

    if (!this.showProjectInput)
      this.editor.projectId = null;
  }

  onProjectFilterChanged(searchText: any) {
    this.historicalDocService.getProjectOptions(searchText).subscribe(options => this.projectOptions = options);
  }

  worksheetValueChanged(worksheet: HistoricalDocumentWorksheetModel) {
    this.editor.worksheetIndex = worksheet.index;
    this.columnMappings = [];
    this.editor.columnMappings = [];

    this.populateSuggestedColumnMappings();
  }

  columnValueChanged(value: string, property: ApiOption<string>) {
    let index = this.selectedWorksheet.headings.indexOf(value);

    if (index == -1 && value != "Not Mapped")
      throw new Error('Column value does not exist');

    let column = index + 1;
    let mapping = this.editor.columnMappings.find(e => e.property == property.value);


    if (!mapping)
      this.editor.columnMappings.push({ column: column, property: property.value });
    else
      mapping.column = column;
  }

  submit() {
    this.loading = true;
    this.validationResult = null;
    window.scrollToTop();
    this.historicalDocService.import(this.editor).subscribe(res => {
      this.succeeded = true;
      setTimeout(() => {
        this.router.navigateByUrl('/historical/documents');
      }, 3000);
    }, err => {
      this.loading = false;
      if (err instanceof HttpErrorResponse && err.status == 412) {
        this.validationResult = err.error;
      }
    })
  }

  goBack(){
    this.router.navigateByUrl('/historical/documents');
  }

  private populateSuggestedColumnMappings() {
    this.selectedWorksheet.headings.forEach(e => {
      this.propertyOptions.forEach((p, i) => {
        if (this.matchColumn(e, p.name)) {
          this.columnValueChanged(e, p);
          this.columnMappings[i] = e;
        }
      })
    })
  }

  private matchColumn(s1: string, s2: string): boolean {
    let a = s1.toLowerCase().indexOf(s2.toLowerCase()) != -1 || s2.toLowerCase().indexOf(s1.toLowerCase()) != -1;
    if (a) return true;
    let s1NoWhitespace = s1.replace(/\s/g, '').toLowerCase();
    if (s2 == "Identifier")
      s2 = "Document Number";
    if (s2 == "Revision Identifier")
      s2 = "Revision Number";
    let s2NoWhitespace = s2.replace(/\s/g, '').toLowerCase();
    let b = s1NoWhitespace.indexOf(s2NoWhitespace) != -1 || s2NoWhitespace.indexOf(s1NoWhitespace) != -1;
    return b;
  }
}
